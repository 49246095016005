import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { RESET_PASSWORD_ACTION_BEFORE, RESET_PASSWORD_ACTION_AFTER } from "../../actionTypes";

const setResetPasswordActionBefore = () => ({
	type: RESET_PASSWORD_ACTION_BEFORE
});

const setResetPasswordActionFinished = () => ({
	type: RESET_PASSWORD_ACTION_AFTER
});

export const resetPassword = (token, newPassword, confirmPassword, onSuccess) => {
	return (dispatch) => {
		dispatch(setResetPasswordActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.RESET_PASSWORD}`,
			method: Methods.POST,
			data: { token, newPassword, confirmPassword }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					onSuccess && onSuccess();
				}
				dispatch(setResetPasswordActionFinished());
			})
			.catch((ex) => {
				dispatch(setResetPasswordActionFinished());
			});
	};
};

export const setPassword = (token, newPassword, confirmPassword, onSuccess) => {
	return (dispatch) => {
		dispatch(setResetPasswordActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PASSWORD}`,
			method: Methods.POST,
			data: { newPassword, confirmPassword },
			headers: {
				Authorization: "Bearer " + token
			}
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					onSuccess && onSuccess();
				}
				dispatch(setResetPasswordActionFinished());
			})
			.catch((ex) => {
				dispatch(setResetPasswordActionFinished());
			});
	};
};
