import { GAME_TYPE, PENALTY_SCENE_INFO } from "constants/game.constants";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const noGoalColor = "#D9D9D9";
const goalColor = "#178217";

const PenaltyTooltipTitle = ({ scene }) => {
	const { t } = useTranslation();

	const getRectColor = (enumArg) => {
		return enumArg === scene.enumValue ? goalColor : noGoalColor;
	}

	const renderGoalSector = (enumValue) => ` - ${(Math.log2(enumValue) + 1)}`;
	const renderNoGoal = (enumValue) => {
		switch (enumValue) {
			case PENALTY_SCENE_INFO.NOGOAL_MISS_SHOOT:
				return ` - ${t(`markets.${GAME_TYPE.PENALTY_SHOOTOUT}.OutOfGoals`)}`
			case PENALTY_SCENE_INFO.NOGOAL_SAVE:
				return ` - ${t(`markets.${GAME_TYPE.PENALTY_SHOOTOUT}.GoalSaves`)}`;
			case PENALTY_SCENE_INFO.NOGOAL_WOODWORK:
				return ` - ${t(`markets.${GAME_TYPE.PENALTY_SHOOTOUT}.GoalWoodworks`)}`;
			default:
				return null;
		}
	};

	const standartColor = getRectColor(null);

	return (
		<div className="vs--flex vs--flex-col vs--justify-center vs--align-center">
			{
				scene.hasGoal
					? (
						<svg width="59" height="42" viewBox="0 0 59 42" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2.45654 7.95967C2.45654 6.27871 3.81923 4.91602 5.50019 4.91602V36.8743H2.45654V7.95967Z" fill={standartColor} />
							<path d="M54.1985 4.91602C55.8794 4.91602 57.2421 6.2787 57.2421 7.95967V36.8743H54.1985V4.91602Z" fill={standartColor} />
							<rect width="3.04365" height="48.6984" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 54.1985 7.95508)" fill={standartColor} />
			
							<rect x="8.54382" y="11.0039" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_TOP_LEFT_SPECTER)} />
							<rect x="23.7621" y="11.0039" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_TOP_CENTRE_SPECTER)} />
							<rect x="38.9805" y="11.0039" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_TOP_RIGHT_SPECTER)} />
			
							<rect x="8.54382" y="20.1367" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_CENTRE_LEFT_SPECTER)} />
							<rect x="23.7621" y="20.1367" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_CENTRE_CENTRE_SPECTER)} />
							<rect x="38.9805" y="20.1367" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_CENTRE_RIGHT_SPECTER)} />
			
							<rect x="8.54382" y="29.2637" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_BOTTOM_LEFT_SPECTER)} />
							<rect x="23.7621" y="29.2637" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_BOTTOM_CENTRE_SPECTER)} />
							<rect x="38.9805" y="29.2637" width="12.1746" height="6.0873" fill={getRectColor(PENALTY_SCENE_INFO.GOAL_BOTTOM_RIGHT_SPECTER)} />
						</svg>
					)
					: null
			}
			<span>
				{
					scene.hasGoal
						? (
							<Fragment>
								<span className="vs--match-result-penalty-tooltip-text">
									{t('cashier.ticketGoal')}
								</span>
								{renderGoalSector(scene.enumValue)}
							</Fragment>
						)
						: (
							<Fragment>
								<span className="vs--match-result-penalty-tooltip-text">
									{t('cashier.noGoal')}
								</span>
								{renderNoGoal(scene.enumValue)}
							</Fragment>
						)
				}
			</span>
		</div>
	)
}

PenaltyTooltipTitle.propTypes = {
	scene: PropTypes.object
}

export default PenaltyTooltipTitle