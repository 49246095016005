import  { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalWrapper from "components/modalWrapper";
import { isSeasonGame } from "utils/common";
import { GAME_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";
import hotKeyType from "types/hotKey.type";

/** Hot Keys Guide Modal Component */
const HotKeysGuide = ({ coordinates, onCancel, hotkeys, currentGameType }) => {
	const { t } = useTranslation();

	/** Function to get hotkeys depend on current game type
	 * @function
	 * @returns {array} - hotkeys
	 * @memberOf HotKeysGuide
	 */
	const getHotkeys = (gameType) => {
		if (gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH) {
			return hotkeys["footballSingleMatch"];
		} else if (gameType === GAME_TYPE.HORSE_RACING) {
			return hotkeys["horseRaceFlat"];
		} else if(gameType === GAME_TYPE.HORSE_STEEPLECHASING) {
			return hotkeys["horseRaceSteeplechasing"]
		} else if (gameType === GAME_TYPE.GREYHOUNDS_RACE) {
			return hotkeys["greyhoundRace"];
		}
		return [];
	};
	const IgnoredGames = [GAME_TYPE.KENO, ...Object.values(GAME_TYPE).filter((gt) => isSeasonGame(gt))];
	const subTabs = useMemo(() => {
		return Object.values(GAME_TYPE)
			.filter((type) => !IgnoredGames.includes(type))
			.map((type) => ({
				key: type,
				element: t(`common.${GAME_TYPE_TEXT_KEYS[type]}`)
			}));
	}, []);

	return (
		<ModalWrapper
			coordinates={coordinates}
			title={t("cashier.hotkeyGuide")}
			classNames={{ content: "vs--hot-key" }}
			onCancel={onCancel}
			visible={true}
			modalContentBodyStyles={{ padding: "0 12px" }}
			subTabs={subTabs}
			activeSubTabKey={!IgnoredGames.includes(currentGameType) ? currentGameType : null}
			modalContentPreWrapper={
				<div className="vs--hotkeyGuide vs--flex-col">
					<div className="vs--pt-24 vs--pb-24 vs--pl-24 vs--hotkeyGuide-keyboard-title">
						<b className="vs--title vs--font-condensed vs--font-small vs--font-medium">{t("cashier.keyboardShortcuts")}</b>
					</div>
					<div className="vs--hotkeyGuide-keyboard vs--flex vs--justify-between vs--pl-24">
						<div className="vs--hotkeyGuide-content vs--flex">
							<div className="vs--flex vs--flex-col vs--hotkeyGuide-content-stake">
								<b className="vs--mb-32">{t("cashier.minimalStake")}</b>
								<b>{t("cashier.betWithStake")}</b>
							</div>
							<div className="vs--flex vs--flex-col vs--text-right">
								<span className="vs--mb-32">
									{t("cashier.code")} + <span className="vs--hotkeyGuide-content-key">Enter</span>
								</span>
								<span>
									{t("cashier.code")} + {t("cashier.stake")} + <span className="vs--hotkeyGuide-content-key">Enter</span>
								</span>
							</div>
						</div>
						<div className="vs--hotkeyGuide-content vs--flex">
							<div className="vs--flex vs--flex-col">
								<b className="vs--mb-32">{t("cashier.deleteLastBet")}</b>
								<b>{t("cashier.emptyBetslip")}</b>
							</div>
							<div className="vs--mb-32 vs--flex vs--flex-col vs--text-right">
								<span className="vs--mb-32">
									<span className="vs--hotkeyGuide-content-key">Ctrl</span> + <span className="vs--hotkeyGuide-content-key">Backspace</span>
								</span>
								<span>
									<span className="vs--hotkeyGuide-content-key">Ctrl</span> + <span className="vs--hotkeyGuide-content-key">Shift</span> + <span className="vs--hotkeyGuide-content-key">backspace</span>
								</span>
							</div>
						</div>
						<div className="vs--hotkeyGuide-content vs--flex">
							<div className="vs--flex vs--flex-col vs--hotkeyGuide-content-stake">
								<b>{t("cashier.printTicket")}</b>
							</div>
							<div className="vs--flex vs--flex-col vs--text-right">
								<span>
									<span className="vs--hotkeyGuide-content-key">Ctrl</span> + <span className="vs--hotkeyGuide-content-key">Enter</span>
								</span>
							</div>
						</div>
						<div></div>
					</div>
					<div className="vs--hotkeyGuide-codes vs--pt-24 vs--pb-24 vs--pl-24">
						<b className="vs--title vs--font-condensed vs--font-small vs--font-medium">{t("cashier.hotkeyCodes")}</b>
					</div>
				</div>
			}
		>
			{({ activeSubTab }) => (
				<div className="vs--modal-wrapper">
					<div className="vs--modal-table vs--flex vs--flex-col">
						<div className="vs--modal-table-head vs--flex vs--flex-row vs--align-center vs--justify-between vs--title vs--font-medium vs--font-small">
							<div data-type="market">
								<span>{t("cashier.market")}</span>
							</div>
							<div data-type="hotkeyCode">
								<span>{t("cashier.hotkeyCode")}</span>
							</div>
							<div data-type="usageExample">
								<span>{t("cashier.usageExample")}</span>
							</div>
							<div data-type="description">
								<span>{t("cashier.description")}</span>
							</div>
						</div>
						<div className="vs--modal-table-body vs--mb-14">
							{getHotkeys(activeSubTab).map((key, index) => (
								<div className={"vs--modal-table-row vs--flex vs--flex-row vs--align-center vs--justify-between s--title vs--font-regular" + (index !== 0 ? " vs--mt-2" : "")} key={key.market}>
									<div data-type="market">
										<span>{key.market}</span>
									</div>
									<div data-type="hotkeyCode">
										<b className="vs--font-regular">{key.hotKeyCode}</b>
									</div>
									<div data-type="usageExample">
										<span>{key.usageExample}</span>
									</div>
									<div data-type="description">
										<span title={key.description}>{key.description}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</ModalWrapper>
	);
};

/** HotKeysGuide propTypes
 * PropTypes
 */
HotKeysGuide.propTypes = {
	/** React property, css variables` viewport x, y coordinates */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** Function that will be called on Hot Keys Guide modal close */
	onCancel: PropTypes.func,
	/** Redux state property, hotkeys array */
	hotkeys: PropTypes.objectOf(PropTypes.arrayOf(hotKeyType)),
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		hotkeys: state.auth.session.hotKeys,
		currentGameType: state.game.currentGameType
	};
};

export default connect(mapStateToProps, null)(HotKeysGuide);
