import  { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Report from "components/ui/report";

import { getTurnoverReport } from "store/actions/report/report.actions";

import turnoverType from "types/turnover.type";

/** Report print view page Component */
const ReportPage = ({ sessionLoaded, getTurnoverReport, turnover }) => {
	/** Load Turnover Report */
	useEffect(() => {
		getTurnoverReport();
	}, []);

	return sessionLoaded && (turnover?.report?.footballSingleMatch ?? {}).balance !== undefined ? <Report /> : <Fragment />;
};

/** ReportPage propTypes
 * PropTypes
 */
ReportPage.propTypes = {
	/** Redux state property, is true when session is loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, current session */
	turnover: turnoverType,
	/** Redux action to get turnover report */
	getTurnoverReport: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sessionLoaded: state.auth.sessionLoaded,
		turnover: state.report.turnover
	};
};

const mapDispatchToProps = (dispatch) => ({
	getTurnoverReport: () => {
		dispatch(getTurnoverReport());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
