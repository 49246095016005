import axios from 'axios';

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import {
	JACKPOT_BONUS_BEFORE,
	JACKPOT_BONUS_AFTER,
	SET_JACKPOT_BONUS,
	UPDATE_JACKPOT_BONUS,
	SET_WON_JACKPOT_BONUSES
} from '../../actionTypes';

const setJackpotBonusBefore = () => ({
	type: JACKPOT_BONUS_BEFORE
})

const setJackpotBonusAfter = () => ({
	type: JACKPOT_BONUS_AFTER
})

const setJackpotBonus = jackpotInfo => ({
	type: SET_JACKPOT_BONUS,
	payload: { jackpotInfo }
})

export const updateJackpotBonus = (updatedData) => ({
	type: UPDATE_JACKPOT_BONUS,
	payload: { updatedData }
})

export const setWonJackpotBonuses = (wonJackpotBonuses) => ({
	type: SET_WON_JACKPOT_BONUSES,
	payload: { wonJackpotBonuses }
})

export const getActiveJackpot = (fromStream) => {
	return dispatch => {
		dispatch(setJackpotBonusBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_ACTIVE_JACKPOT}`,
			method: Methods.GET,
			headers: fromStream ? { stream: true } : {}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setJackpotBonus(data));
			})
			.finally((ex) => {
				dispatch(setJackpotBonusAfter());
			})
	}
}