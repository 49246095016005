import { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import { DATE_TIME_FORMAT } from "constants/date.constants";

import Loader from "components/ui/loader";
import NoData from "components/ui/noData";

import { setTicketConfirmation } from "store/actions/tickets/confirmation.actions";

import useIntegrationType from "hooks/useIntegrationType";

import { TICKETS_LIST_TYPE, TICKET_CONFIRMATION_MODAL_TYPE, PROJECT_TYPE } from "constants/common.constants";
import { JACKPOT_LEVELS_ICONS, JACKPOT_LEVELS_NAMES } from "constants/bonus.constants";

import sessionType from "types/session.type";

import { makeCurrencyText } from "utils/common";

/** Tickets List Component */
const WonJackpots = ({
	betslipId,
	className,
	jackpots,
	isLoading,
	session,
	setTicketConfirmation,
	onCancel
}) => {
	const { t } = useTranslation();
	const integrationType = useIntegrationType();

	/** Function, fires on ticket payout button click
	 * @function
	 * @param {number} ticketId - ticket Id to payout
	 * @memberOf WonJackpots
	 */
	const handleTicketPayout = (ticketId, amount, jackpotLevelType) => {
		setTicketConfirmation({ type: TICKET_CONFIRMATION_MODAL_TYPE.JACKPOT_PAYOUT, ticketId, amount, jackpotLevelType });
		onCancel();
	};

	/** Function which Renders row
	 * @function
	 * @param {object} item - the item to show in row
	 * @param {number} index - the row index
	 * @returns {JSX}
	 * @memberOf TicketsList
	 */
	const renderRow = (item) => {
		return (
			<div key={item.levelType} className={"vs--modal-table-row vs--flex vs--flex-row vs--align-center vs--justify-between vs--font-regular vs--pr-16 vs--pl-2"}>
				<div className="vs--ml-16" data-type="jackpotId">
					<span title={betslipId}>{betslipId}</span>
				</div>
				<div data-type="jackpotLevel">
					<img src={JACKPOT_LEVELS_ICONS[`level-${item.levelType}`]} />
					<span className="vs--ml-8">{t(JACKPOT_LEVELS_NAMES[item.levelType])}</span>
				</div>
				<div data-type="jackpotPoolName">
					<span>{item.poolName || "-"}</span>
				</div>
				<div data-type="jackpotAmount">
					<span title={makeCurrencyText(item.amount, session.currency)}>{makeCurrencyText(item.amount, session.currency)} </span>
				</div>
				<div data-type="jackpotWindate">
					<span title={item.winTime ? moment.utc(item.winTime).local().format(DATE_TIME_FORMAT) : "-"}>{item.winTime ? moment.utc(item.winTime).local().format(DATE_TIME_FORMAT) : "-"}</span>
				</div>
				<div data-type="actions" className="vs--flex vs--align-center vs--justify-end">
					{!item.payoutTime && integrationType !== PROJECT_TYPE.IFRAME ? (
						<button
							type="button"
							className="vs--ticket-bet-button vs--ticket-bet-action-button vs--ticket-bet-action-button-payout vs--button vs--modal-table-button vs--font-smallest vs--font-medium"
							onClick={() => handleTicketPayout(betslipId, makeCurrencyText(item.amount, session.currency), item.levelType)}
							title={t("cashier.payout")}
						>
							<span>{t("cashier.payout")}</span>
						</button>
					) : null}
				</div>
			</div>
		);
	};

	return (
		<Fragment>
			{!isLoading ? (
				jackpots.length > 0 ? (
					<div className="vs--modal-wrapper vs--flex-equal">
						<div
							className="vs--flex vs--flex-col vs--tickets-table-won-jackpots"
						>
							<div className="vs--modal-table-head vs--flex vs--flex-row vs--align-center vs--justify-between vs--font-medium vs--font-small">
								<div className="vs--modal-table-head-column" data-type="jackpotId">
									<span title={t("common.id")}>{t("common.id")}</span>
								</div>
								<div className="vs--modal-table-head-column" data-type="jackpotLevel">
									<span title={t("cashier.jackpotLevel")}>{t("cashier.jackpotLevel")}</span>
								</div>
								<div className="vs--modal-table-head-column" data-type="jackpotPoolName">
									<span title={t("cashier.jackpotPoolName")}>{t("cashier.jackpotPoolName")}</span>
								</div>
								<div className="vs--modal-table-head-column" data-type="jackpotAmount">
									<span title={t("cashier.jackpotWinAmount")}>{t("cashier.jackpotWinAmount")}</span>
								</div>
								<div className="vs--modal-table-head-column" data-type="jackpotWindate">
									<span title={t("cashier.jackpotWinDate")}>{t("cashier.jackpotWinDate")}</span>
								</div>
								<div data-type="actions"></div>
							</div>
							<div className="vs--modal-table-body vs--flex-equal vs--flex vs--flex-col">
								{jackpots.map((jackpot) => renderRow(jackpot))}
							</div>
						</div>
					</div>
				) : (
					<NoData />
				)
			) : (
				<Loader style={{ height: "100%" }} />
			)}
		</Fragment>
	);
};

/** WonJackpots propTypes
 * PropTypes
 */
WonJackpots.propTypes = {
	/** Is true when loading items */
	isLoading: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux action ,to open ticket cancel confirmation popup */
	setTicketConfirmation: PropTypes.func,
	/** Function to close ticket list popup */
	onCancel: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	setTicketConfirmation: (params) => {
		dispatch(setTicketConfirmation({ ...params, betId: null }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(WonJackpots);
