import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import Paths from "constants/path.constants";

import store from "store/configureStore";
import { PROJECT_TYPE } from "constants/common.constants";

import { setPlayer, setTokenExpiration, setStreamAccess } from "store/actions/auth/auth.actions";

import sessionStorageUtils from "./sessionStorage";

import { getProjectType, getUrlVars } from "utils/common";
import { sendPostMessageToParent } from "./iframe";
import { REFRESH_TOKEN_EXPIRATION } from "constants/date.constants";

let isRefreshing = false;

/** Sends request to server to refresh token
 * @function
 * @param {string} refresh_token - refresh token
 * @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
 * @returns {Promise}
 */
export const refreshToken = (refresh_token, requestConfig) => {
	if (isRefreshing) return Promise.reject();
	isRefreshing = true;
	return axios({
		url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_TOKEN}`,
		method: Methods.POST,
		data: { refreshToken: refresh_token },
		headers: { grant_type: "refresh_token" }
	})
		.then(({ status, data: { value: authData } }) => {
			if (status === 200) {
				loginUser(authData);
				isRefreshing = false;
				return requestConfig && axios.request(requestConfig);
			} else {
				logout();
				isRefreshing = false;
			}
		})
		.catch((err) => {
			isRefreshing = false;
			console.log(err);
		});
};

/** Sends request to server to refresh stream token, this method created for using call without redux/dispatch
 * @function
 * @param {string} refreshToken - refresh token
 * @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
 * @returns {Promise}
 */
export const utils_refreshStreamAccess = (refreshToken, requestConfig) => {
	return axios({
		url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_STREAM_ACCESS}`,
		method: Methods.POST,
		data: { refreshToken },
		headers: { grant_type: "refresh_token" }
	})
		.then(({ status, data: { value: session } }) => {
			if (status === 200) {
				store.dispatch(setStreamAccess(session));
				return requestConfig && axios.request(requestConfig);
			}
		})
		.catch((err) => {
			console.log(err);
		});
};

/** Function to Login User
 * @param {object} authData - user data
 * @function
 */
export const loginUser = (authData) => {
	store.dispatch(setPlayer(authData));
	if (getProjectType() === PROJECT_TYPE.STANDALONE) {
		sessionStorageUtils.set("authorizationData", authData);
	}
	startWatchingRefreshTokenExpiration();
};

let interval = null;
let timer = 0;

/** start interval, to check if refresh token is near to be expired
 * @function
 */
export const startWatchingRefreshTokenExpiration = () => {
	stopWatchingRefreshTokenExpiration();
	interval = setInterval(() => {
		timer--;
		store.dispatch(setTokenExpiration(timer));
	}, 1000);
};

/** stop interval, to check if refresh token is near to be expired
 * @function
 */
const stopWatchingRefreshTokenExpiration = () => {
	clearInterval(interval);
	timer = REFRESH_TOKEN_EXPIRATION;
	store.dispatch(setTokenExpiration(timer));
};

/** check if user is Authenticated
 * @function
 * @returns {boolean}
 */
export const isAuthenticated = () => {
	if (getUrlVars()["isPreview"]) {
		return true;
	}
	const authData = sessionStorageUtils.get("authorizationData");
	return !!authData;
};

/** Logout from system
 * @function
 */
export const logout = () => {
	stopWatchingRefreshTokenExpiration();
	if (getProjectType() === PROJECT_TYPE.STANDALONE) {
		sessionStorageUtils.remove("authorizationData");
		window.location.href = window.location.origin;
	} else {
		sendPostMessageToParent({ type: "vs-logout" });
	}
};
