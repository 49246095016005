import  { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { BETSLIP_MODES } from "constants/betslip.constants";
import betType from "types/bet.type";
import bonustype from "types/bonus.type";
import { placeBet, showPaymentMethod } from "store/actions/betslip/betslip.actions";
import { numberWithSpaces, toFixed, makeCurrencyText } from "utils/common";
import { getMinLimit, getMaxLimit, getMaxPossibleWin, isPlaceBetDisabled, isStakeInValid, calculatePossibleWin, calculateTotalOdds, calculateTotalStake } from "utils/bets";
import Loader from "components/ui/loader";
import StakeInput from "../stakeInput";
import HotKeyInput from "../hotKeyInput";
import FavoriteBets from "../favoriteBets";
import RemoveAll from "../removeAll";
import TicketIDFormButton from "../ticketIDFormButton";
import { GAME_TYPE } from "constants/game.constants";
import { PAYMENT_TYPE } from "constants/common.constants";
import KEY_CODE from "constants/keyboard.constants";
import sessionType from "types/session.type";
import useMarketDisabledForBonus from "hooks/useMarketDisabledForBonus";
import Tooltip from "components/ui/tooltip";

/** Stake section component for betslip*/
const Stake = ({ mode, bets, stake, placeBet, loading, session, currentGameType, useBonus, bonus, showPaymentMethod }) => {
	const { t } = useTranslation();

	const { isMultiMarketDisable } = useMarketDisabledForBonus();
	const disabledForBonus = isMultiMarketDisable(bets);

	// Flag to allow place bet or not
	const isBetDisabled = isPlaceBetDisabled(bets, mode, stake, loading, session, useBonus && bonus?.maxPossibleWinAmount !== null, bonus?.maxPossibleWinAmount) || disabledForBonus;

	/** Function which fires on "place bet" button click
	 * @function
	 * @memberOf Stake
	 */
	const handlePlaceBet = () => {
		if (!isBetDisabled) {
			if (useBonus) {
				placeBet(session.paymentType === PAYMENT_TYPE.CREDIT_CARD ? PAYMENT_TYPE.CREDIT_CARD : PAYMENT_TYPE.CASH);
			} else {
				if (session.paymentType === PAYMENT_TYPE.BOTH) {
					showPaymentMethod(true);
				} else {
					placeBet(session.paymentType);
				}
			}
		}
	};

	/** Function which filters on not expired bets
	 * @function
	 * @returns {array} - active bets
	 * @memberOf Stake
	 */
	const getActiveBets = () => bets.filter((bet) => !bet.isExpired);

	/** Place bet when {Ctrl} + {Enter} pressed on keyboard */
	useEffect(() => {
		const handler = (e) => {
			if (e.ctrlKey && e.keyCode === KEY_CODE.ENTER) {
				handlePlaceBet();
				e.preventDefault();
				e.stopPropagation();
			}
		};
		document.addEventListener("keyup", handler, false);

		return () => document.removeEventListener("keyup", handler);
	}, [bets]);

	return (
		<Fragment>
			<div className="vs--stake vs--pl-16 vs--pr-16 vs--pt-16 vs--pb-16">
				<div className="vs--flex">
					<StakeInput mode={mode} />
					{session.hotKeyEnabled && currentGameType !== GAME_TYPE.KENO && <HotKeyInput />}
				</div>
				{session?.currency ? (
					<div className="vs--stake-maxmin vs--flex vs--flex-row vs--justify-between vs--mb-16">
						<span className="vs--font-regular vs--font-mini vs--flex-equal">{`${t("common.min")} ${numberWithSpaces(getMinLimit(mode, session))} - ${t("common.max")} ${numberWithSpaces(getMaxLimit(mode, session))}`}</span>
					</div>
				) : null}
				<FavoriteBets />
				<div className="vs--stake-count vs--pt-16 vs--mb-24">
					<div className="vs--flex vs--flex-row vs--justify-between vs--align-center vs--mb-8">
						<span className="vs--font-regular vs--font-smallest">{mode === BETSLIP_MODES.SINGLE ? t("bet.totalStake") : t("bet.totalOdds")}</span>
						<div className="vs--flex vs--flex-row vs--justify-center vs--align-center">
							<span className="vs--font-medium vs--font-smallest vs--stake-count-value">{mode === BETSLIP_MODES.SINGLE ? numberWithSpaces(calculateTotalStake(getActiveBets(), session)) : numberWithSpaces(calculateTotalOdds(getActiveBets(), session))}</span>
						</div>
					</div>
					{!isStakeInValid(stake, mode, session, bets, useBonus && bonus?.maxPossibleWinAmount !== null, bonus?.maxPossibleWinAmount) ? (
						<div className="vs--flex vs--flex-row vs--justify-between vs--align-center">
							<span className="vs--font-regular vs--font-smallest">{t("bet.possibleWin")}</span>
							<div className="vs--flex vs--flex-row vs--justify-center vs--align-center">
								<span className="vs--font-medium vs--font-smallest vs--stake-count-value">{numberWithSpaces(toFixed(calculatePossibleWin(mode, bets, stake, session), session?.currency?.decimalCount ?? 2))}</span>
								<span className="vs--font-medium vs--font-smallest vs--pl-4 vs--stake-count-value">{session?.currency?.code}</span>
							</div>
						</div>
					) : (
						<div className="vs--flex vs--flex-row vs--align-center vs--single-bet-error">
							<i className="ic_warning"></i>
							{Number(stake) < getMinLimit(mode, session) && stake !== "" && mode === BETSLIP_MODES.MULTI ? (
								<span className="vs--font-regular vs--font-small vs--pl-5 vs--stake-count-error">
									{t("bet.minBetAmountIs")} {makeCurrencyText(getMinLimit(mode, session), session.currency)}{" "}
								</span>
							) : Number(stake) > getMaxLimit(mode, session) && stake !== "" && mode === BETSLIP_MODES.MULTI ? (
								<span className="vs--font-regular vs--font-small vs--stake-count-error  vs--pl-5">
									{t("bet.maxBetAmountIs")} {makeCurrencyText(getMaxLimit(mode, session), session.currency)}{" "}
								</span>
							) : (
								<span className="vs--font-regular vs--font-small vs--stake-count-error vs--pl-5">
									{t("bet.maxPosWinAmountIs")}{" "}
									{!(useBonus && bonus?.maxPossibleWinAmount !== null && Number(calculatePossibleWin(mode, bets, stake, session)) > Number(bonus?.maxPossibleWinAmount))
										? makeCurrencyText(getMaxPossibleWin(mode, session), session.currency)
										: makeCurrencyText(bonus.maxPossibleWinAmount, session.currency)}
								</span>
							)}
						</div>
					)}
				</div>

				<div className="vs--stake-buttons vs--flex vs--flex-row vs--justify-center vs--align-center">
					<div className="vs--stake-buttons-child">
						<TicketIDFormButton />
					</div>
					<div className="vs--stake-buttons-child">
						<RemoveAll />
					</div>
					<Tooltip placement="left" title={disabledForBonus ? <span className="vs--markets-list-item-content-odd-item-bonus-title">{t("cashier.unavailableForFreeBet")}</span> : null}>
						<div className="vs--stake-buttons-child">
							<button type="button" className={"vs--button vs--flex vs--justify-center vs--align-center" + (isBetDisabled ? " vs--button-disabled" : "")} onClick={handlePlaceBet}>
								<i className="ic_done vs--font-bigest"></i>
							</button>
							{loading && <Loader />}
						</div>
					</Tooltip>
				</div>
			</div>
		</Fragment>
	);
};

/** Stake propTypes
 * PropTypes
 */
Stake.propTypes = {
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, common stake of betslip */
	stake: PropTypes.string,
	/** Redux action to place bet */
	placeBet: PropTypes.func,
	/** Redux state property, is true when placing bet */
	loading: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool,
	/** Redux state property, currecnt bonus */
	bonus: bonustype,
	/** Redux action to show/hide payment method modal */
	showPaymentMethod: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		mode: state.betslip.mode,
		bets: state.betslip.bets,
		stake: state.betslip.stake,
		loading: state.betslip.loading,
		session: state.auth.session,
		currentGameType: state.game.currentGameType,
		useBonus: state.bonuses.standard.useBonus,
		bonus: state.bonuses.standard.bonus
	}
}

const mapDispatchToProps = (dispatch) => ({
	placeBet: (method) => {
		dispatch(placeBet(method));
	},
	showPaymentMethod: (show) => {
		dispatch(showPaymentMethod(show));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Stake);
