import PropTypes from "prop-types";
import moment from "moment";

import Tile from "components/ui/datePicker/calendar/tile";

import { getDecadeStart, getDecadeRange, getDecadeEnd } from "utils/calendar";
import { CALENDAR_VIEW } from "constants/calendar.constants";

const Decade = ({ className, date, selectedDate, ...otherProps }) => {
	const decadeStartEndDates = getDecadeRange(date);
	const decadeLabel = decadeStartEndDates.map((date) => moment(date).format("YYYY")).join("-");

	const getClassName = () => {
		let classNames = "vs--ui-calendar-century-view-decades-decade";

		if (className) {
			classNames += ` ${className}`;
		}

		if (selectedDate.isSameOrAfter(decadeStartEndDates[0]) && selectedDate.isSameOrBefore(decadeStartEndDates[1])) {
			classNames += " vs--ui-calendar-century-view-decades-decade-active";
		}

		return classNames;
	};

	return (
		<Tile {...otherProps} date={date} selectedDate={selectedDate} className={getClassName()} maxDateTransform={getDecadeEnd} minDateTransform={getDecadeStart} view={CALENDAR_VIEW.CENTURY}>
			{decadeLabel}
		</Tile>
	);
};

Decade.propTypes = {
	className: PropTypes.string.isRequired,
	date: PropTypes.instanceOf(Date),
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired
};

export default Decade;
