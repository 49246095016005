import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import moment from "moment";
import store from "store/configureStore";

import { setTranslationsLoaded } from "store/actions/auth/auth.actions";

const buildTranslationUrl = (path) => {
	return `${import.meta.env.SYSTEM_CDN_URL}/${path?.toLowerCase()}`;
};

const buildSystemTranslationUrl = (language) => {
	return `system/translations/${language.toLowerCase()}.json`;
};

const buildSystemLiveMonitorTranslationUrl = (language) => {
	return `system/translations/livemonitor_${language.toLowerCase()}.json`;
};

export const initTranslations = (path, language, ticketPath, ticketLanguage, onSuccess, onError) => {
	const english = "en";

	let BackEndAPI = (t) => t;

	const currentLang = language || english;
	const ticketLang = ticketLanguage || english;

	const configuration = {
		debug: false,
		lng: currentLang,
		interpolation: {
			escapeValue: false
		},

		ns: ["common", "ticket"].concat(language.includes("livemonitor_") ? ["livemonitor"] : []),
		defaultNS: "common"
	};

	if (!path) {
		path = buildSystemTranslationUrl(currentLang);
	}

	if (!ticketPath) {
		ticketPath = buildSystemTranslationUrl(ticketLang);
	}

	BackEndAPI = HttpApi;
	configuration.backend = {
		loadPath: (l, ns) => {
			if (ns[0] === "common" || ns[0] === "livemonitor") {
				return buildTranslationUrl(path);
			} else {
				return buildTranslationUrl(ticketPath);
			}
		},
		crossDomain: true,
		parse: (data) => {
			try {
				const result = JSON.parse(data);
				return result;
			} catch (ex) {
				console.log(ex);
			}
		}
	};

	i18n
		.use(BackEndAPI)
		.use(initReactI18next)
		.init(configuration)
		.then(() => {
			store.dispatch(setTranslationsLoaded());
			onSuccess();
			initializeLocalsForMomentJS();
		})
		.catch((err) => {
			onError && onError();
		});
};

const initializeLocalsForMomentJS = () => {
	moment.updateLocale("en", {
		weekdaysMin: [i18n.t("common.sunday"), i18n.t("common.monday"), i18n.t("common.tuesday"), i18n.t("common.wednesday"), i18n.t("common.thursday"), i18n.t("common.friday"), i18n.t("common.saturday")],
		monthsShort: [
			i18n.t("common.january"),
			i18n.t("common.february"),
			i18n.t("common.march"),
			i18n.t("common.april"),
			i18n.t("common.may"),
			i18n.t("common.june"),
			i18n.t("common.july"),
			i18n.t("common.august"),
			i18n.t("common.september"),
			i18n.t("common.october"),
			i18n.t("common.november"),
			i18n.t("common.december")
		]
	});
};

export default i18n;
