import { Fragment, useEffect, useState, useRef, useMemo, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useOutletContext } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Loader from "components/ui/loader";
import KenoLive from "./live";
import KenoPreamble from "./preamble";
import JackpotAnimations from 'components/pages/jackpotLiveInfo/animations';
import Pools from "components/jackpotInfo/pools";

import { GAME_STATUSES } from "constants/game.constants";
import eventType from "types/event.type";
import KenoShuffleVideo from "./kenoShuffle/kenoShuffleVideo";
const MemorizedKenoShuffleVideo = memo(KenoShuffleVideo);

import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common";

let interval = null;

/** Kneo Game page Component */
const KenoPage = ({
	liveAndUpcomings,
	isLoading,
	jackpotInfo
}) => {
	const { t } = useTranslation();
	const decodedData = useOutletContext();

	const [countdown, setCountDown] = useState(null);

	const logoPath = decodedData?.game?.liveMonitorLogoURL ?? null;

	const currentEvent = useMemo(() => {
		const event = liveAndUpcomings.find((ev) => [GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(ev.status));

		if (event) {
			return event;
		}

		return liveAndUpcomings[0];
	}, [liveAndUpcomings]);

	useEffect(() => {
		if (currentEvent?.status === GAME_STATUSES.STARTED || currentEvent?.status === GAME_STATUSES.CLOSE_FOR_BETTING) {
			clearInterval(interval);

			setCountDown(null);
		} else {
			clearInterval(interval);

			interval = setInterval(() => {
				const startTime = currentEvent?.startTime ?? undefined;
				const date = moment.utc(startTime).local().toDate();
				const diff = moment().diff(date, "seconds");

				if (diff <= 0) {
					setCountDown(moment.utc((0 - diff) * 1000).format("mm:ss"));
				} else {
					setCountDown(null);
				}
			}, 1000);
		}
	}, [currentEvent?.status]);

	return (
		<div className="vs--keno">
			<MemorizedKenoShuffleVideo />
			{isLoading ? (
				<Loader />
			) : (
				<Fragment>
					<JackpotAnimations getMainContainer={() => document.getElementsByClassName("vs--keno")[0]} />

					<div className="vs--keno-header vs--flex vs--align-center vs--pl-40 vs--pr-40">
						<div className="vs--flex vs--align-center vs--justify-start vs--keno-header-side">
							<img src={logoPath ? `${import.meta.env.SYSTEM_CDN_URL}/${logoPath.toLowerCase()}` : buildPathToStaticFolderOfCDN("cashier-images/keno-logo.svg")} alt="logo" />
						</div>
						<div className="vs--keno-header-middle vs--flex vs--flex-col vs--align-center">
							{countdown ? (
								<div className="vs--keno-header-countdown vs--pt-12 vs--pb-16 vs--pl-38 vs--pr-38 vs--flex vs--align-center vs--justify-center">
									<span className="vs--title vs--font-slim-bold">{countdown}</span>
								</div>
							) : null}
							{currentEvent?.id && (
								<div className={mergeClassNames("vs--flex vs--align-center vs--justify-end vs--keno-header-side", !countdown && "vs--mt-12")}>
									<span className="vs--title vs--font-medium vs--pr-16">
										{t("common.eventId")} #: <b>{currentEvent.id}</b>
									</span>
								</div>
							)}
						</div>
						{jackpotInfo?.levels && (
							<Pools
								className="vs--keno-header-pools"
								showLevelIcon={true}
								data={jackpotInfo.levels}
							/>
						)}
					</div>
					<div className="vs--keno-content">{currentEvent?.status === GAME_STATUSES.STARTED || currentEvent?.status === GAME_STATUSES.CLOSE_FOR_BETTING ? <KenoLive event={currentEvent} /> : <KenoPreamble event={currentEvent} />}</div>
				</Fragment>
			)}
		</div>
	);
};

/** KenoPage propTypes
 * PropTypes
 */
KenoPage.propTypes = {
	/** Redux state property, the array of live and upcomings events */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Redux state property, is true when live and upcomings are loading */
	isLoading: PropTypes.bool,
	/** Redux state property, active Jackpot Info */
	jackpotInfo: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		isLoading: state.game.liveAndUpcomings.loading,
		jackpotInfo: state.bonuses.jackpot.data
	};
};

export default connect(mapStateToProps, null)(KenoPage);
