import  { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import withAnanymous from "hocs/withAnanymous";

import { initTranslations } from "translations/config";

import Loader from "components/ui/loader";

import { isMobile, isTablet, isIOS } from "utils/common";

/** QR Layout Component */
const Layout = ({ translationsLoaded }) => {
	/** Init default system translations */
	useEffect(() => {
		document.documentElement.classList.add("vs--body-unscale");

		initTranslations(null, "en", null, "en", Function.prototype, Function.prototype);
	}, []);

	return (
		<div className="vs--container">
			{translationsLoaded ? (
				<div className={"vs--qr" + (isTablet() ? " vs--qr-tablet" : isMobile() ? " vs--qr-mobile" : "") + (isIOS() ? " vs--qr-ios" : "")}>
					<Outlet />
				</div>
			) : (
				<Loader full={true} />
			)}
		</div>
	);
};

/** Layout propTypes
 * PropTypes
 */
Layout.propTypes = {
	/** Redux state property, Is true when translations are loaded */
	translationsLoaded: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		translationsLoaded: state.auth.translationsLoaded
	};
};

export default connect(mapStateToProps, null)(withAnanymous(Layout));
