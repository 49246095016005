import PropTypes from "prop-types";
import { connect } from "react-redux";

import PortalWrapper from "components/portalWrapper";
import Message from "components/ui/messageAlerter/message";

import { removeAlertMessage } from "store/actions/common/common.actions";

const MessageAlerter = ({ messages }) => {
	return (
		<PortalWrapper wrapperId="message" className="vs--ui-message">
			{messages.map((message) => (
				<Message key={message.id} message={message} />
			))}
		</PortalWrapper>
	);
};

MessageAlerter.propTypes = {
	/** Redux state property, alert messages data */
	messages: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
	removeAlertMessage: (id) => {
		dispatch(removeAlertMessage(id));
	}
});

const mapStateToProps = (state) => {
	return {
		messages: state.common.alertMessages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageAlerter);
