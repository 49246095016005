import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Tooltip from "components/ui/tooltip";
import Button from "components/ui/button";
import Input from "components/ui/input";
import Form from "components/ui/form";
import PasswordValidationTooltip from "components/ui/validationTooltips/passwordValidationTooltip";

import { required } from "utils/common";
import { redirectToPage } from "utils/navigate";
import { validatePasword } from "utils/password";

import { activateCashier } from "store/actions/auth/activate.actions";
import { getPasswordSettings } from "store/actions/auth/passwordSettings.actions";

import PATHS from "constants/path.constants";
import passwordSettingsType from "types/passwordSettings.type";

/** Activate page Component */
const ActivatePage = ({ activateCashier, getPasswordSettings, passwordSettings, isActivating }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [formInstance] = Form.useForm();

	const [newPassword, setNewPassword] = useState("");
	const [newPasswordFocused, setNewPasswordFocused] = useState(false);

	/** Load password settings */
	useEffect(() => {
		const token = searchParams.get("t");
		if (token) {
			getPasswordSettings(token);
		}
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf ActivatePage
	 */
	const handleForm = () => {
		const token = searchParams.get("t");
		formInstance
			.validateFields()
			.then((data) => {
				activateCashier(token, data.newPassword, data.confirmPassword, () => {
					redirectToPage(navigate, PATHS.LOGIN);
				});
			})
			.catch(Function.prototype);
	};

	return (
		<Fragment>
			<b className="vs--auth-popup-title vs--title vs--font-medium vs--text-center">{t("cashier.activate")}</b>
			<div className="vs--change-password">
				<Form
					form={formInstance}
					initialValues={{
						newPassword: "",
						confirmPassword: ""
					}}
				>
					<Tooltip title={<PasswordValidationTooltip password={newPassword} passwordSettings={passwordSettings} />} placement="right" overlayClassName="vs--activate-password-tooltip" isOpen={newPasswordFocused || !!newPassword}>
						<Form.Item
							name="newPassword"
							rules={[
								required(t("cashier.passwordRequired")),
								() => ({
									validator(value) {
										return validatePasword(value, passwordSettings);
									}
								})
							]}
						>
							<Input.Password
								className="vs-login-action-inputs"
								name="confirmPassword"
								placeholder={t("cashier.enterPassword")}
								onChange={(value) => {
									setNewPassword(value);
									setTimeout(() => {
										if (formInstance.getFieldValue("confirmPassword") !== "") {
											formInstance.validateFields(["confirmPassword"]);
										}
									}, 0);
								}}
								onFocus={() => setNewPasswordFocused(true)}
								onBlur={() => setNewPasswordFocused(false)}
							/>
						</Form.Item>
					</Tooltip>
					<Form.Item
						name="confirmPassword"
						rules={[
							required(t("cashier.passwordRequired")),
							({ getFieldValue }) => ({
								validator(value) {
									if (value !== getFieldValue("newPassword")) {
										return Promise.reject(t("cashier.passwordsDoNotMatch"));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<Input.Password className="vs-login-action-inputs" name="newPassword" placeholder={t("cashier.confirmPassword")} onPaste={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} />
					</Form.Item>
					<Button htmlType="submit" className="vs--button vs--font-small vs--font-bold vs--pt-12 vs--pb-12 vs--mt-16" onClick={handleForm} disabled={isActivating} loading={isActivating}>
						{t("cashier.activate")}
					</Button>
				</Form>
			</div>
		</Fragment>
	);
};

/** ActivatePage propTypes
 * PropTypes
 */
ActivatePage.propTypes = {
	/** Redux action to activate cashier */
	activateCashier: PropTypes.func,
	/** Redux state property, is true when activate request is in process */
	isActivating: PropTypes.bool,
	/** Redux action to get password settings */
	getPasswordSettings: PropTypes.func,
	/** Redux state property, the activation password settings */
	passwordSettings: passwordSettingsType
};

const mapDispatchToProps = (dispatch) => ({
	activateCashier: (token, newPassword, confirmPassword, onSuccess) => {
		dispatch(activateCashier(token, newPassword, confirmPassword, onSuccess));
	},
	getPasswordSettings: (token) => {
		dispatch(getPasswordSettings(token));
	}
});

const mapStateToProps = (state) => {
	return {
		isActivating: state.auth.activation.isActivating,
		passwordSettings: state.auth.passwordSettings.passwordSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePage);
