import { SET_SHOW_TEMPLATES, SAVE_TEMPLATE_AFTER } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_SHOW_TEMPLATES:
			return {
				...state,
				isVisible: payload
			};
		case SAVE_TEMPLATE_AFTER:
			return {
				...state,
				isVisible: false
			};
		default:
			return state;
	}
};
