import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_RESULTS, RESULTS_BEFORE, RESULTS_AFTER, SET_RESULTS_FILTERS, RESET_RESULTS_FILTERS, RESULTS_TO_PRINT_BEFORE, RESULTS_TO_PRINT_AFTER, SET_RESULTS_TO_PRINT, RESET_RESULTS_TO_PRINT, SET_RESULTS_PRINTING } from "../../actionTypes";

const setResultsBefore = () => ({
	type: RESULTS_BEFORE
});

const setResultsAfter = () => ({
	type: RESULTS_AFTER
});

const setResults = (results) => ({
	type: SET_RESULTS,
	payload: { results }
});

export const getResults = () => {
	return (dispatch, getState) => {
		dispatch(setResultsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RESULTS}`,
			method: Methods.GET,
			params: {
				...getState().results.filters
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setResults(data));
				dispatch(setResultsAfter());
			})
			.catch((ex) => {
				dispatch(setResultsAfter());
			});
	};
};

export const setResultsFilters = (filters) => ({
	type: SET_RESULTS_FILTERS,
	payload: { filters }
});

export const resetResultsFilters = () => ({
	type: RESET_RESULTS_FILTERS
});

const setResultsToPrintBefore = () => ({
	type: RESULTS_TO_PRINT_BEFORE
});

const setResultsToPrintAfter = () => ({
	type: RESULTS_TO_PRINT_AFTER
});

const setResultsToPrint = (results) => ({
	type: SET_RESULTS_TO_PRINT,
	payload: { results }
});

export const getResultsToPrint = (type, count, forPrint = false) => {
	return (dispatch, getState) => {
		dispatch(setResultsToPrintBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_RESULTS}`,
			method: Methods.GET,
			params: {
				type: type,
				page: 1,
				limit: count,
				forPrint: forPrint
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setResultsToPrint(data));
				dispatch(setResultsToPrintAfter());
			})
			.catch((ex) => {
				dispatch(setResultsToPrintAfter());
			});
	};
};

export const resetResultsToPrint = () => ({
	type: RESET_RESULTS_TO_PRINT
});

export const setResultsPrinting = (type) => ({
	type: SET_RESULTS_PRINTING,
	payload: { type }
});
