import PropTypes from 'prop-types';
import useMarketDisabledForBonus from 'hooks/useMarketDisabledForBonus';
import Tooltip from 'components/ui/tooltip';
import { useTranslation } from 'react-i18next';

/** Regular Markets odd component */
const MarketOdd = ({ odd, isOddDisabled, isOddSelected, handleOddClick, numberWithSpaces }) => {
	const { isSingleMarketDisable } = useMarketDisabledForBonus();
	const isDisabledForBonus = isSingleMarketDisable(odd.factor)
	const { t } = useTranslation();
	return (
		<div className='vs--markets-list-items'>
			<Tooltip
				placement="bottom"
				title={isDisabledForBonus ? <span className='vs--markets-list-item-content-odd-item-bonus-title'>{t("cashier.unavailableForFreeBet")}</span> : null}
				overlayClassName="vs--markets-list-item-content-odd-item-tooltip"
			>
				<div
					className={'vs--markets-list-item-content-odd-item vs--flex-inline vs--flex-col vs--align-center vs--pl-12 vs--pr-12 vs--pt-8 vs--pb-8' + (isOddSelected(odd.id) ? " vs--markets-list-item-content-odd-item-selected vs--pt-8 vs--pb-8" : "") + (isDisabledForBonus || isOddDisabled(odd) ? " vs--markets-list-item-content-odd-item-disabled vs--pt-8 vs--pb-8" : " vs--pt-8 vs--pb-8")}
					onClick={() => !isDisabledForBonus && handleOddClick(odd, odd)}
					title={!isOddDisabled(odd) ? odd.showName : ""}
				>
					{
						!isOddDisabled(odd)
							? (
								<>
									<div className='vs--markets-list-item-content-odd-item-title vs--flex-equal vs--mb-8'>
										<span className='vs--title vs--font-small vs--font-regular'>
											{
												odd.showName
											}
										</span>
									</div>
									<div className='vs--markets-list-item-content-odd-item-factor'>
										<span className='vs--title vs--font-small vs--font-regular'>
											{
												numberWithSpaces(odd.factor)
											}
										</span>
									</div>
								</>
							)
							: (
								<div className='vs--pt-12 vs--pb-12 vs--pr-16 vs--pl-16'>
									<i className="ic_lock vs--font-bigest" />
								</div>
							)
					}

				</div>
			</Tooltip>
		</div>

	)
}

/** MarketOdd propTypes
	 * PropTypes
*/
MarketOdd.propTypes = {
	/** Current odd info */
	odd: PropTypes.object,
	/** function for get disabled state */
	isOddDisabled: PropTypes.func,
	/** function for get selected state */
	isOddSelected: PropTypes.func,
	/** function handler for odd click */
	handleOddClick: PropTypes.func,
	/** function for formatting odd factor */
	numberWithSpaces: PropTypes.func,
}

export default MarketOdd;