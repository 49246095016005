import i18n from "translations/config";

import { toFixed, getUrlVars } from "utils/common";
import { BET_STATE, BETSLIP_MODES } from "constants/betslip.constants";

import runMarketUtilsFunction from "utils/markets/run";

/** Get the text of bet state
 * @function
 * @param {number} state - the state enum value
 * @returns {string}
 */
export const getBetStateText = (state) => {
	switch (state) {
		case BET_STATE.PENDING:
			return i18n.t("bet.pending");
		case BET_STATE.RETURN:
			return i18n.t("bet.return");
		case BET_STATE.WON:
			return i18n.t("bet.won");
		case BET_STATE.SEMIWON:
			return i18n.t("bet.semiWon");
		case BET_STATE.LOST:
			return i18n.t("bet.lost");
		case BET_STATE.SEMILOST:
			return i18n.t("bet.semiLost");
		case BET_STATE.CANCELLED:
			return i18n.t("bet.cancelled");
		case BET_STATE.REJECTED_BY_OPERATOR:
			return i18n.t("bet.rejected");
	}
};

/** Function get bet min limit depend on bet type
 * @function
 * @param {number} mode - betslip mode
 * @param {object} session - current session
 * @returns {number}
 */
export const getMinLimit = (mode, session) => (mode === BETSLIP_MODES.SINGLE ? (session?.currency?.singleMin ?? 0) : (session?.currency?.multiMin ?? 0));

/** Function get bet min limit depend on bet type
 * @function
 * @param {number} mode - betslip mode
 * @param {object} session - current session
 * @returns {number}
 */
export const getMaxLimit = (mode, session) => (mode === BETSLIP_MODES.SINGLE ? (session?.currency?.singleMax ?? Infinity) : (session?.currency?.multiMax ?? Infinity));

/** Function get bet min limit depend on bet type
 * @function
 * @param {number} mode - betslip mode
 * @param {object} session - current session
 * @returns {number}
 */
export const getMaxPossibleWin = (mode, session) => (mode === BETSLIP_MODES.SINGLE ? (session?.currency?.singlePossibleWinMax ?? Infinity) : (session?.currency?.multiPossibleWinMax ?? Infinity));

/** Function which calculates the possible win of betslip
 * @function
 * @param {number} mode - betslip mode
 * @param {array} bets - array of bets
 * @param {number} stake - betslip stale
 * @param {object} session - current session
 * @returns {number}
 */
export const calculatePossibleWin = (mode, bets, stake, session) => {
	let possibleWin = 0;
	if (mode === BETSLIP_MODES.SINGLE) {
		possibleWin = bets.filter((bet) => !bet.expired).reduce((total, bet) => total + Number(bet.stake) * bet.factor, 0);
	} else if (mode === BETSLIP_MODES.MULTI) {
		possibleWin = bets.filter((bet) => !bet.expired).reduce((total, bet) => total * bet.factor, 1) * stake;
	}
	return toFixed(possibleWin, session?.currency?.decimalCount ?? 2);
};

/** Function which checks if the stake is valid
 * @function
 * @param {number} stake - betslip stale
 * @param {number} mode - betslip mode
 * @param {object} session - current session
 * @param {array} bets - array of bets
 * @returns {boolean}
 */
export const isStakeInValid = (stake, mode, session, bets, useBonus, bonusMaxWin) =>
	(mode === BETSLIP_MODES.MULTI && (Number(stake) < getMinLimit(mode, session) || Number(stake) > getMaxLimit(mode, session)) && stake !== "") ||
	Number(calculatePossibleWin(mode, bets, stake, session)) > getMaxPossibleWin(mode, session) ||
	(useBonus && bonusMaxWin < Number(calculatePossibleWin(mode, bets, stake, session)));

/** Function which checks if place bet can be done
 * @function
 * @param {array} bets - array of bets
 * @param {number} mode - betslip mode
 * @param {number} stake - betslip stale
 * @param {boolean} loading - is place bet in process
 * @param {object} session - current session
 * @returns {boolean}
 */
export const isPlaceBetDisabled = (bets, mode, stake, loading, session, useBonus, bonusMaxWin) => {
	const mapped = bets.map((b) => b.eventId);
	const activeBets = bets.filter((bet) => !bet.expired);
	return (
		getUrlVars()["isPreview"] ||
		activeBets.length === 0 ||
		loading ||
		(mode === BETSLIP_MODES.MULTI && (stake === "" || mapped.length !== new Set(mapped).size)) ||
		isStakeInValid(stake, mode, session, bets, useBonus, bonusMaxWin) ||
		(mode === BETSLIP_MODES.SINGLE && bets.filter((b) => ((Number(b.stake) < getMinLimit(mode, session) || Number(b.stake) > getMaxLimit(mode, session)) && b.stake !== "") || b.stake === "").length > 0)
	);
};

/** Function for mapping bet to ticket info for printing
 * @function
 * @param {object} bet - the bet to map
 * @param {number} ticketType - printing ticket type(bet/cancel/payout)
 * @returns {object}
 */
export const mapBetToPrintingTicket = (bet, ticketType) => ({
	ticketType: ticketType,
	id: bet.id,
	type: bet.type,
	totalAmount: bet.totalAmount,
	possibleWin: bet.possibleWin,
	bets: bet.bets.map((b) => ({
		gameData: b?.gameData ?? {},
		eventId: b.eventId,
		id: b.id,
		groupTitle: runMarketUtilsFunction("makeGroupTitle", [{ group: b.group, argument: b.argument, gameData: b?.gameData ?? {}, gameType: b.gameType, isTicket: true }], b.gameType),
		showName: runMarketUtilsFunction("makeWinnerMarketName", [{ outcome: b.outcome, group: b.group, gameData: b?.gameData ?? {}, gameType: b.gameType, isTicket: true }], b.gameType),
		factor: b.factor,
		stake: b.amount,
		startTime: b.startTime,
		gameType: b.gameType,
		state: b.state
	})),
	winning: bet.winning,
	redeem: bet.redeem,
	bonusId: bet.bonusId,
	additionalFactor: bet.additionalFactor,
	bonusType: bet.bonusType,
	payoutType: bet.payoutType,
	wonJackpots: bet.wonJackpots,
	jackpotLevelType: bet.jackpotLevelType
});

/** Checks if the betslip can be canceled
 * @function
 * @param {object} betslip
 * @returns {boolean}
 */
export const isBetslipCancelable = (betslip) => {
	return (betslip?.bets ?? []).every((b) => b.allowCancel || (b.state === BET_STATE.CANCELLED && betslip.type === BETSLIP_MODES.SINGLE)) && betslip.state === BET_STATE.PENDING;
};

/** Calculate total odds from bets
 * @function
 * @param {array} bets - array of bets
 * @param {object} object - session
 * @returns {number}
 */
export const calculateTotalOdds = (bets, session) =>
	toFixed(
		bets.reduce((total, bet) => total * bet.factor, 1),
		2
	);

/** Calculate total staje from bets
 * @function
 * @param {array} bets - array of bets
 * @param {object} object - session
 * @returns {number}
 */
export const calculateTotalStake = (bets, session) =>
	toFixed(
		bets.reduce((total, bet) => total + Number(bet.stake), 0),
		session?.currency?.decimalCount ?? 2
	);

/** Function which checks if there are another bet with same match in betslip for multi mode
* @function
* @param {array} bets - array of bets
* @param {mode} number - the betslip mode
* @returns {object}
*/
export const getEventsRepeatedBets = (bets, mode) => {
	if (mode !== BETSLIP_MODES.MULTI) {
		return null;
	}

	const { groupedEvents, groupedWeekEvents, groupedSeasonEvents } = bets.reduce((acc, bet) => {
		acc.groupedEvents[bet.eventId] = bet;

		if (bet.weekId) {
			acc.groupedWeekEvents[bet.weekId] = bet;
		}
		if (bet.seasonId) {
			acc.groupedSeasonEvents[bet.seasonId] = bet;
		}

		return acc;
	}, { groupedEvents: {}, groupedWeekEvents: {}, groupedSeasonEvents: {} });

	const { repeatedBets } = bets.reduce((acc, bet) => {
		if (acc[bet.eventId] && groupedEvents[bet.eventId]) {
			acc.repeatedBets[bet.eventId] = bet;
		}
		if (groupedSeasonEvents[bet.eventId] || groupedEvents[bet.seasonId] || groupedWeekEvents[bet.eventId] || groupedEvents[bet.weekId]) {
			// setting LEAGUE/WEEK/EVENT type event in repeated bets when there are LEAGUE/WEEK/EVENT type events related to it in Betslip
			acc.repeatedBets[bet.eventId] = bet;
		}

		acc[bet.eventId] = bet;

		return acc;
	}, { repeatedBets: {} });

	return repeatedBets;
}