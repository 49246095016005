import PropTypes from "prop-types";
import ModalWrapper from "components/modalWrapper";
import Button from "components/ui/button";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setErrorMessage } from "store/actions/common/common.actions";

/** Error Message Dialog Component */
const ErrorMessage = ({ message, closeMessage }) => {
	const { t } = useTranslation();

	return (
		<ModalWrapper
			classNames={{ content: "vs--error vs--error-modal", body: "vs--error-modal-body" }}
			visible={true}
			onCancel={closeMessage}
			footer={
				<Button type="button" className="vs--error-modal-close-btn" onClick={closeMessage}>
					{t("common.ok")}
				</Button>
			}
		>
			<>
				<div className="vs--error-inner-row">
					<div className="vs--error-inner-icon-section">
						<i className="ic_error-alert" />
					</div>
				</div>
				<div className="vs--error-inner-row ">
					<span className="vs--title vs--font-normal vs--font-medium">{message}</span>
				</div>
			</>
		</ModalWrapper>
	);
};

/** ErrorMessage propTypes
 * PropTypes
 */
ErrorMessage.propTypes = {
	/** Redux state property, current error message to show */
	message: PropTypes.string,
	/** Redux action to close dialog */
	closeMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		message: state.common.errorMessage
	};
};

const mapDispatchToProps = (dispatch) => ({
	closeMessage: () => {
		dispatch(setErrorMessage(""));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
