import axios from "axios";
import Methods from "constants/methods.constants";

import { SET_SHOW_TEMPLATES, SAVE_TEMPLATE_BEFORE, SAVE_TEMPLATE_AFTER } from "../../actionTypes";

import ApiUrls from "constants/api.constants";

export const setShowTemplates = (bool) => ({
	type: SET_SHOW_TEMPLATES,
	payload: bool
});

const saveTemplateBefore = () => ({
	type: SAVE_TEMPLATE_BEFORE
});

const saveTemplateAfter = () => ({
	type: SAVE_TEMPLATE_AFTER
});

export const saveTemplate = (templateType) => {
	return (dispatch) => {
		dispatch(saveTemplateBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_TEMPLATE}`,
			method: Methods.POST,
			data: {
				templateType
			}
		})
			.then(() => {
				dispatch(saveTemplateAfter());
			})
			.catch((ex) => {
				dispatch(saveTemplateAfter());
			});
	};
};
