import { ADD_TEAMS_HEAD_TO_HEAD, SET_SEASON_MARKETS, SET_SEASON_STANDINGS, SET_TEAMS_HEAD_TO_HEAD } from "store/actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_SEASON_MARKETS:
			return {
				...state,
				special: {
					...state.special,
					...payload.data
				}
			};
		case SET_SEASON_STANDINGS:
			return {
				...state,
				stendings: payload.data.map((payloadStanding, currentIndex) => {
					const prevIndex = state.stendings.findIndex((stateStanding) => stateStanding.countryCode === payloadStanding.countryCode);
					return {
						...payloadStanding,
						up: prevIndex === -1 || prevIndex === currentIndex ? null : currentIndex > prevIndex
					};
				})
			};
		case SET_TEAMS_HEAD_TO_HEAD:
			return {
				...state,
				teamsHeadToHead: payload.data
			};
		case ADD_TEAMS_HEAD_TO_HEAD:
			return {
				...state,
				teamsHeadToHead: {
					...state.teamsHeadToHead,
					[payload.data.key]: payload.data.value
				}
			};
		default:
			return state;
	}
};
