import { TICKETS_LIST_TYPE, TICKET_CONFIRMATION_MODAL_TYPE } from "constants/common.constants";
import { TICKET_TYPE } from "constants/ticket.constants";
import { BET_STATE, BETSLIP_MODES } from "constants/betslip.constants";

import { getDefaultDate } from "utils/dateTime";
import { isBetslipCancelable } from "utils/bets";

import {
	ADD_PENDING,
	UPDATE_PENDING,
	UPDATE_PENDING_BET,
	SET_PENDINGS,
	REMOVE_PENDING,
	PENDING_BEFORE,
	PENDING_AFTER,
	SET_SETTLED,
	SETTLED_BEFORE,
	SETTLED_AFTER,
	SET_SETTLED_FILTERS,
	RESET_SETTLED_FILTERS,
	UPDATE_SETTLED,
	UPDATE_TICKET_REPRINT_REQUEST,
	TICKET_CONFIRMATION_BEFORE,
	TICKET_CONFIRMATION_AFTER,
	SET_TICKET_CONFIRMATION,
	CLOSE_TICKET_CONFIRMATION,
	VIEWING_TICKET_BEFORE,
	VIEWING_TICKET_AFTER,
	SET_VIEWING_TICKET,
	UPDATE_VIEWING_TICKET,
	UPDATE_VIEWING_TICKET_BET,
	SHOW_VIEWING_TICKET,
	HIDE_VIEWING_TICKET,
	SET_PRINTING_TICKET,
	REMOVE_PRINTING_TICKET,
	SET_REPRINT_LAST_TICKET_SHOW,
	SET_LAST_REPRINT_TICKET_BEFORE,
	SET_LAST_REPRINT_TICKET_AFTER,
	SET_LAST_REPRINT_TICKET
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case PENDING_BEFORE:
			return {
				...state,
				isPendingsLoading: true
			};
		case PENDING_AFTER:
			return {
				...state,
				isPendingsLoading: false
			};
		case ADD_PENDING:
			return {
				...state,
				pendings: [payload.pending, ...state.pendings]
			};
		case SET_PENDINGS:
			return {
				...state,
				pendings: payload.pendings
			};
		case REMOVE_PENDING:
			return {
				...state,
				pendings: state.pendings.filter((p) => p.id !== payload.pending.id)
			};
		case UPDATE_PENDING:
			return {
				...state,
				pendings: state.pendings.map((p) => (p.id !== payload.pending.id ? p : { ...payload.pending, ticketsInfo: !payload.updateTicketInfo ? p.ticketsInfo : payload.pending.ticketsInfo, bets: p.bets }))
			};
		case UPDATE_PENDING_BET:
			return {
				...state,
				pendings: state.pendings.map((p) => ({ ...p, bets: p.bets ? p.bets.map((bet) => (bet.id === payload.bet.id ? payload.bet : bet)) : p.bets }))
			};
		case SETTLED_BEFORE:
			return {
				...state,
				isSettledLoading: true
			};
		case SETTLED_AFTER:
			return {
				...state,
				isSettledLoading: false
			};
		case SET_SETTLED:
			return {
				...state,
				settled: payload.settled.item2,
				settledTotal: payload.settled.item1
			};
		case SET_SETTLED_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					...payload.filters
				}
			};
		case RESET_SETTLED_FILTERS:
			return {
				...state,
				filters: {
					page: 1,
					limit: state.filters.limit,
					betSlipId: "",
					eventId: "",
					period: "24_hours",
					...getDefaultDate()
				}
			};
		case UPDATE_SETTLED:
			return {
				...state,
				settled: state.settled.map((s) => (s.id !== payload.settled.id ? s : { ...payload.settled, bets: s.bets }))
			};
		case UPDATE_TICKET_REPRINT_REQUEST:
			let ticketsInfo = {};
			if (payload.listType === TICKETS_LIST_TYPE.PENDING) {
				ticketsInfo = state.pendings.find((p) => p.id === payload.id).ticketsInfo;
			} else if (payload.listType === TICKETS_LIST_TYPE.SETTLED) {
				ticketsInfo = state.settled.find((p) => p.id === payload.id).ticketsInfo;
			} else if (payload.listType === null) {
				if (state.pendings.some((p) => p.id === payload.id)) {
					ticketsInfo = state.pendings.find((p) => p.id === payload.id).ticketsInfo;
				} else if (state.settled.some((p) => p.id === payload.id)) {
					ticketsInfo = state.settled.find((p) => p.id === payload.id).ticketsInfo;
				}
			}

			const updated = { ...ticketsInfo };
			if (payload.type === TICKET_TYPE.BET) {
				updated.betTicketPrintRequestState = payload.state;
			} else if (payload.type === TICKET_TYPE.CANCEL) {
				updated.cancelTicketPrintRequestState = payload.state;
			} else if (payload.type === TICKET_TYPE.PAYOUT) {
				updated.payoutTicketPrintRequestState = payload.state;
			}
			return {
				...state,
				pendings: payload.listType === TICKETS_LIST_TYPE.PENDING || payload.listType === null ? state.pendings.map((p) => (p.id !== payload.id ? p : { ...p, ticketsInfo: updated })) : state.pendings,
				settled: payload.listType === TICKETS_LIST_TYPE.SETTLED || payload.listType === null ? state.settled.map((p) => (p.id !== payload.id ? p : { ...p, ticketsInfo: updated })) : state.settled
			};
		case TICKET_CONFIRMATION_BEFORE:
			return {
				...state,
				confirmation: {
					...state.confirmation,
					isLoading: true
				}
			};
		case TICKET_CONFIRMATION_AFTER:
			return {
				...state,
				confirmation: {
					...state.confirmation,
					isLoading: false
				}
			};
		case SET_TICKET_CONFIRMATION:
			return {
				...state,
				confirmation: {
					...state.confirmation,
					type: payload.type,
					ticketId: payload.ticketId,
					amount: payload.amount,
					betId: payload.betId,
					jackpotLevelType: payload.jackpotLevelType
				}
			};
		case CLOSE_TICKET_CONFIRMATION:
			return {
				...state,
				confirmation: {
					...state.confirmation,
					type: null,
					ticketId: null,
					amount: null,
					betId: null
				}
			};
		case VIEWING_TICKET_BEFORE:
			return {
				...state,
				isTicketLoading: true
			};
		case VIEWING_TICKET_AFTER:
			return {
				...state,
				isTicketLoading: false
			};
		case SET_VIEWING_TICKET:
			return {
				...state,
				viewingTicket: payload.ticket
			};
		case UPDATE_VIEWING_TICKET:
			let confirmation = { ...state.confirmation };
			if (payload.ticket.id === Number(confirmation.ticketId) && confirmation.type === TICKET_CONFIRMATION_MODAL_TYPE.CANCEL && payload.ticket.state !== BET_STATE.PENDING) {
				confirmation = {
					...state.confirmation,
					type: null,
					ticketId: null,
					amount: null,
					betId: null,
					jackpotLevelType: null
				};
			}

			return {
				...state,
				viewingTicket: state.viewingTicket && state.viewingTicket.id === payload.ticket.id ? (
					{
						...payload.ticket, 
						ticketsInfo: state.viewingTicket.ticketsInfo, 
						bets: state.viewingTicket.bets,
						wonJackpots: state.viewingTicket?.wonJackpots ?? []
					}
				) : state.viewingTicket,
				confirmation: confirmation
			};
		case UPDATE_VIEWING_TICKET_BET:
			const viewingTicket = state.viewingTicket ? { ...state.viewingTicket, bets: state.viewingTicket.bets ? state.viewingTicket.bets.map((bet) => (bet.id === payload.bet.id ? payload.bet : bet)) : state.viewingTicket.bets } : state.viewingTicket;
			const bets = viewingTicket?.bets ?? [];
			let conf = { ...state.confirmation };

			if (
				viewingTicket &&
				viewingTicket.id === Number(conf.ticketId) &&
				((conf.type === TICKET_CONFIRMATION_MODAL_TYPE.CANCEL && !isBetslipCancelable(viewingTicket)) ||
					(conf.type === TICKET_CONFIRMATION_MODAL_TYPE.BET_CANCEL &&
						bets.some((b) => b.id === payload.bet.id) &&
						!bets.some((b) => (b.allowCancel || (b.state === BET_STATE.CANCELLED && viewingTicket.type === BETSLIP_MODES.SINGLE)) && b.id === payload.bet.id)))
			) {
				conf = {
					...state.confirmation,
					type: null,
					ticketId: null,
					amount: null,
					betId: null,
					jackpotLevelType: null
				};
			}

			return {
				...state,
				viewingTicket: viewingTicket,
				confirmation: conf
			};
		case SHOW_VIEWING_TICKET:
			return {
				...state,
				showTicket: true
			};
		case HIDE_VIEWING_TICKET:
			return {
				...state,
				showTicket: false,
				viewingTicket: null
			};
		case SET_PRINTING_TICKET:
			return {
				...state,
				printingTicket: payload.ticket
			};
		case REMOVE_PRINTING_TICKET:
			return {
				...state,
				printingTicket: null
			};
		case SET_REPRINT_LAST_TICKET_SHOW:
			return {
				...state,
				reprintLastTicket: {
					...state.reprintLastTicket,
					show: payload.show
				}
			};
		case SET_LAST_REPRINT_TICKET_BEFORE:
			return {
				...state,
				reprintLastTicket: {
					...state.reprintLastTicket,
					isLoading: true
				}
			};
		case SET_LAST_REPRINT_TICKET_AFTER:
			return {
				...state,
				reprintLastTicket: {
					...state.reprintLastTicket,
					isLoading: false
				}
			};
		case SET_LAST_REPRINT_TICKET:
			return {
				...state,
				reprintLastTicket: {
					...state.reprintLastTicket,
					ticket: payload.ticket
				}
			};
		default:
			return state;
	}
};
