import  { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setStake, setOnFocusBets, setOnBlurBets, setOnBlurBetsWithTimeout } from "store/actions/betslip/betslip.actions";
import useEvent from "hooks/useEvent";
import { numberWithSpaces, isValidAmount } from "utils/common";
import { BETSLIP_MODES } from "constants/betslip.constants";
import betType from "types/bet.type";
import sessionType from "types/session.type";

/** Stake section component for betslip*/
const StakeInput = ({ stake, setStake, session, useBonus, setOnFocusBets, setOnBlurBets, setOnBlurBetsWithTimeout, mode, bets }) => {
	const { t } = useTranslation();

	/** Function which fires on stake input change
	 * @function
	 * @param {object} e - event object
	 * @memberOf StakeInput
	 */
	const onChange = (e) => {
		if (useBonus) {
			return;
		}
		const value = e.target.value.replace(/\s/g, "");
		if (isValidAmount(value, session?.currency?.decimalCount ?? 0)) setStake(value);
	};

	const inputRef = useRef();

	const onFocus = useEvent(() => {
		setOnBlurBets();
		setOnFocusBets();
	});

	const onBlur = useEvent(() => {
		setOnBlurBetsWithTimeout();
	});

	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.addEventListener("focus", onFocus);
				inputRef.current.addEventListener("blur", onBlur);
			}
		}, 0);
		return () => {
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.removeEventListener("focus", onFocus);
					inputRef.current.removeEventListener("blur", onBlur);
				}
			}, 0);
		};
	}, []);

	useEffect(() => {
		if (bets.length === 0 || (mode === BETSLIP_MODES.SINGLE && bets.every((bet) => bet.stake === ""))) {
			setStake("");
		}
	}, [mode, bets]);

	return (
		<div className="vs--stake-input vs--mb-8 vs--flex-equal">
			<span className="vs--stake-input-label vs--font-regular vs--font-smalest">{t("bet.totalStake")}</span>
			<input ref={inputRef} disabled={bets.length === 0 || useBonus} className="vs--main-stake-input vs--font-smallest vs--font-regular vs--pr-16 vs--pl-16 vs--pt-10 vs--pb-10 vs--mt-8" value={numberWithSpaces(stake)} onChange={onChange} />
		</div>
	);
};

/** StakeInput propTypes
 * PropTypes
 */
StakeInput.propTypes = {
	/** Redux action to set common stake in betslip */
	setStake: PropTypes.func,
	/** Redux state property, common stake of betslip */
	stake: PropTypes.string,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool,
	/** Redux action to set focused stake input identifiers */
	setOnFocusBets: PropTypes.func,
	/** Redux action to clear focused input data */
	setOnBlurBets: PropTypes.func,
	/** Redux action to clear focused input data with using timeout */
	setOnBlurBetsWithTimeout: PropTypes.func,
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType)
};

const mapStateToProps = (state) => {
	return {
		stake: state.betslip.stake,
		session: state.auth.session,
		useBonus: state.bonuses.standard.useBonus,
		mode: state.betslip.mode,
		bets: state.betslip.bets
	};
};

const mapDispatchToProps = (dispatch) => ({
	setStake: (stake) => {
		dispatch(setStake(stake));
	},
	setOnFocusBets: () => {
		dispatch(setOnFocusBets({ main: true }));
	},
	setOnBlurBets: () => {
		dispatch(setOnBlurBets());
	},
	setOnBlurBetsWithTimeout: () => {
		dispatch(setOnBlurBetsWithTimeout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(StakeInput);
