import  { Fragment } from "react";

import ActivateComponent from "components/pages/activate";

const ActivateRoute = () => {
	return (
		<Fragment>
			<ActivateComponent />
		</Fragment>
	);
};

export default ActivateRoute;
