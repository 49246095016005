import { mergeClassNames } from "utils/common";

const ContentGroup = ({ title, children, className, titleClassName, contentClassName, style = null, titleStyle = null, contentStyle = null }) => {
	return (
		<div style={style} className={mergeClassNames("content-group", className)}>
			<div style={titleStyle} className={mergeClassNames("content-group-title", titleClassName)}>
				{title}
			</div>
			<div style={contentStyle} className={mergeClassNames("content-group-content", contentClassName)}>
				{children}
			</div>
		</div>
	);
};
export default ContentGroup;
