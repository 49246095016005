import { useSelector } from "react-redux";
import AmountNums from "components/jackpotInfo/amountNums";

import { useOutletContext } from 'react-router';

import { buildPathToStaticFolderOfCDN, mergeClassNames, toFixed } from "utils/common";

import { MAX_HIT_AMOUNT_NUM_LENGTH } from "constants/bonus.constants";

const JACKPOT_LEVELS = {
	"level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-1.svg"),
	"level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-2.svg"),
	"level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-4.svg")
}

const getSessionCurrencyDecimalCount = (state) => state.auth.session?.currency?.decimalCount;
const getSessionCurrencySymbol = (state) => state.auth.session?.currency?.symbol;

const Pools = ({ data, className = "", showLevelIcon = false }) => {
	const decodedData = useOutletContext();

	const currencyDecimalCount = useSelector(getSessionCurrencyDecimalCount) ?? decodedData?.currencyDecimalCount ?? 0;
	const currencySymbol = useSelector(getSessionCurrencySymbol) ?? decodedData?.currencySymbol;
	const maxNums = Array.from({ length: MAX_HIT_AMOUNT_NUM_LENGTH + currencyDecimalCount });

	return (
		<div className={mergeClassNames("vs--jackpot-pools vs--flex vs--flex-col", className)}>
			{data?.map((level) => (
				<div
					key={level.levelType}
					className={mergeClassNames(
						"vs--jackpot-pools-item vs--flex vs--align-center vs--justify-center vs--pl-8 vs--pr-8 vs--pt-6 vs--pb-6",
						`vs--jackpot-pools-item-${level.levelType}`
					)}
				>
					{showLevelIcon && (
						<img className="vs--jackpot-pools-item-level-icon" src={JACKPOT_LEVELS[`level-${level.levelType}`]} />
					)}
					<div className="vs--flex vs--flex-equal vs--flex-col vs--align-center vs--justify-center">
						<span className={mergeClassNames(
							"vs--jackpot-pools-item-title vs--font-smallest vs--font-medium"
						)}>
							{level.poolName}
						</span>
						<AmountNums
							className="vs--jackpot-pools-item-amount"
							maxNums={maxNums}
							amount={toFixed(level?.amount, currencyDecimalCount)}
							currencySymbol={currencySymbol}
						/>
					</div>
				</div>
			))}
		</div>
	)
};

export default Pools;