import { Children, useEffect, useImperativeHandle, useInsertionEffect, useMemo, useState } from "react";
import LiveMonitorAnimationContext from "contexts/liveMonitorAnimationContext";

const LiveMonitorAnimationProvider = ({ children, perChildAnimationDelay, initialMountValue, startAnimation, perPage, pageCount }) => {
	const getDefaultState = () => new Array(pageCount).fill(initialMountValue);
	const [state, setState] = useState(getDefaultState);

	useEffect(() => {
		if (startAnimation) {
			setState((prev) => {
				const index = prev.findIndex((v) => v === false);
				if (index === -1) {
					return prev;
				}

				return prev.map((v, i) => (i === index ? true : v));
			});
		}
	}, [startAnimation]);

	return (
		<LiveMonitorAnimationContext.Provider
			value={useMemo(
				() => ({
					state,
					setState,
					perChildAnimationDelay,
					perPage
				}),
				[state, setState, perChildAnimationDelay, perPage]
			)}
		>
			{children}
		</LiveMonitorAnimationContext.Provider>
	);
};
export default LiveMonitorAnimationProvider;
