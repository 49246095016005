const LocalStorageUtils = {
	/** Check if localstorage is avalable
	 * @function
	 * @returns {boolean}
	 */
	isAvailable: () => {
		try {
			let valueToStore = "localstorage-test";
			let key = "localstorage-test";
			localStorage.setItem(key, valueToStore);
			let recoveredValue = localStorage.getItem(key);
			localStorage.removeItem(key);
			return recoveredValue === valueToStore;
		} catch (e) {
			return false;
		}
	},
	/** Set in localstorage
	 * @function
	 * @param {string} key - key
	 * @param {Object} value - value
	 */
	set: (key, value) => {
		if (LocalStorageUtils.isAvailable()) localStorage.setItem(key, JSON.stringify(value));
	},
	/** Clear localstorage
	 * @function
	 */
	clear: () => {
		if (LocalStorageUtils.isAvailable()) localStorage.clear();
	},
	/** Remove from localstorage
	 * @function
	 * @param {string} key - key
	 */
	remove: (key) => {
		if (LocalStorageUtils.isAvailable()) localStorage.removeItem(key);
	},
	/** Get from localstorage
	 * @function
	 * @param {string} key - key
	 * @returns {Object}
	 */
	get: (key) => {
		if (LocalStorageUtils.isAvailable()) {
			var data = localStorage.getItem(key);
			return JSON.parse(data);
		}
		return undefined;
	}
};

export default LocalStorageUtils;
