import  { useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

/** Video Player Layers */
const Layers = ({ onPlay, error }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--video-layers">
			{error ? (
				<div className="vs--video-layer vs--video-layer-error vs--flex vs--align-center vs--justify-center">
					<i className="ic_noVideo" />
					<span className="vs--title vs--font-small vs--pt-8">{t("common.noVideoFound")}</span>
				</div>
			) : (
				<div className="vs--video-layer vs--video-layer-playpause vs--flex vs--align-center vs--justify-center" onClick={onPlay} data-action="playpause">
					<i className="ic_video-play"></i>
				</div>
			)}
		</div>
	);
};

/** Layers propTypes
 * PropTypes
 */
Layers.propTypes = {
	/** Function to call on layers play/pause button click */
	onPlay: PropTypes.func,
	/** Show error message */
	error: PropTypes.bool
};

export default Layers;
