const sessionStorageUtils = {
	/** Check if sessionstorage is avalable
	 * @function
	 * @returns {boolean}
	 */
	isAvailable: () => {
		try {
			let valueToStore = "sessionstorage-test";
			let key = "sessionstorage-test";
			sessionStorage.setItem(key, valueToStore);
			let recoveredValue = sessionStorage.getItem(key);
			sessionStorage.removeItem(key);
			return recoveredValue === valueToStore;
		} catch (e) {
			return false;
		}
	},
	/** Set in sessionStorage
	 * @memberof sessionStorageUtils
	 * @function
	 * @param {string} key - key
	 * @param {Object} value - value
	 */
	set: (key, value) => {
		if (sessionStorageUtils.isAvailable()) sessionStorage.setItem(key, JSON.stringify(value));
	},

	/** Clear sessionStorage
	 * @memberof sessionStorageUtils
	 * @function
	 */
	clear: () => {
		if (sessionStorageUtils.isAvailable()) sessionStorage.clear();
	},

	/** Remove from sessionStorage
	 * @memberof sessionStorageUtils
	 * @function
	 * @param {string} key - key
	 */
	remove: (key) => {
		if (sessionStorageUtils.isAvailable()) sessionStorage.removeItem(key);
	},

	/** Get from sessionStorage
	 * @memberof sessionStorageUtils
	 * @function
	 * @param {string} key - key
	 * @returns {Object}
	 */
	get: (key) => {
		if (sessionStorageUtils.isAvailable()) {
			var data = sessionStorage.getItem(key);
			return JSON.parse(data);
		}
		return undefined;
	}
};

export default sessionStorageUtils;
