import moment from "moment";
import PropTypes from "prop-types";

import Button from "components/ui/button";

import { getViewPrevContentActiveDate, getViewNextContentActiveDate, getDecadeRange, getCenturyRange } from "utils/calendar";
import { CALENDAR_VIEW } from "constants/calendar.constants";

const Navigation = ({ onNavigationTitleClick, nextIcon = <i className="ic_right" />, prevIcon = <i className="ic_left" />, minDate, maxDate, setSelectedDate, selectedDate, view, views }) => {
	const className = "vs--ui-calendar-navigation";
	const labelClassName = `${className}-label`;

	const viewPrevContentActiveDate = getViewPrevContentActiveDate(view, selectedDate);
	const viewNextContentActiveDate = getViewNextContentActiveDate(view, selectedDate);

	const getDisabledState = (type, date) => {
		switch (view) {
			case CALENDAR_VIEW.DECADE:
				const [startDate, endDate] = getDecadeRange(type === "prev" ? viewPrevContentActiveDate : viewNextContentActiveDate);

				return type === "prev" ? date.year() >= endDate.getFullYear() : date.year() <= startDate.getFullYear();
			case CALENDAR_VIEW.YEAR:
				return date.year() === selectedDate.year();
			case CALENDAR_VIEW.MONTH:
				return date.year() === selectedDate.year() && date.month() === selectedDate.month();
		}
	};

	const isPrevBtnDisabled = getDisabledState("prev", minDate);
	const isNextBtnDisabled = getDisabledState("next", maxDate);

	const getValidActiveDate = (type) => {
		let nextActiveStartDate;

		if (type === "prev") {
			nextActiveStartDate = minDate.isAfter(viewPrevContentActiveDate) ? minDate : viewPrevContentActiveDate;
		} else {
			nextActiveStartDate = maxDate.isBefore(viewNextContentActiveDate) ? maxDate : viewNextContentActiveDate;
		}

		return moment(nextActiveStartDate);
	};

	const onPrevBtnClick = () => {
		if (!isPrevBtnDisabled) {
			setSelectedDate(getValidActiveDate("prev"));
		}
	};

	const onNextBtnClick = () => {
		if (!isNextBtnDisabled) {
			setSelectedDate(getValidActiveDate("next"));
		}
	};

	const renderLabel = (date) => {
		const label = (() => {
			switch (view) {
				case CALENDAR_VIEW.CENTURY:
					return (
						<span onClick={() => onNavigationTitleClick(view)} className={`${labelClassName}-labelText-${view}`}>
							{getCenturyRange(date)
								.map((date) => moment(date).format("YYYY"))
								.join(" – ")}
						</span>
					);
				case CALENDAR_VIEW.DECADE:
					return (
						<span onClick={() => onNavigationTitleClick(CALENDAR_VIEW.CENTURY)} className={`${labelClassName}-labelText-${view}`}>
							{getDecadeRange(date)
								.map((date) => moment(date).format("YYYY"))
								.join(" – ")}
						</span>
					);
				case CALENDAR_VIEW.YEAR:
					return (
						<span onClick={() => onNavigationTitleClick(CALENDAR_VIEW.DECADE)} className={`${labelClassName}-labelText-${view}`}>
							{moment(date).format("YYYY")}
						</span>
					);
				case CALENDAR_VIEW.MONTH:
					return (
						<>
							<span onClick={() => onNavigationTitleClick(CALENDAR_VIEW.YEAR)}>{moment(date).format("MMM")}</span>
							<span onClick={() => onNavigationTitleClick(CALENDAR_VIEW.DECADE)}>{moment(date).format("YYYY")}</span>
						</>
					);
				default:
					return "";
			}
		})();

		return label;
	};

	return (
		<div className={className}>
			<div className={`${className}-left-arrows`}>
				{prevIcon && (
					<Button className={`${className}-arrow ${className}-prev-button`} disabled={isPrevBtnDisabled} onClick={onPrevBtnClick} type="button">
						{prevIcon}
					</Button>
				)}
			</div>
			<div>
				<Button className={labelClassName} type="button">
					<span className={`${labelClassName}-labelText`}>{renderLabel(selectedDate)}</span>
				</Button>
			</div>
			<div className={`${className}-right-arrows`}>
				{nextIcon && (
					<Button className={`${className}-arrow ${className}-next-button`} disabled={isNextBtnDisabled} onClick={onNextBtnClick} type="button">
						{nextIcon}
					</Button>
				)}
			</div>
		</div>
	);
};

Navigation.propTypes = {
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired,
	/** Callback that will be called when user clicks on navigation buttons */
	setSelectedDate: PropTypes.func.isRequired,
	/** Callback that will be called when user clicks on Navigation title */
	onNavigationTitleClick: PropTypes.func.isRequired,
	/** Navigation Right part next icon */
	nextIcon: PropTypes.node,
	/** Navigation Left part prev  icon */
	prevIcon: PropTypes.node,
	/** Moment object, minimum date for opened calendar active date select */
	minDate: PropTypes.object,
	/** Moment object, maximum date for opened calendar active date select */
	maxDate: PropTypes.object,
	/** Calendar content view, is showing calendar content */
	view: PropTypes.oneOf(Object.values(CALENDAR_VIEW)),
	/** Calendar content available views, that are showing calendar content */
	views: PropTypes.array
};

export default Navigation;
