import { useTranslation } from "react-i18next";
import moment from "moment";

import AmountNums from 'components/jackpotInfo/amountNums';
import {  mergeClassNames, toFixed, isNullish } from "utils/common";

import { JACKPOT_LEVEL, JACKPOT_LEVELS_ICONS, MAX_HIT_AMOUNT_NUM_LENGTH } from "constants/bonus.constants";

import { DATE_TIME_FORMAT } from "constants/date.constants";

const LEVELS_HIERARCHY = {
	[JACKPOT_LEVEL.BRONZE]: [JACKPOT_LEVEL.BRONZE],
	[JACKPOT_LEVEL.SILVER]: [JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.BRONZE],
	[JACKPOT_LEVEL.GOLD]: [JACKPOT_LEVEL.GOLD, JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.BRONZE]
}

const Card = ({
	className = "",
	currencyDecimalCount = 0,
	currencyCode,
	currencySymbol,
	jackpot
}) => {
	const { t } = useTranslation();

	const maxNums = Array.from({ length: MAX_HIT_AMOUNT_NUM_LENGTH + currencyDecimalCount });

	return (
		<div className={mergeClassNames(
			className,
			"vs--flex vs--flex-col vs--justify-between",
			"vs--jackpot-content-levels-level-card",
			`vs--jackpot-content-levels-level-card-${jackpot.levelType}`
		)}>
			<div className="vs--jackpot-content-levels-level-card-content vs--flex vs--flex-col vs--justify-start vs--align-center vs--pt-32 vs--pb-32">
				<div className="vs--jackpot-content-levels-level-card-content-body vs--flex vs--flex-col vs--justify-center vs--align-center">
					<span className="vs--jackpot-content-levels-level-card-content-body-title vs--font-bold vs--font-big">{jackpot.poolName}</span>
					<img src={JACKPOT_LEVELS_ICONS[`level-${jackpot.levelType}`]} />

					<div className={mergeClassNames(
						"vs--jackpot-content-levels-level-card-content-body-amount vs--jackpot-content-levels-level-card-content-body-amount-countdown",
						"vs--flex vs--align-center"
					)}>
						<AmountNums
							maxNums={maxNums}
							amount={toFixed(jackpot?.amount || 0, currencyDecimalCount)}
							currencySymbol={currencySymbol}
						/>
					</div>

					<div className="vs--jackpot-content-levels-level-card-content-body-min-bet">
						<span className="vs--jackpot-content-levels-level-card-content-body-min-bet-title vs--font-big vs--font-medium ">{t('cashier.minBet')}:&nbsp;</span>
						<span className="vs--jackpot-content-levels-level-card-content-body-min-bet-value vs--font-big vs--font-medium">{jackpot.minBetAmount}&nbsp;{currencyCode}</span>
					</div>
				</div>

				<div className="vs--jackpot-content-levels-level-card-content-levels vs--flex vs--mt-8">
					{LEVELS_HIERARCHY[jackpot.levelType].map((level) => (
						<img key={level} className="vs--jackpot-content-levels-level-card-content-levels-level" src={JACKPOT_LEVELS_ICONS[`level-${level}`]} />
					))}
				</div>

				<div className="vs--jackpot-content-levels-level-card-content-footer vs--flex vs--align-center vs--justify-between vs--pl-16 vs--pr-16">
					<div className="vs--font-big vs--font-medium">
						<span className="vs--jackpot-content-levels-level-card-content-footer-title">{t('cashier.lastWinner')}:&nbsp;</span>
						<span className="vs--jackpot-content-levels-level-card-content-footer-value">{isNullish(jackpot.lastWinTime) || isNullish(jackpot.betSlipId) ? "-" : `${t('common.id')} ${jackpot.betSlipId}`}</span>
					</div>
					<div className="vs--font-big vs--font-medium">
						<span className="vs--jackpot-content-levels-level-card-content-footer-title">{t('common.date')}:&nbsp;</span>
						<span className="vs--jackpot-content-levels-level-card-content-footer-value">{jackpot.lastWinTime === null ? "-" : moment.utc(jackpot.lastWinTime).local().format(DATE_TIME_FORMAT)}</span>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Card;