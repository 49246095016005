import { TICKET_CONFIRMATION_BEFORE, TICKET_CONFIRMATION_AFTER, SET_TICKET_CONFIRMATION, CLOSE_TICKET_CONFIRMATION } from "../../actionTypes";

export const setTicketConfirmationBefore = () => ({
	type: TICKET_CONFIRMATION_BEFORE
});

export const setTicketConfirmationAfter = () => ({
	type: TICKET_CONFIRMATION_AFTER
});

export const setTicketConfirmation = ({ type, ticketId, betId, amount, jackpotLevelType }) => ({
	type: SET_TICKET_CONFIRMATION,
	payload: { type, ticketId, betId, amount, jackpotLevelType }
});

export const closeTicketConfirmation = () => ({
	type: CLOSE_TICKET_CONFIRMATION
});
