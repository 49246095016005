import  { useState, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { CALENDAR_VIEW } from "constants/calendar.constants";

const Time = ({ view, selectedDate, value, onChange, closeCalendar }) => {
	const { t } = useTranslation();

	const [hour, setHour] = useState(value.format("HH"));
	const [minute, setMinute] = useState(value.format("mm"));

	const minuteInputRef = useRef(null);

	const isDisabled = view !== CALENDAR_VIEW.MONTH;

	/** Function handler for time input change
	 * @function
	 * @description keep only numeric characters
	 * @param {string} value - input value
	 * @param {string} type - hour or minute
	 * @memberOf DatePicker
	 */
	const onInputChange = (value, type) => {
		if (value.length > 1) {
			minuteInputRef.current.focus();
		}
		if (value.length > 2) return true;
		value = value.replace(/[^\d]/g, "");
		if (type === "hour") {
			setHour(value);
		} else if (type === "minute") {
			setMinute(value);
		}
	};

	/** Function handler for time input blur
	 * @function
	 * @description validate input value and update time
	 * @param {string} value - input value
	 * @param {string} type - hour or minute
	 * @memberOf DatePicker
	 */
	const onInputBlur = (value, type) => {
		if (type === "hour") {
			const newHour = Math.min(Math.max(0, Number(value)), 23);

			setHour(`${newHour < 10 ? "0" : ""}${newHour}`);
		} else if (type === "minute") {
			const newMinute = Math.min(Math.max(0, Number(value)), 59);

			setMinute(`${newMinute < 10 ? "0" : ""}${newMinute}`);
		}
	};

	/** Function to increment, decrement time
	 * @function
	 * @param {string} type - hour or minute
	 * @param {string} dir - up or down
	 * @memberOf DatePicker
	 */
	const changeTime = (type, dir) => {
		if (type === "hour") {
			setHour((hour) => {
				dir === "up" ? hour++ : hour--;
				if (hour > 23) hour = 0;
				if (hour < 0) hour = 23;

				return `${hour < 10 ? "0" : ""}${hour}`;
			});
		} else if (type === "minute") {
			setMinute((minute) => {
				dir === "up" ? minute++ : minute--;
				if (minute > 59) minute = 0;
				if (minute < 0) minute = 59;

				return `${minute < 10 ? "0" : ""}${minute}`;
			});
		}
	};

	/** Function which will fire on "ok" button click
	 * @function
	 * @memberOf DatePicker
	 */
	const handleOk = () => {
		onChange(selectedDate.clone().hour(Number(hour)).minute(Number(minute)));
		closeCalendar();
	};

	/** Function which will fire on "cancel" button click
	 * @function
	 * @memberOf DatePicker
	 */
	const handleCancel = () => {
		closeCalendar();
	};

	return (
		<div className="vs--calendar-foot">
			<div className="vs--calendar-foot-time vs--flex vs--justify-end vs--align-center vs--pr-8">
				<div className="vs--calendar-foot-time-item">
					<div className={`vs--calendar-foot-time-item-control${isDisabled ? " vs--calendar-foot-time-item-control-disabled" : ""} vs--calendar-foot-time-item-up vs--flex vs--justify-center vs--align-center vs--mb-4`} onClick={() => changeTime("hour", "up")}>
						<i className="ic_down vs--font-big"></i>
					</div>
					<input className={`vs--calendar-foot-time-item-field${isDisabled ? " vs--calendar-foot-time-item-field-disabled" : ""}`} type="text" disabled={isDisabled} value={hour} onChange={(e) => onInputChange(e.target.value, "hour")} onBlur={(e) => onInputBlur(e.target.value, "hour")} />
					<div className={`vs--calendar-foot-time-item-control${isDisabled ? " vs--calendar-foot-time-item-control-disabled" : ""} vs--calendar-foot-time-item-down vs--flex vs--justify-center vs--align-center vs--mt-4`} onClick={() => changeTime("hour", "down")}>
						<i className="ic_down vs--font-big"></i>
					</div>
				</div>
				<span className="vs--pl-8 vs--pr-8 vs--calendar-foot-time-separator vs--font-bigest">:</span>
				<div className="vs--calendar-foot-time-item">
					<div className={`vs--calendar-foot-time-item-control${isDisabled ? " vs--calendar-foot-time-item-control-disabled" : ""} vs--calendar-foot-time-item-up vs--flex vs--justify-center vs--align-center vs--mb-4`} onClick={() => changeTime("minute", "up")}>
						<i className="ic_down vs--font-big"></i>
					</div>
					<input
						ref={minuteInputRef}
						className={`vs--calendar-foot-time-item-field${isDisabled ? " vs--calendar-foot-time-item-field-disabled" : ""}`}
						type="text"
						disabled={isDisabled}
						value={minute}
						onChange={(e) => onInputChange(e.target.value, "minute")}
						onBlur={(e) => onInputBlur(e.target.value, "minute")}
					/>
					<div className={`vs--calendar-foot-time-item-control${isDisabled ? " vs--calendar-foot-time-item-control-disabled" : ""} vs--calendar-foot-time-item-down vs--flex vs--justify-center vs--align-center vs--mt-4`} onClick={() => changeTime("minute", "down")}>
						<i className="ic_down vs--font-big"></i>
					</div>
				</div>
			</div>
			<div className="vs--calendar-foot-buttons vs--flex vs--justify-end vs--align-center">
				<button type="button" className="vs--button vs--font-medium vs--font-normal vs--pl-16 vs--pr-16 vs--pt-10 vs--pb-10 vs--calendar-foot-button-cancel" onClick={handleCancel}>
					{t("common.cancel")}
				</button>
				<button type="button" className={`vs--button vs--font-medium vs--font-normal vs--pl-16 vs--pr-16 vs--pt-10 vs--pb-10 vs--calendar-foot-button-ok${isDisabled ? " vs--calendar-foot-button-ok-disabled" : ""}`} disabled={isDisabled} onClick={handleOk}>
					{t("common.ok")}
				</button>
			</div>
		</div>
	);
};

Time.propTypes = {
	/** Calendar content view, is showing calendar content */
	view: PropTypes.oneOf(Object.values(CALENDAR_VIEW)),
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired,
	/** Moment object, DatePicker input value */
	value: PropTypes.object.isRequired,
	/** Callback that will be called when user set calendar value in maxDetail view */
	onChange: PropTypes.func.isRequired,
	/** Callback that will be called to close Calendar */
	closeCalendar: PropTypes.func.isRequired
};

export default Time;
