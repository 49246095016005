import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router';

import JackpotAnimations from 'components/pages/jackpotLiveInfo/animations';
import Levels from "components/jackpotInfo/levels";

const getJackpotInfo = (state) => state.bonuses.jackpot.data;

const ActiveJackpot = () => {
	const jackpotInfo = useSelector(getJackpotInfo);

	const decodedData = useOutletContext();

	return (
		<div className="vs--active-jackpot-live-info vs--flex vs--align-center vs--justify-center">
			<JackpotAnimations getMainContainer={() => document.getElementsByClassName("vs--active-jackpot-live-info")[0]} />

			<Levels
				data={jackpotInfo?.levels}
				currencyDecimalCount={decodedData?.currencyDecimalCount}
				currencySymbol={decodedData?.currencySymbol}
				currencyCode={decodedData?.currencyCode}
				isInOtherRoute={true}
			/>
		</div>
	)
}

export default ActiveJackpot;