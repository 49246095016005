export const TICKET_GENERAL_DETAILS = {
	BETSHOP_NAME: 1,
	BETSHOP_ADDRESS: 2,
	CASHIER_ID: 4,
	TICKET_ID: 8,
	PRINTED_DATETIME: 16,
	VALIDATION_DATE: 32
};

export const TICKET_MARKET_DETAILS = {
	TEAM_NAMES: 1,
	MARKETS: 2,
	BET_TYPE: 4,
	STAKE: 8,
	MAX_POSSIBLE_WIN: 16,
	MAX_WIN_PER_TICKET: 32,
	GAME_TYPE: 64,
	DRAW_ID: 128,
	START_TIME: 256,
	POSSIBLE_WIN: 512,
	STATE: 1024
};

export const TICKET_PREVIEW_VALUES = {
	BETSHOP_NAME: "Name",
	BETSHOP_ADDRESS: "Address",
	TICKET_ID: 132141,
	DATETIME_PRINTED: "12.12.16 12:3:120",
	CASHIER_ID: 126,
	BET_TYPE: "Ordinar",
	STAKE: "500$",
	MAX_POSSIBLE_WIN: "1263$",
	MAX_WIN_PER_TICKET: "15500$",
	BARCODE_NUMBERS: "5 54 74 56 488 17 5 7",
	CUSTOM_TEXT: "Custom Text"
};

export const TICKET_TYPE = {
	BET: 1,
	CANCEL: 2,
	PAYOUT: 3
};

export const TICKET_PRINT_REQUEST_STATE = {
	NONE: 0,
	PENDING: 1,
	APPROVED: 2,
	DECLINED: 3
};

export const TICKET_REDEMPTION_DATE_VALUES = {
	NEVER: 1,
	SEVEN_DAYS: 2,
	FORTEEN_DAYS: 4,
	THIRTHY_DAYS: 8,
	FORTYFIVE_DAYS: 16,
	SIXTY_DAYS: 32,
	NINTY_DAYS: 64,
	HUNDREDTWENTY_DAYS: 128
};

export const RESULTS_PRINTING_OPTIONS_VALUES = [1, 2, 3];
