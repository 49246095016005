import { Fragment } from "react"
import MarketOdd from "./marketOdd"
import { numberWithSpaces } from 'utils/common';

const MarketItem = ({ marketsInfo, marketContainer, isOddDisabled, isOddSelected, handleOddClick, bodyProps, bodyElementProps, ...props }) => {
	return (
		<div className='vs--markets-list-item' data-market={marketsInfo.name} {...props}>
			<div className='vs--markets-list-item-inner'>
				<div className="vs--markets-list-item-header vs--mb-12">
					<span className="vs--title vs--font-exstrasmall vs--font-medium vs--font-condensed">
						{marketsInfo.title}
					</span>
				</div>
				<div className={`vs--markets-list-item-body vs--flex vs--flex-${marketsInfo.gridBy}`} style={Object.assign({ gap: 8 }, bodyProps)}>

					{marketContainer.map((marketRow, i) => {
						if (!marketRow.length) {
							return <Fragment key={i} />
						}

						return (
							<div key={i} className={`vs--flex vs--flex-${marketsInfo.oddBy}`} style={Object.assign({ gap: 8 }, bodyElementProps)}>
								{marketRow.map((odd, j) => (
									<MarketOdd
										key={`${i}:${j}`}
										odd={odd}
										isOddDisabled={isOddDisabled}
										isOddSelected={isOddSelected}
										handleOddClick={handleOddClick}
										numberWithSpaces={numberWithSpaces}
									/>
								))}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
export default MarketItem