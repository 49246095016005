import { PENALTY_SHOOTOUT_MARKETS_GROUPS } from "constants/market.constants";
import MarketItem from "../marketItem";
import MarketItemByTab from "../marketItemByTab";

const MainMarketContents = ({ groupedMarkets, ...props }) => {

	const renderMarketItem = (groupName, propForOverride) => {
		return (
			groupedMarkets[groupName]
				? <MarketItem {...groupedMarkets[groupName]} {...Object.assign({}, props, propForOverride)} />
				: null
		)
	}

	const renderMarketItemNyTab = (groupName, propForOverride, panelProps) => {
		return (
			groupedMarkets[groupName]
				? <MarketItemByTab panelProps={panelProps} {...groupedMarkets[groupName]} {...Object.assign({}, props, propForOverride)} />
				: null
		)
	}

	return (
		<div className="vs--flex vs--flex-col">
			<div className="vs--flex vs--flex-row">
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, { style: { marginLeft: 32, marginTop: 18, marginBottom: 24 } })
					}
					<div className="vs--markets-border-regular vs--markets-border-regular-horizontal vs--ml-16" />
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME, { style: { marginLeft: 32, marginTop: 18, marginBottom: 24 } })
					}
					<div className="vs--markets-border-regular vs--markets-border-regular-horizontal vs--ml-16" />
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT, { style: { marginLeft: 32, marginTop: 18, marginBottom: 24, flexGrow: 1 } })
					}
				</div>
				<div className="vs--markets-border-regular" />
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT, { style: { marginLeft: 10, marginTop: 18, marginBottom: 24 } })
					}
				</div>
				<div className="vs--markets-border-regular" />
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItemNyTab(PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE, null, { style: { marginLeft: 16, marginTop: 18, marginBottom: 24 } })
					}
					<div className="vs--markets-border-regular vs--markets-border-regular-horizontal" />
					<div className="vs--flex vs--flex-row">
						{
							renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, { style: { marginLeft: 16, marginTop: 18, marginBottom: 24 } })
						}
						<div className="vs--markets-border-regular" />
						{
							renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP, { style: { marginLeft: 16, marginTop: 18, marginBottom: 24 } })
						}
					</div>
				</div>
			</div>
			<div className="vs--flex vs--flex-row vs--ml-16 vs--mb-24">
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, { style: { marginLeft: 16, marginTop: 18 } })
					}
				</div>
				<div className="vs--markets-border-regular" />
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, { style: { marginLeft: 10, marginTop: 18 } })
					}
				</div>
				<div className="vs--markets-border-regular" />
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, { style: { marginLeft: 16, marginTop: 18 } })
					}
				</div>
				<div className="vs--markets-border-regular" />
				<div className="vs--flex vs--flex-col">
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, { style: { marginLeft: 16, marginTop: 18 } })
					}
				</div>
			</div>
		</div>
	)
}
export default MainMarketContents