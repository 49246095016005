import LiveMonitorAnimationProvider from "providers/liveMonitorAnimationProvider";
import { mergeClassNames } from "utils/common";

const AnimatedPages = ({ children, perChildAnimationDelay = 0, initialMountValue = false, startAnimation = true, perPage = true, style, className, pageCount = 0 }) => {
	return (
		<div style={style} className={mergeClassNames("live-monitor-animated-pages", className)}>
			<LiveMonitorAnimationProvider perChildAnimationDelay={perChildAnimationDelay} initialMountValue={initialMountValue} startAnimation={startAnimation} perPage={perPage} pageCount={pageCount}>
				{children}
			</LiveMonitorAnimationProvider>
		</div>
	);
};

export default AnimatedPages;
