import  { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import moment from "moment";
import { useTranslation } from "react-i18next";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { yesterday, weekago, monthAgo } from "utils/dateTime";

import { setSettledFilters, getSettledTickets, resetSettledFilters } from "store/actions/tickets/settled.actions";
import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";

import Input from "components/ui/input";
import Select from "components/ui/select";
import DatePicker from "components/ui/datePicker";

import settledTicketsFilterType from "types/settledTicketsFilter.type";

/** Filters section component in history page */
const Filters = ({ setSettledFilters, resetSettledFilters, getSettledTickets, filters }) => {
	const { t } = useTranslation();

	const [enableClear, setEnableClear] = useState(false);

	/** Set default filters */
	useEffect(() => {
		return () => {
			resetSettledFilters();
		};
	}, []);

	/** Update datepicker values on, period dropdown change */
	useEffect(() => {
		const TIME_FROM = moment(filters.period !== "24_hours" ? "00:00" : new Date(), TIME_FORMAT);
		const TIME_TO = moment(new Date(), TIME_FORMAT);

		let from_d, to_d;

		to_d = moment(new Date(), DATE_TIME_FORMAT);

		switch (filters.period) {
			case "24_hours":
				from_d = yesterday();
				break;
			case "1_week":
				from_d = weekago(1);
				break;
			case "2_weeks":
				from_d = weekago(2);
				break;
			case "1_month":
				from_d = monthAgo(1);
				break;
			case "3_months":
				from_d = monthAgo(3);
				break;
		}
		if (from_d) from_d.set({ hour: TIME_FROM.get("hour"), minute: TIME_FROM.get("minute") });
		if (to_d) to_d.set({ hour: TIME_TO.get("hour"), minute: TIME_TO.get("minute") });
		setSettledFilters({
			from: from_d.toDate(),
			to: to_d.toDate()
		});
	}, [filters.period]);

	/** Function which fires on "show" button click
	 * @function
	 * @memberOf Filters
	 */
	const handleShow = () => {
		setSettledFilters({ page: 1 });
		getSettledTickets();
	};

	/** Function which fires on "clear" button click
	 * @function
	 * @memberOf Filters
	 */
	const handleClear = () => {
		if (!enableClear) return;
		setEnableClear(false);
		resetSettledFilters();
		getSettledTickets();
	};

	/** Function , handler for inputs change
	 * @function
	 * @param {string} name - field name
	 * @param {string} value - field value
	 * @memberOf Filters
	 */
	const handleInputChange = (name, value) => {
		if (!/^[0-9]*$/.test(value) && value !== "") return;
		setSettledFilters({ [name]: value });
		setEnableClear(true);
	};

	/** Function , handler for select change
	 * @function
	 * @param {string} name - field name
	 * @param {string} value - field value
	 * @memberOf Filters
	 */
	const handleSelectChange = (name, value) => {
		setSettledFilters({ [name]: value });
		setEnableClear(true);
	};

	return (
		<Fragment>
			<div className="vs--flex vs--flex-row vs--filters vs--align-end vs--justify-between vs--mb-24">
				<div className="vs--flex-col vs--mr-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.periodOfTime")}</span>
					<Select value={filters.period} suffixIcon={<i className="ic_down"></i>} onChange={(e) => handleSelectChange("period", e)}>
						<Select.Option value="24_hours">{t("common.24Hours")}</Select.Option>
						<Select.Option value="1_week">{t("common.1Week")}</Select.Option>
						<Select.Option value="2_weeks">{t("common.2Weeks")}</Select.Option>
						<Select.Option value="1_month">{t("common.1Month")}</Select.Option>
						<Select.Option value="3_months">{t("common.3Months")}</Select.Option>
					</Select>
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item" id="vs--filters-item-from">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.from")}</span>
					<DatePicker value={moment(filters.from)} suffixIcon={<i className="ic_down"></i>} onChange={(value) => handleSelectChange("from", value.toDate())} maxDate={moment(filters.to)} />
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item" id="vs--filters-item-to">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.to")}</span>
					<DatePicker value={moment(filters.to)} suffixIcon={<i className="ic_down"></i>} onChange={(value) => handleSelectChange("to", value.toDate())} minDate={moment(filters.from)} maxDate={moment()} />
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("bet.betType")}</span>
					<Select suffixIcon={<i className="ic_down"></i>} onChange={(e) => handleSelectChange("type", e === "all" ? null : e)} value={filters.type || "all"}>
						<Select.Option value="all">{t("cashier.all")}</Select.Option>
						<Select.Option value={BETSLIP_MODES.SINGLE}>{t("bet.single")}</Select.Option>
						<Select.Option value={BETSLIP_MODES.MULTI}>{t("bet.multi")}</Select.Option>
					</Select>
				</div>

				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("bet.status")}</span>
					<Select suffixIcon={<i className="ic_down"></i>} onChange={(e) => handleSelectChange("state", e === "all" ? null : e)} value={filters.state || "all"}>
						<Select.Option value="all">{t("cashier.all")}</Select.Option>
						<Select.Option value={BET_STATE.WON}>{t("bet.won")}</Select.Option>
						<Select.Option value={BET_STATE.LOST}>{t("bet.lost")}</Select.Option>
						<Select.Option value={BET_STATE.CANCELLED}>{t("bet.cancelled")}</Select.Option>
						<Select.Option value={BET_STATE.REJECTED_BY_OPERATOR}>{t("bet.rejected")}</Select.Option>
					</Select>
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("cashier.ticketID")}</span>
					<Input onChange={(value) => handleInputChange("betSlipId", value)} value={filters.betSlipId} maxLength={18} placeholder={t("cashier.ticket_id_placeholder")} />
				</div>

				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.eventId")}</span>
					<Input onChange={(value) => handleInputChange("eventId", value)} value={filters.eventId} maxLength={18} placeholder={t("cashier.event_id_placeholder")} />
				</div>
				<div className="vs--flex-col vs--ml-8 vs--filters-button">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8"> </span>
					<button type="button" className="vs--button vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-smallest vs--font-medium" onClick={handleShow}>
						{t("common.show")}
					</button>
				</div>
				<div className="vs--flex-equal vs--flex-col vs--ml-8 vs--filters-button">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8"> </span>
					<button type="button" className={"vs--button vs--button-secondary vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-smallest vs--font-medium" + (!enableClear ? " vs--button-disabled" : "")} onClick={handleClear}>
						{t("cashier.clear")}
					</button>
				</div>
			</div>
		</Fragment>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to update settled tickets filters */
	setSettledFilters: PropTypes.func,
	/** Redux action to reset settled tickets filters */
	resetSettledFilters: PropTypes.func,
	/** Redux action to get settled tickets */
	getSettledTickets: PropTypes.func,
	/** Redux state property, current filters of bet history page */
	filters: settledTicketsFilterType
};

const mapStateToProps = (state) => {
	return {
		filters: state.tickets.filters
	};
};

const mapDispatchToProps = (dispatch) => ({
	setSettledFilters: (filters) => {
		dispatch(setSettledFilters(filters));
	},
	resetSettledFilters: () => {
		dispatch(resetSettledFilters());
	},
	getSettledTickets: () => {
		dispatch(getSettledTickets());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
