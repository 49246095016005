export const BETSLIP_MODES = {
	SINGLE: 1,
	MULTI: 2
};

export const BET_STATE = {
	PENDING: 1,
	RETURN: 2,
	WON: 4,
	SEMIWON: 8,
	SEMILOST: 16,
	LOST: 32,
	CANCELLED: 64,
	REJECTED_BY_OPERATOR: 128
};

export const BETSLIP_BETS_LIMIT = 30;
