import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import TicketIDForm from "components/tickets/ticketIDForm";
import ModalWrapper from "components/modalWrapper";
import WonJackpotsNotification from "./wonJackpotsNotification";

import onScan from "utils/scan";
import { insertAtCursor, removeAtCursor } from "utils/common";

import { getTicket, showTicket, closeTicket } from "store/actions/tickets/ticket.actions";
import { getBonus } from "store/actions/bonuses/bonuses.actions";
import { closeTicketConfirmation } from "store/actions/tickets/confirmation.actions";

import useIntegrationType from "hooks/useIntegrationType";


import sessionType from "types/session.type";
import historyType from "types/history.type";

const idCheckIds = {
	ticketId: "0",
	voucherId: "1"
};

/** Ticket ID Form Component */
const Tickets = ({
	coordinates,
	visible,
	onCancel,
	isTicketVisible,
	ticket,
	confirmationTicketId,
	getTicket,
	closeTicketConfirmation,
	showTicket,
	closeTicket,
	session,
	viewingBonus,
	getBonus
}) => {
	const { t } = useTranslation();
	const integrationType = useIntegrationType();

	const [inputValue, setInputValue] = useState("");
	const [activComponentKey, setActivComponentKey] = useState(idCheckIds.ticketId);
	const [wonJackpots, setWonJackpots] = useState(null);
	const inputRef = useRef(null);
	const coordinatesRef = useRef();

	const subTabs = [
		{
			key: idCheckIds.ticketId,
			element: t("cashier.ticketID")
		},
		{
			key: idCheckIds.voucherId,
			element: t("cashier.voucherID")
		}
	];

	/** Function, fires when scanning barcode
	 * @function
	 * @param {object} e - event object
	 * @memberOf TicketIDForm
	 */
	const handleScan = (e) => {
		let scanCode = e?.detail?.scanCode ?? null;
		if (scanCode) {
			scanCode = scanCode.replace(/vs/gi, "");
			if (scanCode.startsWith("0")) {
				scanCode = scanCode.substr(1);
			}
			loadTicket(scanCode);
		}
	};

	/** Function, fires on buttons click
	 * @function
	 * @param {string} value - button key
	 * @memberOf TicketIDForm
	 */
	const handleButtonClick = (event, value) => {
		switch (value) {
			case "back":
				removeAtCursor(inputRef.current);
				setInputValue(inputRef.current.value);
				break;
			case "enter":
				coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
				loadTicket(inputValue);
				break;
			default:
				insertAtCursor(inputRef.current, value + "");
				setInputValue(inputRef.current.value);
				break;
		}
	};

	/** Function, fires on input change
	 * @function
	 * @param {object} e - event object
	 * @memberOf TicketIDForm
	 */
	const handleInputChange = (e) => {
		const val = e.target.value.replace(/\D+/g, "");
		setInputValue(val);
	};

	/** Function, to load ticket
	 * @function
	 * @param {string} id - ticket id to load
	 * @memberOf TicketIDForm
	 */
	const loadTicket = (id) => {
		if (id) {
			switch (activComponentKey) {
				case idCheckIds.ticketId:
					if (ticket?.id !== Number(id) || (!isTicketVisible && !confirmationTicketId)) {
						if (confirmationTicketId !== null) {
							closeTicketConfirmation();
						}
						if (isTicketVisible) {
							closeTicket();
						}
						getTicket(id, (ticket) => {
							onCancel();

							if (ticket.wonJackpots?.length > 0) {
								setWonJackpots(ticket.wonJackpots);
							}
							else {
								showTicket();
							}
						});
					}
					break;
				case idCheckIds.voucherId:
					if (viewingBonus !== null) {
						return;
					}
					getBonus(id, () => onCancel());
					break;
				default:
					break;
			}
		}
	};

	/** Initialzie scan events */
	useEffect(() => {
		onScan.attachTo(document, {
			avgTimeByChar: 5
		});
		document.addEventListener("scan", handleScan);
		return () => {
			document.removeEventListener("scan", handleScan);
			onScan.detachFrom(document);
		};
	}, []);

	/** Reset input value, on modal open */
	useEffect(() => {
		if (visible) {
			setInputValue("");
			if (inputRef.current) {
				setTimeout(() => {
					inputRef.current.focus();
				}, 0);
			}
		} else {
			inputRef.current && inputRef.current.blur();
		}
	}, [visible]);

	return (
		<>
			<ModalWrapper
				title={t("cashier.idCheck")}
				visible={visible}
				onCancel={onCancel}
				coordinates={coordinates}
				classNames={{ content: "vs--ticket-id-form vs--ticket-id-form-modal", header: "vs--ticket-id-form-modal-header", body: "vs--ticket-id-form-modal-body" }}
				footer={null}
				centered={true}
				forceRender={true}
				subTabs={subTabs}
				onSubTabClick={(key) => setActivComponentKey(key)}
				activeSubTabKey={idCheckIds.ticketId}
			>
				<div className="vs--tickets-content">
					<div className="vs--tickets-content-body vs--flex vs--flex-col">
						<TicketIDForm
							inputRef={inputRef}
							inputValue={inputValue}
							onChange={handleInputChange}
							disabled={!visible || (idCheckIds.voucherId === activComponentKey && viewingBonus !== null)}
							onKeyDown={(e) => e.key === "Enter" && visible && !(idCheckIds.voucherId === activComponentKey && viewingBonus !== null) && loadTicket(inputValue)}
							onButtonClick={handleButtonClick}
						/>
					</div>
				</div>
			</ModalWrapper>
			{wonJackpots?.length > 0 && (
				<WonJackpotsNotification
					coordinates={coordinatesRef?.current}
					wonJackpots={wonJackpots}
					onCancel={() => setWonJackpots(null)}
				/>
			)}
		</>
	);
};

/** TicketIDForm propTypes
 * PropTypes
 */
Tickets.propTypes = {
	/** Css variables` viewport x, y coordinates and position top */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** If true the modal will be visible */
	visible: PropTypes.bool,
	/** Function, will br called on modal close */
	onCancel: PropTypes.func,
	/** Redux state property, confirmation ticket id */
	confirmationTicketId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Redux state property, the viewing ticket */
	ticket: historyType,
	/** Redux state property, if true ticket view modal will be visible */
	isTicketVisible: PropTypes.bool,
	/** Redux action to get ticket */
	getTicket: PropTypes.func,
	/** Redux action to close confirmation modal */
	closeTicketConfirmation: PropTypes.func,
	/** Redux action to show ticket viewing Modal */
	showTicket: PropTypes.func,
	/** Redux action, to close viewing ticket modal */
	closeTicket: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property of voucher */
	viewingBonus: PropTypes.object,
	/** Redux action to get voucher */
	getBonus: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		viewingBonus: state.bonuses.standard.viewingBonus,
		confirmationTicketId: state.tickets.confirmation.ticketId,
		ticket: state.tickets.viewingTicket,
		isTicketVisible: state.tickets.showTicket
	};
};

const mapDispatchToProps = (dispatch) => ({
	getTicket: (ticketId, cb) => {
		dispatch(getTicket(ticketId, cb));
	},
	closeTicketConfirmation: () => {
		dispatch(closeTicketConfirmation());
	},
	showTicket: () => {
		dispatch(showTicket());
	},
	closeTicket: () => {
		dispatch(closeTicket());
	},
	getBonus: (voucherId, cb) => {
		dispatch(getBonus(voucherId, cb));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
