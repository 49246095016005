import { v4 as uuidV4 } from "uuid";

import { SET_ALERT_MESSAGE, REMOVE_ALERT_MESSAGE, SET_ERROR_MESSAGE, SET_CURRENT_TIME } from "../../actionTypes";

export const setAlertMessage = (content, action, duration) => ({
	type: SET_ALERT_MESSAGE,
	payload: { content, id: uuidV4(), duration, action }
});

export const removeAlertMessage = (id) => ({
	type: REMOVE_ALERT_MESSAGE,
	payload: { id }
});

export const setErrorMessage = (message) => ({
	type: SET_ERROR_MESSAGE,
	payload: { message }
});

export const setCurrentTime = () => ({
	type: SET_CURRENT_TIME
});
