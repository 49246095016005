import  { useState, useRef, Fragment } from "react";

import TicketIDForm from "components/tickets/scanTicket";
import { getUrlVars } from "utils/common";

/** Ticket ID Form button in header */
const TicketIDFormButton = () => {
	const [showForm, setShowForm] = useState(false);
	const coordinatesRef = useRef();

	return (
		<Fragment>
			<button
				className={"vs--button vs--button-ticket vs--font-normal vs--flex vs--justify-center vs--align-center" + (getUrlVars()["isPreview"] ? " vs--header-section-actions-disabled" : "")}
				onClick={(event) => {
					coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
					getUrlVars()["isPreview"] ? false : setShowForm(true);
				}}
			>
				<i className="ic_barcode" />
			</button>
			<TicketIDForm coordinates={coordinatesRef?.current} visible={showForm} onCancel={() => setShowForm(false)} />
		</Fragment>
	);
};

export default TicketIDFormButton;
