import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BONUS_BEFORE, BONUS_AFTER, SET_VIEW_BONUS, HIDE_VIEWING_BONUS, SET_USE_BONUS, CLEAN_USE_BONUS } from "../../actionTypes";

const setBonusBefore = () => ({
	type: BONUS_BEFORE
});

const setBonusAfter = () => ({
	type: BONUS_AFTER
});

const setViewingBonus = (viewingBonus) => ({
	type: SET_VIEW_BONUS,
	payload: { viewingBonus }
});

export const closeBonus = () => ({
	type: HIDE_VIEWING_BONUS
});

export const getBonus = (id, cb) => {
	return (dispatch) => {
		dispatch(setViewingBonus(null));
		dispatch(setBonusBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BONUS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setViewingBonus(data));
				if (cb) {
					cb(data);
				}
			})
			.finally((ex) => {
				dispatch(setBonusAfter());
			});
	};
};

export const useBonus = (callback) => {
	return (dispatch) => {
		return Promise.resolve({ type: SET_USE_BONUS }).then((actionObj) => {
			dispatch(actionObj);
			if (typeof callback === "function") {
				callback();
			}
		});
	};
};
export const cleanUseBonus = () => ({
	type: CLEAN_USE_BONUS
});
