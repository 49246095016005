import LiveMarketsTiming from "./liveMarketsTiming";
import LiveMarketProgressLine from "./liveMarketProgressLine";
import { useTranslation } from "react-i18next";

const LiveMarketsProgress = () => {
	const { t } = useTranslation();
	return (
		<div className="monitor-live-progress">
			<div className="monitor-live-progress-text-container">
				<span className="monitor-live-progress-text vs--fs-26 vs--font-regular">{t("livemonitor.football_england_league.live", "live")}</span>
				<svg className="monitor-live-progress-text-container-border" xmlns="http://www.w3.org/2000/svg" width="2" height="84" viewBox="0 0 2 84" fill="none">
					<path d="M2 83.0455L2 0.954545C2 0.427365 1.55228 0 1 0C0.447715 0 0 0.427365 0 0.954545L0 83.0455C0 83.5726 0.447715 84 1 84C1.55228 84 2 83.5726 2 83.0455Z" fill="url(#paint0_linear_2367_10113)" />
					<defs>
						<linearGradient id="paint0_linear_2367_10113" x1="2" y1="0" x2="2" y2="84" gradientUnits="userSpaceOnUse">
							<stop stopColor="white" stopOpacity="0" />
							<stop offset="0.5" stopColor="white" stopOpacity="0.502" />
							<stop offset="1" stopColor="white" stopOpacity="0" />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div className="monitor-live-progressbar" style={{ flex: "1 1 50%" }}>
				<span className="monitor-live-progressbar-background monitor-live-progressbar-background-left"></span>
				<span className="monitor-live-progressbar-background monitor-live-progressbar-background-right"></span>

				<span className="monitor-live-progressbar-text">
					{t("livemonitor.football_england_league.matchtime")
						.split(" ")
						.map((txt) => (
							<span key={txt} className="vs--fs-26 vs--font-regular">
								{txt}
							</span>
						))}
				</span>

				<LiveMarketProgressLine />

				<span className="monitor-live-progressbar-slash monitor-live-progressbar-slash-1"></span>
				<span className="monitor-live-progressbar-slash monitor-live-progressbar-slash-2"></span>
				<span className="monitor-live-progressbar-slash monitor-live-progressbar-slash-3"></span>
				<span className="monitor-live-progressbar-slash monitor-live-progressbar-slash-4"></span>
			</div>
			<div className="monitor-live-progress-percent">
				<svg className="monitor-live-progress-percent-border" xmlns="http://www.w3.org/2000/svg" width="2" height="100%" viewBox="0 0 2 84" fill="none">
					<path d="M2 83.0455L2 0.954545C2 0.427365 1.55228 0 1 0C0.447715 0 0 0.427365 0 0.954545L0 83.0455C0 83.5726 0.447715 84 1 84C1.55228 84 2 83.5726 2 83.0455Z" fill="url(#paint0_linear_2367_10113)" />
					<defs>
						<linearGradient id="paint0_linear_2367_10113" x1="2" y1="0" x2="2" y2="84" gradientUnits="userSpaceOnUse">
							<stop stopColor="white" stopOpacity="0" />
							<stop offset="0.5" stopColor="white" stopOpacity="0.502" />
							<stop offset="1" stopColor="white" stopOpacity="0" />
						</linearGradient>
					</defs>
				</svg>
				<span className="monitor-live-progress-percent-text vs--fs-26 vs--font-regular">
					<LiveMarketsTiming />
				</span>
			</div>
		</div>
	);
};
export default LiveMarketsProgress;
