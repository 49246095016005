import  { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalWrapper from "components/modalWrapper";
import historyType from "types/history.type";
import { getSettledTickets } from "store/actions/tickets/settled.actions";
import { TICKETS_LIST_TYPE } from "constants/common.constants";
import TicketsList from "../list";
import NoData from "components/ui/noData";

const SwitcherPendingsSettled = ({ coordinates, defaultKey, onCancel, isPendingsLoading, pendings, isSettledLoading, settled, getSettledTickets, ...props }) => {
	const { t } = useTranslation();

	const subTabs = [
		{
			key: TICKETS_LIST_TYPE.PENDING,
			element: t("cashier.pendingTickets")
		},
		{
			key: TICKETS_LIST_TYPE.SETTLED,
			element: t("cashier.settledTickets")
		}
	];
	const [activComponentKey, setActivComponentKey] = useState(defaultKey ? defaultKey : subTabs[0].key);
	/** Load settled tickets */
	useEffect(() => {
		if (activComponentKey === TICKETS_LIST_TYPE.SETTLED) {
			getSettledTickets();
		}
	}, [activComponentKey]);

	return (
		<ModalWrapper
			coordinates={coordinates}
			title={t("cashier.tickets")}
			classNames={{ content: "vs--pending-modal" }}
			onCancel={onCancel}
			visible={true}
			subTabs={subTabs}
			onSubTabClick={(key) => setActivComponentKey(key)}
			modalContentWrapperStyles={{
				height: "740px"
			}}
			activeSubTabKey={defaultKey ? defaultKey : subTabs[0].key}
		>
			{({ activeSubTab }) => {
				switch (activeSubTab) {
					case TICKETS_LIST_TYPE.PENDING:
						return <TicketsList type={TICKETS_LIST_TYPE.PENDING} items={pendings} isLoading={isPendingsLoading} onCancel={onCancel} />;
					case TICKETS_LIST_TYPE.SETTLED:
						return <TicketsList type={TICKETS_LIST_TYPE.SETTLED} items={settled} isLoading={isSettledLoading} onCancel={onCancel} />;
					default:
						break;
				}
				return <NoData />;
			}}
		</ModalWrapper>
	);
};

/** SwitcherPendingsSettled propTypes
 * PropTypes
 */
SwitcherPendingsSettled.propTypes = {
	/** Css variables` viewport x, y coordinates */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** React component property, is equal the component key which on will mount by default */
	defaultKey: PropTypes.number,
	/** Function that will be called on pending tickets modal close */
	onCancel: PropTypes.func,
	/** Redux state property, is true when loading pending tickets */
	isPendingsLoading: PropTypes.bool,
	/** Redux state property, array of pending tickets */
	pendings: PropTypes.arrayOf(historyType),
	/** Redux state property, is true when loading settled tickets */
	isSettledLoading: PropTypes.bool,
	/** Redux state property, array of settled tickets */
	settled: PropTypes.arrayOf(historyType),
	/** Redux action to get settled tickets */
	getSettledTickets: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		pendings: state.tickets.pendings,
		isPendingsLoading: state.tickets.isPendingsLoading,
		settled: state.tickets.settled,
		isSettledLoading: state.tickets.isSettledLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSettledTickets: () => {
		dispatch(getSettledTickets());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitcherPendingsSettled);
