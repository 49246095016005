import  { Fragment, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { doBet, clearForecast } from "store/actions/betslip/betslip.actions";
import useMarketDisabledForBonus from "hooks/useMarketDisabledForBonus";
import Tooltip from "components/ui/tooltip";
import useDocumentListener from "hooks/useDocumentListener";
import KEY_CODE from "constants/keyboard.constants";

const ForecastButton = ({ forecast, clearForecast, doBet, bets, current }) => {
	const { t } = useTranslation();

	// Need to check on duplicated forecast for disabling of add betting action
	const checkIsForecastDuplicated = useCallback(() => bets.some((bet) => bet.oddId === forecast.odd.id && bet.eventId === current), [bets, forecast, current]);

	const { isSingleMarketDisable } = useMarketDisabledForBonus();

	/** Function which fires on forecast button click
	 * @function
	 * @memberOf Stake
	 */
	const handleForecastButtonClick = () => {
		if (forecast && !isSingleMarketDisable(forecast.odd.factor)) {
			doBet(forecast.odd, forecast.group, forecast.eventInfo);
			clearForecast();
		}
	};

	// Handler for space key down event
	// Add listener for key down
	useDocumentListener("keydown", (e) => {
		if (e.keyCode === KEY_CODE.SPACE) {
			handleForecastButtonClick();
		}
	});

	return forecast ? (
		<Tooltip title={isSingleMarketDisable(forecast.odd.factor) || checkIsForecastDuplicated() ? <span className="vs--markets-list-item-content-odd-item-bonus-title">{t("cashier.unavailableForFreeBet")}</span> : null}>
			<button
				type="button"
				className={"vs--button vs--button-forecast vs--pr-8 vs--pl-8 vs--pt-12 vs--pb-12" + (isSingleMarketDisable(forecast.odd.factor) || checkIsForecastDuplicated() ? " vs--button-disabled" : "")}
				onClick={handleForecastButtonClick}
				disabled={isSingleMarketDisable(forecast.odd.factor) || checkIsForecastDuplicated()}
			>
				{t("bet.addToBetslip")}
			</button>
		</Tooltip>
	) : (
		<Fragment />
	);
};

/** ForecastButton propTypes
 * PropTypes
 */
ForecastButton.propTypes = {
	/** Redux state property, Forecast/Tricast bet selection */
	forecast: PropTypes.object,
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func,
	/** Redux action to clear forecast */
	clearForecast: PropTypes.func,
	/** Redux state property, selected bets for add bet action */
	bets: PropTypes.array,
	current: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		forecast: state.betslip.forecast,
		bets: state.betslip.bets,
		current: state.game.current
	};
};

const mapDispatchToProps = (dispatch) => ({
	doBet: (market, group, eventInfo) => {
		dispatch(doBet(market, group, eventInfo));
	},
	clearForecast: () => {
		dispatch(clearForecast());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastButton);
