import { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrentGame } from "store/actions/game/game.actions";
import ScrollableNavbar from "components/ui/scrollableNavbar";
import Dropdown from "components/ui/dropdown";
import GameButton from "components/ui/buttons/gameButton";
import LocalStorageUtils from "utils/localStorage";
import { binaryToFlags } from "utils/common";
import { GAME_TYPE, GAME_TYPE_ICONS, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";
import sessionType from "types/session.type";
import playerType from "types/player.type";
import bonustype from "types/bonus.type";

/* Games Component */
const Games = ({ session, player, setCurrentGame, currentGameType, useBonus, bonus }) => {
	const { t } = useTranslation();
	const [showMoreGames, setShowMoreGames] = useState(false);

	const disabledGames = useMemo(() => {
		if (!useBonus || !bonus) {
			return [];
		}
		const availableGamesOfBonus = binaryToFlags(session?.games?.map((g) => g.type) || [], bonus?.gameType || 0);
		return session?.games?.reduce((disabled, g) => {
			if (!availableGamesOfBonus.includes(g.type)) {
				disabled.push(g.type);
			}
			return disabled;
		}, []);
	}, [useBonus, bonus, session]);

	/** Set default game */
	useEffect(() => {
		let type = null;
		if (session.games.length > 0) {
			type = session.games[0].type;
		}

		const typeInCache = LocalStorageUtils.get("vs__cashier__current_game_" + session.projectId + "__" + player.userId);
		if (typeInCache && session.games.some((g) => g.type === typeInCache)) {
			type = typeInCache;
		}

		if (type) {
			setCurrentGame(type);
		}
	}, []);

	/** Set default game */
	useEffect(() => {
		if (disabledGames.length > 0) {
			let type = null;
			if (disabledGames.includes(currentGameType)) {
				for (let i = 0; i < session.games.length; i++) {
					if (!disabledGames.includes(session.games[i].type)) {
						type = session.games[i].type;
						break;
					}
				}
			}
			if (type !== null && type !== currentGameType) {
				setCurrentGame(type);
			}
		}
	}, [currentGameType, disabledGames]);

	/** Update current game in local storage */
	useEffect(() => {
		if (currentGameType && session.projectId && player.userId) {
			LocalStorageUtils.set("vs__cashier__current_game_" + session.projectId + "__" + player.userId, currentGameType);
		}
	}, [currentGameType]);

	/** Function , fires on game tab click
	 * @function
	 * @param {number} type - clicked game type
	 * @memberOf Games
	 */
	const handleGameClick = (type) => {
		if (type !== currentGameType && !disabledGames.includes(type)) {
			setCurrentGame(type);
		}
	};

	return (
		<footer className="vs--footer">
			<ScrollableNavbar
				wrapperClassName="vs--games"
				containerClassName="vs--mt-8 vs--mb-8"
				innerClassName="vs--games-tabs vs--flex vs--flex-row"
				elements={session.games}
				keyFieldName="id"
				isActiveChecking={(game) => currentGameType === game.type}
				onContentFullyFit={(isContentFullyFit) => {
					setShowMoreGames(!isContentFullyFit);
				}}
				renderFunction={(game) => {
					return (
						<GameButton className="btn-game" onClick={() => handleGameClick(game.type)} selected={currentGameType === game.type} disabled={disabledGames.includes(game.type)}>
							<i className={`${GAME_TYPE_ICONS[game.type] || ""} btn-textable-content btn-icon`} />
							<span className="btn-textable-content btn-text">{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}</span>
						</GameButton>
					);
				}}
			/>
			{showMoreGames ? (
				<Dropdown className="vs--mt-8 vs--mb-8 vs--ml-4 vs--mr-4" content={<i className="ic_more vs--pr-18 vs--pl-18 vs--pb-18 vs--pt-18" />}>
					<Fragment>
						{session.games.map((game) => {
							return (
								<div key={game.type} className={"vs--dropdown-children" + (currentGameType === game.type ? " vs--dropdown-children-active" : "") + " vs--flex vs--align-center vs--justify-start"} onClick={() => handleGameClick(game.type)}>
									<i className={`${GAME_TYPE_ICONS[game.type]} vs--dropdown-children-icon`} />
									<span className="vs--font-smallest vs--font-regular vs--ml-10 vs--dropdown-children-text">{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}</span>
								</div>
							);
						})}
					</Fragment>
				</Dropdown>
			) : null}
		</footer>
	);
};

/** Games propTypes
 * PropTypes
 */
Games.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, current authenticated user */
	player: playerType,
	/** Redux action to set current game */
	setCurrentGame: PropTypes.func,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool,
	/** Redux state property, currecnt bonus */
	bonus: bonustype
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		player: state.auth.player,
		currentGameType: state.game.currentGameType,
		useBonus: state.bonuses.standard.useBonus,
		bonus: state.bonuses.standard.bonus
	}
}

const mapDispatchToProps = (dispatch) => ({
	setCurrentGame: (type) => {
		dispatch(setCurrentGame(type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Games);
