import  { Children, useRef, useState } from "react";
import PropTypes from "prop-types";

import useOutsideClick from "hooks/useOutsideClick";
import { isDropdownFitsOnScreen } from "utils/ui";

const getSelectorNode = ({ children, selectedValue }) => {
	for (const child of children) {
		if (Array.isArray(child)) {
			return child.find(item => selectedValue === item?.props?.value)
		}
		
		if (selectedValue === child?.props?.value) {
			return child
		}
	}
};

const Select = ({ children, defaultValue = null, placeholder = "Select Item", onChange, value, suffixIcon = null, className = "", renderValue = null, dropdownClassname = "" }) => {
	/**
	 * isControlledFromOutside indicates that component
	 * should controlled from parent component.
	 * This prevents local state changes
	 */
	const isControlledFromOutside = value !== undefined;

	const [selectedItemValue, setSelectedItemValue] = useState(() => {
		if (isControlledFromOutside) {
			return;
		}

		return defaultValue;
	});

	const [isDropdownVisible, setIsDropdownVisible] = useState(null);

	const containerRef = useRef(null);
	const selectorRef = useRef(null);
	const dropdownRef = useRef(null);
	/**
	 * directionToOpenDropdownClassRef should only change
	 * when we want to show the dropdown, not hide it
	 */
	const directionToOpenDropdownClassRef = useRef(null);

	const currentSelectedValue = isControlledFromOutside ? value : selectedItemValue;

	const hideDropDown = () => {
		setIsDropdownVisible(false);
	};

	const showDropDown = () => {
		setIsDropdownVisible(true);

		directionToOpenDropdownClassRef.current = isDropdownFitsOnScreen({
			sizeChangerElement: containerRef.current,
			dropDownElement: dropdownRef.current
		})
			? "vs--ui-open-to-down"
			: "vs--ui-open-to-up";
	};

	const toggleDropdown = (e) => {
		e.stopPropagation();

		if (isDropdownVisible) {
			hideDropDown();
			return;
		}

		showDropDown();
	};

	const handleSelectChange = (selectedValue, child) => {
		if (currentSelectedValue === selectedValue) {
			hideDropDown();
			return;
		}

		if (typeof onChange === "function") {
			onChange(selectedValue);
		}

		hideDropDown();

		if (isControlledFromOutside) {
			return;
		}

		setSelectedItemValue(selectedValue);
	};

	const showHideDropdownClass = isDropdownVisible ? "vs--ui-select-dropdown-visible" : isDropdownVisible === false ? "vs--ui-select-dropdown-hidden" : ""; // If isDropdownVisible is null(not open yet), don't set any class

	useOutsideClick(containerRef, () => {
		if (isDropdownVisible !== null) {
			hideDropDown();
		}
	});

	return (
		<div className={`vs--ui-select ${className}`} ref={containerRef}>
			<div
				className={`vs--ui-select-selector ${isDropdownVisible ? "vs--ui-select-selector-active" : ""}`}
				onClick={toggleDropdown}
				ref={selectorRef}
				tabIndex={1}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						toggleDropdown(e);
					}
				}}
			>
				{currentSelectedValue || currentSelectedValue === 0 ? renderValue && typeof renderValue === "function" ? renderValue(currentSelectedValue) : getSelectorNode({ children, selectedValue: currentSelectedValue }) : <div className="vs--ui-select-selector-placeholder">{placeholder}</div>}
				<div className={`vs--ui-select-selector-icon ${isDropdownVisible ? "vs--ui-select-selector-icon-active" : ""}`}>{suffixIcon === null ? <i className="ic_down"></i> : suffixIcon}</div>
			</div>

			<div className={`vs--ui-select-dropdown ${directionToOpenDropdownClassRef.current} ${showHideDropdownClass} ${dropdownClassname}`} ref={dropdownRef}>
				{Children.map(children, (child, index) => {
					const { value } = child?.props ?? {};
					const isActive = currentSelectedValue === value;

					return (
						<SelectItemRenderer
							isActive={isActive}
							onClick={(e) => {
								e.stopPropagation();
								handleSelectChange(value, child);
							}}
							tabIndex={index + 1}
						>
							{child}
						</SelectItemRenderer>
					);
				})}
			</div>
		</div>
	);
};
/** Select propTypes
 * PropTypes
 */
Select.propTypes = {
	/** Function which will fire on change of Select */
	onChange: PropTypes.func,
	/** suffix icon */
	suffixIcon: PropTypes.node,
	/** Class to rewrite default styles of select */
	className: PropTypes.string,
	/** Default selected item's value */
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Elements to render  */
	children: PropTypes.node,
	/** Placeholder of selector */
	placeholder: PropTypes.string,
	/** Selected value controlled from outside */
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Class to rewrite default styles of select dropdown */
	dropdownClassname: PropTypes.string
};

const SelectOption = ({
	/** value */ // will be used in Select component
	children
}) => {
	return <>{children}</>;
};

/** SelectOption propTypes
 * PropTypes
 */
SelectOption.propTypes = {
	/** Elements to render */
	children: PropTypes.node
};

const SelectItemRenderer = ({ children, onClick, isActive, tabIndex }) => {
	return (
		<div
			tabIndex={tabIndex}
			className={`vs--ui-select-dropdown-item ${isActive ? "vs--ui-select-dropdown-item-active" : ""}`}
			onClick={onClick}
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					onClick(e);
				}
			}}
		>
			{children}
		</div>
	);
};

/** SelectItemRenderer propTypes
 * PropTypes
 */
SelectItemRenderer.propTypes = {
	/** Function which will fire on select item click */
	onClick: PropTypes.func,
	/** Elements to render */
	children: PropTypes.node,
	/** Specify that current item active */
	isActive: PropTypes.bool,
	/** tabIndex of Select item */
	tabIndex: PropTypes.number,
	// Function prop which renders select custom value
	renderValue: PropTypes.func
	// dropdownClassname
};

Select.Option = SelectOption;

export default Select;
