import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import AnimatedPages from "../animatedPages";
import AnimatedPage from "../animatedPages/animatedPage";
import MonitorTitle from "../sections/monitorTitle";
import MonitorSubtitle from "../sections/monitorSubtitle";
import ContentGroup from "../contentGroup";
import ContentList from "../contentList";
import { buildPathToStaticFolderOfCDN, mergeClassNames, numberWithSpaces } from "utils/common";
import { useSelector } from "react-redux";
import SpecialOdd from "./specialOdd";
import { standingsConfig } from "utils/liveMonitor";
import { useTranslation } from "react-i18next";

const LeagSpecialAndStatistics = ({ calculatedData = {}, gameType, status = null, seasonId = null, orderNumber = null, onFinishScenes = null, monitorSpecial, timeoutsConfig }) => {
	const { t } = useTranslation();
	const seasonStendings = useSelector((state) => state.season.stendings);
	const [standingsCollection, setStandingsCollection] = useState({});
	const { parentEvent } = calculatedData;
	const isOnFinishScenes = typeof onFinishScenes === "function";
	const animationRef = useRef(
		standingsConfig.reduce((acc, standingConfig) => {
			acc[standingConfig.key] = false;
			return acc;
		}, {})
	);

	const setAndCheckAnimationEnd = (key) => {
		animationRef.current[key] = true;
		if (isOnFinishScenes && Object.values(animationRef.current).every(Boolean)) {
			setTimeout(() => {
				onFinishScenes();
			}, timeoutsConfig.mainPerChildTimout || 0);
		}
	};

	useEffect(() => {
		const recordLength = seasonStendings.length;
		const collection = standingsConfig.reduce((acc, standingConfig) => {
			acc[standingConfig.key] = Array.from({ length: recordLength }, () => null);
			return acc;
		}, {});

		seasonStendings.forEach((standing, i) => {
			standingsConfig.forEach((standingConfig) => {
				collection[standingConfig.key][i] = standingConfig.getData(standing);
			});
		});

		setStandingsCollection(collection);
	}, [seasonStendings, standingsConfig]);

	const translateGroup = (group) => {
		switch (group) {
			case "Winner":
				return t("livemonitor.football_england_league.leaguechamp");
			case "Loser":
				return t("livemonitor.football_england_league.atthebottom");
			default:
				return "";
		}
	};

	const { markets, maxLength } = useMemo(() => {
		const markets = (monitorSpecial?.markets || []).map((market) => {
			const odds = (market?.odds || []).map((oddList) => {
				return oddList
					.reduce((acc, odd, i) => {
						if (!odd.factor || odd.factor < 1.01) {
							return acc;
						}
						acc.push(odd);
						return acc;
					}, [])
					.sort((next, prev) => (prev.factor < next.factor ? 1 : -1));
			});
			return {
				...market,
				odds
			};
		});

		const maxLength = Math.max(
			...markets
				.map((market) => {
					return market.odds.map((oddList) => oddList.length);
				})
				.flat(Infinity)
		);
		return {
			markets,
			maxLength
		};
	}, [monitorSpecial]);

	return (
		<section className="live-monitor">
			<MonitorTitle status={status} seasonId={seasonId} orderNumber={orderNumber} />
			<MonitorSubtitle event={parentEvent} logoSrc={buildPathToStaticFolderOfCDN("cashier-images/live-monitor-logo.svg")} />
			<div className="live-monitor-body">
				<AnimatedPages initialMountValue={true} perPage={false} className="vs--flex vs--flex-row" style={{ width: 1920, columnGap: 20 }} pageCount={2}>
					<AnimatedPage
						pageKey={0}
						className="live-monitor-left-to-right-animation vs--flex vs--flex-row"
						style={{
							width: 1028,
							"--delay": timeoutsConfig?.mainDelay ? timeoutsConfig?.mainDelay + "ms" : null,
							"--duration": timeoutsConfig?.mainDuration ? timeoutsConfig?.mainDuration + "ms" : null
						}}
					>
						<ContentGroup
							title={t("livemonitor.football_england_league.leaguespecialmarkets")}
							titleClassName="vs--flex vs--flex-col vs--justify-center vs--align-center monitor-bg-header heading-color vs--fs-28 vs--font-medium vs--mb-4"
							contentClassName="vs--flex vs--flex-row"
							className="vs--flex-grow"
							style={{ width: 1028 }}
							titleStyle={{ height: 60 }}
							contentStyle={{ columnGap: 2 }}
						>
							<ContentList
								header={<Fragment>&#8470;</Fragment>}
								headerClassName="vs--pr-2 monitor-content-title-shadow monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center"
								style={{ width: 66 }}
								headerStyle={{ height: 60, zIndex: 1 }}
								bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
							>
								{Array.from({ length: maxLength }, (_, i) => {
									return (
										<ContentList.ListItem key={i} className="vs--pr-2 monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 80 }}>
											{i + 1}
										</ContentList.ListItem>
									);
								})}
							</ContentList>
							{markets.map((specialMarket, i, arr) => {
								const [oddList] = specialMarket.odds || [[]];
								return (
									<ContentList
										key={i}
										header={<Fragment>{translateGroup(specialMarket.group)}</Fragment>}
										headerClassName={mergeClassNames(
											"vs--pr-2 monitor-content-title-shadow vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center",
											arr.length - 1 === i ? null : "monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2]"
										)}
										bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
										style={{ width: 479 }}
										headerStyle={{ height: 60, width: "100%", zIndex: 1, position: "relative" }}
									>
										{Array.from({ length: maxLength }, (_, j) => {
											const odd = oddList[j];
											return (
												<ContentList.ListItem key={odd?.name || j} className="monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 80 }}>
													{odd ? (
														<SpecialOdd
															gameType={gameType}
															odd={odd}
															style={{ width: "100%" }}
															textClassName="vs--font-exta-bigest vs--font-medium"
															textContainerClassName="vs--pr-24 vs--pl-24 vs--height-full vs--width-full vs--font-uppercas vs--flex vs--flex-row vs--justify-between vs--align-center"
															teamContainerClassName="vs--flex vs--justify-start vs--align-center"
															teamFlagContainerClassName="vs--mr-8"
														/>
													) : null}
												</ContentList.ListItem>
											);
										})}
									</ContentList>
								);
							})}
						</ContentGroup>
					</AnimatedPage>
					<AnimatedPage
						pageKey={1}
						className="live-monitor-right-to-left-animation"
						style={{
							width: 872,
							"--delay": timeoutsConfig?.mainDelay ? timeoutsConfig?.mainDelay + "ms" : null,
							"--duration": timeoutsConfig?.mainDuration ? timeoutsConfig?.mainDuration + "ms" : null
						}}
					>
						<ContentGroup
							title={t("livemonitor.football_england_league.teamstandings")}
							titleClassName="vs--flex vs--flex-col vs--justify-center vs--align-center monitor-bg-header heading-color vs--fs-28 vs--font-medium vs--mb-4"
							contentClassName="vs--flex vs--flex-row"
							className="vs--flex-grow"
							style={{ width: 1028 }}
							titleStyle={{ height: 60 }}
							contentStyle={{ columnGap: 2, overflowY: "hidden" }}
						>
							{standingsConfig.map((standingConfig, i) => {
								const standings = standingsCollection[standingConfig.key] || [];
								return (
									<ContentList
										key={standingConfig.key}
										header={t(`livemonitor.football_england_league.${standingConfig.title}`)}
										headerClassName="vs--pr-2 monitor-content-title-shadow monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center"
										style={{ width: standingConfig.width }}
										headerStyle={{ height: 60, zIndex: 1 }}
										bodyClassName="live-monitor-standing-animation monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
										bodyStyle={{
											"--delay": timeoutsConfig?.mainScrollAnimationDelay ? timeoutsConfig?.mainScrollAnimationDelay + "ms" : null,
											"--duration": timeoutsConfig?.mainScrollAnimationDuration ? timeoutsConfig?.mainScrollAnimationDuration + "ms" : null,
											"--transition-to": "-800px"
										}}
										bodyProps={{
											onAnimationEnd: () => setAndCheckAnimationEnd(standingConfig.key)
										}}
									>
										{standings.map((standing, i) => {
											return (
												<ContentList.ListItem key={i} className="vs--pr-2 monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 80 }}>
													{typeof standingConfig.render === "function" ? standingConfig.render(standing, i, t) : standing}
												</ContentList.ListItem>
											);
										})}
									</ContentList>
								);
							})}
						</ContentGroup>
					</AnimatedPage>
				</AnimatedPages>
			</div>
		</section>
	);
};
export default LeagSpecialAndStatistics;
