import PropTypes from "prop-types";

import { GAME_TYPE } from "constants/game.constants";

import hotkeyType from "types/hotKey.type";
import rtpType from "types/rtp.type";

export default PropTypes.shape({
	activeJackpotId: PropTypes.string,
	isAuthorized: PropTypes.bool,
	loginUrl: PropTypes.string,
	isDemo: PropTypes.bool,
	languageId: PropTypes.string,
	exitUrl: PropTypes.string,
	depositUrl: PropTypes.string,
	address: PropTypes.string,
	projectName: PropTypes.string,
	balance: PropTypes.number,
	hotKeyEnabled: PropTypes.bool,
	paymentType: PropTypes.number,
	currency: PropTypes.shape({
		code: PropTypes.string,
		name: PropTypes.string,
		symbol: PropTypes.string,
		favoriteBets: PropTypes.arrayOf(PropTypes.string),
		decimalCount: PropTypes.number,
		singleMin: PropTypes.number,
		singleMax: PropTypes.number,
		multiMin: PropTypes.number,
		multiMax: PropTypes.number,
		multiPossibleWinMax: PropTypes.number,
		singlePossibleWinMax: PropTypes.number
	}),
	games: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		rtPs: PropTypes.arrayOf(rtpType),
		name: PropTypes.string,
		type: PropTypes.oneOf(Object.values(GAME_TYPE)),
		streamUrl: PropTypes.string
	})),
	betTicket: PropTypes.shape({
		customText: PropTypes.string,
		enableBarcodePrinting: PropTypes.bool,
		generalDetails: PropTypes.number,
		marketSelection: PropTypes.number
	}),
	cancelTicket: PropTypes.shape({
		customText: PropTypes.string,
		enableBarcodePrinting: PropTypes.bool,
		generalDetails: PropTypes.number,
		marketSelection: PropTypes.number,
		printCancellationTickets: PropTypes.bool
	}),
	payoutTicket: PropTypes.shape({
		customText: PropTypes.string,
		enableBarcodePrinting: PropTypes.bool,
		generalDetails: PropTypes.number,
		marketSelection: PropTypes.number,
		printPayoutTickets: PropTypes.bool
	}),
	hotkeys: PropTypes.objectOf(PropTypes.arrayOf(hotkeyType)),
	cashier: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		userName: PropTypes.string
	}),
	ticketRedemptionPeriod: PropTypes.number,
	logoId: PropTypes.number
})
