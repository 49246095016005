import { Fragment } from "react";
import { GAME_STATUSES } from "constants/game.constants";
import { useTranslation } from "react-i18next";

const MonitorTitle = ({ id, status, seasonId, orderNumber }) => {
	const { t } = useTranslation();
	return (
		<div className="live-monitor-title live-monitor-title-animation">
			<div className="live-monitor-title-bg live-monitor-title-bg-animation">
				<div className="live-monitor-title-element live-monitor-title-status live-monitor-title-status-1">
					{GAME_STATUSES.FINISHED === status ? (
						<Fragment>
							<span>{t("livemonitor.football_england_league.results")}</span>
						</Fragment>
					) : [GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(status) ? (
						<Fragment>
							<span>{t("livemonitor.football_england_league.betsare")}</span>
							&nbsp;
							<span className="status-text">{[GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED].includes(status) ? t("livemonitor.football_england_league.open") : t("livemonitor.football_england_league.closed")}</span>
						</Fragment>
					) : null}
				</div>
				<div className="live-monitor-title-element live-monitor-title-game-name">{t("livemonitor.football_england_league.englishleague")}</div>
				<div className="live-monitor-title-element live-monitor-title-event-identifiers">
					<span className="live-monitor-title-event-identifier">
						{t("livemonitor.football_england_league.league")} #:&nbsp;<span className="identifier-text">{seasonId}</span>
					</span>
					&nbsp;&nbsp;
					<span className="live-monitor-title-event-identifier">
						{t("livemonitor.football_england_league.week")} #:&nbsp;<span className="identifier-text">{orderNumber}</span>
					</span>
				</div>
			</div>
		</div>
	);
};
export default MonitorTitle;
