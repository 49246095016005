import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { SET_LANGUAGES_BEFORE, SET_LANGUAGES_AFTER, SET_LANGUAGES } from "../../actionTypes";

const setLanguagesBefore = () => ({
	type: SET_LANGUAGES_BEFORE
});

const setLanguagesFinished = () => ({
	type: SET_LANGUAGES_AFTER
});

const setSystemLanguages = (languages) => ({
	type: SET_LANGUAGES,
	payload: { languages }
});

export const getSystemLanguages = () => {
	return (dispatch) => {
		dispatch(setLanguagesBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SYSTEM_LANGUAGES}`,
			method: Methods.GET
		})
			.then(({ data: { message: msg, status, value: languages } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(setSystemLanguages(languages));
				}
				dispatch(setLanguagesFinished());
			})
			.catch((ex) => {
				dispatch(setLanguagesFinished());
			});
	};
};
