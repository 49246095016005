import moment from "moment";
import PropTypes from "prop-types";

import Tile from "components/ui/datePicker/calendar/tile";

import { getYearStart, getYearEnd } from "utils/calendar";

const Year = ({ className, date, selectedDate, ...otherProps }) => {
	return (
		<Tile
			{...otherProps}
			selectedDate={selectedDate}
			date={date}
			className={`vs--ui-calendar-decade-view-years-year ${className} ${date.getFullYear() === selectedDate.year() ? "vs--ui-calendar-decade-view-years-year-active" : ""}`}
			maxDateTransform={getYearEnd}
			minDateTransform={getYearStart}
			view="decade"
		>
			{moment(date).format("YYYY")}
		</Tile>
	);
};

Year.propTypes = {
	className: PropTypes.string.isRequired,
	date: PropTypes.instanceOf(Date).isRequired,
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired
};

export default Year;
