export const REPORT_GAME_TYPES = {
	TOTAL: "total",
	MIXED_MULTI: "0",
}

export const BANNERS_ICONS = {
	betSlipCount: "ic_bet-ticket",
	bonusBetSlipsCount: "ic_bet-ticket",
	betCount: "ic_betslip",
	turnover: "ic_turnover",
	bonusTurnover: "ic_turnover",
	ggr: "ic_ggr",
	startingLimit: "ic_start",
	finalLimit: "ic_end",
	won: "ic_won",
	bonusWon: "ic_won"
};