import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** Blocked Message Component */
const BlockedMessage = ({ imageSrc, title, message, className }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--noGameFound">
			<div className="vs--noGameFound-inner vs--flex vs--flex-col vs--align-center vs--justify-center">
				<img src={imageSrc} alt="noGameFound" />
				<span className={`vs--noGameFound-title vs--font-medium vs--pb-8 vs--pt-8 ${className}`}>{t(title)}</span>
				<span className="vs--noGameFound-desc vs--font-regular">{t(message)}</span>
			</div>
		</div>
	);
};

/** BlockedMessage propTypes
 * PropTypes
 */
BlockedMessage.propTypes = {
	/** Current block title to show */
	title: PropTypes.string,
	/** Current block message to show */
	message: PropTypes.string,
	/** Current block image to show */
	imageSrc: PropTypes.string,
	className: PropTypes.string
};

export default BlockedMessage;
