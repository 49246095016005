import { JACKPOT_LEVEL } from "constants/bonus.constants";
import PropTypes from "prop-types"


const wonJackpotType = PropTypes.shape({
	id: PropTypes.number,
	levelType: PropTypes.oneOf(Object.values(JACKPOT_LEVEL)),
	amount: PropTypes.number,
	winTime: PropTypes.string,
	largestWin: PropTypes.number,
	payoutTime: PropTypes.string,
	poolName: PropTypes.string
})

export default wonJackpotType;