import { useRef, useEffect } from "react";
import confetti from "canvas-confetti";

import { mergeClassNames } from "utils/common";

const colors = ["#FFFFFF", "#EC9B59"];

const ConfettiAnimation = ({ className, animateableJackpot, showCanfetti }) => {
	const canvasRef = useRef();
	const customCavnasInstanceRef = useRef();
	const frameRef = useRef();

	useEffect(() => {
		if (canvasRef.current) {
			// Set up the confetti with the custom canvas without getting its 2D context
			customCavnasInstanceRef.current = confetti.create(canvasRef.current, {
				resize: true, // Will fit the size of the canvas
				useWorker: true // Render the confetti in a seperate thread for performance
			});

			frameRef.current = () => {
				if (!showCanfetti || animateableJackpot === null) return;

				customCavnasInstanceRef.current({
					particleCount: 5,
					angle: 50,
					spread: 220,
					origin: { x: 0, y: 0.5 },
					colors: colors,
				});
				customCavnasInstanceRef.current({
					particleCount: 1,
					angle: 50,
					spread: 190,
					origin: { x: 0.2, y: 0.5 },
					colors: colors,
				});

				customCavnasInstanceRef.current({
					particleCount: 1,
					angle: 120,
					spread: 220,
					origin: { x: 0.8, y: 0.5 },
					colors: colors,
				});
				customCavnasInstanceRef.current({
					particleCount: 5,
					angle: 120,
					spread: 190,
					origin: { x: 1, y: 0.5 },
					colors: colors,
				});

				requestAnimationFrame(frameRef.current);
			}

			frameRef.current();
		}

		return () => {
			if(frameRef.current) {
				cancelAnimationFrame(frameRef.current);
			}

			if(customCavnasInstanceRef.current) {
				customCavnasInstanceRef.current.reset();
			}
		};
	}, [showCanfetti, animateableJackpot]);

	useEffect(() => {
		return () => {
			customCavnasInstanceRef.current.reset();
		}
	}, [])

	return (
		<div className={mergeClassNames("vs--confetti-container", className)}>
			<canvas ref={canvasRef} className="vs--confetti-container-canvas" />
		</div>
	)
};

export default ConfettiAnimation;