import { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import RaceRects from "components/ui/raceRects";

import { getQRTicket } from "store/actions/qr/qr.actions";

import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";
import { GAME_TYPE, GAME_EVENT_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";

import { numberWithSpaces, makeCurrencyText, isRacingGame, GetSeasonRoundText, isMobile, isTablet, buildPathToStaticFolderOfCDN } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";
import { getBetStateText } from "utils/bets";

import Loader from "components/ui/loader";

import historyType from "types/history.type";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** QR page Component */
const QRPage = ({ isLoading, ticket, getQRTicket }) => {
	const { t } = useTranslation();

	const [additionalData, setAdditionalData] = useState({});
	const [showTrophy, setShowTrophy] = useState(false);

	const routeParams = useParams();

	const isNotTablet = !isTablet() && isMobile();

	/** Load QR Ticket data */
	useEffect(() => {
		const token = routeParams.id;
		if (token) {
			try {
				const data = JSON.parse(atob(token));
				setAdditionalData(data);
				if (data.id) {
					getQRTicket(data.id);
				}
			} catch (ex) {
				console.log(ex);
			}
		}
	}, []);

	useEffect(() => {
		if (ticket.state === BET_STATE.WON || ticket.state === BET_STATE.SEMIWON || ticket.state === BET_STATE.SEMILOST) {
			setShowTrophy(true);
			setTimeout(() => setShowTrophy(false), 2000);
		}
	}, [ticket]);

	const getBets = () => {
		const list = ticket.bets || [];
		return list;
	};

	const getLayout = () => {
		let layout = "";
		if (getBets().length === 1) {
			layout = "1/1";
		} else if (getBets().length === 3) {
			layout = "1/3";
		} else if (getBets().length === 2) {
			layout = "1/2";
		} else if (getBets().length === 4) {
			layout = "1/4";
		} else {
			layout = "1/5";
		}
		return layout;
	};

	const getResultText = () => {
		const getKeys = () => {
			if (ticket.state === BET_STATE.PENDING) {
				const filteredBets = getBets().filter((b) => b.state === BET_STATE.PENDING);

				return {
					className: "pending",
					text: `${filteredBets.length} ${filteredBets.length > 1 ? "Bets are" : "Bet is"} pending.`
				};
			}
			if (ticket.state === BET_STATE.RETURN || ticket.state === BET_STATE.WON || ticket.state === BET_STATE.SEMIWON || ticket.state === BET_STATE.SEMILOST) {
				return {
					className: "winner",
					icon: "ic_smile vs--font-bigest",
					text: `You Win ${makeCurrencyText(ticket.winning, { code: additionalData.currencyCode, decimalCount: additionalData.minorUnit })}`
				};
			}
			if (ticket.state === BET_STATE.LOST || ticket.state === BET_STATE.REJECTED_BY_OPERATOR) {
				return { className: "loser", icon: "ic_frown vs--font-bigest", text: "Oops! Try next time." };
			}
			if (ticket.state === BET_STATE.CANCELLED) {
				return { className: "canceled", text: "The Betslip is Canceled" };
			}

			return {};
		};

		const { className, icon, text } = getKeys();

		return (
			<div className={`vs--qr-result vs--qr-result-${className} vs--flex vs--justify-center vs--align-center`} data-state={ticket.state}>
				<span className={`vs--qr-result-${className}-text vs--title vs--font-medium vs--font-exstrasmall ${isNotTablet ? "vs--mr-12" : "vs--mr-8"}`}>{text}</span>
				{icon && <i className={icon} />}
			</div>
		);
	};

	const getEventFieldText = (bet) => {
		const eventType = bet?.eventType ?? null;

		if (eventType === GAME_EVENT_TYPE.WEEK) {
			return GetSeasonRoundText(bet?.gameType ?? null, bet?.eventOrderNumber ?? null);
		}
		if (eventType === GAME_EVENT_TYPE.LEAGUE) {
			return "-";
		}

		const team1 = bet?.gameData?.team1;
		const team2 = bet?.gameData?.team2;
		const venue = bet?.gameData?.venue;

		return team1 && team2 ?
			([GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(bet.gameType) ? `${team1.countryName ?? ""} - ${team2.countryName ?? ""}` :
				`${team1.countryCode ?? ""} - ${team2.countryCode ?? ""}`)
			: venue ? venue : "-";
	};

	const getEventIdField = (eventType) => {
		if (eventType === GAME_EVENT_TYPE.WEEK) {
			return "Round ID";
		}
		if (eventType === GAME_EVENT_TYPE.LEAGUE) {
			return "Cup ID";
		}

		return "Event ID";
	};

	return (
		<Fragment>
			{showTrophy && (
				<div className="vs--qr-trophy vs--flex vs--flex-col vs--align-center vs--justify-center">
					<img src={buildPathToStaticFolderOfCDN("cashier-images/trophy.png")} alt="trophy" />
					<b className="vs--pt-24 vs--pb-16 vs--font-bold">Congratulations!!</b>
					<span>{`You have won ${makeCurrencyText(ticket.winning, { code: additionalData.currencyCode, decimalCount: additionalData.minorUnit })}`}</span>
				</div>
			)}
			<div className="vs--qr-header" />
			{!isLoading ? (
				<div className="vs--qr-inner vs--pl-22 vs--pr-22" data-layout={getLayout()}>
					{getResultText()}
					<div className={`vs--qr-inner-main vs--flex vs--justify-center vs--align-start ${isNotTablet ? "vs--pl-16 vs--pr-16" : ""}`}>
						<div className={`vs--qr-section vs--qr-section-main vs--mr-8 vs--mt-6 ${isNotTablet ? "vs--qr-section-mobile" : ""}`}>
							<div className="vs--qr-section-row vs--mb-4 vs--flex vs--justify-between vs--align-center">
								<span className="vs--qr-section-row-field vs--title">Bet Shop</span>
								<span className="vs--qr-section-row-field vs--title vs--text-right vs--pl-16">{additionalData.betShopName}</span>
							</div>
							<div className="vs--qr-section-row vs--mb-4 vs--flex vs--justify-between vs--align-center">
								<span className="vs--qr-section-row-field vs--title">Ticket ID</span>
								<span className="vs--qr-section-row-field vs--title vs--text-right vs--pl-16">{additionalData.id}</span>
							</div>
							<div className="vs--qr-section-row vs--mb-4 vs--flex vs--justify-between vs--align-center">
								<span className="vs--qr-section-row-field vs--title">Printed</span>
								<span className="vs--qr-section-row-field vs--title vs--text-right vs--pl-16">{moment.utc(additionalData.betTime).local().format(DATE_TIME_FORMAT)}</span>
							</div>
						</div>
						<div className="vs--qr-section vs--qr-section-main vs--ml-8 vs--mt-6">
							<div className="vs--qr-section-row vs--mb-4 vs--flex vs--justify-between vs--align-center">
								<span className="vs--qr-section-row-field vs--title">Bet Type</span>
								<span className="vs--qr-section-row-field vs--title vs--text-right vs--pl-16">{ticket.type === BETSLIP_MODES.SINGLE ? "Single" : Array.isArray(ticket?.bets) ? `Multi(${ticket.bets.length})` : null}</span>
							</div>
							<div className="vs--qr-section-row vs--mb-4 vs--flex vs--justify-between vs--align-center">
								<span className="vs--qr-section-row-field vs--title">Stake</span>
								<span className="vs--qr-section-row-field vs--title vs--text-right vs--pl-16">
									{makeCurrencyText(ticket.totalAmount, {
										code: additionalData.currencyCode,
										decimalCount: additionalData.minorUnit
									})}
								</span>
							</div>
							<div className="vs--qr-section-row vs--mb-4 vs--flex vs--justify-between vs--align-center">
								<span className="vs--qr-section-row-field vs--title">Max. Possible Win</span>
								<span className="vs--qr-section-row-field vs--title vs--text-right vs--pl-16">
									{makeCurrencyText(ticket.possibleWin, {
										code: additionalData.currencyCode,
										decimalCount: additionalData.minorUnit
									})}
								</span>
							</div>
						</div>
					</div>
					<div className="vs--qr-inner-list vs--flex vs--justify-center vs--align-start vs--mt-8">
						{getBets().map((bet) => (
							<div className="vs--qr-section-list vs--mt-16 vs--ml-8 vs--mr-8" key={bet.id} data-state={bet.state}>
								<div className="vs--qr-section-list-row-header vs--pl-16 vs--pr-16 vs--flex vs--justify-between vs--align-center">
									<span className="vs--qr-section-list-row-header-field vs--title vs--font-medium vs--font-exstrasmall">ID {bet.id}</span>
									<div className="vs--qr-section-list-row-state vs--flex vs--justify-center vs--align-start">
										<span className="vs--title vs--font-regular vs--qr-section-list-state">{getBetStateText(bet.state)}</span>
									</div>
								</div>
								<div className="vs--qr-section-list-content vs--pl-16 vs--pr-16">
									<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
										<span className="vs--title">Game Type</span>
										<span className="vs--title vs--text-right vs--pl-16">{t(`common.${GAME_TYPE_TEXT_KEYS[bet.gameType]}`)}</span>
									</div>
									{bet.gameType !== GAME_TYPE.KENO && !isRacingGame(bet.gameType) && (
										<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
											<span className="vs--title">Event</span>
											<span className="vs--title vs--text-right vs--pl-16">{getEventFieldText(bet)}</span>
										</div>
									)}

									<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
										<span className="vs--title">{getEventIdField(bet?.eventType ?? null)}</span>
										<span className="vs--title vs--text-right vs--pl-16">{bet.eventId}</span>
									</div>
									<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
										<span className="vs--title">Start At</span>
										<span className="vs--title vs--text-right vs--pl-16">{moment.utc(bet.startTime).local().format(DATE_TIME_FORMAT)}</span>
									</div>
									<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
										<span className="vs--title">Market</span>
										{isRacingGame(bet.gameType) ? (
											<div className="vs--flex vs--align-center">
												<span>{runMarketUtilsFunction("makeGroupTitle", [{ group: bet.group, argument: bet.argument, gameData: bet?.gameData ?? {}, gameType: bet.gameType }], bet.gameType)}</span>
												{/* html space code */}
												&nbsp;:&nbsp;
												<RaceRects 
													gameType={bet.gameType} 
													rectData={runMarketUtilsFunction("getRectData", [{ group: bet.group, outcome: bet.outcome, gameData: bet?.gameData ?? {}, gameType: bet.gameType }], bet.gameType)} 
													placement={`${isNotTablet ? "left" : "top"}`} />
											</div>
										) : (
											<span className="vs--title vs--text-right vs--pl-16">
												{runMarketUtilsFunction(`${bet.gameType === GAME_TYPE.KENO ? "makeOddText" : "makeBetText"}`, [{ outcome: bet.outcome, group: bet.group, argument: bet.argument, gameData: bet?.gameData ?? {}, gameType: bet.gameType }], bet.gameType)}
											</span>
										)}
									</div>
									<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
										<span className="vs--title">Odds</span>
										<span className="vs--title vs--text-right vs--pl-16">{numberWithSpaces(bet.factor)}</span>
									</div>
									{ticket.type === BETSLIP_MODES.SINGLE ? (
										<>
											<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
												<span className="vs--title">Stake</span>
												<span className="vs--title vs--text-right vs--pl-16">
													{makeCurrencyText(bet.amount, {
														code: additionalData.currencyCode,
														decimalCount: additionalData.minorUnit
													})}
												</span>
											</div>
											<div className="vs--qr-section-list-row vs--flex vs--justify-between vs--align-center">
												<span className="vs--title">Possible Win</span>
												<span className="vs--title vs--text-right vs--pl-16">
													{makeCurrencyText(bet.possibleWin, {
														code: additionalData.currencyCode,
														decimalCount: additionalData.minorUnit
													})}
												</span>
											</div>
										</>
									) : null}
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<Loader full={true} />
			)}
		</Fragment>
	);
};

/** QRPage propTypes
 * PropTypes
 */
QRPage.propTypes = {
	/** Redux state property, qr ticket data */
	ticket: historyType,
	/** Redux state property, is true when qr ticket data is loading */
	isLoading: PropTypes.bool,
	/** Redux action to load qr ticket data */
	getQRTicket: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getQRTicket: (id) => {
		dispatch(getQRTicket(id));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.qr.isLoading,
		ticket: state.qr.ticket
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QRPage);
