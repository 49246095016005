import  { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import NotSupported from "components/ui/notSupported";
import Loader from "components/ui/loader";
import MessageAlerter from "components/ui/messageAlerter";

import { createSession } from "store/actions/auth/auth.actions";
import { getSessionDetails } from "store/actions/auth/auth.actions";

import useCurrentPath from "hooks/useCurrentPath";

import { isMobile, isTablet, getProjectType } from "utils/common";

import playerType from "types/player.type";
import { PROJECT_TYPE } from "constants/common.constants";
import Paths from "constants/path.constants";

const RootLayout = ({ createSession, sessionLoaded, sessionFailed, getSessionDetails, player, messages }) => {
	const path = useCurrentPath();

	const projectType = getProjectType();
	const isSupported = !isMobile() || isTablet() || path === Paths.QR;

	/** Function to scale document
	 * @function
	 * @description scale document to fit in screen
	 * @memberOf Container
	 */
	const scale = () => {
		const coificient = window.innerWidth / 1920;
		if (coificient <= 1) {
			document.body.style.transform = "scale(" + coificient + ")";
			document.body.style.height = window.innerHeight / coificient + "px";
			document.documentElement.style.width = "auto";
		}
	};

	/** Scale functionality */
	useEffect(() => {
		if (!isSupported) {
			return;
		}
		const unscaleable = ["vs--html-keno", "vs--html-live-monitor"];
		if (!unscaleable.some((className) => document.documentElement.classList.contains(className))) {
			scale();
			window.addEventListener("resize", scale);
			return () => {
				window.removeEventListener("resize", scale);
			};
		}
	}, []);

	/** Create sesion on application start */
	useEffect(() => {
		if (!isSupported) {
			return;
		}

		if (projectType === PROJECT_TYPE.IFRAME) {
			createSession();
		}
		if (projectType === PROJECT_TYPE.STANDALONE && player.userId) {
			getSessionDetails();
		}
	}, []);

	switch (true) {
		case projectType === PROJECT_TYPE.IFRAME && !sessionLoaded && !sessionFailed:
			return <Loader full={true} />;
		case isSupported:
			return (
				<>
					<Outlet />
					{messages.length > 0 && <MessageAlerter />}
				</>
			);
		default:
			return <NotSupported />;
	}
};

RootLayout.propTypes = {
	/** Redux action to create session */
	createSession: PropTypes.func,
	/** Redux state property, is true when session loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, is true when session failed */
	sessionFailed: PropTypes.bool,
	/** Redux action to get session details */
	getSessionDetails: PropTypes.func,
	/** Redux state property, current authenticated user */
	player: playerType,
	/** Redux state property, alert messages data */
	messages: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
	createSession: () => {
		dispatch(createSession());
	},
	getSessionDetails: () => {
		dispatch(getSessionDetails());
	}
});

const mapStateToProps = (state) => {
	return {
		sessionLoaded: state.auth.sessionLoaded,
		sessionFailed: state.auth.sessionFailed,
		player: state.auth.player,
		messages: state.common.alertMessages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
