const PATHS = {
	MAIN: "/",
	TICKET: "/ticket",
	LOGIN: "/login",
	ACTIVATE: "/activate",
	RESET_PASSWORD: "/resetPassword",
	FORGOT_PASSWORD: "/forgotPassword",
	REPORT: "/report",
	STREAM: "/stream",
	JACKPOT_LIVE_INFO: "/jackpot-live-info",
	JACKPOT_LIVE_INFO_ACTIVE: "/jackpot-live-info/:activeJackpotId",
	LIVE_MONITOR: "/live-monitor",
	LIVE_MONITOR_GAME: "/live-monitor/:gameType",
	KENO: "/stream/keno",
	QR: "/QR/:id"
};

export default PATHS;
