import  { Fragment } from 'react';

import ActiveJackpot from 'components/pages/jackpotLiveInfo/activeJackpot';

const JackpotActiveLayoutRoute = (props) => {
	return (
		<Fragment>
			<ActiveJackpot {...props} />
		</Fragment>
	)
}

export default JackpotActiveLayoutRoute;