import  { useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { getLineFromMatrix, getUniqueRandomNumbersByRangeAndCount } from "utils/common";

import { GAME_TYPE, KENO_FORMAT, KENO_FORMAT_BALLS_QTY, KENO_BALLS_QTY_IN_ROW, KENO_BALLS_QTY_IN_COLUMN } from "constants/game.constants";

import { setKenoBalls } from "store/actions/betslip/betslip.actions";

import eventType from "types/event.type";

/** Keno Market Line Selection component*/
const KenoSelectLine = ({ eventInfo, currentGameType, kenoBalls, setKenoBalls, side, markets, isOddDisabled }) => {
	const raceFormatCount = KENO_FORMAT_BALLS_QTY[Object.values(KENO_FORMAT).find((value) => value === (eventInfo?.gameData?.raceFormat ?? 0))] || 0;
	const arrowsCount = side === "left" ? KENO_BALLS_QTY_IN_COLUMN : KENO_BALLS_QTY_IN_ROW;
	const arrows = useMemo(() => Array.from({ length: arrowsCount }, (_, i) => i), []);
	const arrowSelect = async (num) => {
		if (isOddDisabled()) {
			return;
		}
		const line = getLineFromMatrix(markets, num, side === "left");
		const sortedSelectedMarkets = (kenoBalls?.balls ?? []).sort((next, prev) => (prev > next ? -1 : 1));
		const firstOrderedSelection = await line.slice(0, Math.min(line.length, raceFormatCount));
		const lastOrderedSelection = await line.concat().reverse().slice(0, Math.min(line.length, raceFormatCount));
		const isOrderedFirstSelected = await (sortedSelectedMarkets.length > 0 && firstOrderedSelection.length === sortedSelectedMarkets.length && firstOrderedSelection.every((elem) => sortedSelectedMarkets.includes(elem)));
		const isOrderedLastSelected = await (sortedSelectedMarkets.length > 0 && lastOrderedSelection.length === sortedSelectedMarkets.length && lastOrderedSelection.every((elem) => sortedSelectedMarkets.includes(elem)));

		const newObj = { eventInfo, gameType: currentGameType, balls: [] };

		switch (true) {
			case !isOrderedFirstSelected && !isOrderedLastSelected && !(sortedSelectedMarkets.length > 0 && sortedSelectedMarkets.every((elem) => line.includes(elem))):
				newObj.balls = firstOrderedSelection;
				break;
			case isOrderedFirstSelected && !isOrderedLastSelected && !(line.length === raceFormatCount):
				newObj.balls = lastOrderedSelection;
				break;
			default:
				newObj.balls = await getUniqueRandomNumbersByRangeAndCount(Math.min(line.length, raceFormatCount), side !== "left" ? KENO_BALLS_QTY_IN_COLUMN : KENO_BALLS_QTY_IN_ROW).map((index) => line[index]);
				break;
		}

		newObj.balls = newObj.balls.sort((next, prev) => (prev > next ? -1 : 1));
		setKenoBalls(newObj);
	};

	const onMouseLine = (index, fnName) => {
		if (isOddDisabled()) {
			return;
		}

		Array.prototype.forEach.call(document.querySelectorAll(`[data-${side === "left" ? "row" : "col"}="${index}"]`), (element) => element.classList[fnName]("vs--markets-list-item-content-odd-item-keno-hover-imitation"));
	};

	return (
		<div className={`vs--markets-keno-select vs--markets-keno-select-${side}` + (side === "left" ? " vs--mr-8 vs--mt-4" : " vs--flex vs--ml-4")}>
			{arrows.map((num) => (
				<div
					key={num}
					className={(side === "left" ? "vs--flex vs--justify-center vs--align-center vs--mb-16" : "vs--flex vs--justify-center vs--align-center vs--mr-10") + (isOddDisabled() ? " vs--markets-keno-select-disabled" : "")}
					onClick={() => arrowSelect(num)}
					onMouseOver={() => onMouseLine(num, "add")}
					onMouseLeave={() => onMouseLine(num, "remove")}
				>
					<i className={(side === "left" ? "ic_right-keno" : "ic_top") + " vs--font-bigest"} />
				</div>
			))}
		</div>
	);
};

/** KenoSelectLine propTypes
 * PropTypes
 */
KenoSelectLine.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, keno balls bet selection */
	kenoBalls: PropTypes.shape({
		balls: PropTypes.arrayOf(PropTypes.number),
		eventInfo: eventType,
		gameType: PropTypes.number
	}),
	/** Redux action to update elected balls array */
	setKenoBalls: PropTypes.func,
	/** React property, component render style */
	side: PropTypes.oneOf(["left", "bottom"]).isRequired,
	/** React property, matrix of keno market */
	markets: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
	/** React property function, is odds disabled */
	isOddDisabled: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType,
		kenoBalls: state.betslip.kenoBalls
	};
};

const mapDispatchToProps = (dispatch) => ({
	setKenoBalls: (kenoBalls) => {
		dispatch(setKenoBalls(kenoBalls));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(KenoSelectLine);
