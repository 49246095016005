import PropTypes from "prop-types";

const SliderRange = ({ value, onChange, min = 0, max = 10, rangeClassname = "" }) => {
	return (
		<div className="vs--ui-sliderRange-container">
			<input
				type="range"
				min={`${min}`}
				max={`${max}`}
				value={`${value}`}
				className={`${rangeClassname || "vs--ui-sliderRange-track"}`}
				onInput={(e) => {
					const { value } = e.target;
					onChange && onChange(value);
				}}
			/>
		</div>
	);
};

SliderRange.propTypes = {
	// Gets value for slider range
	value: PropTypes.number,
	// Sets min value
	min: PropTypes.number,
	// Sets max value
	max: PropTypes.number,
	// Callback function which handles target value
	onChange: PropTypes.func.isRequired,
	// Classname which should ovveride slider range ui at all.
	rangeClassname: PropTypes.string
};

export default SliderRange;
