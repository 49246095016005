import { useMemo } from "react";

import Num from './num';

import usePrevious from 'hooks/usePrevious';

import { mergeClassNames } from "utils/common";

const ANIMATION_CLASSNAME_PREFIX_BY_INDICES = {
	FASTEST: "fastest",
	FAST: "fast",
	COMMON: "common"
}

const getAnimationClassNamePrefix = (arrLength, index) => {
	if (index === arrLength - 1) {
		return ANIMATION_CLASSNAME_PREFIX_BY_INDICES.FASTEST;
	}
	if (arrLength >= 2 && index === arrLength - 2) {
		return ANIMATION_CLASSNAME_PREFIX_BY_INDICES.FAST;
	}

	return ANIMATION_CLASSNAME_PREFIX_BY_INDICES.COMMON;
}

const AmountNums = ({ className = "", numClassName, amount, maxNums, currencySymbol }) => {
	const splittedNums = useMemo(() => {
		if (amount) {
			return (`${amount}`).split('');
		}

		return [];
	}, [amount]);

	const prevSplittedNums = usePrevious(splittedNums, []);

	return (
		<div className={mergeClassNames(
			className,
			"vs--jackpot-content-levels-level-card-content-body-amount",
			"vs--flex vs--align-center vs--justify-center"
		)}>
			{maxNums.map((_, index, arr) => {
				const startIndex = arr.length - splittedNums.length;
				const numIndex = index - startIndex;
				const num = index >= startIndex ? splittedNums[numIndex] : null;

				const animationClassName = (numIndex >= 0 && prevSplittedNums?.[numIndex] !== splittedNums?.[numIndex]) && (
					`vs--jackpot-content-levels-level-card-content-body-amount-num-animation-${getAnimationClassNamePrefix(splittedNums.length, numIndex)}`
				);

				return (
					<Num
						key={index}
						index={index}
						className={mergeClassNames("vs--jackpot-content-levels-level-card-content-body-amount-num", numClassName)}
						num={num}
						numlength={splittedNums?.length}
						animationClassName={animationClassName}
						numIndex={numIndex}
					/>
				)
			})}
			<div className={mergeClassNames(`vs--jackpot-content-levels-level-card-content-body-amount-symbol`, 'vs--ml-6')}>{currencySymbol?.slice(0, 8)}</div>
		</div>
	)
};

export default AmountNums;