import  { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import OutsideAlerter from "components/ui/outsideAlerter";
import ResultsTicket from "components/ui/resultsTicket";
import Select from "components/ui/select";

import { getResultsToPrint, resetResultsToPrint, setResultsPrinting } from "store/actions/results/results.actions";

import { isSeasonGame, isLeagueGame, getPrintingOptionsValues } from "utils/common";

import { GAME_TYPE, GAME_TYPE_ICONS, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";
import { RESULTS_PRINTING_OPTIONS_VALUES } from "constants/ticket.constants";

import resultType from "types/result.type";
import sessionType from "types/session.type";

/** Print button component in results filter section */
const FiltersPrint = ({ getResultsToPrint, resetResultsToPrint, setResultsPrinting, resultsToPrint, printingResults, session, ...props }) => {
	const { t } = useTranslation();

	const [show, setShow] = useState(false);

	const [type, setType] = useState(GAME_TYPE.FOOTBALL_SINGLE_MATCH);
	const [count, setCount] = useState(20);

	const printingOptionsValuesRef = useRef();
	printingOptionsValuesRef.current = getPrintingOptionsValues(type);
	const printingOptionsTxt = " " + (!isSeasonGame(type) ? t("common.event") : isLeagueGame(type) ? t("common.week") : t("common.cup"));
	const games = session?.games ?? [];

	const handlePrint = () => {
		setShow(false);
		getResultsToPrint(type, count, true);
	};

	useEffect(() => {
		if (resultsToPrint.length > 0) {
			setResultsPrinting(true);
		}
	}, [resultsToPrint]);

	useEffect(() => {
		const count = isSeasonGame(type) ? printingOptionsValuesRef.current.at(-1) : printingOptionsValuesRef.current.at(1);
		setCount(count);
	}, [type]);

	return (
		<OutsideAlerter callback={() => setShow(false)} {...props}>
			<Fragment>
				<div className={"vs--filters-action vs--flex vs--justify-center vs--align-center " + (show ? " vs--filters-action-active" : "")} onClick={() => setShow((prev) => !prev)}>
					<i className="ic_print-fill" />
				</div>
				{show && (
					<div className="vs--filters-action-modal vs--pl-16 vs--pr-16 vs--pt-16 vs--pb-16">
						<div className="vs--flex-col vs--filters-action-modal-item vs--mb-16">
							<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("cashier.gameType")}</span>
							<Select
								suffixIcon={<i className="ic_down"></i>}
								onChange={(e) => {
									setType(e);
								}}
								value={type}
								renderValue={(selectedValue) => {
									return t(`common.${GAME_TYPE_TEXT_KEYS[selectedValue]}`);
								}}
								className="vs--print-select"
								dropdownClassname="vs--print-select-dropdown"
							>
								{games.map((game) => (
									<Select.Option key={game.id} value={game.type}>
										<div className="vs--flex vs--align-center">
											<i className={`${GAME_TYPE_ICONS[game.type]} vs--font-small`} />
											<span className="vs--print-select-game_type-option_title">{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}</span>
										</div>
									</Select.Option>
								))}
							</Select>
						</div>

						<div className="vs--flex-col vs--filters-action-modal-item vs--mb-16">
							<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("cashier.eventsCount")}</span>
							<Select suffixIcon={<i className="ic_down"></i>} onChange={(e) => setCount(e)} value={count} className="vs--print-select" dropdownClassname="vs--print-select-dropdown">
								{printingOptionsValuesRef.current.map((num) => {
									return (
										<Select.Option key={num} value={num}>
											{num + printingOptionsTxt}
										</Select.Option>
									);
								})}
							</Select>
						</div>
						<div className="vs--flex-col vs--filters-action-modal-item vs--flex vs--justify-center vs--align-end">
							<button type="button" className="vs--button vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-smallest vs--font-medium" onClick={handlePrint}>
								{t("cashier.print")}
							</button>
						</div>
					</div>
				)}

				{printingResults && <ResultsTicket gameType={type} onPrint={() => resetResultsToPrint()} />}
			</Fragment>
		</OutsideAlerter>
	);
};

/** FiltersPrint propTypes
 * PropTypes
 */
FiltersPrint.propTypes = {
	/** Redux action to get results to print */
	getResultsToPrint: PropTypes.func,
	/** Redux action to reset results to print */
	resetResultsToPrint: PropTypes.func,
	/** Redux action to show/hide printing modal */
	setResultsPrinting: PropTypes.func,
	/** Redux state property, array of results */
	resultsToPrint: PropTypes.arrayOf(resultType),
	/** Redux state property, is printing is opened */
	printingResults: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		resultsToPrint: state.results.resultsToPrint,
		printingResults: state.results.printingResults,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	getResultsToPrint: (type, count, forPrint) => {
		dispatch(getResultsToPrint(type, count, forPrint));
	},
	resetResultsToPrint: () => {
		dispatch(resetResultsToPrint());
	},
	setResultsPrinting: (type) => {
		dispatch(setResultsPrinting(type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPrint);
