import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import KenoLastResults from "./lastResults";

import { getUrlVars } from "utils/common";

import { GAME_TYPE, KENO_FORMAT } from "constants/game.constants";

import eventType from "types/event.type";

/** Kneo Game page Preambled Event */
const KenoPreamble = ({ rtps, event }) => {
	const { t } = useTranslation();

	const ballsCount = event?.gameData?.raceFormat === KENO_FORMAT.KENO_8 ? 8 : 10;

	const selections = Array.from({ length: ballsCount }, (_, i) => i + 1).reverse();

	/** Function to get markets for each selection
	 * @function
	 * @param {number} selection
	 * @memberOf KenoPreamble
	 */
	const getMarketsBySelection = (selection) => {
		const markets = (rtps || []).filter((r) => r.enabled && r.name.split("-")[1] === "" + selection).reverse();

		return markets;
	};

	return (
		<div className="vs--keno-preamble vs--flex vs--flex-col vs--mt-40">
			<div className="vs--keno-preamble-selections vs--flex vs--flex-wrap vs--justify-center">
				{[0, 1].map((i) => (
					<div className="vs--keno-preamble-selections-row vs--flex vs--pl-20 vs--pr-20 vs--justify-center" key={i}>
						{selections.slice((i * ballsCount) / 2, ((i + 1) * ballsCount) / 2).map((selection) => (
							<div key={selection} className={"vs--keno-preamble-selection vs--pb-20" + (selection > ballsCount / 2 ? " vs--keno-preamble-selection-large" : "")}>
								<div className="vs--keno-preamble-selection-title vs--flex vs--justify-center vs--align-center">
									<b className="vs--title vs--font-bold">{`${selection} ${t("common.selections")}`}</b>
								</div>
								<div className="vs--keno-preamble-selection-markets vs--pl-20 vs--pr-20 vs--pt-30">
									{getMarketsBySelection(selection)
										.slice(0, 6)
										.map((market) => (
											<div className="vs--keno-preamble-selection-market vs--flex vs--align-center vs--justify-between vs--mb-12" key={market.name}>
												<span className="vs--title vs--font-medium">{market.name.split("-")[0]}</span>
												<b className="vs--title vs--font-medium">x{market.margin}</b>
											</div>
										))}
								</div>
							</div>
						))}
					</div>
				))}
			</div>
			<KenoLastResults />
		</div>
	);
};

/** KenoPreamble propTypes
 * PropTypes
 */
KenoPreamble.propTypes = {
	/** Redux state property, the array of game rtps */
	rtps: PropTypes.array,
	/** Current event */
	event: eventType
};

const mapStateToProps = (state) => {
	return {
		rtps: state.auth.stream.rtps
	};
};

export default connect(mapStateToProps, null)(KenoPreamble);
