import PropTypes from "prop-types";

import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";

export default PropTypes.shape({
	limit: PropTypes.number,
	page: PropTypes.number,
	from: PropTypes.instanceOf(Date),
	to: PropTypes.instanceOf(Date),
	type: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/* Allow an empty string for cast state and for many existing components whose initial values are "" */
	betSlipId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([""])]),
	/* Allow an empty string for cast state and for many existing components whose initial values are "" */
	eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([""])]),
	state: PropTypes.oneOf(Object.values(BET_STATE))
});
