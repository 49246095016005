import PropTypes from "prop-types";
import gameDataType from "./gameData.type";

import { GAME_STATUSES, GAME_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	status: PropTypes.oneOf(Object.values(GAME_STATUSES)),
	startTime: PropTypes.string,
	gameData: gameDataType,
	eventId: PropTypes.number,
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	id: PropTypes.number,
	finishTime: PropTypes.string,
	gameName: PropTypes.string,
	result: PropTypes.string,
	betSlipsCount: PropTypes.number,
	noMoreBetsTime: PropTypes.string
});
