import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { REPORT_BEFORE, REPORT_AFTER, SET_TURNOVER_REPORT, CLEAR_TURNOVER_REPORT, SET_PRINTING_REPORT, SET_TURNOVER_TYPE } from "../../actionTypes";

const setReportBefore = () => ({
	type: REPORT_BEFORE
});

const setReportAfter = () => ({
	type: REPORT_AFTER
});

const setTurnoverReport = (data) => ({
	type: SET_TURNOVER_REPORT,
	payload: { data }
});

const clearTurnoverReport = (data) => ({
	type: CLEAR_TURNOVER_REPORT
});

export const getTurnoverReport = (isSettlement) => {
	return (dispatch) => {
		dispatch(setReportBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_TURNOVER_REPORT}`,
			method: Methods.GET,
			params: { isSettlement }
		})
			.then(({ data: { value: data } }) => {
				if (!isSettlement) dispatch(setTurnoverReport(data));
				else dispatch(clearTurnoverReport());
				dispatch(setReportAfter());
			})
			.catch((ex) => {
				dispatch(setReportAfter());
			});
	};
};

export const setReportPrinting = (type) => ({
	type: SET_PRINTING_REPORT,
	payload: { type }
});

export const setTurnoverType = (type) => ({
	type: SET_TURNOVER_TYPE,
	payload: { type }
});
