import { buildPathToStaticFolderOfCDN } from "utils/common";

/** Not Supported Component */
const NotSupported = () => {
	return (
		<div className="vs--container">
			<div className="vs--not-supported">
				<img src={buildPathToStaticFolderOfCDN("cashier-images/alert.png")} alt="alert" />
				<h1>Not Supported</h1>
			</div>
		</div>
	);
};

export default NotSupported;
