import { useLayoutEffect } from "react";

/*
	Component for managing KenoShuffleVideo visibility state.
	When this component mounted the KenoShuffleVideo will be visible.
	When this component unmounted the KenoShuffleVideo will be hidden.
	The class keno-shuffle-hidden must not use display: none,
	becouse that's way video tag load video again.
*/
const KenoShuffleHideShow = () => {
	useLayoutEffect(() => {
		const videoTag = document.getElementById("keno-shuffle");
		if (videoTag) {
			videoTag.classList.remove("keno-shuffle-hidden");
		}
		return () => {
			if (videoTag) {
				videoTag.classList.add("keno-shuffle-hidden");
			}
		};
	}, []);
	return null;
};

export default KenoShuffleHideShow;
