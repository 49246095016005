import  { Fragment } from "react";

import ResetPasswordComponent from "components/pages/resetPassword";

const ResetPasswordRoute = () => {
	return (
		<Fragment>
			<ResetPasswordComponent />
		</Fragment>
	);
};

export default ResetPasswordRoute;
