import { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Regular from "../../markets/regular";
import Flag from "components/ui/flag";
import runMarketUtilsFunction from "utils/markets/run";
import eventType from "types/event.type";
import rtpType from "types/rtp.type";

const CorrectScore = ({ eventInfos, activeTab = "", rtPs = [] }) => {
	if (!Array.isArray(eventInfos) || !eventInfos.length) {
		return <Fragment />;
	}
	const [currentEventId, setCurrentEventId] = useState(eventInfos?.[0]?.id ?? 0);
	const { currentEvent, markets } = useMemo(() => {
		const currentEvent = eventInfos.find((e) => e.id === currentEventId);
		const allMarkets = runMarketUtilsFunction("makeAllMarkets",
			[
				currentEvent?.markets ?? [],
				currentEvent?.gameData ?? {},
				rtPs,
				currentEvent?.gameType ?? 0,
				[activeTab]
			],
			currentEvent?.gameType ?? 0
		)
		const markets = currentEvent ? (allMarkets[activeTab] ?? []) : [];

		return {
			currentEvent,
			markets
		};
	}, [eventInfos, currentEventId, rtPs, activeTab]);

	return (
		<div className="vs--flex">
			<div className="vs--flex vs--flex-col vs--align-center">
				<ul className="vs--markets-table-body vs--markets-table-body-correct-score">
					{eventInfos.map((eventInfo) => {
						const team1 = eventInfo?.gameData?.team1;
						const team2 = eventInfo?.gameData?.team2;

						return (
							<li key={eventInfo.id} className={"vs--markets-table-row vs--flex vs--align-center" + (eventInfo.id === currentEventId ? " vs--markets-table-row-correct-score-active" : "")} onClick={() => setCurrentEventId(eventInfo.id)}>
								<div className="vs--flex vs--markets-table-cell vs--align-center vs--justify-left">
									<div className="vs--markets-table-number vs--flex vs--align-center vs--justify-center vs--font-normal vs--font-medium vs--pl-16 vs--pr-24 vs--pt-24 vs--pb-24">{eventInfo?.orderNumber ?? 0}</div>
									<div className="vs--flex-col vs--align-center vs--justify-center vs--market-match">
										<div className="vs--flex vs--align-center vs--justify-center vs--mb-8">
											<span className="vs--pr-4">
												<Flag code={team1?.flagCode ?? ""} gameType={eventInfo?.gameType} />
											</span>
											<span className="vs--font-exstrasmall vs--font-medium">{team1?.countryCode}</span>
											<span className="vs--pl-16 vs--pr-16">VS</span>
											<span className="vs--font-exstrasmall vs--font-medium">{team2?.countryCode}</span>
											<span className="vs--pl-4">
												<Flag code={team2?.flagCode ?? ""} gameType={eventInfo?.gameType} />
											</span>
										</div>
										<div className="vs--flex vs--align-center vs--justify-center">
											<span className="vs--font-smallest vs--font-regular">{eventInfo?.id}</span>
										</div>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="vs--flex vs--flex-col vs--flex-equal vs--pl-40 vs--pt-24">
				<Regular eventInfo={currentEvent} markets={markets} />
			</div>
		</div>
	);
};

CorrectScore.propTypes = {
	/** Active tab */
	activeTab: PropTypes.string,
	/** Current event info */
	eventInfos: PropTypes.arrayOf(eventType),
	/** RTPS of current game */
	rtPs: PropTypes.arrayOf(rtpType)
};

export default CorrectScore;
