import { useRef } from "react";

const usePrevious = (value, defaultPrevious = undefined) => {
	const currentRef = useRef(value);
	const previousRef = useRef(defaultPrevious);
	if (currentRef.current !== value) {
		previousRef.current = currentRef.current;
		currentRef.current = value;
	}
	return previousRef.current;
};

export default usePrevious;
