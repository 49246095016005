import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BET_STATE } from "constants/betslip.constants";
import { TICKET_TYPE, TICKET_PRINT_REQUEST_STATE } from "constants/ticket.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { removePending, updatePending } from "./pendings.actions";
import { updateSettled } from "./settled.actions";
import { setTicketConfirmationBefore, setTicketConfirmationAfter, closeTicketConfirmation } from "./confirmation.actions";

import { mapBetToPrintingTicket } from "utils/bets";

import { VIEWING_TICKET_BEFORE, VIEWING_TICKET_AFTER, SET_VIEWING_TICKET, UPDATE_VIEWING_TICKET, UPDATE_VIEWING_TICKET_BET, SHOW_VIEWING_TICKET, HIDE_VIEWING_TICKET, SET_PRINTING_TICKET, REMOVE_PRINTING_TICKET, UPDATE_TICKET_REPRINT_REQUEST } from "../../actionTypes";
import { TICKET_PAYOUT_TYPE } from "constants/common.constants";

const setViewingTicketBefore = () => ({
	type: VIEWING_TICKET_BEFORE
});

const setViewingTicketAfter = () => ({
	type: VIEWING_TICKET_AFTER
});

const setViewingTicket = (ticket) => ({
	type: SET_VIEWING_TICKET,
	payload: { ticket }
});

export const updateTicketReprintRequest = (id, type, listType, state) => ({
	type: UPDATE_TICKET_REPRINT_REQUEST,
	payload: { id, type, listType, state }
});

export const updateViewingTicket = (ticket) => ({
	type: UPDATE_VIEWING_TICKET,
	payload: { ticket }
});

export const updateViewingTicketBet = (bet) => ({
	type: UPDATE_VIEWING_TICKET_BET,
	payload: { bet }
});

export const cancelTicket = (id) => {
	return (dispatch, getState) => {
		dispatch(setTicketConfirmationBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CANCEL_TICKET}`,
			method: Methods.POST,
			data: { id }
		})
			.then(({ data: { value: result } }) => {
				/** Print Ticket */
				if (getState().auth.session.cancelTicket.printCancellationTickets) {
					dispatch(printTicket(mapBetToPrintingTicket(result, TICKET_TYPE.CANCEL)))
				};

				dispatch(removePending(result));
				dispatch(setTicketConfirmationAfter());
				dispatch(closeTicketConfirmation());
			})
			.catch((ex) => {
				dispatch(setTicketConfirmationAfter());
			});
	};
};

export const cancelBet = (id, betId) => {
	return (dispatch) => {
		dispatch(setTicketConfirmationBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CANCEL_BET}`,
			method: Methods.POST,
			data: { id, betId }
		})
			.then(({ data: { value: data } }) => {
				if (data.item1.state !== BET_STATE.PENDING) {
					dispatch(removePending(data.item1));
				} else {
					dispatch(updatePending(data.item1));
				}
				dispatch(setTicketConfirmationAfter());
				dispatch(closeTicketConfirmation());
			})
			.catch((ex) => {
				dispatch(setTicketConfirmationAfter());
			});
	};
};

export const payoutTicket = ({ id, payoutType = null, jackpotLevelType = null }) => {
	return (dispatch, getState) => {
		dispatch(setTicketConfirmationBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PAYOUT_TICKET}`,
			method: Methods.POST,
			data: { id, payoutType, jackpotLevelType }
		})
			.then(({ data: { value: result } }) => {
				/** Print Ticket */
				if (getState().auth.session.payoutTicket.printPayoutTickets) {
					dispatch(printTicket(mapBetToPrintingTicket({...result, payoutType, jackpotLevelType }, TICKET_TYPE.PAYOUT)))
				};

				dispatch(setTicketConfirmationAfter());
				dispatch(closeTicketConfirmation());
			})
			.catch((ex) => {
				dispatch(setTicketConfirmationAfter());
			});
	};
};

export const getTicket = (id, cb) => {
	return (dispatch) => {
		dispatch(setViewingTicketBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_TICKET}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setViewingTicket(data));
				if (cb) {
					cb(data);
				}
				dispatch(setViewingTicketAfter());
			})
			.catch((ex) => {
				dispatch(setViewingTicketAfter());
			});
	};
};

export const showTicket = () => ({
	type: SHOW_VIEWING_TICKET
});

export const closeTicket = () => ({
	type: HIDE_VIEWING_TICKET
});

export const printTicket = (ticket) => ({
	type: SET_PRINTING_TICKET,
	payload: { ticket }
});

export const closePrintTicket = () => ({
	type: REMOVE_PRINTING_TICKET
});

export const requestForTicketReprint = (id, type, listType) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REPRINT_REQUEST_TICKET}`,
			method: Methods.POST,
			data: { id, type, payoutType: TICKET_PAYOUT_TYPE.BETSLIP }
		})
			.then(({ data: { status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					dispatch(updateTicketReprintRequest(id, type, listType, TICKET_PRINT_REQUEST_STATE.PENDING));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const sendPrintTicketData = (id, type, payoutType = null) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PRINT_TICKET}`,
			method: Methods.POST,
			data: { id, type, payoutType }
		})
			.then(({ data: { value: data } }) => {
				if (data.state === BET_STATE.PENDING) {
					dispatch(updatePending(data));
				}
				dispatch(updateViewingTicket(data));
				dispatch(closePrintTicket());
			})
			.catch((ex) => {
				dispatch(closePrintTicket());
			});
	};
};

export const sendReprintTicketData = (id, type) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REPRINT_TICKET}`,
			method: Methods.POST,
			data: { id, type, payoutType: TICKET_PAYOUT_TYPE.BETSLIP }
		})
			.then(({ data: { value: data } }) => {
				if (data.state === BET_STATE.PENDING) {
					dispatch(updatePending(data, true));
				}
				dispatch(updateSettled(data));
				dispatch(closePrintTicket());
			})
			.catch((ex) => {
				dispatch(closePrintTicket());
			});
	};
};

export const getQRImage = (id, cb) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_QR}`,
			method: Methods.GET,
			params: { id, height: 114, width: 114 }
		})
			.then(({ data: { value: data } }) => {
				cb && cb(data);
			})
			.catch(Function.prototype);
	};
};
