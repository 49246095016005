import PropTypes from "prop-types";
import moment from "moment";

import { getDayOfWeek, isCurrentDayOfWeek, isWeekend } from "utils/calendar";

import { CALENDAR_TYPE } from "constants/calendar.constants";

const className = "vs--ui-calendar-month-view-weekdays";
const weekdayClassName = `${className}-weekday`;

const Weekdays = ({ calendarType }) => {
	const beginOfMonth = moment().startOf("month");
	const year = beginOfMonth.get("year");
	const monthIndex = beginOfMonth.get("month");

	const weekdays = Array.from({ length: 7 }).map((_, index) => {
		const weekday = index + 1;
		const weekdayDate = new Date(year, monthIndex, weekday - getDayOfWeek(beginOfMonth, calendarType));

		return (
			<div
				key={weekday}
				className={`${weekdayClassName}
					${isCurrentDayOfWeek(weekdayDate) ? ` ${weekdayClassName}-current` : ""}
					${isWeekend(weekdayDate, calendarType) ? ` ${weekdayClassName}-weekend` : ""}`}
			>
				<span title={moment(weekdayDate).format("dddd")}>{moment(weekdayDate).format("dd")}</span>
			</div>
		);
	});

	return <div className={className}>{weekdays}</div>;
};

Weekdays.propTypes = {
	calendarType: PropTypes.oneOf(Object.values(CALENDAR_TYPE))
};

export default Weekdays;
