import PropTypes from "prop-types";

export default PropTypes.shape({
	id: PropTypes.string,
	bonusId: PropTypes.string,
	amount: PropTypes.number,
	gameType: PropTypes.number,
	betType: PropTypes.number,
	currency: PropTypes.string,
	betshopNamesAndAdresses: PropTypes.object,
	endDate: PropTypes.string,
	maxPossibleWinAmount: PropTypes.number,
	minOddsMulti: PropTypes.number,
	minOddsSingle: PropTypes.number
});
