import PropTypes from "prop-types";
import Tooltip from "components/ui/tooltip";

const ConditionalTooltip = ({ children, title = null, allowToShowTooltip = false, placement = "bottom", overlayClassName = null }) => {
	if (!allowToShowTooltip) {
		return children;
	}
	return (
		<Tooltip placement={placement} title={title} overlayClassName={overlayClassName}>
			{children}
		</Tooltip>
	);
};

ConditionalTooltip.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	allowToShowTooltip: PropTypes.bool,
	placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
	overlayClassName: PropTypes.string
};

export default ConditionalTooltip;
