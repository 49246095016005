import { useContext, useState } from "react";

import { FormContext } from "components/ui/form/formContext";

import useFirstRender from "hooks/useFirstRender";

import { VALIDATION_INITIAL_STATE } from "constants/ui.constants";

const useFormItem = ({ name, dependencies, rules }) => {
	const {
		formInstance: { _INTERNAL_ },
		initialValues
	} = useContext(FormContext);

	const [value, setValue] = useState(initialValues?.[name] || "");
	const [validationState, setValidationState] = useState({
		...VALIDATION_INITIAL_STATE
	});

	useFirstRender({
		callBackFn: () => {
			//==================================================================
			//=                   FIELD STOR CREATION                          =
			//==================================================================
			const fieldStoreData = {
				value,
				valueSetStateFn: setValue,
				fieldName: name
			};

			if (Array.isArray(rules)) {
				fieldStoreData.validationData = {
					validationSetStateFn: setValidationState,
					rules,
					message: null
				};
			}

			if (Array.isArray(dependencies)) {
				fieldStoreData.dependencies = dependencies;
			}

			_INTERNAL_.createFieldStore(fieldStoreData);
		}
	});

	return {
		value,
		validationState,
		_INTERNAL_
	};
};

export default useFormItem;
