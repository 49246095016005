import PropTypes from "prop-types";

import Days from "components/ui/datePicker/calendar/monthView/days";
import Weekdays from "components/ui/datePicker/calendar/monthView/weekdays";

import { CALENDAR_TYPE } from "constants/calendar.constants";

const MonthView = ({ showFixedNumberOfWeeks, showNeighboringMonth, calendarType = CALENDAR_TYPE.ISO_8601, formatShortWeekday, formatWeekday, ...childProps }) => {
	return (
		<div className="vs--ui-calendar-month-view vs--flex vs--align-end">
			<div>
				<Weekdays calendarType={calendarType} formatShortWeekday={formatShortWeekday} formatWeekday={formatWeekday} />
				<Days showNeighboringMonth={showNeighboringMonth} showFixedNumberOfWeeks={showFixedNumberOfWeeks} calendarType={calendarType} {...childProps} />
			</div>
		</div>
	);
};

MonthView.propTypes = {
	calendarType: PropTypes.oneOf(Object.values(CALENDAR_TYPE)),
	formatDay: PropTypes.func,
	formatLongDate: PropTypes.func,
	formatShortWeekday: PropTypes.func,
	formatWeekday: PropTypes.func,
	showFixedNumberOfWeeks: PropTypes.bool,
	showNeighboringMonth: PropTypes.bool
};

export default MonthView;
