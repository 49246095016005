import  { useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Calendar from "components/ui/datePicker/calendar";

import useOutsideClick from "hooks/useOutsideClick";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

const DatePicker = ({ classNames = { picker: "", calendar: "" }, showTime, value, onChange, minDate, maxDate, suffixIcon = <i className="ic_calendar" /> }) => {
	const [isCalendarOpened, setIsCalendarOpened] = useState(false);
	const calendarRef = useRef(null);

	const formattedValue = moment(value).format(DATE_TIME_FORMAT);

	const closeCalendar = () => setIsCalendarOpened(false);

	useOutsideClick(calendarRef, closeCalendar);

	return (
		<div ref={calendarRef} className={`vs--ui-date-picker ${classNames.picker}`} onClick={() => setIsCalendarOpened((prev) => !prev)}>
			<div className="vs--ui-picker-content">
				<input readOnly={true} title={formattedValue} value={formattedValue} />
				{suffixIcon}
			</div>
			<div className="vs--ui-calendar-overlay">{isCalendarOpened && <Calendar className={classNames.calendar} showTime={showTime} minDate={minDate} maxDate={maxDate} value={value} onChange={onChange} closeCalendar={closeCalendar} />}</div>
		</div>
	);
};

DatePicker.propTypes = {
	/** Enabled/Disable Calendar Time settings footer */
	showTime: PropTypes.bool,
	/** React property, additional classNames for DatePicker */
	classNames: PropTypes.object,
	/** DatePicker input icon */
	suffixIcon: PropTypes.element,
	/** Moment object, DatePicker input value */
	value: PropTypes.object,
	/** Callback function, called when calendar value is changed */
	onChange: PropTypes.func,
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object,
	/** Moment object, minimum date for opened calendar active date select */
	minDate: PropTypes.object,
	/** Moment object, maximum date for opened calendar active date select */
	maxDate: PropTypes.object
};

export default DatePicker;
