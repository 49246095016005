import  { Fragment } from 'react';

import JackpotLiveInfo from 'components/pages/jackpotLiveInfo';

const JackpotLiveInfoRoute = (props) => {
	return (
		<Fragment>
			<JackpotLiveInfo {...props} />
		</Fragment>
	)
}

export default JackpotLiveInfoRoute;