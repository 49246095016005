import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import TransitionSvg from "./transitionSvg";

import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common";

const JACKPOT_LEVELS = {
	"level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-1.svg"),
	"level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-2.svg"),
	"level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-4.svg")
}

const JackpotWinAnimation = ({
	animateableJackpot,
	getMainContainer,
	currencySymbol,
	setAnimationFinish,
	setShowCanfetti
}) => {
	const { t } = useTranslation();

	if (animateableJackpot === null) {
		return null;
	}

	return (
		<Fragment key={animateableJackpot.levelType}>
			<TransitionSvg getMainContainer={getMainContainer} jackpot={animateableJackpot} setShowCanfetti={setShowCanfetti} setAnimationFinish={setAnimationFinish} />
			<div className={mergeClassNames("vs--active-jackpot-live-info-win-winner", `vs--active-jackpot-live-info-win-winner-${animateableJackpot.levelType}`)}>
				<div className="vs--active-jackpot-live-info-win-winner-content vs--flex vs--flex-col vs--align-center vs--justify-center">
					<span className="vs--active-jackpot-live-info-win-winner-content-title">{t('cashier.youWonJackpot')}</span>
					<span className="vs--active-jackpot-live-info-win-winner-content-subTitle">{t('cashier.congratulation')}</span>
					<img className="vs--active-jackpot-live-info-win-winner-content-level-icon" src={JACKPOT_LEVELS[`level-${animateableJackpot.levelType}`]} />
					<div className="vs--active-jackpot-live-info-win-winner-content-ticket vs--flex">
						<span className="vs--active-jackpot-live-info-win-winner-content-ticket-item vs--active-jackpot-live-info-win-winner-content-ticket-id">{t('cashier.ticketID')}:</span>
						<span className="vs--active-jackpot-live-info-win-winner-content-ticket-item">#</span>
						<span className="vs--active-jackpot-live-info-win-winner-content-ticket-item">{animateableJackpot.betSlipId}</span>
					</div>
					<div className="vs--active-jackpot-live-info-win-winner-content-amount vs--flex">
						<span className="vs--active-jackpot-live-info-win-winner-content-amount-title" id="countdown">{animateableJackpot.amount}</span>
						<span className="vs--active-jackpot-live-info-win-winner-content-amount-title">{currencySymbol}</span>
					</div>
				</div>
			</div>
		</Fragment>
	)
};

export default JackpotWinAnimation;