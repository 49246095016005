import PropTypes from "prop-types";
import moment from "moment";

import Tile from "components/ui/datePicker/calendar/tile";

import { getMonthStart, getMonthEnd } from "utils/calendar";

const Month = ({ className, date, selectedDate, ...otherProps }) => {
	return (
		<Tile
			{...otherProps}
			selectedDate={selectedDate}
			date={date}
			className={`${className} vs--ui-calendar-year-view-months-month ${date.getMonth() === selectedDate.month() ? "vs--ui-calendar-year-view-months-month-active" : ""}`}
			formatAbbr={(date) => moment(date).format("YYYY MMMM")}
			maxDateTransform={getMonthEnd}
			minDateTransform={getMonthStart}
			view="year"
		>
			{moment(date).format("MMM")}
		</Tile>
	);
};

Month.propTypes = {
	className: PropTypes.string.isRequired,
	date: PropTypes.instanceOf(Date).isRequired,
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired
};

export default Month;
