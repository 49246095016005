import { QR_TICKET_BEFORE, QR_TICKET_AFTER, SET_QR_TICKET } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case QR_TICKET_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case QR_TICKET_AFTER:
			return {
				...state,
				isLoading: false
			};
		case SET_QR_TICKET:
			return {
				...state,
				ticket: payload.ticket
			};
		default:
			return state;
	}
};
