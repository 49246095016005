import { PENALTY_SHOOTOUT_MARKETS_GROUPS } from "constants/market.constants";
import MarketItem from "../marketItem";
import MarketItemByTab from "../marketItemByTab";
import { Fragment } from "react";

const CorrectScoreMarketContents = ({ groupedMarkets, ...props }) => {

	const anyOddsExists = groupName => groupedMarkets[groupName]?.marketContainer.some(mArr => mArr.length > 0)

	const renderMarketItem = (groupName, propForOverride) => {
		return (
			groupedMarkets[groupName]
				? <MarketItem {...groupedMarkets[groupName]} {...Object.assign({}, props, propForOverride)} />
				: null
		)
	}

	const renderMarketItemNyTab = (groupName, propForOverride, panelProps) => {
		return (
			groupedMarkets[groupName]
				? <MarketItemByTab panelProps={panelProps} {...groupedMarkets[groupName]} {...Object.assign({}, props, propForOverride)} />
				: null
		)
	}

	return (
		<div className="vs--flex vs--flex-col vs--flex-equal vs--pl-32">
			{
				anyOddsExists(PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE)
					? (
						<Fragment>
							<div className="vs--flex vs--flex-row">
								{
									renderMarketItem(
										PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE,
										{
											style: { marginLeft: 16, marginTop: 18, marginBottom: 24 },
											bodyProps: {
												gap: 12
											}
										}
									)
								}
							</div>
							<div className="vs--markets-border-regular vs--markets-border-regular-horizontal" />
						</Fragment>
					)
					: null 
			}
			{
				anyOddsExists(PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT)
					? (
						<div className="vs--flex vs--flex-row">
							{
								renderMarketItemNyTab(PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT, null, { style: { marginLeft: 16, marginTop: 18 } })
							}
						</div>
					)
					: null
			}

		</div>
	)
}
export default CorrectScoreMarketContents