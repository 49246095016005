import { useTranslation } from "react-i18next";
import moment from "moment";

import AmountNums from "components/jackpotInfo/amountNums";
import { mergeClassNames, toFixed, isNullish, buildPathToStaticFolderOfCDN } from "utils/common";

import { JACKPOT_LEVEL, MAX_HIT_AMOUNT_NUM_LENGTH } from "constants/bonus.constants";

import { DATE_TIME_FORMAT } from "constants/date.constants";

import { Fragment } from "react";

const JACKPOT_LEVELS = {
	"level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-1.svg"),
	"level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-2.svg"),
	"level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-4.svg")
}

const SingleCard = ({ className = "", currencyDecimalCount = 0, currencyCode, currencySymbol, jackpot }) => {
	const { t } = useTranslation();

	const maxNums = Array.from({ length: MAX_HIT_AMOUNT_NUM_LENGTH + currencyDecimalCount });

	return (
		<div className="vs--single">
			<div className={mergeClassNames(className, "vs--flex vs--flex-col vs--justify-between", "vs--jackpot-content-levels-level-card", `vs--jackpot-content-levels-level-card-${jackpot.levelType}`, "vs--jackpot-content-levels-level-card-single")}>
				<video autoplay="autoplay" muted loop="true" controls>
					<source src={buildPathToStaticFolderOfCDN("media/cashier/bg-gold-3.mp4")} type="video/mp4" />
				</video>
				<div className="vs--jackpot-content-levels-level-card-content vs--flex vs--flex-col vs--justify-start vs--align-center vs--pt-32 vs--pb-32">
					<div className="vs--jackpot-content-levels-level-card-content-body vs--flex vs--flex-col vs--justify-center vs--align-center">
						<span className="vs--jackpot-content-levels-level-card-content-body-title vs--font-bold vs--font-big">{jackpot.poolName}</span>
						<img src={JACKPOT_LEVELS[`level-${jackpot.levelType}`]} />

						<div className={mergeClassNames("vs--jackpot-content-levels-level-card-content-body-amount vs--jackpot-content-levels-level-card-content-body-amount-countdown", "vs--flex vs--align-center")}>
							<AmountNums
								maxNums={maxNums}
								amount={toFixed(jackpot?.amount || 0, currencyDecimalCount)}
								currencySymbol={currencySymbol}
								numClassName="vs--jackpot-content-levels-level-card-content-body-amount-num-single-info"
							/>
						</div>

						<div className="vs--jackpot-content-levels-level-card-content-body-min-bet">
							<span className="vs--jackpot-content-levels-level-card-content-body-min-bet-title vs--font-big vs--font-medium ">{t("cashier.minBet")}:&nbsp;</span>
							<span className="vs--jackpot-content-levels-level-card-content-body-min-bet-value vs--font-big vs--font-medium">
								{jackpot.minBetAmount}&nbsp;{currencyCode}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="vs--jackpot-content-levels-level-card-content-footer vs--flex vs--align-center vs--justify-between vs--pl-16 vs--pr-16">
				{
					isNullish(jackpot.betSlipId)
						? null
						: (
					<Fragment>
						<div className="vs--font-big vs--font-medium">
							<span className="vs--jackpot-content-levels-level-card-content-footer-title">{t("cashier.lastWinner")}:&nbsp;</span>
							<span className="vs--jackpot-content-levels-level-card-content-footer-value">{isNullish(jackpot.lastWinTime) ? "-" : `${t("common.id")} ${jackpot.betSlipId}`}</span>
						</div>
						<div className="vs--font-big vs--font-medium">
							<span className="vs--jackpot-content-levels-level-card-content-footer-title">{t("common.date")}:&nbsp;</span>
							<span className="vs--jackpot-content-levels-level-card-content-footer-value">{isNullish(jackpot.lastWinTime) ? "-" : moment.utc(jackpot.lastWinTime).local().format(DATE_TIME_FORMAT)}</span>
						</div>
						<div className="vs--font-big vs--font-medium">
							<span className="vs--jackpot-content-levels-level-card-content-footer-title">{t('cashier.largestWin')}:&nbsp;</span>
							<span className="vs--jackpot-content-levels-level-card-content-footer-value">{jackpot.largestWinAmount}&nbsp;{currencyCode}</span>
						</div>
					</Fragment>
					)
				}
			</div>
		</div>
	);
};

export default SingleCard;
