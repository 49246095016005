import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import runMarketUtilsFunction from "utils/markets/run";
import { SET_SEASON_MARKETS, SET_SEASON_STANDINGS, SET_TEAMS_HEAD_TO_HEAD, ADD_TEAMS_HEAD_TO_HEAD } from "store/actionTypes";

const setSeasonMarkets = (data) => ({
	type: SET_SEASON_MARKETS,
	payload: { data }
});

const setSeasonStandings = (data) => ({
	type: SET_SEASON_STANDINGS,
	payload: { data }
});

const setTeamsHeadToHead = (data) => ({
	type: SET_TEAMS_HEAD_TO_HEAD,
	payload: { data }
});

const addTeamsHeadToHead = (data) => ({
	type: ADD_TEAMS_HEAD_TO_HEAD,
	payload: { data }
});

export const getSeasonMarkets = (id, fromStream) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_FOOTBALL_EVENT}`,
			method: Methods.GET,
			params: { id },
			headers: fromStream ? { stream: true } : null
		})
			.then(({ data: { value: data } }) => {
				const games = getState()?.auth?.session?.games ?? [];
				const currentGame = games.find((g) => g.type === data.gameType);
				const rtps = currentGame?.rtPs ?? [];
				const allMarkets = runMarketUtilsFunction("makeAllMarkets", [data.markets, null, rtps, data.gameType, ["fulltime"]], data.gameType);
				const markets = allMarkets?.fulltime ?? [];
				dispatch(
					setSeasonMarkets({
						markets: markets,
						seasonId: data.id,
						status: data.status,
						startTime: data.startTime
					})
				);
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const updateSeasonMarkets = (season) => {
	return (dispatch, getState) => {
		if (season.id === getState().season.special.seasonId) {
			const games = getState()?.auth?.session?.games ?? [];
			const currentGameType = getState()?.game?.currentGameType ?? 1;
			const currentGame = games.find((g) => g.type === currentGameType);
			const rtps = currentGame?.rtPs ?? [];
			const allMarkets = runMarketUtilsFunction(
				"makeAllMarkets", 
				[season.markets, null, rtps, currentGameType, ["fulltime"]],
				currentGameType
			);
			const markets = allMarkets?.fulltime ?? [];
			dispatch(setSeasonMarkets({
				markets: markets,
				seasonId: season.id,
				status: season.status,
				startTime: season.startTime
			}));
		}
	};
};

export const getFootballLeagueStatistics = (projectId, fromStream, gameType) => {
	return (dispatch) => {
		const headers = {};
		if (fromStream) {
			headers.stream = true;
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FOOTBALL_LEAGUE_STATISTICS}`,
			method: Methods.GET,
			params: { projectId, gameType },
			headers: headers
		}).then(({ data: { value: data } }) => {
			if (!Array.isArray(data)) {
				return;
			}
			const sorted = [...data].sort((next, prev) => (next.points === prev.points ? 0 : next.points > prev.points ? -1 : 1));
			dispatch(setSeasonStandings(sorted));
		});
	};
};

export const getFootballTeamToTeamStatistics = (gameType, listOfCountriesTouple, fromStream) => {
	return (dispatch) => {
		listOfCountriesTouple.forEach((countryCodeTouple) => {
			const [countryCode1, countryCode2] = countryCodeTouple;
			return axios({
				url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FOOTBALL_TEAM_TO_TEAM_STATISTICS}`,
				method: Methods.GET,
				params: { gameType, countryCode1, countryCode2 },
				headers: fromStream ? { stream: true } : null
			}).then(({ data: { value } }) =>
				dispatch(
					addTeamsHeadToHead({
						key: countryCodeTouple.join("-"),
						value
					})
				)
			);
		});
	};
};
