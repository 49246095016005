import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "utils/common";
import ConditionalTooltip from "./conditionalTooltip";

const SeasonOdd = ({ odd, market, isOddSelected, isOddDisabled, handleOddClick, isDisabledForBonus }) => {
	const { t } = useTranslation();
	return (
		<div className="vs--league-tooltip vs--flex vs--align-center vs--justify-center vs--mr-8">
			<ConditionalTooltip allowToShowTooltip={isDisabledForBonus} placement="bottom" title={<span className="vs--markets-list-item-content-odd-item-bonus-title">{t("cashier.unavailableForFreeBet")}</span>} overlayClassName="vs--league-tool">
				<div
					className={"vs--markets-table-factor vs--flex vs--align-center vs--justify-center" + (isOddSelected(odd.id) ? " vs--markets-table-factor-selected" : "") + (isDisabledForBonus || isOddDisabled(odd) ? " vs--markets-table-factor-disabled" : "")}
					onClick={() => !isDisabledForBonus && handleOddClick(odd, market)}
				>
					{isOddDisabled(odd) ? <i className="ic_lock vs--font-bigest" /> : <b className="vs--font-regular vs--font-small">{numberWithSpaces(odd.factor)}</b>}
				</div>
			</ConditionalTooltip>
		</div>
	);
};

SeasonOdd.propTypes = {
	odd: PropTypes.object,
	market: PropTypes.object,
	isOddSelected: PropTypes.func,
	isOddDisabled: PropTypes.func,
	handleOddClick: PropTypes.func,
	isDisabledForBonus: PropTypes.bool
};

export default SeasonOdd;
