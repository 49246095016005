import  { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalWrapper from "components/modalWrapper";
import VideoPlayer from "components/ui/videoPlayer";
import { isSeasonGame, isLeagueGame, isChampionsCupGame } from "utils/common";
import { GAME_TYPE } from "constants/game.constants";
import resultType from "types/result.type";
import sessionType from "types/session.type";

/** Results Event Video */
const EventVideo = ({ coordinates, onClose, event, session }) => {
	const { t } = useTranslation();

	/** Function which Render the result string
	 * @function
	 * @param {object} item - the item to show in row
	 * @returns {string}
	 * @memberOf EventVideo
	 */
	const renderResult = (item) => {
		let str = "";
		if ([GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(item.gameType) || isSeasonGame(item.gameType)) {
			const team1 = item?.gameData?.team1 ?? {};
			const team2 = item?.gameData?.team2 ?? {};

			str = `${team1.goalCount ?? ""} - ${team2.goalCount ?? ""}`
			if (isLeagueGame(item.gameType) || isChampionsCupGame(item.gameType)) {
				str = `${team1.countryCode ?? ""} ${str} ${team2.countryCode ?? ""}`;
			} else {
				str = `${t(`countries.${team1.countryCode ?? ""}`)} ${str} ${t(`countries.${team2.countryCode ?? ""}`)}`;
			}
		} else {
			str = item.result;
		}

		return str;
	};

	/** Function , to get current game id
	 * @function
	 * @memberOf EventVideo
	 */
	const getCurrentGameId = () => session.games.find((g) => g.type === event.gameType).id;

	return (
		<ModalWrapper
			coordinates={coordinates}
			title={
				<Fragment>
					<span className="vs--results-video-title">{renderResult(event)}</span>
					<span className="vs--results-video-id">{`${t("common.eventId")} ${event.id}`}</span>
				</Fragment>
			}
			onCancel={onClose}
			visible={true}
			classNames={{ content: "vs--result-modal vs--result-modal-event", header: "vs--result-modal-header" }}
		>
			<div className="vs--results-video">
				<VideoPlayer
					streamConfiguration={{
						url: import.meta.env.SYSTEM_ARCHIVE_URL ? `${import.meta.env.SYSTEM_ARCHIVE_URL}/${getCurrentGameId()}/${event?.id ?? ""}.mp4` : null
					}}
					defaultExtended={false}
					showMinimize={false}
					showFullscreen={true}
				/>
			</div>
		</ModalWrapper>
	);
};

/** EventVideo propTypes
 * PropTypes
 */
EventVideo.propTypes = {
	/** Css variables` viewport x, y coordinates and position top */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** Function that will be called on modal close */
	onClose: PropTypes.func,
	/** The event id, video to show */
	event: resultType,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session
	};
};

export default connect(mapStateToProps, null)(EventVideo);
