import  { useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { getUniqueRandomNumbersByRangeAndCount } from "utils/common";

import { GAME_TYPE, KENO_FORMAT, KENO_FORMAT_BALLS_QTY, KENO_BALLS_COUNT } from "constants/game.constants";

import { setKenoBalls } from "store/actions/betslip/betslip.actions";

import eventType from "types/event.type";

/** Keno Market Random Selection component*/
const KenoRandomSelection = ({ eventInfo, setKenoBalls, currentGameType, isOddDisabled }) => {
	const randomSelect = (randCount) => {
		if (isOddDisabled()) {
			return;
		}

		setKenoBalls({
			eventInfo,
			gameType: currentGameType,
			balls: getUniqueRandomNumbersByRangeAndCount(randCount, KENO_BALLS_COUNT, 1)
		});
	};

	const raceFormatCount = KENO_FORMAT_BALLS_QTY[Object.values(KENO_FORMAT).find((value) => value === (eventInfo?.gameData?.raceFormat ?? 0))] || 0;
	const randomButtonsCount = useMemo(() => Array.from({ length: raceFormatCount }, (_, i) => i + 1), []);

	return (
		<div className="vs--markets-keno-random vs--flex vs--mt-24">
			{randomButtonsCount.map((randNum) => (
				<div key={randNum} className={"vs--pt-6 vs--pb-6" + (isOddDisabled() ? " vs--markets-keno-random-disabled" : "")} onClick={() => randomSelect(randNum)}>
					<span className="vs--font-bigest">{randNum}</span>
				</div>
			))}
		</div>
	);
};

/** KenoRandomSelection propTypes
 * PropTypes
 */
KenoRandomSelection.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux action to update elected balls array */
	setKenoBalls: PropTypes.func,
	/** React property function, is odds disabled */
	isOddDisabled: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setKenoBalls: (kenoBalls) => {
		dispatch(setKenoBalls(kenoBalls));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(KenoRandomSelection);
