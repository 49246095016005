import  { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Navigation from "components/ui/datePicker/calendar/navigation";
import Content from "components/ui/datePicker/calendar/content";
import Time from "components/ui/datePicker/calendar/time";

import { CALENDAR_TYPE, CALENDAR_VIEW } from "constants/calendar.constants";

const baseClassName = "vs--ui-calendar";
const views = [CALENDAR_VIEW.DECADE, CALENDAR_VIEW.YEAR, CALENDAR_VIEW.MONTH];

import { getBegin, between } from "utils/calendar";

const Calendar = ({ showTime = true, className = "", calendarType = CALENDAR_TYPE.ISO_8601, maxDate = moment(), minDate = moment("2019-01-01 00:00:00"), showNeighboringMonth = true, showFixedNumberOfWeeks = true, isContentTileDisabled, value, closeCalendar, onChange }) => {
	const getDetailValue = (value) => {
		if (!value) {
			return null;
		}

		return moment(between(getBegin(CALENDAR_VIEW.MONTH, value), minDate, maxDate));
	};

	const getInitialActiveStartDate = (value, view) => {
		const valueFrom = getDetailValue(value) || moment();

		return moment(getBegin(view, valueFrom));
	};

	const [view, setView] = useState(CALENDAR_VIEW.MONTH);
	const [selectedDate, setSelectedDate] = useState(() => getInitialActiveStartDate(value, view));

	const isDrillDownAvailable = views.indexOf(view) < views.length - 1;
	const isDrillUpAvailable = views.indexOf(view) > 0;

	/** Callback that will be called when user clicks on content item */
	const onContentTileClick = (nextActiveStartDate) => {
		if (!isDrillDownAvailable) {
			return;
		}

		const nextView = view === CALENDAR_VIEW.DECADE ? views[views.indexOf(view) + 2] : views[views.indexOf(view) + 1];

		setSelectedDate(moment(nextActiveStartDate));
		setView(nextView);
	};

	/** Callback that will be called when user navigates from navigation */
	const onNavigationTitleClick = (passedView) => {
		if (!isDrillUpAvailable) {
			return;
		}

		const nextView = passedView || views[views.indexOf(view) - 1];
		setView(nextView);
	};

	/** Callback that will be called on first (month) view content item click */
	const onSelectedDateChange = (value, event) => {
		const nextValue = getDetailValue(value);

		event.persist();

		setSelectedDate(moment(nextValue));

		if (!showTime && onChange) {
			onChange(nextValue);
		}
	};

	const commonProps = {
		selectedDate,
		maxDate,
		minDate,
		onClick: isDrillDownAvailable ? onContentTileClick : onSelectedDateChange,
		isContentTileDisabled,
		value
	};

	return (
		<div className={`${baseClassName} ${className}`} onClick={(e) => e.stopPropagation()}>
			<Navigation selectedDate={selectedDate} setSelectedDate={(date) => setSelectedDate(moment(date))} onNavigationTitleClick={onNavigationTitleClick} minDate={minDate} maxDate={maxDate} view={view} views={views} />
			<div className={`${baseClassName}-body`}>
				<Content view={view} calendarType={calendarType} showFixedNumberOfWeeks={showFixedNumberOfWeeks} showNeighboringMonth={showNeighboringMonth} {...commonProps} />
			</div>
			{showTime && <Time view={view} value={value} selectedDate={selectedDate} onChange={onChange} closeCalendar={closeCalendar} />}
		</div>
	);
};

Calendar.propTypes = {
	/** Default type is ISO_8601 */
	calendarType: PropTypes.oneOf(Object.values(CALENDAR_TYPE)),
	/** Classname for calendar container */
	className: PropTypes.string,
	/** Moment object, DatePicker input value` will be controlled by Parent component */
	value: PropTypes.object,
	/** Moment object, minimum date for opened calendar active date select */
	minDate: PropTypes.object,
	/** Moment object, maximum date for opened calendar active date select */
	maxDate: PropTypes.object,
	/** Navigation Right side next 2 icon */
	next2Label: PropTypes.node,
	/** Navigation Right side next icon */
	nextIcon: PropTypes.node,
	/** Navigation left side previous 2 icon */
	prev2Icon: PropTypes.node,
	/** Navigation left side previous icon */
	prevIcon: PropTypes.node,
	/** Callback that will be called when user set calendar value in month view */
	onChange: PropTypes.func,
	/** Callback that will be called when user clicks on calendar content tile */
	onContentTileClick: PropTypes.func,
	/** Callback that will be called when user clicks on calendar navigation title  */
	onNavigationTitleClick: PropTypes.func,
	/** Boolean prop, for showing 6 number of weeks in month view */
	showFixedNumberOfWeeks: PropTypes.bool,
	/** Boolean prop, for filling empty tiles with prev and next month days in month view */
	showNeighboringMonth: PropTypes.bool,
	/** Additional Callback function for disabling Calendar View Tile */
	isContentTileDisabled: PropTypes.func,
	/** Time settings footer */
	showTime: PropTypes.bool,
	/** Callback that will be called to close Calendar */
	closeCalendar: PropTypes.func
};

export default Calendar;
