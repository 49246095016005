import { ODD_STATE } from "constants/market.constants";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mergeClassNames, numberWithSpaces } from "utils/common";
import { liveUpdateStrategy } from "utils/liveMonitor";

const LiveOdd = ({
	markets,
	gameData,
	className,
	textContainerClassName,
	textClassName,
	textClassNameWhenLock,
	showNameClassName,
	lockClassName,
	outcomesWidth,
	separateOdds
}) => {

	const { t } = useTranslation();
	const [odds, setOdds] = useState([]);
	const oddsRef = useRef();
	oddsRef.current = odds;

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const updatedOdds = liveUpdateStrategy(gameData, markets);
			const elementsSelectorForAnimation = [];
			updatedOdds.forEach((updatedOdd, i) => {
				const oddJson = JSON.stringify(updatedOdd);
				if (oddsRef.current[i] === null || oddsRef.current[i] === undefined) {
					return;
				}
				const oldOddJson = JSON.stringify(oddsRef.current[i]);

				if (oddJson === oldOddJson) {
					return;
				}

				elementsSelectorForAnimation.push(updatedOdd.at(1).id);
			});

			setOdds(updatedOdds);

			elementsSelectorForAnimation.forEach((selector) => {
				const tag = document.querySelector(`[data-odd-id="${selector}"] > .odd-text-container`);
				if (!tag) {
					return;
				}

				tag.classList.add("live-monitor-odd-flicker-animation");
				tag.addEventListener("animationend", () => {
					tag.classList.remove("live-monitor-odd-flicker-animation");
				});
			});
		}, 200);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [gameData?.complitedScenes?.length]);

	return (
		<Fragment>
			{odds.map(([name, odd], i, arr) => {
				const isLock = !odd || !odd.factor || odd.factor < 1.01;
				return (
					<div
						key={odd.id}
						data-odd-id={odd.id}
						className={mergeClassNames("odd", className, separateOdds && i < arr.length - 1 && "monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-1]")}
						style={{ height: "100%", flex: `0 0 ${outcomesWidth[i]}px`, maxWidth: outcomesWidth[i] }}
					>
						<div
							className={mergeClassNames(
								"odd-text-container",
								textContainerClassName
							)}
						>
							<span className={mergeClassNames("odd-showname", showNameClassName)}>{t(`livemonitor.football_england_league.${name}`, name)}</span>
							<span className={mergeClassNames("odd-text", textClassName, isLock && textClassNameWhenLock)}>{isLock ? <i className={mergeClassNames("ic_lock", lockClassName)} /> : numberWithSpaces(odd.factor)}</span>
						</div>
					</div>
				);
			})}
		</Fragment>
	);
};
export default LiveOdd;
