import { Fragment, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { numberWithSpaces, toFixed, multiPrintElement } from "utils/common";
import { setReportPrinting } from "store/actions/report/report.actions";
import { logout } from "store/actions/auth/auth.actions";
import { REPORT_TYPE, TURNOVER_TYPE } from "constants/common.constants";
import { GAME_TYPE_TEXTS_BY_NAME } from "constants/game.constants";
import sessionType from "types/session.type";
import turnoverType from "types/turnover.type";

/** Report print Component */
const Report = ({ session, turnover, hideReportPrinting, printingReport, logout }) => {
	const { t } = useTranslation(["ticket"]);

	const allowPrintBonusData = useMemo(() => Object.keys(turnover).some((key) => key.includes("bonus") && turnover[key] !== 0), [turnover]);
	const turnoverReportTypes = useMemo(() => {
		const types = [TURNOVER_TYPE.SIMPLE];
		if (allowPrintBonusData) {
			types.push(TURNOVER_TYPE.BONUS);
		}
		return types;
	}, [allowPrintBonusData]);

	/** Print Report */
	useEffect(() => {
		multiPrintElement(
			turnoverReportTypes.map((trt) => `vs--print-wrapper-${trt}`),
			() => {
				setTimeout(() => {
					hideReportPrinting();
					if (printingReport === REPORT_TYPE.SETTLEMENT) {
						logout();
					}
				}, 0);
			}
		);
	}, [turnoverReportTypes, printingReport]);

	/** Function to get respective value for respective game type and field from turnover report
	 * @function
	 * @param {string} gameType - game type or total
	 * @param {string} reportField - the field name
	 * @returns {number}
	 * @memberOf Report
	 */
	const getColValue = (gameType, reportField) => {
		if (gameType !== "total") {
			const res = turnover?.report?.[gameType]?.[reportField] ?? "";
			if (res !== "") {
				if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount", "betCount", "canceledCount"].includes(reportField)) {
					return res;
				} else {
					return numberWithSpaces(toFixed(res, session?.currency?.decimalCount ?? 0));
				}
			} else {
				return "";
			}
		} else {
			const res = turnover[reportField] !== undefined ? turnover[reportField] : "";

			if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount", "betCount", "canceledCount"].includes(reportField)) {
				return res;
			} else {
				return numberWithSpaces(toFixed(res, session?.currency?.decimalCount ?? 0));
			}
		}
	};

	/** Table Cols data */
	const tableCols = useCallback((argType) => {
		switch (argType) {
			case TURNOVER_TYPE.SIMPLE:
				return [
					[
						{
							key: "betSlipCount",
							title: t("cashier.betSlipCount")
						},

						{
							key: "turnover",
							title: t("cashier.ticketTurnover")
						},

						{
							key: "realGGR",
							title: t("cashier.ggr")
						}
					],
					[
						{
							key: "wonCount",
							title: t("cashier.wonBets")
						},
						{
							key: "won",
							title: t("cashier.wonAmount")
						},
						{
							key: "payout",
							title: t("cashier.paid")
						}
					],

					[
						{
							key: "canceledCount",
							title: t("cashier.count")
						},
						{
							key: "canceled",
							title: t("cashier.amount")
						}
					]
				];
			case TURNOVER_TYPE.BONUS:
				return [
					[
						{
							key: "bonusTurnover",
							title: t("bonus.bonus") + " " + t("cashier.ticketTurnover")
						},
						{
							key: "bonusBetSlipsCount",
							title: t("bonus.bonus") + " " + t("cashier.betSlipCount")
						},
					],
					[
						{
							key: "bonusWonCount",
							title: t("bonus.bonus") + " " + t("cashier.ticketWon")
						},
						{
							key: "bonusWon",
							title: t("bonus.bonus") + " " + t("cashier.wonAmount")
						},
						{
							key: "bonusPayout",
							title: t("bonus.bonus") + " " + t("cashier.paid")
						}
					]
				];
			default:
				break;
		}
		return [];
	}, []);

	return (
		<Fragment>
			{turnoverReportTypes.map((trt) => {
				return (
					<div key={trt} id={`vs--print-wrapper-${trt}`} className="vs--print-wrapper">
						<div className="vs--print" key={trt}>
							{session.betTicket.ticketLogoId ? (
								<div className="vs--print-header">
									<img src={`${import.meta.env.SYSTEM_CDN_URL}/${session.partnerId}/images/${session.betTicket.ticketLogoId}_ticket_logo.png`} alt="logo" />
								</div>
							) : null}
							<div className="vs--print-header-sub vs--print-section">
								<div className="vs--print-header-sub-title">
									<i className="ic_virtualSports" />
									<h1>{t("common.virtualSports")}:</h1>
									<h1>
										{trt === TURNOVER_TYPE.BONUS
											? "Bonus Report" 
											: printingReport === REPORT_TYPE.INTERIM 
												? t("cashier.interimReport") 
												: t("cashier.settlementReport")
										}
										</h1>
								</div>					
							</div>
							<div className="vs--print-info vs--print-info-dotted vs--print-section">
								<div className="vs--print-info-row">
									<span>{t("cashier.betshop")}</span>
									<span>{session.projectName}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.address")}</span>
									<span>{session.address}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.printed")}</span>
									<span>{moment.utc().local().format(DATE_TIME_FORMAT)}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.cashier")}</span>
									<span>{session?.cashier?.userName}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.currency")}</span>
									<span>{session?.currency?.code}</span>
								</div>
							</div>

							{tableCols(trt).map((group) => (
								<div className="vs--print-info vs--print-info-dotted vs--print-section" key={group[0].key}>
									{group.some((item) => item.key === "canceledCount") && <span className="vs--print-col-title">{t("cashier.canceledBets")}</span>}
									<div className="vs--print-table">
										<div className="vs--print-table-row vs--print-table-row-head">
											<div className="vs--print-table-cell"></div>
											{group.map((item) => (
												<div className="vs--print-table-cell" key={item.key}>
													<span>{item.title}</span>
												</div>
											))}
										</div>

										{Object.keys(turnover.report)
											.concat(Object.keys(turnover.report).length > 0 ? ["0"] : [])
											.filter((game) => game !== "0") 
											.map((game) => (
												<div className="vs--print-table-row" key={game} data-game={game}>
													<div className="vs--print-table-cell">
														<span>{GAME_TYPE_TEXTS_BY_NAME[game] ? t(GAME_TYPE_TEXTS_BY_NAME[game]) : ""}</span>
													</div>
													{group.map((item) => (
														<div className="vs--print-table-cell" key={item.key}>
															<span>{getColValue(game, item.key)}</span>
														</div>
													))}
												</div>
											))}

										{Object.keys(turnover.report).length > 0 && (
											<div className="vs--print-table-row" key="mixed" data-game="mixed">
												<div className="vs--print-table-cell">
													<span>{t("mixed")}</span>
												</div>
												{group.map((item) => (
													<div className="vs--print-table-cell" key={item.key}>
														<span>{getColValue(0, item.key)}</span>
													</div>
												))}
											</div>
										)}

										{Object.keys(turnover.report).length > 0 && (
											<div className="vs--print-table-row" key="total" data-game="total">
												<div className="vs--print-table-cell">
													<span>{t("total")}</span>
												</div>
												{group.map((item) => (
													<div className="vs--print-table-cell" key={item.key}>
														<span>{getColValue("total", item.key)}</span>
													</div>
												))}
											</div>
										)}
									</div>
								</div>
							))}

							{trt === TURNOVER_TYPE.SIMPLE ? (
								<div className="vs--print-info vs--print-info-dotted vs--print-section">
									<div className="vs--print-info-row">
										<span className="vs--print-col-title">{t("cashier.totalMetrics")}</span>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.totalBetslip")}</span>
										<b>{getColValue("total", "betSlipCount")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.totalBets")}</span>
										<b>{getColValue("total", "betCount")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.turnover")}</span>
										<b>{getColValue("total", "turnover")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.ggr")}</span>
										<b>{getColValue("total", "realGGR")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.startingLimit")}</span>
										<b>{getColValue("total", "startingLimit")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.finalLimit")}</span>
										<b>{getColValue("total", "finalLimit")}</b>
									</div>
								</div>
							) : (
								<div className="vs--print-info vs--print-info-dotted vs--print-section">
									<div className="vs--print-info-row">
										<span className="vs--print-col-title">{t("cashier.totalMetrics")}</span>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.bonusBetSlipsCount")}</span>
										<b>{getColValue("total", "bonusBetSlipsCount")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.turnover")}</span>
										<b>{getColValue("total", "bonusTurnover")}</b>
									</div>
									<div className="vs--print-info-row">
										<span>{t("cashier.wonAmount")}</span>
										<b>{getColValue("total", "bonusWon")}</b>
									</div>
								</div>
							)}
							<div className="vs--print-signature">
								<div>
									<span>{t("cashier.cashier")}:</span>
								</div>
								<div>
									<span>{t("cashier.manager")}:</span>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</Fragment>
	);
};

/** Report propTypes
 * PropTypes
 */
Report.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, the turnover report */
	turnover: turnoverType,
	/** Redux action to hide report print component*/
	hideReportPrinting: PropTypes.func,
	/** Redux state property, printing report type */
	printingReport: PropTypes.oneOf(Object.values(REPORT_TYPE)),
	/** Redux action to logout */
	logout: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		turnover: state.report.turnover,
		printingReport: state.report.printingReport
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideReportPrinting: () => {
		dispatch(setReportPrinting(null));
	},
	logout: () => {
		dispatch(logout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
