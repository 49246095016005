import VideoPlayer from "../../player";
import FlussonicMsePlayer from "@flussonic/flussonic-mse-player";
import { HIDDEN_CLASS, ICON_POSTER_LOADING_CLASS, ICON_POSTER_PLAY_CLASS, MEDIA_CONTROL_FULLSCREEN_CLASS, MEDIA_CONTROL_GROUP_CLASS, MEDIA_CONTROL_PLAY_CLASS, MEDIA_CONTROL_VOLUME_CLASS, MEDIA_CONTROLS_CLASS, PLAYER_CONTAINER_CLASS, PLAYER_EVENT_PROPERTY_NAMES, PLAYER_OVERLAY_CLASS, PLAYER_VIDEO_CLASS, PLAYER_WRAPPER_CLASS, POSTER_CONTAINER_CLASS, POSTER_WRAPPER_CLASS, VS_PLAYER_SELECTOR_ID } from "./constants";
import { fullscreenListener, hideMediaControlsListener, pauseHandler, playPauseListener, resizeListener, resumeHandler, showMediaControlsListener, stallListener, volumeHandler, volumeListener } from "./handlers";
import { createMaximizeIcon, createMinimizeIcon, createPauseIcon, createPlayIcon, createPosterLoaderIcon, createPosterPlayIcon, createVolumeOffIcon, createVolumeOnIcon } from "./iconsGenerators";
import { isNullish } from "utils/common";

class FlussonicCustomVideoPlayer extends VideoPlayer {
	constructor(streamConfiguration, options) {
		super(streamConfiguration, options);
	}

	init() {
		if (this.initialized) return;
		if (isNullish(this.streamConfiguration)) return this.setError(true);
		if (!this.streamConfiguration.url) return this.setError(true);

		const element = document.getElementById(this.options.elementID);
		this._tags = this._defineTags()
		element.appendChild(this._tags.vsPlayer);
		this._player = window.player = new FlussonicMsePlayer(this._tags.playerVideo, this.streamConfiguration.url, { debug: true, controls: true });
		this._defineHandlers()
		this.setError(false);
		this.initialized = true;
	}

	destroy() {
		this._player?.stop();
		this._player = null;
		const element = document.getElementById(this.options.elementID);
		this.initialized = false;
		if (element) {
			element.innerHTML = "";
		}
	}

	_defineTags() {
		const vsPlayer = document.createElement("div");
		vsPlayer.id = VS_PLAYER_SELECTOR_ID;

		const playerWrapper = document.createElement("div");
		playerWrapper.classList.add(PLAYER_WRAPPER_CLASS);
		vsPlayer.appendChild(playerWrapper);

		const playerContainer = document.createElement("div");
		playerContainer.classList.add(PLAYER_CONTAINER_CLASS);
		playerWrapper.appendChild(playerContainer);

		const playerVideo = document.createElement("video");
		playerVideo.classList.add(PLAYER_VIDEO_CLASS);
		playerVideo.src = "";
		playerVideo.muted = true;
		playerVideo.setAttribute("crossorigin", "anonymous");
		playerContainer.appendChild(playerVideo);

		const playerOverlay = document.createElement("div");
		playerOverlay.classList.add(PLAYER_OVERLAY_CLASS);
		playerContainer.appendChild(playerOverlay);

		const posterWrapper = document.createElement("div");
		posterWrapper.classList.add(POSTER_WRAPPER_CLASS);
		playerContainer.appendChild(posterWrapper);

		const posterContainer = document.createElement("div");
		posterContainer.classList.add(POSTER_CONTAINER_CLASS);
		posterWrapper.appendChild(posterContainer);

		const iconPosterPlay = createPosterPlayIcon()
		iconPosterPlay.classList.add(ICON_POSTER_PLAY_CLASS)
		posterContainer.appendChild(iconPosterPlay);

		const iconPosterLoader = createPosterLoaderIcon()
		iconPosterLoader.classList.add(ICON_POSTER_LOADING_CLASS)
		iconPosterLoader.classList.add(HIDDEN_CLASS)
		posterContainer.appendChild(iconPosterLoader);

		const mediaControls = document.createElement("div");
		mediaControls.classList.add(MEDIA_CONTROLS_CLASS);
		playerContainer.appendChild(mediaControls);

		const mediaControlPlay = document.createElement("div");
		mediaControlPlay.classList.add(MEDIA_CONTROL_PLAY_CLASS);
		mediaControlPlay.appendChild(createPlayIcon())
		mediaControlPlay.appendChild(createPauseIcon())
		mediaControls.appendChild(mediaControlPlay);

		const mediaControlGroup = document.createElement("div");
		const mediaControlVolume = document.createElement("div");
		const mediaControlFullscreen = document.createElement("div");
		mediaControlGroup.classList.add(MEDIA_CONTROL_GROUP_CLASS)
		mediaControlVolume.classList.add(MEDIA_CONTROL_VOLUME_CLASS)
		mediaControlFullscreen.classList.add(MEDIA_CONTROL_FULLSCREEN_CLASS);
		mediaControlVolume.appendChild(createVolumeOffIcon());
		mediaControlVolume.appendChild(createVolumeOnIcon());
		mediaControlFullscreen.appendChild(createMaximizeIcon());
		mediaControlFullscreen.appendChild(createMinimizeIcon());
		mediaControlGroup.appendChild(mediaControlVolume)
		mediaControlGroup.appendChild(mediaControlFullscreen)
		mediaControls.appendChild(mediaControlGroup);

		return {
			vsPlayer,
			playerWrapper,
			playerContainer,
			playerVideo,
			posterWrapper,
			posterContainer,
			mediaControls,
			mediaControlPlay,
			mediaControlVolume,
			mediaControlFullscreen,
			iconPosterPlay,
			iconPosterLoader,
			playerOverlay
		}
	}

	_defineHandlers() {

		["onError", "onEvent", "onMediaInfo", "onMessage", "onMuted", "onPause", "onResume"].forEach(eventName => {
			this._player[eventName] = (...args) => console.log(`${eventName}: `, ...args);
		})

		playPauseListener({ target: this._tags.iconPosterPlay, eventName: "click", tags: this._tags, player: this._player })
		playPauseListener({ target: this._tags.mediaControlPlay, eventName: "click", tags: this._tags, player: this._player })
		resumeHandler({ target: this._player, eventName: PLAYER_EVENT_PROPERTY_NAMES.RESUME, tags: this._tags, player: this._player })
		pauseHandler({ target: this._player, eventName: PLAYER_EVENT_PROPERTY_NAMES.PAUSE, tags: this._tags, player: this._player })
		stallListener({ target: this._tags.playerVideo, tags: this._tags, player: this._player })
		showMediaControlsListener({ target: this._tags.playerContainer, eventName: "mouseover", tags: this._tags, player: this._player })
		hideMediaControlsListener({ target: this._tags.playerContainer, eventName: "mouseout", tags: this._tags, player: this._player })
		resizeListener({ target: this._tags.playerVideo, tags: this._tags, player: this._player })
		fullscreenListener({ target: this._tags.mediaControlFullscreen, tags: this._tags, player: this._player })
		volumeListener({ target: this._tags.mediaControlVolume, tags: this._tags, player: this._player })
		volumeHandler({ target: this._tags.playerVideo, tags: this._tags })
	}
}

export default FlussonicCustomVideoPlayer;
