import  { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import ModalWrapper from "components/modalWrapper";
import Loader from "components/ui/loader";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { GAME_TYPE_TEXT_KEYS, GAME_TYPE_ICONS } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/betslip.constants";
import { clearBets } from "store/actions/betslip/betslip.actions";
import { closeBonus, useBonus } from "store/actions/bonuses/bonuses.actions";
import { showMessageAlert } from "utils/message";
import { binaryToFlags } from "utils/common";

import bonustype from "types/bonus.type";
import sessionType from "types/session.type";

/** Viewing Bonus Component */
const ViewingBonus = ({ bonus, isLoading, closeBonus, useBonus, session, clearBets }) => {
	const { t } = useTranslation();

	return (
		<ModalWrapper classNames={{ content: "vs--use-bonus vs--use-bonus-modal", body: "vs--use-bonus-modal-body" }} visible={true} closeIcon={null}>
			{!isLoading ? (
				<>
					<div className="vs--use-bonus">
						<div className="vs--use-bonus-header vs--text-center">
							<div className="vs--mb-16 vs--mt-32">
								<span className="vs--font-small vs--font-medium">
									{t("bonus.freeBet")}
									&nbsp;
									<span className="vs--use-bonus-amount">
										{bonus?.amount} {bonus?.currency}
									</span>
								</span>
							</div>
							<div className="vs--mb-24">
								<span className="vs--font-smallest vs--font-regular vs--use-bonus-sub">{t("common.chooseTheAvailableMarketsAndPlaceBets")}</span>
							</div>
						</div>
						<div className="vs--use-bonus-line vs--mb-16"></div>
						<div className="vs--use-bonus-details vs--mb-16">
							<span className="vs--font-smallest vs--font-medium">{t("common.details")}</span>
						</div>
						<div className="vs--use-bonus-game vs--mb-28">
							<div className="vs--flex vs--justify-start">
								<div className="vs--flex-equal vs--game-type">
									<span>{t("common.gameType")}</span>
								</div>
								<div className="vs--flex vs--flex-wrap vs--justify-start">
									{binaryToFlags(session?.games?.map((g) => g.type) || [], bonus?.gameType ?? 0).map((gt, i, arr) =>
										GAME_TYPE_TEXT_KEYS[gt] ? (
											<div key={gt} className="vs--mr-12">
												<i className={GAME_TYPE_ICONS[gt]}></i>
												<span className="vs--ml-6">
													{t(`common.${GAME_TYPE_TEXT_KEYS[gt]}`)}
													{arr.length !== i + 1 ? ", " : ""}
												</span>
											</div>
										) : (
											<Fragment key={gt} />
										)
									)}
								</div>
							</div>
						</div>
						<div className="vs--use-bonus-info vs--flex vs--justify-between">
							<div className="vs--use-bonus-date">
								<div>
									<div className="vs--flex">
										<div className="vs--flex vs--flex-equal vs--mb-12">{t("bonus.amount")}</div>
										<div>{bonus?.amount}&nbsp;{bonus?.currency}</div>
									</div>
									<div className="vs--flex">
										<div className="vs--flex vs--flex-equal vs--mb-12">{t("bonus.validThrough")}</div>
										<div>
											{(() => {
												const date = bonus?.endDate ?? " - ";
												if (date === " - ") {
													return date;
												}
												return moment.utc(date).local().format(DATE_TIME_FORMAT);
											})()}
										</div>
									</div>
									<div className="vs--flex">
										<div className="vs--flex vs--flex-equal vs--mb-12">{t("bet.maxPosWinAmountIs")}</div>
										<div>{bonus?.maxPossibleWinAmount ? bonus.maxPossibleWinAmount + " " + (bonus?.currency ?? "") : " - "}</div>
									</div>
								</div>
							</div>
							<div className="vs--use-bonus-info-line vs--ml-40 vs--mr-40 vs--mt-6"></div>
							<div className="vs--use-bonus-odd">
								<div className="vs--flex">
									<div className="vs--flex vs--flex-equal vs--mb-12">{t("bet.betType")}</div>
									<div className="vs--flex">
										{binaryToFlags(Object.values(BETSLIP_MODES), bonus?.betType ?? 0)
											.map((bt) => t(`bet.${bt === BETSLIP_MODES.SINGLE ? "single" : "multi"}`))
											.map((str) => str.charAt(0).toUpperCase() + str.slice(1))
											.join("/")}
									</div>
								</div>
								<div className="vs--flex">
									<div className="vs--flex vs--flex-equal vs--mb-12">{t("bonus.minOddsSingle")}</div>
									<div>{bonus?.minOddsSingle ?? " - "}</div>
								</div>
								<div className="vs--flex">
									<div className="vs--flex vs--flex-equal vs--mb-12">{t("bonus.minOddsMulti")}</div>
									<div>{bonus?.minOddsMulti ?? " - "}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="vs--use-bonus-btn vs--mt-26 vs--mb-24">
						<button className="vs--button vs--font-smallest vs--font-medium" onClick={closeBonus}>
							{t("common.cancel")}
						</button>
						<button
							className="vs--button vs--ml-16 vs--font-smallest vs--font-medium"
							onClick={() => {
								useBonus(() => {
									clearBets();
									showMessageAlert(
										<div className="vs--flex vs--justify-center vs--align-center">
											<i className="ic_gift vs--font-bigest" />
											<span>{t("bonus.placeFreeBetsNow")}</span>
										</div>
									);
								});
							}}
						>
							{t("bonus.useBonus")}
						</button>
					</div>
				</>
			) : (
				<Loader />
			)}
		</ModalWrapper>
	);
};

/** ViewingBonus propTypes
 * PropTypes
 */
ViewingBonus.propTypes = {
	/** Redux state property, the viewing bonus */
	bonus: bonustype,
	/** Redux state property, is true when loading viewing bonus */
	isLoading: PropTypes.bool,
	/** Redux action, to close viewing bonus modal */
	closeBonus: PropTypes.func,
	/** Redux action, to use bonus */
	useBonus: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux Function to clean betslip */
	clearBets: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		bonus: state.bonuses.standard.viewingBonus,
		isLoading: state.bonuses.standard.isBonusLoading,
		session: state.auth.session,
	}
}

const mapDispatchToProps = (dispatch) => ({
	closeBonus: () => {
		dispatch(closeBonus());
	},
	useBonus: (callback) => {
		dispatch(useBonus(callback));
	},
	clearBets: () => {
		dispatch(clearBets());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewingBonus);
