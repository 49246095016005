import  { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { doBet, clearKenoBalls } from "store/actions/betslip/betslip.actions";
import useMarketDisabledForBonus from "hooks/useMarketDisabledForBonus";
import eventType from "types/event.type";
import sessionType from "types/session.type";
import Tooltip from "components/ui/tooltip";
import useDocumentListener from "hooks/useDocumentListener";
import { GAME_TYPE } from "constants/game.constants";
import KEY_CODE from "constants/keyboard.constants";

const KenoButton = ({ kenoBalls, clearKenoBalls, doBet, session }) => {
	const { t } = useTranslation();

	const { isSingleMarketDisable } = useMarketDisabledForBonus();

	/** Function to calculate factor
	 * @function
	 * @memberOf KenoButton
	 */
	const calculateFactor = () => {
		const kenoGame = session.games.find((g) => g.type === GAME_TYPE.KENO);
		if (!kenoGame) {
			return null;
		}
		const rtPs = kenoGame.rtPs;
		const ballsCount = kenoBalls && kenoBalls.balls ? kenoBalls.balls.length : 0;
		if (ballsCount > 0) {
			const rtp = rtPs.find((r) => r.enabled && r.name === `${ballsCount}-${ballsCount}`);
			return rtp ? rtp.margin : 0;
		}
		return 0;
	};

	const isDisable = isSingleMarketDisable(calculateFactor());

	/** Function which fires on kenoBalls button click
	 * @function
	 * @memberOf KenoButton
	 */
	const handleKenoBallsButtonClick = () => {
		if (kenoBalls && kenoBalls.balls) {
			if (!isDisable) {
				doBet(
					{
						balls: kenoBalls.balls,
						id: kenoBalls.balls.join(":"),
						showName: kenoBalls.balls.join(", "),
						ticketShowName: kenoBalls.balls.join(", "),
						factor: calculateFactor()
					},
					t("common.ballNumbers"),
					kenoBalls.eventInfo
				);
				clearKenoBalls();
			}
		}
	};

	// Handler for space key down event
	// Add listener for key down
	useDocumentListener("keydown", (e) => {
		if (e.keyCode === KEY_CODE.SPACE) {
			handleKenoBallsButtonClick();
		}
	});

	return kenoBalls && kenoBalls.balls && kenoBalls.balls.length > 0 ? (
		<Tooltip title={isDisable ? <span className="vs--markets-list-item-content-odd-item-bonus-title">{t("cashier.unavailableForFreeBet")}</span> : null}>
			<button type="button" className={"vs--button vs--button-forecast vs--pr-8 vs--pl-8 vs--pt-12 vs--pb-12" + (isDisable ? " vs--button-disabled" : "")} onClick={handleKenoBallsButtonClick} disabled={isDisable}>
				{t("bet.addToBetslip")}
			</button>
		</Tooltip>
	) : (
		<Fragment />
	);
};

/** KenoButton propTypes
 * PropTypes
 */
KenoButton.propTypes = {
	/** Redux state property, keno balls bet selection */
	kenoBalls: PropTypes.shape({
		balls: PropTypes.arrayOf(PropTypes.number),
		eventInfo: eventType,
		gameType: PropTypes.number
	}),
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func,
	/** Redux action to clear current keno balls */
	clearKenoBalls: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		kenoBalls: state.betslip.kenoBalls,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	doBet: (market, group, eventInfo) => {
		dispatch(doBet(market, group, eventInfo));
	},
	clearKenoBalls: () => {
		dispatch(clearKenoBalls());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(KenoButton);
