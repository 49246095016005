import { groupByQty, isCupGame, isChampionsCupGame } from "utils/common";
import resultType from "types/result.type";
import EventComponent from "./eventComponent";

/** Details of single result in results list */
const ResultDetails = ({ item }) => {
	const isCup = isCupGame(item?.gameType ?? 0);
	const isSisChampionsCupR16 = isChampionsCupGame(item?.gameType) && [1, 2].includes(item?.orderNumber);
	return (
		<div className="vs--modal-table-details">
			<div className="vs--modal-table-details-body">
				<div className="vs--modal-table-details-row vs--flex vs--flex-row vs--pt-16">
					{groupByQty(item?.events ?? [], isCup && !isSisChampionsCupR16 ? 1 : 2).map((eventsGroup, index) => {
						return (
							<div className="vs--league-scene-tooltip vs--modal-table-details-row-section vs--flex-equal" key={index} data-is-cup={isCup}>
								{eventsGroup.map((event) => (
									<EventComponent key={event.id} event={event} expanded={true} />
								))}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

/** ResultDetails propTypes
 * PropTypes
 */
ResultDetails.propTypes = {
	/** The item */
	item: resultType
};

export default ResultDetails;
