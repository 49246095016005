import  { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import useCountDown from "hooks/useCountDown";

/* Clock Counter Component on Match anons block */
const ClockCounter = ({ date }) => {
	const [countdownValue, setCountdownValue] = useState(null);

	const time = useCountDown(countdownValue);

	/** Function to initialize Countdown
	 * @function
	 * @param { date } date
	 * @memberOf ClockCounter
	 */
	const initialize = (date) => {
		if (moment().diff(date, "seconds") < 0) {
			setCountdownValue(moment.utc(date).local().toDate());
		}
	};

	/** Reinitialize Countdown on date change */
	useEffect(() => {
		initialize(moment.utc(date).local().toDate());
	}, [date]);

	if (countdownValue === null) {
		return null;
	}

	return (
		<div className="vs--clock-counter vs--flex vs--align-center">
			<div>{time.minutes}</div>
			<span>:</span>
			<div>{time.seconds}</div>
		</div>
	);
};

/** ClockCounter propTypes
 * PropTypes
 */
ClockCounter.propTypes = {
	/** Countdown date */
	date: PropTypes.string
};

export default ClockCounter;
