import  { useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";

import ViewingTicket from "components/tickets/viewing/viewingTicket";
import ViewingBonus from "components/tickets/viewing/viewingBonus";
import ReprintLastTicket from "components/tickets/reprintLastTicket";
import Ticket from "components/ui/ticket";
import Report from "components/ui/report";
import ErrorMessage from "components/ui/errorMessage";

import { setCurrentTime } from "store/actions/common/common.actions";
import { getLiveAndUpcomings, removeFromLiveAndUpcomings, updateFromLiveAndUpcomings, addLiveAndUpcomings, updateEvent, getEvent } from "store/actions/game/game.actions";
import { removePending, updatePending, updatePendingBet, getPendingTickets } from "store/actions/tickets/pendings.actions";
import { updateViewingTicket, updateViewingTicketBet, updateTicketReprintRequest } from "store/actions/tickets/ticket.actions";
import { setBetslip, updateEventBets } from "store/actions/betslip/betslip.actions";
import { setBalance, logout, setLogoId, setBonusConfigs } from "store/actions/auth/auth.actions";
import { updateSeasonMarkets, getSeasonMarkets } from "store/actions/season/season.actions";
import { updateJackpotBonus } from "store/actions/bonuses/jackpot.actions";

import SignalRUtils from "utils/signalR";
import { refreshToken } from "utils/auth";
import LocalStorageUtils from "utils/localStorage";
import { startRetailIntegration } from "utils/integration";
import { isSeasonGame } from "utils/common";

import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { BET_STATE, BETSLIP_MODES } from "constants/betslip.constants";
import { REPORT_TYPE, PROJECT_TYPE } from "constants/common.constants";

import useIntegrationType from "hooks/useIntegrationType";

import playerType from "types/player.type";
import sessionType from "types/session.type";
import eventType from "types/event.type";
import betType from "types/bet.type";
import ticketType from "types/ticket.type";
import useEvent from "hooks/useEvent";

const ORIGIN_FOR_IFRAME_POST_MESSAGE = "*";

/* Wrapper Container Component - Wrapper, which handle all initial actions for logged in user */
const Wrapper = ({
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	removePending,
	updatePending,
	updatePendingBet,
	getPendingTickets,
	updateViewingTicket,
	updateViewingTicketBet,
	updateEvent,
	updateEventBets,
	getEvent,
	setBetslip,
	player,
	session,
	sessionLoaded,
	sessionFailed,
	translationsLoaded,
	setLogoId,
	refreshTokenExpiration,
	currentGameType,
	liveAndUpcomings,
	matches,
	current,
	showTicket,
	bets,
	stake,
	mode,
	printingTicket,
	printingReport,
	updateTicketReprintRequest,
	setBalance,
	setBonusConfigs,
	logout,
	children,
	showBonus,
	showReprintLastTicket,
	setCurrentTime,
	getSeasonMarkets,
	updateSeasonMarkets,
	updateJackpotBonus,
	errorMessage,
}) => {
	const currentGameTypeRef = useRef(currentGameType);

	const integrationType = useIntegrationType();

	/** Load Season markets */
	const seasonId = liveAndUpcomings.some((e) => e.id === current) ? liveAndUpcomings.find((e) => e.id === current).seasonId : null;
	const storageKey = `vs__cashier__${session.projectId}__${player.userId}`;

	/** Function to get current game rtps
	 * @function
	 * @returns {array}
	 * @memberOf MarketsTabs
	 */
	const getCurrentGameRtps = () => {
		let rtps = [];
		let game = session.games.find((g) => g.type === currentGameTypeRef.current);
		if (game) {
			rtps = game.rtPs;
		}
		return rtps;
	};

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Wrapper
	 */
	const handleSignalREvents = useEvent((connectionArg) => {
		SignalRUtils.getConnections().forEach((con, index) => {
			const connection = con.getConnection()
			if (connection !== connectionArg.connection) {
				return;
			}
			if ((index === 0 || index === 2) && connection.state === "Connected") {
				session.games.forEach(g => {
					connection.invoke("Subscribe", `Events_${g.type}_${g.id}`);
				});

				setInterval(() => {
					if (connection.state === "Connected") {
						connection.invoke("UpdateSession", session.sessionId, 2, player.userId);
					}
				}, 60000);
			}
			if(index === 1 && connection.state === "Connected") {
				connection.invoke("Subscribe", `Jackpots_${session.activeJackpotId}`);
			}

			connection.off("Events");
			connection.off("BetSlip");
			connection.off("BetSlipBet");
			connection.off("Balance");
			connection.off("Logout");
			connection.off("ReprintRequest");
			connection.off("Bonus");
			connection.off("Jackpots");

			connection.on("Events", (data) => {
				const d = JSON.parse(data);
				if (d.gameType === currentGameTypeRef.current) {
					if (!isSeasonGame(d.gameType) || d.type === GAME_EVENT_TYPE.WEEK) {
						if (d.status === GAME_STATUSES.FINISHED) {
							removeFromLiveAndUpcomings(d.id);
						} else if (d.status === GAME_STATUSES.CLOSE_FOR_BETTING || d.status === GAME_STATUSES.PREAMBLE_STARTED || d.status === GAME_STATUSES.STARTED) {
							updateFromLiveAndUpcomings(d);
						} else if (d.status === GAME_STATUSES.NEW) {
							addLiveAndUpcomings(d);
						}
					}

					/** If season markets are updated */
					if (isSeasonGame(d.gameType) && d.seasonId === d.id && d.markets) {
						updateSeasonMarkets(d);
					}
				}

				updateEvent(d.id, d, getCurrentGameRtps());
				updateEventBets(d);
			});

			connection.on("BetSlip", (data) => {
				const d = JSON.parse(data);
				if (d.state !== BET_STATE.PENDING) {
					removePending(d);
				} else {
					updatePending(d);
				}
				updateViewingTicket(d);
			});

			connection.on("BetSlipBet", (data) => {
				const d = JSON.parse(data);
				updatePendingBet(d);
				updateViewingTicketBet(d);
			});

			connection.on("Balance", (data) => {
				setBalance(Number(JSON.parse(data)));
			});

			connection.on("Logout", () => {
				logout();
			});

			connection.on("ReprintRequest", (data) => {
				const d = JSON.parse(data);
				updateTicketReprintRequest(d.betSlipId, d.printType, null, d.state);
			});

			connection.on("Bonus", (data) => {
				const d = JSON.parse(data);
				setBonusConfigs(d);
			})

			connection.on('Jackpots', data => {
				const d = JSON.parse(data);
				updateJackpotBonus(d);
			})
		})
	})

	/** Keep current time */
	useEffect(() => {
		setCurrentTime();
		setInterval(() => {
			setCurrentTime();
		}, 1000);
	}, []);

	/** Load live and upcoming events */
	useEffect(() => {
		if (currentGameType) {
			getLiveAndUpcomings();
		}
		currentGameTypeRef.current = currentGameType;
	}, [currentGameType]);

	useEffect(() => {
		if (seasonId && isSeasonGame(currentGameType)) {
			getSeasonMarkets(seasonId);
		}
	}, [seasonId]);

	/** Subscribe to signalR when session loaded,
	 * Load pending tickets
	 * */
	useEffect(() => {
		if (sessionLoaded && !sessionFailed) {
			if (session.templateType) {
				document.documentElement.setAttribute("data-template", session.templateType);
			}

			SignalRUtils.buildConnections(handleSignalREvents);

			getPendingTickets();

			const betslip = LocalStorageUtils.get(storageKey);

			if (betslip) {
				setBetslip(betslip);
			}
			return () => {
				SignalRUtils.removeConnections();
			};
		}
	}, [sessionLoaded]);

	// Temporarily updates current template type before permanent saving
	useEffect(() => {
		if (session.templateType) {
			document.documentElement.setAttribute("data-template", session.templateType);
		}
	}, [session.templateType]);

	/** Initialize message events */
	useEffect(() => {
		try {
			window.parent.postMessage(
				{
					eventName: "vs--customization-ready"
				},
				ORIGIN_FOR_IFRAME_POST_MESSAGE
			);
		} catch (ex) {
			console.log(ex);
		}
		window.addEventListener(
			"message",
			(e) => {
				const d = e.data;
				if (d) {
					if (d.eventName === "vs--customization") {
						document.documentElement.setAttribute("data-template", d.data);
					} else if (d.eventName === "vs--customization-logo") {
						setLogoId(d.data);
					}
				}
			},
			false
		);
	}, []);

	/** If less then 2 minute left to refresh token expiration, then refresh it */
	useEffect(() => {
		if (0 < refreshTokenExpiration && refreshTokenExpiration < 120) {
			const refresh_token = player.refreshToken;
			refreshToken(refresh_token);
		}
	}, [refreshTokenExpiration]);

	/** Load match details on current match change */
	useEffect(() => {
		if (current) {
			if (!matches.find((m) => m.id === current)) {
				getEvent(current);
			}
		}
	}, [current]);

	/** keep redux sync with localstorage, for the data which need to be saved in browser */
	useEffect(() => {
		if (session && session.projectId) {
			LocalStorageUtils.set(storageKey, {
				bets: bets,
				stake: stake,
				mode: mode
			});
		}
	}, [bets, stake, mode]);

	useEffect(() => {
		const currentEvent = liveAndUpcomings.find((event) => event.id === current);
		if (currentEvent) {
			const startTime = currentEvent.startTime;
			const eventTime = moment.utc(startTime).local();
			const currentTime = moment();
			const diffTime = eventTime - currentTime;
			const duration = moment.duration(diffTime * 1000, "milliseconds");
			const seconds = duration.asSeconds() / 1000;

			const game = session.games.find((gameObj) => gameObj.type === currentGameType);
			if (game) {
				const cycle = game.cycleMinutes * 60;
				if (seconds > cycle) {
					getLiveAndUpcomings();
				}
			}
		}
	}, [current]);

	/** Mirror Ticket integration */
	useEffect(() => {
		if (sessionLoaded && !sessionFailed && translationsLoaded) {
			if (integrationType === PROJECT_TYPE.IFRAME) {
				startRetailIntegration();
			}
		}
	}, [integrationType, sessionLoaded && !sessionFailed && translationsLoaded]);

	return (
		<Fragment>
			{children}
			{showTicket && <ViewingTicket />}
			{printingTicket && <Ticket />}
			{printingReport && <Report />}
			{showBonus && <ViewingBonus />}
			{showReprintLastTicket && <ReprintLastTicket />}
			{errorMessage && <ErrorMessage />}
		</Fragment>
	);
};

/** Wrapper propTypes
 * PropTypes
 */
Wrapper.propTypes = {
	/** Redux action to set current time */
	setCurrentTime: PropTypes.func,
	/** Redux action to get live and upcoming matches */
	getLiveAndUpcomings: PropTypes.func,
	/** Redux action to remove match from live and upcomings matches */
	removeFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to update match in live and upcomings matches */
	updateFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to add match to live and upcomings matches */
	addLiveAndUpcomings: PropTypes.func,
	/** Redux action to update match in loaded matches */
	updateEvent: PropTypes.func,
	/** Redux action to get match details */
	getEvent: PropTypes.func,
	/** Redux action to update all the bets from betslip section, for specified match */
	updateEventBets: PropTypes.func,
	/** Redux action to update whole betslip */
	setBetslip: PropTypes.func,
	/** Redux action to remove ticket from pending tickets */
	removePending: PropTypes.func,
	/** Redux action to update ticket in pending tickets */
	updatePending: PropTypes.func,
	/** Redux action to update ticket bet in pending tickets */
	updatePendingBet: PropTypes.func,
	/** Redux action to get pending tickets */
	getPendingTickets: PropTypes.func,
	/** Redux action to update viewing ticket */
	updateViewingTicket: PropTypes.func,
	/** Redux action to update viewing ticket bet*/
	updateViewingTicketBet: PropTypes.func,
	/** Redux action to update ticket print data*/
	updateTicketReprintRequest: PropTypes.func,
	/** Redux action to update balance*/
	setBalance: PropTypes.func,
	/** Redux action to update logo id */
	setLogoId: PropTypes.func,
	/** Redux action to set bonus configs */
	setBonusConfigs: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, is true when session loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, is true when session failed */
	sessionFailed: PropTypes.bool,
	/** Redux state property, is true when translations loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, current authenticated user */
	player: playerType,
	/** Redux state property, seconds before refresh token will be expired */
	refreshTokenExpiration: PropTypes.number,
	/** Redux state property, if true ticket view modal will be visible */
	showTicket: PropTypes.bool,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, current stake in betslip section */
	stake: PropTypes.string,
	/** Redux state property, current bet mode(single/multi) in betslip section */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, all loaded matches */
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			event: eventType
		})
	),
	/** Redux state property, the array of live and upcomings events */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Redux state property, current match id */
	current: PropTypes.number,
	/** Redux state property, the printing ticket data */
	printingTicket: ticketType,
	/** Redux state property, printing report type */
	printingReport: PropTypes.oneOf(Object.values(REPORT_TYPE)),
	/** Redux action to logout */
	logout: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	/** Redux state property, if true bonus view modal will be visible */
	showBonus: PropTypes.bool,
	/** Redux state property, if true reprint last ticket modal will be visible */
	showReprintLastTicket: PropTypes.bool,
	/** Redux action to get season markets */
	getSeasonMarkets: PropTypes.func,
	/** Redux action to update season markets */
	updateSeasonMarkets: PropTypes.func,
	/** Redux action to update jackpot info */
	updateJackpotBonus: PropTypes.func,
	/** Redux state property, current error message to show */
	errorMessage: PropTypes.string
}

const mapDispatchToProps = dispatch => (
	{
		getLiveAndUpcomings: () => {
			dispatch(getLiveAndUpcomings());
		},
		removeFromLiveAndUpcomings: id => {
			dispatch(removeFromLiveAndUpcomings(id));
		},
		updateFromLiveAndUpcomings: game => {
			dispatch(updateFromLiveAndUpcomings(game));
		},
		addLiveAndUpcomings: game => {
			dispatch(addLiveAndUpcomings(game));
		},
		updateEvent: (id, data, rtps) => {
			dispatch(updateEvent(id, data, rtps))
		},
		getEvent: id => {
			dispatch(getEvent(id))
		},
		removePending: pending => {
			dispatch(removePending(pending));
		},
		updatePending: pending => {
			dispatch(updatePending(pending));
		},
		updatePendingBet: bet => {
			dispatch(updatePendingBet(bet));
		},
		getPendingTickets: () => {
			dispatch(getPendingTickets());
		},
		updateViewingTicket: ticket => {
			dispatch(updateViewingTicket(ticket));
		},
		updateViewingTicketBet: bet => {
			dispatch(updateViewingTicketBet(bet));
		},
		updateTicketReprintRequest: (id, type, listType, state) => {
			dispatch(updateTicketReprintRequest(id, type, listType, state));
		},
		setBetslip: betslip => {
			dispatch(setBetslip(betslip));
		},
		updateEventBets: event => {
			dispatch(updateEventBets(event));
		},
		setBalance: balance => {
			dispatch(setBalance(balance));
		},
		setLogoId: logoId => {
			dispatch(setLogoId(logoId));
		},
		setBonusConfigs: configs => {
			dispatch(setBonusConfigs(configs))
		},
		setCurrentTime: () => {
			dispatch(setCurrentTime());
		},
		logout: () => {
			dispatch(logout());
		},
		getSeasonMarkets: id => {
			dispatch(getSeasonMarkets(id))
		},
		updateSeasonMarkets: season => {
			dispatch(updateSeasonMarkets(season))
		},
		updateJackpotBonus: data => {
			dispatch(updateJackpotBonus(data))
		}
	}
);

const mapStateToProps = (state) => {
	return {
		player: state.auth.player,
		session: state.auth.session,
		sessionLoaded: state.auth.sessionLoaded,
		sessionFailed: state.auth.sessionFailed,
		translationsLoaded: state.auth.translationsLoaded,
		refreshTokenExpiration: state.auth.refreshTokenExpiration,
		currentGameType: state.game.currentGameType,
		matches: state.game.matches.data,
		current: state.game.current,
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		showTicket: state.tickets.showTicket,
		bets: state.betslip.bets,
		stake: state.betslip.stake,
		mode: state.betslip.mode,
		printingTicket: state.tickets.printingTicket,
		printingReport: state.report.printingReport,
		showBonus: state.bonuses.standard.showBonus,
		showReprintLastTicket: state.tickets.reprintLastTicket.show,
		errorMessage: state.common.errorMessage
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
