import  { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PARTNER_SECURITY_PASSWORD_PATTERN, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS } from "constants/common.constants";
import passwordSettingsType from "types/passwordSettings.type";

const PasswordValidationTooltip = ({ password = "", passwordSettings }) => {
	const { t } = useTranslation();
	const getIconContainerClass = useCallback((bool) => (bool === null ? "" : bool ? "vs--password-rules-checked" : "vs--password-rules-wrong"), []);
	const getIconClass = useCallback((bool) => (bool === null ? "ic_selected" + getIconContainerClass(bool) : bool ? `ic_selected ${getIconContainerClass(bool)}` : `ic_point ${getIconContainerClass(bool)}`), []);
	const GetResultAndMassage = useCallback((value, valObj) => {
		const retVal = { result: null, massage: null, key: valObj.type };

		if (valObj.value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE) {
			retVal.massage = t("cashier.mustNotContain").replace("%X%", t(`cashier.contain_type_${valObj.type}`));
		} else if (valObj.value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST) {
			retVal.massage = t("cashier.mustContain").replace("%X%", t(`cashier.contain_type_${valObj.type}`));
		}
		if (value === "") {
			return retVal;
		}

		switch (valObj.type) {
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.UPPERCASE:
				retVal.result = /[A-Z]/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.LOWERCASE:
				retVal.result = /[a-z]/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.DIGITS:
				retVal.result = /[0-9]/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.DASH:
				retVal.result = /-/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.UNDERSCORE:
				retVal.result = /_/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.SPACE:
				retVal.result = /\s/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.SPECIAL:
				retVal.result = /[!#$%&*+,./:;=?@\\^`|~'"]/.test(value);
				break;
			case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.BRACKETS:
				retVal.result = /[{([<>\])}]/.test(value);
				break;
		}

		if (valObj.value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE) {
			retVal.result = !retVal.result;
		}

		return retVal;
	}, []);

	const validatorList = [
		{
			result: password === "" ? null : password.length >= passwordSettings.passwordMinLimit && password.length <= passwordSettings.passwordMaxLimit,
			massage: t("cashier.mustBeBetween").replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit),
			key: 0
		}
	];

	if (passwordSettings.pattern?.type === PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS) {
		(passwordSettings.pattern?.contain ?? []).forEach((valObj) => {
			if (PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MAY === valObj.value) {
				return;
			}
			validatorList.push(GetResultAndMassage(password, valObj));
		});
		if (passwordSettings.pattern?.alsoIncludeCharacters) {
			validatorList.push({
				key: "alsoIncludeCharacters",
				result:
					password === ""
						? null
						: (passwordSettings.pattern?.alsoIncludeCharacters ?? "")
								.split("")
								.every((char) => password.includes(char)),
				massage: t("cashier.mustInclude").replace("%X%", passwordSettings.pattern?.alsoIncludeCharacters ?? null)
			});
		}
	} else if (passwordSettings.pattern?.type === PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN) {
		const reg = new RegExp(passwordSettings.pattern?.regularExpression ?? "", "g");
		validatorList.push({
			key: "regularExpression",
			result: reg.test(password),
			massage: t("cashier.mustMatchRegularExpression").replace("%X%", passwordSettings.pattern?.regularExpression ?? null)
		});
	}

	return (
		<label className="vs--password-rules">
			<span className="vs--password-rules-title vs--font-smallest vs--font-medium"> {t("cashier.passwordMustContain")} </span>
			<ul>
				{validatorList.map((validator) => (
					<li key={validator.key} className="vs--font-smallest vs--font-regular vs--flex">
						<div className={`${getIconContainerClass(validator.result)} vs--password-rules-cont vs--flex vs--align-center vs--justify-center vs--mr-8`}>
							<i className={getIconClass(validator.result)} />
						</div>
						<div>{validator.massage}</div>
					</li>
				))}
			</ul>
		</label>
	);
};

PasswordValidationTooltip.propTypes = {
	/* Password field value */
	password: PropTypes.string,
	/** Redux state property, the activation password settings */
	passwordSettings: passwordSettingsType
};

export default PasswordValidationTooltip;
