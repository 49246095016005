import  { useRef } from "react";
import PropTypes from "prop-types";
import useOutsideClick from "hooks/useOutsideClick";

/** Component to add "click outside" event on wrapped component */
const OutsideAlerter = ({ children, callback, ...props }) => {
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, callback);
	return (
		<div ref={wrapperRef} {...props}>
			{children}
		</div>
	);
};

/** OutsideAlerter propTypes
 * PropTypes
 */
OutsideAlerter.propTypes = {
	/** Wrapped component */
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string, PropTypes.number]).isRequired,
	/** Function to call when clicked outside */
	callback: PropTypes.func
};

export default OutsideAlerter;
