import  { Fragment } from "react";

import ReportComponent from "components/pages/report";

const ReportRoute = () => {
	return (
		<Fragment>
			<ReportComponent />
		</Fragment>
	);
};

export default ReportRoute;
