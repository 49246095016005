import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { initTranslations } from "translations/config";

import Loader from "components/ui/loader";
import KenoWrapper from "components/pages/keno/wrapper";

import { refreshStreamAccess } from "store/actions/auth/auth.actions";
import { getUrlVars } from "utils/common";

/** Main Layout Component */
const Layout = ({ translationsLoaded, stream, refreshStreamAccess }) => {
	const [decodedData, setDecodedData] = useState(null);

	const streamRefreshToken = decodedData?.refreshToken ?? null;

	const scale = () => {
		const coificientX = window.innerWidth / 1920;
		const coificientY = window.innerHeight / 1080;
		document.body.style.transform = "scaleX(" + coificientX + ") scaleY(" + coificientY + ")";
	};

	/** Scale functionality */
	useEffect(() => {
		scale();
		window.addEventListener("resize", scale);
		return () => {
			window.removeEventListener("resize", scale);
		};
	}, []);

	/** Load translations */
	useEffect(() => {
		document.documentElement.classList.add("vs--html-keno");
		let streamData = { languageId: "en" };
		try {
			const streamEncodedData = getUrlVars()["data"];
			streamData = JSON.parse(decodeURIComponent(atob(streamEncodedData)));
			setDecodedData(streamData);
		} catch (ex) {
			console.log("can't decode data");
		}

		initTranslations(streamData.languageFilePath, streamData.languageId, streamData.languageFilePath, streamData.languageId, Function.prototype, Function.prototype);
	}, []);

	useEffect(() => {
		if (translationsLoaded && streamRefreshToken) {
			refreshStreamAccess(streamRefreshToken);
		}
	}, [translationsLoaded, streamRefreshToken]);

	return (
		<div className="vs--container vs--container-keno">
			<div className="vs--container-keno-blur">{translationsLoaded && stream.accessLoaded ? <KenoWrapper decodedData={decodedData} /> : <Loader full={true} />}</div>
		</div>
	);
};

/** Layout propTypes
 * PropTypes
 */
Layout.propTypes = {
	/** Redux state porperty, Is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux state property, the object of stream */
	stream: PropTypes.object,
	/** Redux action to refresh stream access */
	refreshStreamAccess: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		translationsLoaded: state.auth.translationsLoaded,
		stream: state.auth.stream
	};
};

const mapDispatchToProps = (dispatch) => ({
	refreshStreamAccess: (refreshToken) => {
		dispatch(refreshStreamAccess(refreshToken));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
