import { REPORT_BEFORE, REPORT_AFTER, SET_TURNOVER_REPORT, CLEAR_TURNOVER_REPORT, SET_PRINTING_REPORT, SET_TURNOVER_TYPE } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case REPORT_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case REPORT_AFTER:
			return {
				...state,
				isLoading: false
			};
		case SET_TURNOVER_REPORT:
			return {
				...state,
				turnover: payload.data
			};
		case CLEAR_TURNOVER_REPORT:
			return {
				...state,
				turnover: {
					report: {
						footballSingleMatch: {},
						horseRaceFlat: {},
						greyhoundRace: {}
					},
					startingLimit: 0,
					finalLimit: 0,
					betCount: 0,
					betSlipCount: 0,
					wonCount: 0,
					playedCount: 0,
					balance: 0,
					canceled: 0,
					ggr: 0,
					netTurnover: 0,
					payout: 0,
					turnover: 0,
					won: 0,
					wonUnpaid: 0
				}
			};
		case SET_PRINTING_REPORT:
			return {
				...state,
				printingReport: payload.type
			};
		case SET_TURNOVER_TYPE:
			return {
				...state,
				type: payload.type
			};
		default:
			return state;
	}
};
