import PropTypes from "prop-types";

const FormButton = ({ children }) => {
	return <div className="vs--ui-form-button">{children}</div>;
};

/** FormButton propTypes
 * PropTypes
 */
FormButton.propTypes = {
	/** Items to render */
	children: PropTypes.node
};

export default FormButton;
