import  { Fragment } from "react";

import QRComponent from "components/pages/qr";

const QRRoute = () => {
	return (
		<Fragment>
			<QRComponent />
		</Fragment>
	);
};

export default QRRoute;
