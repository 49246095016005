import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Tooltip from "components/ui/tooltip";
import ModalWrapper from "components/modalWrapper";
import PasswordValidationTooltip from "components/ui/validationTooltips/passwordValidationTooltip";
import Input from "components/ui/input";
import Form from "components/ui/form";

import { required } from "utils/common";
import { validatePasword } from "utils/password";
import { showSuccess } from "utils/message";

import { changePassword, getPasswordSettings } from "store/actions/auth/changePassword.actions";

import passwordSettingsType from "types/passwordSettings.type";

/** Change Password Modal Component */
const ChangePasswordModal = ({ coordinates, onCancel, changePassword, getPasswordSettings, passwordSettings, isPasswordChanging }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();

	/** State to show success message in modal */
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordFocused, setNewPasswordFocused] = useState(false);

	/** Load password settings */
	useEffect(() => {
		getPasswordSettings();
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf ChangePasswordModal
	 */
	const handleForm = () => {
		formInstance
			.validateFields()
			.then((data) => {
				changePassword(data.currentPassword, data.newPassword, data.confirmPassword, () => {
					showSuccess(t("cashier.successfullyChanged"));
					onCancel();
				});
			})
			.catch(Function.prototype);
	};

	return (
		<ModalWrapper coordinates={coordinates} onCancel={onCancel} visible={true} classNames={{ content: "vs--change-password-modal", header: "vs--change-password-modal-header", body: "vs--change-password-modal-body vs--ui-modal-body-visible" }}>
			<div className="vs--change-password">
				<Form
					form={formInstance}
					initialValues={{
						currentPassword: "",
						newPassword: "",
						confirmPassword: ""
					}}
				>
					<div className="vs--change-password-title vs--text-center vs--mt-2 vs--mb-8">
						<div className="vs--mb-16 vs--mt-48">
							<b className="vs--font-small vs--font-medium">{t("cashier.changePassword")}</b>
						</div>
						<span className="vs--font-smallest vs--font-regular">{t("cashier.changePasswordSub")}</span>
					</div>
					<div className="vs--change-password-rules vs--mb-30">
						<div>
							<Form.Item label={t("cashier.currentPassword")} name="currentPassword" rules={[required(t("cashier.passwordRequired"))]} className="vs--change-password-form">
								<Input.Password placeholder={t("cashier.enterCurrentPassword")} />
							</Form.Item>

							<Tooltip title={<PasswordValidationTooltip password={newPassword} passwordSettings={passwordSettings} />} placement="right" overlayClassName="vs--change-password-tooltip" isOpen={newPasswordFocused || !!newPassword}>
								<Form.Item
									label={t("cashier.newPassword")}
									name="newPassword"
									className="vs--change-password-form"
									rules={[
										required(t("cashier.passwordRequired")),
										() => ({
											validator(value) {
												return validatePasword(value, passwordSettings);
											}
										})
									]}
								>
									<Input.Password
										name="registration"
										onChange={(value) => {
											setNewPassword(value);
											setTimeout(() => {
												if (formInstance.getFieldValue("confirmPassword") !== "") formInstance.validateFields(["confirmPassword"]);
											}, 0);
										}}
										placeholder={t("cashier.enterNewPassword")}
										onFocus={() => setNewPasswordFocused(true)}
										onBlur={() => setNewPasswordFocused(false)}
									/>
								</Form.Item>
							</Tooltip>

							<Form.Item
								label={t("cashier.confrimNewPassword")}
								name="confirmPassword"
								className="vs--change-password-form"
								rules={[
									required(t("cashier.passwordRequired")),
									({ getFieldValue }) => ({
										validator(value) {
											if (value !== getFieldValue("newPassword")) {
												return Promise.reject(t("cashier.passwordsDoNotMatch"));
											}
											return Promise.resolve();
										}
									})
								]}
							>
								<Input.Password placeholder={t("cashier.confirmPassword")} onPaste={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} />
							</Form.Item>
						</div>
					</div>

					<div className="vs--flex vs--align-center vs--mt-16 vs--justify-center">
						<button type="button" className="vs--button vs--button-modal vs--mr-8 vs--confirm-cancel" onClick={onCancel} title={t("common.cancel")}>
							{t("common.cancel")}
						</button>
						<button type="submit" className={"vs--button vs--button-modal vs--ml-8 vs--confirm-confirm" + (isPasswordChanging ? " vs--button-disabled" : "")} onClick={handleForm} title={t("cashier.confirm")}>
							{t("cashier.changePassword")}
						</button>
					</div>
				</Form>
			</div>
		</ModalWrapper>
	);
};

/** ChangePasswordModal propTypes
 * PropTypes
 */
ChangePasswordModal.propTypes = {
	/** Css variables` viewport x, y coordinates */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** Function that will be called on Change Password modal close */
	onCancel: PropTypes.func,
	/** Redux action to change password */
	changePassword: PropTypes.func,
	/** Redux action to get password settings */
	getPasswordSettings: PropTypes.func,
	/** Redux state property, the activation password settings */
	passwordSettings: passwordSettingsType,
	/** Redux state property, is true when change pasword request is in process */
	isPasswordChanging: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		isPasswordChanging: state.auth.changePassword.isPasswordChanging,
		passwordSettings: state.auth.changePassword.passwordSettings
	};
};

const mapDispatchToProps = (dispatch) => ({
	changePassword: (currentPassword, newPassword, confirmPassword, onSuccess) => {
		dispatch(changePassword(currentPassword, newPassword, confirmPassword, onSuccess));
	},
	getPasswordSettings: () => {
		dispatch(getPasswordSettings());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
