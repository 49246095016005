import { GAME_TYPE } from "constants/game.constants";
import SeasonMarkets from "./markets/seasonMarkets";
import { MARKETS_GROUP_NAME } from "constants/liveMonitor.constants";
import { flagCodeMapper } from "./flags";
import { buildPathToStaticFolderOfCDN } from "./common";

export const generateMarketsPerPeageGroups = (gameType, marketGroupName) => {
	switch (gameType) {
		case GAME_TYPE.ENGLISH_LEAGUE:
			return generateLeagueMarketsPerPeageGroups(marketGroupName);
		default:
			return [];
	}
};

export const generateLeagueMarketsPerPeageGroups = (marketGroupName) => {
	const { correctScoreOutcomes } = SeasonMarkets.prototype;
	switch (marketGroupName) {
		case MARKETS_GROUP_NAME.REGULAR:
			return [
				[
					[
						"main",
						{
							group: "Winner3Way",
							groupTranslation: "result",
							outcomes: ["Home", "Draw", "Away"],
							outcomeTranslations: ["results_1", "results_x", "results_2"],
							argument: null,
							outcomesWidth: [95, 95, 95],
							groupWidth: 291,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "DoubleChance",
							groupTranslation: "doublechance",
							outcomes: ["HomeOrDraw", "HomeOrAway", "AwayOrDraw"],
							outcomeTranslations: ["doublechance_1x", "doublechance_12", "doublechance_x2"],
							argument: null,
							outcomesWidth: [95, 95, 95],
							groupWidth: 291,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "Total",
							groupTranslation: "total_2.5_long",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["total_over", "total_under"],
							argument: "2.5",
							outcomesWidth: [144, 144],
							groupWidth: 291,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "WillBothTeamsScore",
							groupTranslation: "bothteamstoscore",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [144, 144],
							groupWidth: 291,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "YellowCard",
							groupTranslation: "yellowcard",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [142, 142],
							groupWidth: 286,
							separateOutcomes: false,
							separateOdds: false
						}
					]
				],
				[
					[
						"Total",
						{
							group: "Total",
							groupTranslation: "total_2.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "2.5",
							outcomesWidth: [119, 119],
							groupWidth: 240,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "Total",
							groupTranslation: "total_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "1.5",
							outcomesWidth: [119, 119],
							groupWidth: 240,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "HomeTotal",
							groupTranslation: "hometotal_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "1.5",
							outcomesWidth: [120, 120],
							groupWidth: 242,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "HomeTotal",
							groupTranslation: "hometotal_0.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "0.5",
							outcomesWidth: [120, 120],
							groupWidth: 242,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "AwayTotal",
							groupTranslation: "awaytotal_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "1.5",
							outcomesWidth: [120, 120],
							groupWidth: 242,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "AwayTotal",
							groupTranslation: "awaytotal_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "0.5",
							outcomesWidth: [120, 120],
							groupWidth: 242,
							separateOutcomes: false,
							separateOdds: false
						}
					]
				],
				[
					[
						"Handicap",
						{
							group: "Handicap",
							groupTranslation: "handicap_0.5",
							outcomes: ["Home", "Away"],
							outcomeTranslations: ["t1(0.5)", "t2(-0.5)"],
							argument: "0.5",
							outcomesWidth: [129, 129],
							groupWidth: 260,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Handicap",
						{
							group: "AsianHandicap",
							groupTranslation: "asianhandicap_0.25",
							outcomes: ["Home", "Away"],
							outcomeTranslations: ["t1(0.25)", "t2(-0.25)"],
							argument: "0.25",
							outcomesWidth: [129, 129],
							groupWidth: 260,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"HalfMatch",
						{
							group: "HalfMatch",
							groupTranslation: "halftime/fulltime",
							outcomes: ["P1P1", "P1H", "P1P2", "HP1", "HH", "HP2", "P2P2", "P2H", "P2P1"],
							outcomeTranslations: ["1/1", "1/X", "1/2", "X/1", "XX", "X/2", "2/1", "2/X", "2/2"],
							argument: null,
							outcomesWidth: Array.from({ length: 9 }, () => 102),
							groupWidth: 934,
							separateOutcomes: true,
							separateOdds: true
						}
					]
				],
				[
					[
						"main",
						{
							group: "ResultWithoutDraw",
							groupTranslation: "drawnobet",
							outcomes: ["Home", "Away"],
							outcomeTranslations: ["1", "2"],
							argument: null,
							outcomesWidth: [180, 180],
							groupWidth: 363,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Handicap",
						{
							group: "YellowCard",
							groupTranslation: "yellowcard",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [180, 180],
							groupWidth: 363,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Handicap",
						{
							group: "RedCard",
							groupTranslation: "redcard",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [180, 180],
							groupWidth: 363,
							separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Handicap",
						{
							group: "Penalty",
							groupTranslation: "penalty",
							outcomeTranslations: ["yes", "no"],
							outcomes: ["Yes", "No"],
							argument: null,
							outcomesWidth: [180, 180],
							groupWidth: 363,
							separateOutcomes: false,
							separateOdds: false
						}
					]
				],
				[
					[
						"CorrectScore6Goals",
						{
							group: "CorrectScore6Goals",
							groupTranslation: "correctscore",
							outcomes: correctScoreOutcomes().slice(0, 14),
							outcomeTranslations: correctScoreOutcomes()
								.slice(0, 14)
								.map((str) => str.replace("Home", "").replace("Away", "").split("").join(" : ")),
							argument: null,
							outcomesWidth: Array.from({ length: 14 }, (_, i, arr) => (i === 13 ? 106 : 102)),
							groupWidth: 1458,
							separateOutcomes: true,
							separateOdds: true
						}
					]
				],
				[
					[
						"CorrectScore6Goals",
						{
							group: "CorrectScore6Goals",
							groupTranslation: "correctscore",
							outcomes: correctScoreOutcomes().slice(14),
							outcomeTranslations: correctScoreOutcomes()
								.slice(14)
								.map((str) => str.replace("Home", "").replace("Away", "").split("").join(" : ")),
							argument: null,
							outcomesWidth: Array.from({ length: 14 }, (_, i, arr) => (i === 13 ? 106 : 102)),
							groupWidth: 1458,
							separateOutcomes: true,
							separateOdds: true
						}
					]
				]
			];
		case MARKETS_GROUP_NAME.LIVE:
			return [
				[
					[
						"main",
						{
							group: "Winner3Way",
							groupTranslation: "result",
							// outcomes: ["Home", "Draw", "Away"],
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"CorrectScore6Goals",
						{
							group: "CorrectScore6Goals",
							groupTranslation: "correctscore",
							// outcomes: correctScoreOutcomes(),
							outcomesWidth: [220],
							groupWidth: 220,
							// separateOutcomes: true,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "DoubleChance",
							groupTranslation: "doublechance",
							// outcomes: ["HomeOrDraw", "HomeOrAway", "AwayOrDraw"],
							outcomesWidth: [158, 158],
							groupWidth: 318,
							// separateOutcomes: false,
							separateOdds: true
						}
					],
					[
						"HalfMatch",
						{
							group: "HalfMatch",
							groupTranslation: "ht/ft",
							// outcomes: ["P1P1", "P1H", "P1P2", "HP1", "HH", "HP2", "P2P2", "P2H", "P2P1"],
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: true,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "WillBothTeamsScore",
							groupTranslation: "bothteamstoscore",
							// outcomes: ["Yes", "No"],
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "Total",
							groupTranslation: "total_1.5",
							// outcomes: ["Over", "Under"],
							argument: "1.5",
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "Total",
							groupTranslation: "total_2.5",
							// outcomes: ["Over", "Under"],
							argument: "2.5",
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					]
				]
			];
		case MARKETS_GROUP_NAME.RESULTS:
			return [
				[
					[
						"main",
						{
							group: "Winner3Way",
							groupTranslation: "result",
							outcomes: ["Home", "Draw", "Away"],
							outcomeTranslations: ["results_1", "results_x", "results_2"],
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"CorrectScore6Goals",
						{
							group: "CorrectScore6Goals",
							groupTranslation: "correctscore",
							outcomes: correctScoreOutcomes(),
							outcomeTranslations: correctScoreOutcomes().map((str) => str.replace("Home", "").replace("Away", "").split("").join(" : ")),
							outcomesWidth: [220],
							groupWidth: 220,
							// separateOutcomes: true,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "DoubleChance",
							groupTranslation: "doublechance",
							outcomes: ["HomeOrDraw", "HomeOrAway", "AwayOrDraw"],
							outcomeTranslations: ["doublechance_1x", "doublechance_12", "doublechance_x2"],
							outcomesWidth: [158, 158],
							groupWidth: 318,
							// separateOutcomes: false,
							separateOdds: true
						}
					],
					[
						"HalfMatch",
						{
							group: "HalfMatch",
							groupTranslation: "ht/ft",
							outcomes: ["P1P1", "P1H", "P1P2", "HP1", "HH", "HP2", "P2P2", "P2H", "P2P1"],
							outcomeTranslations: ["1/1", "1/X", "1/2", "X/1", "XX", "X/2", "2/1", "2/X", "2/2"],
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: true,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "WillBothTeamsScore",
							groupTranslation: "bothteamstoscore",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "Total",
							groupTranslation: "total_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "1.5",
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "Total",
							groupTranslation: "total_2.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "2.5",
							outcomesWidth: [198],
							groupWidth: 198,
							// separateOutcomes: false,
							separateOdds: false
						}
					]
				],
				[
					[
						"Total",
						{
							group: "HomeTotal",
							groupTranslation: "hometotal_0.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "0.5",
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "AwayTotal",
							groupTranslation: "awaytotal_0.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "0.5",
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "HomeTotal",
							groupTranslation: "hometotal_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "1.5",
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Total",
						{
							group: "AwayTotal",
							groupTranslation: "awaytotal_1.5",
							outcomes: ["Over", "Under"],
							outcomeTranslations: ["o", "u"],
							argument: "1.5",
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"main",
						{
							group: "YellowCard",
							groupTranslation: "yellowcard",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Handicap",
						{
							group: "RedCard",
							groupTranslation: "redcard",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					],
					[
						"Handicap",
						{
							group: "Penalty",
							groupTranslation: "penalty",
							outcomes: ["Yes", "No"],
							outcomeTranslations: ["yes", "no"],
							argument: null,
							outcomesWidth: [218],
							groupWidth: 218,
							// separateOutcomes: false,
							separateOdds: false
						}
					]
				]
			];
		default:
			return [];
	}
};

export const standingsConfig = [
	{
		key: "POS",
		title: "pos.",
		width: 221,
		getData: (record) => record,
		render: (record, i, translateFn) => {
			const positionIcon = (
				typeof record.up === "boolean"
					? (
						record.up
							? buildPathToStaticFolderOfCDN("cashier-images/icons/league-standings-up.svg")
							: buildPathToStaticFolderOfCDN("cashier-images/icons/league-standings-down.svg")
					)
					: null
			);
			return (
				<div className="vs--width-full vs--flex vs--justify-start vs--align-center vs--pl-16">
					<div className="vs--flex vs--justify-end vs--align-center vs--mr-16" style={{ width: 42 }}>
						{i + 1}.
					</div>
					<img className="vs--mr-8" src={buildPathToStaticFolderOfCDN(`images/flags/${flagCodeMapper(GAME_TYPE.ENGLISH_LEAGUE, record.countryCode)}`)} alt={record.countryCode} style={{ height: 36, width: 36 }} />
					<div className="vs--mr-auto">{translateFn(`livemonitor.football_england_league.${record.countryCode.toLowerCase()}`)}</div>
					<div className="vs--mr-8" style={{ width: 24 }}>
						{positionIcon !== null ? <img className="vs--width-full vs--height-full" style={{ marginTop: -4 }} src={positionIcon} alt={positionIcon ? "up" : "down"} /> : null}
					</div>
				</div>
			);
		}
	},
	{
		key: "P",
		title: "p",
		width: 106,
		getData: (record) => record.played,
		render: null
	},
	{
		key: "W",
		title: "w",
		width: 106,
		getData: (record) => record.win,
		render: null
	},
	{
		key: "D",
		title: "d",
		width: 106,
		getData: (record) => record.draw,
		render: null
	},
	{
		key: "L",
		title: "l",
		width: 106,
		getData: (record) => record.lose,
		render: null
	},
	{
		key: "GD",
		title: "gd",
		width: 106,
		getData: (record) => {
			const subtracted = record.totalGoals - record.totalAgainstGoals;
			return subtracted <= 0 ? subtracted.toString() : `+${subtracted}`;
		},
		render: null
	},
	{
		key: "PTS",
		title: "pts",
		width: 109,
		getData: (record) => record.points,
		render: null
	}
];

export const getScreensAnimationTimouts = (event) => {
	const preambleStartTime = new Date(event.preambleStartTime);
	const startTime = new Date(event.startTime);

	const diffMilliseconds = startTime - preambleStartTime;
	const resultChildCount = 2;
	const suggestedMarketsChildCount = 6;
	const standartDelay = 0;
	const standartDuration = 700;
	const standartPerChildTimout = 5000;
	const standingsScrollAnimationDelay = 2000;
	const standingsScrollAnimationDuration = 7000;
	const standartFullResultTime = resultChildCount * (standartDelay + standartDuration) + (resultChildCount - 1) * standartPerChildTimout;
	const remainingTime = diffMilliseconds - standartFullResultTime;
	const coefficient = {
		duration: 7 / 500,
		nextAnimationDelay: 50.125 / 500,
		standingsDelay: 20.125 / 500,
		standingsDuration: 80 / 500
	};
	const mainDelay = standartDelay;
	const mainDuration = Math.min(remainingTime * coefficient.duration, standartDuration, 1000);
	const mainPerChildTimout = Math.min(remainingTime * coefficient.nextAnimationDelay, standartPerChildTimout, 10000);
	const mainScrollAnimationDelay = Math.min(remainingTime * coefficient.standingsDelay, standingsScrollAnimationDelay, 2000);
	const mainScrollAnimationDuration = Math.min(remainingTime * coefficient.standingsDuration, standingsScrollAnimationDuration, 10000);

	return {
		preambleStartTime,
		startTime,
		diffMilliseconds,
		resultChildCount,
		suggestedMarketsChildCount,
		standartDelay,
		standartDuration,
		standartPerChildTimout,
		standingsScrollAnimationDelay,
		standingsScrollAnimationDuration,
		standartFullResultTime,
		remainingTime,
		coefficient,
		mainDelay,
		mainDuration,
		mainPerChildTimout,
		mainScrollAnimationDelay,
		mainScrollAnimationDuration
	};
};

export const liveUpdateStrategy = (gameData, market) => {
	switch (market.group) {
		case "Winner3Way":
			return liveUpdate_Winner3Way(gameData, market);
		case "CorrectScore6Goals":
			return liveUpdate_CorrectScore6Goals(gameData, market);
		case "DoubleChance":
			return liveUpdate_DoubleChance(gameData, market);
		case "HalfMatch":
			return liveUpdate_HalfMatch(gameData, market);
		case "WillBothTeamsScore":
			return liveUpdate_WillBothTeamsScore(gameData, market);
		case "Total":
			return liveUpdate_Total(gameData, market);
		default:
			return [];
	}
};

export const liveUpdate_Winner3Way = (gameData, market) => {
	const necessaryName = gameData.team1.goalCount > gameData.team2.goalCount ? "Home" : gameData.team1.goalCount < gameData.team2.goalCount ? "Away" : "Draw";

	const mapper = {
		Home: "results_1",
		Draw: "results_x",
		Away: "results_2"
	};

	const [oddList] = market.odds;
	const odds = (oddList ?? []).filter((odd) => {
		return odd.outcome === necessaryName;
	});
	return odds.map((odd) => [mapper[odd.outcome], odd]);
};
export const liveUpdate_CorrectScore6Goals = (gameData, market) => {
	const necessaryName = `${gameData.team1.goalCount}:${gameData.team2.goalCount}`;
	const [oddList] = market.odds;
	const odds = (oddList ?? []).filter((odd) => {
		return odd.name === necessaryName;
	});
	return odds.map((odd) => [odd.name, odd]);
};
export const liveUpdate_DoubleChance = (gameData, market) => {
	const necessaryList = gameData.team1.goalCount > gameData.team2.goalCount ? ["HomeOrDraw", "HomeOrAway"] : gameData.team1.goalCount < gameData.team2.goalCount ? ["AwayOrDraw", "HomeOrAway"] : ["HomeOrDraw", "AwayOrDraw"];

	const mapper = {
		HomeOrDraw: "doublechance_1x",
		HomeOrAway: "doublechance_12",
		AwayOrDraw: "doublechance_x2"
	};

	const [oddList] = market.odds;

	const odds = (oddList ?? []).filter((odd) => {
		return necessaryList.includes(odd.outcome);
	});

	return odds.map((odd) => [mapper[odd.outcome], odd]);
};

export const liveUpdate_HalfMatch = (gameData, market) => {
	const necessaryName = check_HalfMatch(gameData, 1) + check_HalfMatch(gameData, 2);

	const [oddList] = market.odds;

	const odds = (oddList ?? []).filter((odd) => {
		return odd.outcome === necessaryName;
	});

	return odds.map((odd) => [odd.showName, odd]);
};

const check_HalfMatch = ({ team1, team2 }, periodGoalNo) => {
	if (team1[`goalCount${periodGoalNo}`] > team2[`goalCount${periodGoalNo}`]) {
		return `P1`;
	}
	if (team1[`goalCount${periodGoalNo}`] < team2[`goalCount${periodGoalNo}`]) {
		return `P2`;
	}
	return "H";
};

export const liveUpdate_WillBothTeamsScore = (gameData, market) => {
	const necessaryName = gameData.team1.goalCount > 0 && gameData.team2.goalCount > 0 ? "btsYes" : "btsNo";
	const [oddList] = market.odds;
	const odds = (oddList ?? []).filter((odd) => {
		return odd.name === necessaryName;
	});

	return odds.map((odd) => [odd.outcome.toLowerCase(), odd]);
};
export const liveUpdate_Total = (gameData, market) => {
	const totalGoal = gameData.team1.goalCount + gameData.team2.goalCount;
	const argument = Number(market.argument);
	const [oddList] = market.odds;
	const necessaryName = totalGoal > argument ? "Over" : "Under";
	const odds = (oddList ?? []).filter((odd) => {
		return odd.name === necessaryName && odd.argument.toString() === odd.argument.toString();
	});
	const mapper = {
		Over: "o",
		Under: "u"
	};
	return odds.map((odd) => [mapper[odd.name], odd]);
};
