import  { useCallback } from "react";
import { useSelector } from "react-redux";
import { binaryToFlags } from "utils/common";
import { BETSLIP_MODES } from "constants/betslip.constants.js";
import { GAME_TYPE } from "constants/game.constants";

const getMode = state => state.betslip.mode
const getCurrentGameType = state => state.game.currentGameType
const getUseBonus = state => state.bonuses.standard.useBonus
const getBonus = state => state.bonuses.standard.bonus
const getSession = state => state.auth.session

const useMarketDisabledForBonus = () => {
	const mode = useSelector(getMode);
	const currentGameType = useSelector(getCurrentGameType);
	const useBonus = useSelector(getUseBonus);
	const bonus = useSelector(getBonus);
	const session = useSelector(getSession);

	const defaultChecking = useCallback(() => {
		if (!useBonus) {
			return false;
		}
		const bonusGames = binaryToFlags(session?.games?.map((g) => g.type) || [], bonus.gameType);
		if ((bonusGames?.length ?? 0) === 0) {
			return false;
		}
		if (!bonusGames.includes(currentGameType)) {
			return true;
		}
		if (session?.currency?.code !== bonus.currency) {
			return true;
		}
		return false;
	}, [useBonus, bonus, session, currentGameType]);

	const isSingleMarketDisable = useCallback(
		(factor) => {
			if (mode !== BETSLIP_MODES.SINGLE) {
				return false;
			}
			if (defaultChecking()) {
				return true;
			}
			return factor === null || factor === undefined ? false : useBonus && bonus.minOddsSingle !== null && factor < bonus.minOddsSingle;
		},
		[mode, useBonus, bonus]
	);

	const isMultiMarketDisable = useCallback(
		(bets) => {
			if (mode !== BETSLIP_MODES.MULTI) {
				return false;
			}
			if (defaultChecking()) {
				return true;
			}
			if (!Array.isArray(bets) || bets.length === 0) {
				return false;
			}
			return useBonus && bonus.minOddsMulti !== null && bonus.minOddsMulti > bets.reduce((acc, bet) => acc * bet.factor, 1);
		},
		[mode, useBonus, bonus]
	);

	return { isSingleMarketDisable, isMultiMarketDisable };
};

export default useMarketDisabledForBonus;
