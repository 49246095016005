import { useTranslation } from "react-i18next";

import { mergeClassNames } from "utils/common";

import { TURNOVER_TYPE } from "constants/common.constants";
import { BANNERS_ICONS } from "constants/reports.constants";

const Banners = ({ type, data }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--report-banners vs--flex">
			{Object.keys(data).map((banner) => (
				<div
					key={banner}
					className={mergeClassNames(
						"vs--report-banners-banner vs--flex vs--align-center",
						type === TURNOVER_TYPE.BONUS ? "vs--report-banners-banner-bonus" : "vs--flex-equal"
					)}
				>
					<i className={mergeClassNames("vs--fs-32", BANNERS_ICONS[banner])} />
					<div className="vs--flex vs--flex-col">
						<span className="vs--report-banners-banner-text vs--font-uppercase vs--font-mini vs--font-regular">{t(`cashier.${banner}`)}</span>
						<span className="vs--report-banners-banner-value vs--font-exstrasmall vs--font-medium">{data[banner]}</span>
					</div>
				</div>
			))}
		</div>
	)
};

export default Banners;