import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { SET_ACTIVATE_BEFORE, SET_ACTIVATE_AFTER } from "../../actionTypes";

const setActivateBefore = () => ({
	type: SET_ACTIVATE_BEFORE
});

const setActivateFinished = () => ({
	type: SET_ACTIVATE_AFTER
});

export const activateCashier = (token, newPassword, confirmPassword, onSuccess) => {
	return (dispatch) => {
		dispatch(setActivateBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.USER_ACTIVATE}`,
			method: Methods.POST,
			data: { token, newPassword, confirmPassword }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					onSuccess && onSuccess();
				}
				dispatch(setActivateFinished());
			})
			.catch((ex) => {
				dispatch(setActivateFinished());
			});
	};
};
