import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { SET_CHANGE_PASSWORD_SETTINGS_BEFORE, SET_CHANGE_PASSWORD_SETTINGS_AFTER, SET_CHANGE_PASSWORD_BEFORE, SET_CHANGE_PASSWORD_AFTER, SET_CHANGE_PASSWORD_SETTINGS } from "../../actionTypes";

const setChangePasswordBefore = () => ({
	type: SET_CHANGE_PASSWORD_BEFORE
});

const setChangePasswordFinished = () => ({
	type: SET_CHANGE_PASSWORD_AFTER
});

const setPasswordSettingsBefore = () => ({
	type: SET_CHANGE_PASSWORD_SETTINGS_BEFORE
});

const setPasswordSettingsFinished = () => ({
	type: SET_CHANGE_PASSWORD_SETTINGS_AFTER
});

const setPasswordSettings = (settings) => ({
	type: SET_CHANGE_PASSWORD_SETTINGS,
	payload: { settings }
});

export const getPasswordSettings = () => {
	return (dispatch) => {
		dispatch(setPasswordSettingsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PASSWORD_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: settings } }) => {
				dispatch(setPasswordSettings(settings));
				dispatch(setPasswordSettingsFinished());
			})
			.catch(() => {
				dispatch(setPasswordSettingsFinished());
			});
	};
};

export const changePassword = (currentPassword, newPassword, confirmPassword, onSuccess) => {
	return (dispatch) => {
		dispatch(setChangePasswordBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_PASSWORD}`,
			method: Methods.POST,
			data: { currentPassword, newPassword, confirmPassword }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					onSuccess && onSuccess();
				}
				dispatch(setChangePasswordFinished());
			})
			.catch((ex) => {
				dispatch(setChangePasswordFinished());
			});
	};
};
