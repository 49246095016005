import { mergeClassNames } from "utils/common";

const ContentList = ({ header, children, className, headerClassName, bodyClassName, style, headerStyle, bodyStyle, bodyProps = null }) => {
	return (
		<div style={style} className={mergeClassNames("content-list", className)}>
			<div style={headerStyle} className={mergeClassNames("content-list-header", headerClassName)}>
				{header}
			</div>
			<ul {...bodyProps} style={bodyStyle} className={mergeClassNames("content-list-body", bodyClassName)}>
				{children}
			</ul>
		</div>
	);
};

ContentList.ListItem = ({ className, children, style }) => {
	return (
		<li style={style} className={mergeClassNames("content-list-item", className)}>
			{children}
		</li>
	);
};

export default ContentList;
