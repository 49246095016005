import  { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import KenoRandomSelection from "./kenoRandomSelection";
import KenoSelectLine from "./kenoSelectLine";
import KenoSelectSequence from "./kenoSelectSequence";

import useDocumentListener from "hooks/useDocumentListener";

import { groupByQty, getLineFromMatrix } from "utils/common";
import { GAME_TYPE, KENO_BALLS_COUNT, GAME_ACTIVITY_STATE, GAME_STATUSES, KENO_BALLS_QTY_IN_ROW, KENO_FORMAT_BALLS_QTY, KENO_FORMAT } from "constants/game.constants";

import { setKenoBalls, handleSetKenoBalls, clearKenoBalls } from "store/actions/betslip/betslip.actions";

import sessionType from "types/session.type";
import eventType from "types/event.type";

/** Keno Markets list component */
const KenoMarkets = ({ eventInfo, currentGameType, session, kenoBalls, setKenoBalls, handleSetKenoBalls, clearKenoBalls }) => {
	const [selectSequence, setSelectSequence] = useState({});
	const raceFormatCount = KENO_FORMAT_BALLS_QTY[Object.values(KENO_FORMAT).find((value) => value === (eventInfo?.gameData?.raceFormat ?? 0))] || 0;
	const markets = useMemo(
		() =>
			groupByQty(
				Array.from({ length: KENO_BALLS_COUNT }, (_, i) => i + 1),
				KENO_BALLS_QTY_IN_ROW
			),
		[]
	);
	const isDisabledSequence = useCallback((num) => raceFormatCount - kenoBalls.balls.length < num, [raceFormatCount, kenoBalls]);

	// Unchangeable referance of ESC button click handler
	useDocumentListener("keyup", (event) => {
		if (event.key === "Escape") {
			clearKenoBalls();
		}
	});

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {number} market
	 * @returns {boolean}
	 * @memberOf KenoMarkets
	 */
	const isOddSelected = (market) => {
		return kenoBalls && kenoBalls.balls && kenoBalls.balls.includes(market);
	};

	/** Function to check if odd is disabled
	 * @function
	 * @param {object} id
	 * @returns {boolean}
	 * @memberOf KenoMarkets
	 */
	const isOddDisabled = () => {
		const game = session?.games?.find((g) => g.type === currentGameType);

		return (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING || [GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfo?.status)
		);
	};

	/* Clear pending bets of keno when markest disabled */
	useEffect(() => {
		if (isOddDisabled()) {
			setKenoBalls({ balls: [] });
			setSelectSequence({});
		}
	}, [isOddDisabled()]);

	/** Cleare sequence when odds disabled or to many odds selected already */
	useEffect(() => {
		if (!Object.entries(selectSequence)[0]) {
			return;
		}

		if (isDisabledSequence(Object.entries(selectSequence)[0][1])) {
			setSelectSequence({});
		}
	}, [isDisabledSequence, selectSequence]);

	/** Function which fires on market click
	 * @function
	 * @description adds bet to betslip bets list
	 * @param {object} odd - odd info
	 * @memberOf KenoMarkets
	 */
	const handleOddClick = (odd, e) => {
		if (isOddDisabled()) {
			return;
		}

		let sequencConfig = null;

		if (Object.entries(selectSequence)[0]) {
			sequencConfig = { side: null, qty: null, dataRow: null, dataCol: null, init: false };
			sequencConfig.side = Object.entries(selectSequence)[0][0];
			sequencConfig.qty = Object.entries(selectSequence)[0][1];
			let tag = e.target.closest(".vs--markets-list-item-content-odd-item");

			if (tag) {
				sequencConfig.dataRow = Number(tag.dataset.row);
				sequencConfig.dataCol = Number(tag.dataset.col);
				sequencConfig.init = true;
			}
		}

		/** DO BET */
		handleSetKenoBalls(odd, eventInfo, currentGameType, raceFormatCount, sequencConfig, markets);
	};

	return (
		<div className="vs--markets-list vs--markets-list-keno vs--pl-24 vs--pr-24 vs--pt-24">
			<div className="vs--flex vs--flex-row vs--flex-equal vs--justify-center">
				<div className="vs--markets-list-item vs--markets-keno">
					<div className="vs--flex vs--justify-center">
						<KenoSelectSequence side="top" eventInfo={eventInfo} selectSequence={selectSequence} setSelectSequence={setSelectSequence} isOddDisabled={isOddDisabled} isDisabledSequence={isDisabledSequence} raceFormatCount={raceFormatCount} />
					</div>
					<div className="vs--markets-list-item-inner">
						<div className="vs--markets-list-item-content vs--flex vs--flex-row">
							<KenoSelectLine side="left" eventInfo={eventInfo} markets={markets} isOddDisabled={isOddDisabled} />
							<div className="vs--flex vs--flex-equal vs--flex-col">
								<div className="vs--markets-list-item-content-odd vs--flex vs--flex-col vs--flex-equal">
									{markets.reduce((acc, group, i) => {
										acc.push(
											<div key={i} className="vs--markets-keno-row vs--flex vs--justify-center vs--align-center">
												{group.map((market, j) => (
													<div
														className={
															"vs--markets-list-item-content-odd-item vs--markets-list-item-content-odd-item-keno vs--flex-inline vs--flex-col vs--align-center vs--pt-8 vs--pb-8 vs--mr-8 vs--mb-8" +
															(isOddSelected(market) ? " vs--markets-list-item-content-odd-item-selected" : "") +
															(isOddDisabled() ? " vs--markets-list-item-content-odd-item-disabled" : "")
														}
														key={market}
														data-row={i}
														data-col={j}
														onClick={(e) => handleOddClick(market, e)}
													>
														{isOddDisabled() ? (
															<div className="vs--pt-10 vs--pb-12 vs--pr-16 vs--pl-16">
																<i className="ic_lock vs--font-bigest" />
															</div>
														) : (
															<div className="vs--markets-list-item-content-odd-item-title vs--flex-equal vs--flex vs--justify-center vs--align-center" title={market}>
																<span className="vs--font-bigest">{market}</span>
															</div>
														)}
													</div>
												))}
											</div>
										);
										return acc;
									}, [])}
								</div>
								<KenoSelectLine side="bottom" eventInfo={eventInfo} markets={markets} isOddDisabled={isOddDisabled} />
								<KenoRandomSelection eventInfo={eventInfo} isOddDisabled={isOddDisabled} />
							</div>
							<KenoSelectSequence side="right" eventInfo={eventInfo} selectSequence={selectSequence} setSelectSequence={setSelectSequence} isOddDisabled={isOddDisabled} isDisabledSequence={isDisabledSequence} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

/** KenoMarkets propTypes
 * PropTypes
 */
KenoMarkets.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, keno balls bet selection */
	kenoBalls: PropTypes.shape({
		balls: PropTypes.arrayOf(PropTypes.number),
		eventInfo: eventType,
		gameType: PropTypes.number
	}),
	/** Redux action to update elected balls array */
	setKenoBalls: PropTypes.func,
	/** Redux action to clear elected balls array */
	clearKenoBalls: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType,
		session: state.auth.session,
		kenoBalls: state.betslip.kenoBalls
	};
};

const mapDispatchToProps = (dispatch) => ({
	setKenoBalls: (kenoBalls) => {
		dispatch(setKenoBalls(kenoBalls));
	},
	handleSetKenoBalls: (odd, eventInfo, currentGameType, raceFormatCount, sequencConfig, markets) => {
		dispatch(handleSetKenoBalls(odd, eventInfo, currentGameType, raceFormatCount, sequencConfig, markets));
	},
	clearKenoBalls: () => {
		dispatch(clearKenoBalls());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(KenoMarkets);
