import { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import OutsideAlerter from "components/ui/outsideAlerter";
import HotKeysGuide from "components/ui/hotKeysGuide";
import Reports from "components/reports";
import ChangePassword from "components/changePassword";
// import SettledTickets from "components/tickets/settled";
import { TICKETS_LIST_TYPE } from "constants/common.constants";
import SwitcherPendingsSettled from "../../tickets/switcherPendingsSettled";
import Results from "components/results";
import JackpotInfo from "components/jackpotInfo";

import { logout, createStreamAccess } from "store/actions/auth/auth.actions";
import { setShowTemplates } from "store/actions/templates/templates.actions";

import useIntegrationType from "hooks/useIntegrationType";

import { getUrlVars, mergeClassNames } from "utils/common";

import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_TYPE } from "constants/game.constants";

import sessionType from "types/session.type";

/* Menu Component */
const Menu = ({ logout, createStreamAccess, session, currentGameType, setShowTemplates, sessionTemplateType }) => {
	const { t } = useTranslation();
	const integrationType = useIntegrationType();
	const coordinatesRef = useRef();
	const isGoLiveMonitorDisabled = !session.games.some((game) => game.type === GAME_TYPE.ENGLISH_LEAGUE)

	/** State to show/hide menu */
	const [show, setShow] = useState(false);

	/** State to show/hide hotkeys guide popup */
	const [showHotKeysGuide, setShowHotKeysGuide] = useState(false);

	/** State to show/hide reports popup */
	const [showReports, setShowReports] = useState(false);

	/** State to show/hide change password popup */
	const [showChangePassword, setShowChangePassword] = useState(false);

	/** State to show/hide settled tickets popup */
	const [showSettledTickets, setShowSettledTickets] = useState(false);

	/** State to show/hide results popup */
	const [showResults, setShowResults] = useState(false);

	/** State to show/hide Jackpot popup */
	const [showJackpotInfo, setShowJackpotInfo] = useState(false);

	/** Function to open Stream tool in new window
	 * @function
	 * @memberOf Menu
	 */
	const openStream = () => {
		createStreamAccess(refreshToken => {
			const W = 500;
			const H = 384;

			const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;

			const left = ((screen.width) / 2) - (W / 2) + dualScreenLeft;
			const top = (screen.height / 2) - (H / 2);

			const streamData = {
				refreshToken,
				kenoGame: session.games.reduce((acc, game) => {
					if (game.type === GAME_TYPE.KENO) {
						acc.id = game.id,
							acc.type = game.type,
							acc.liveMonitorLogoURL = game.liveMonitorLogoURL
					}

					return acc;
				}, {}),
				languageFilePath: session.languageFilePath,
				languageId: session.languageId,
				liveMonitorTranslationPath: session.liveMonitorTranslationPath,
				sessionTemplateType,
				currencyDecimalCount: session?.currency?.decimalCount,
				currencySymbol: session?.currency?.symbol,
				activeJackpotId: session?.activeJackpotId
			};

			const encodedStreamData = btoa(encodeURIComponent(JSON.stringify(streamData)));

			window.open(`${import.meta.env.SYSTEM_STREAM_PAGE_URL}?data=${encodedStreamData}`, "Stream_" + Math.random(), `toolbar=no,location=no,status=no,menubar=no,titlebar=no,scrollbars=no,resizable=no,width=${W},height=${H},top=${top},left=${left}`);
		})
		setShow(false);
	}

	/** Function to open Stream tool in new window
		* @function
		* @memberOf Menu
	*/
	const openLiveMonitor = () => {
		if (isGoLiveMonitorDisabled) { return }
		createStreamAccess(refreshToken => {
			const W = 500;
			const H = 384;

			const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;

			const left = ((screen.width) / 2) - (W / 2) + dualScreenLeft;
			const top = (screen.height / 2) - (H / 2);
			const streamData = {
				refreshToken,
				games: session.games.reduce((acc, game) => {
					if (game.type === GAME_TYPE.ENGLISH_LEAGUE) {
						acc.push({
							id: game.id,
							type: game.type,
							liveMonitorLogoURL: game.liveMonitorLogoURL
						});
					}
					return acc;
				}, []),
				languageFilePath: session.languageFilePath,
				languageId: session.languageId,
				liveMonitorTranslationPath: session.liveMonitorTranslationPath,
				sessionTemplateType
			};

			const encodedStreamData = btoa(encodeURIComponent(JSON.stringify(streamData))); 

			window.open(`${import.meta.env.SYSTEM_LIVE_MONITOR_PAGE_URL}?data=${encodedStreamData}`, "Stream_" + Math.random(), `toolbar=no,location=no,status=no,menubar=no,titlebar=no,scrollbars=no,resizable=no,width=${W},height=${H},top=${top},left=${left}`);
		})
		setShow(false);
	};

	const changeTemplate = () => {
		setShowTemplates(true);
		setShow(false);
	};

	const handleSettledTicketsBtnClick = (event) => {
		coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
		setShowSettledTickets(true);
		setShow(false);
	};

	const handleReportsBtnClick = (event) => {
		coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
		setShowReports(true);
		setShow(false);
	};

	const handleResultsBtnClick = (event) => {
		coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
		setShowResults(true);
		setShow(false);
	};

	const handleJackpotBtnClick = (event) => {
		coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
		setShowJackpotInfo(true);
		setShow(false);
	}

	const handleHotkeyGuideBtnClick = (event) => {
		coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
		setShowHotKeysGuide(true);
		setShow(false);
	};

	const handleChangePasswordBtnClick = (event) => {
		coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px" };
		setShowChangePassword(true);

		setShow(false);
	};

	const isIntegrationTypeStandalone = integrationType === PROJECT_TYPE.STANDALONE;

	return (
		<Fragment>
			<OutsideAlerter callback={() => setShow(false)}>
				<Fragment>
					<div
						className={(getUrlVars()['isPreview'] ? " vs--header-section-actions-disabled" : "")}
						onClick={() => getUrlVars()['isPreview'] ? false : setShow(!show)}
					>
						{
							isIntegrationTypeStandalone
								? <i className="ic_down" />
								: <i className="ic_menu" />
						}
					</div>

					<div className={"vs--header-menu" + (!show ? " vs--header-menu-hidden" : "")}>
						<div className="vs--header-menu-item" onClick={handleSettledTicketsBtnClick}>
							<span>{t("cashier.settledTickets")}</span>
						</div>
						{isIntegrationTypeStandalone && (
							<div className="vs--header-menu-item" onClick={handleReportsBtnClick}>
								<span>{t("cashier.reports")}</span>
							</div>
						)}
						<div className="vs--header-menu-item" onClick={handleResultsBtnClick}>
							<span>{t("common.results")}</span>
						</div>
						{session.activeJackpotId && (
							<div className="vs--header-menu-item" onClick={handleJackpotBtnClick}>
								<span>{t('cashier.jackpotInfo')}</span>
							</div>
						)}
						{(session.hotKeyEnabled && currentGameType !== GAME_TYPE.KENO) && (
							<div className="vs--header-menu-item" onClick={handleHotkeyGuideBtnClick}>
								<span>{t("cashier.hotkeyGuide")}</span>
							</div>
						)}
						<div className="vs--header-menu-item" onClick={openStream}>
							<span>{t('cashier.goStream')}</span>
						</div>
						<div
							className={
								mergeClassNames(
									"vs--header-menu-item",
									isGoLiveMonitorDisabled && "vs--header-menu-item-disabled"
								)
							}
							onClick={openLiveMonitor}
						>
							<span>{t('cashier.goLiveMonitor')}</span>
						</div>
						<div className="vs--header-menu-item" onClick={changeTemplate}>
							<span>{t('cashier.change_template')}</span>
						</div>
						{isIntegrationTypeStandalone && (
							<div className="vs--header-menu-item" onClick={handleChangePasswordBtnClick}>
								<span>{t("cashier.changePassword")}</span>
							</div>
						)}
						{isIntegrationTypeStandalone && (
							<div className="vs--header-menu-item" onClick={logout}>
								<span>{t("cashier.logout")}</span>
							</div>
						)}
					</div>
				</Fragment>
			</OutsideAlerter>
			{/* { showSettledTickets && <SettledTickets onCancel={() => setShowSettledTickets(false)} />} */}
			{showSettledTickets && <SwitcherPendingsSettled coordinates={coordinatesRef.current} defaultKey={TICKETS_LIST_TYPE.SETTLED} onCancel={() => setShowSettledTickets(false)} />}
			{showResults && <Results coordinates={coordinatesRef.current} onCancel={() => setShowResults(false)} />}
			{showJackpotInfo && <JackpotInfo coordinates={coordinatesRef.current} onCancel={() => setShowJackpotInfo(false)} />}
			{showHotKeysGuide && <HotKeysGuide coordinates={coordinatesRef.current} onCancel={() => setShowHotKeysGuide(false)} />}
			<Reports coordinates={coordinatesRef.current} visible={showReports} onCancel={() => setShowReports(false)} />
			{showChangePassword && <ChangePassword coordinates={coordinatesRef.current} onCancel={() => setShowChangePassword(false)} />}
		</Fragment>
	);
};

/** Menu propTypes
 * PropTypes
 */
Menu.propTypes = {
	/** Redux action to logout */
	logout: PropTypes.func,
	/** Redux action to create stream access */
	createStreamAccess: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	// Redux action which controls the visibilty of templates component in rightbar
	setShowTemplates: PropTypes.func,
	sessionTemplateType: PropTypes.number
};

const mapStateToProps = (state) => {
	let sessionTemplateType = state.auth.session.templateType;

	if (sessionTemplateType < 1) {
		sessionTemplateType = 1;
	}

	return {
		session: state.auth.session,
		currentGameType: state.game.currentGameType,
		sessionTemplateType
	};
};

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logout());
	},
	createStreamAccess: (onSuccess) => {
		dispatch(createStreamAccess(onSuccess));
	},
	setShowTemplates: (bool) => {
		dispatch(setShowTemplates(bool));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
