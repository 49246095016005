import PropTypes from "prop-types";
import Tooltip from "components/ui/tooltip";
import { GAME_TYPE } from "constants/game.constants";
import { shift } from "@floating-ui/dom";
import eventType from "types/event.type";

const ScenesComponent = ({ event, size = "small" }) => {
	const scenes = event?.gameData?.scenes ?? [];
	const needSeparateScenes = ![GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(event.gameType);
	const sclieArg = needSeparateScenes ? [0, 6] : [0];
	return (
		<Tooltip
			overlayClassName={`vs--result-scene-tooltip${!needSeparateScenes ? " vs--result-scene-tooltip-betslip " : " "}vs--result-scene-tooltip-larg`}
			placement='left'
			middlewares={[ shift() ]}
			title={
				(scenes.length > 0)
					? (
						<div className='vs--result-scene-tooltip-container vs--flex vs--flex-col'>
							{
								needSeparateScenes && (scenes.length > 6)
									? (
										<span className='vs--result-scene-tooltip-container-fulltime vs--font-medium vs--font-exstrasmall vs--mb-8'>
											{"Fulltime"}
										</span>
									)
									: null
							}
							{
								scenes.slice(...sclieArg).map((scene, i) => (
									<div key={scene.name} className='vs--result-scene-tooltip-line'>
										<span className='vs--font-medium vs--font-smallest vs--pr-8'>{i + 1}.&nbsp;</span>
										<span className='vs--font-regular vs--font-smallest'>{scene.name}</span>
									</div>
								))
							}
							{
								needSeparateScenes && (scenes.length > 6)
									? (
										<>
											<span className='vs--result-scene-tooltip-container-overtime vs--font-medium vs--font-exstrasmall vs--mt-8 vs--mb-8'>
												{"Overtime"}
											</span>
											{
												scenes.slice(6).map((scene, i) => (
													<div key={scene.name} className='vs--result-scene-tooltip-line'>
														<span className='vs--font-medium vs--font-smallest vs--pr-8'>{i + 1 + 6}.&nbsp;</span>
														<span className='vs--font-regular vs--font-smallest'>{scene.name}</span>
													</div>
												))
											}
										</>
									)
									: null
							}
						</div>
					)
					: null
			}
		>
			<i className={"ic_scene vs--font-bigest" + (size === "big" ? " vs--result-scene vs--pr-20" : " vs--font-normal")} />
		</Tooltip>
	);
};

ScenesComponent.propTypes = {
	event: eventType,
	size: PropTypes.oneOf(["small", "big"])
};

export default ScenesComponent;
