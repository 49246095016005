import  { Component } from "react";

import { Navigate } from "react-router-dom";
import Paths from "constants/path.constants";
import { isAuthenticated } from "utils/auth";

import { getProjectType, getUrlVars } from "utils/common";

import { PROJECT_TYPE } from "constants/common.constants";

/** Hocs which allows access to page only unAuthorized users
 * @function
 * @param {Component} WrappedComponent - Component to add functionality
 */
const WithAnanymous = (WrappedComponent) => {
	class WithAnanymous extends Component {
		render() {
			if (isAuthenticated() && getProjectType() === PROJECT_TYPE.STANDALONE) {
				return <Navigate to={Paths.MAIN + (getUrlVars()["language"] ? "?language=" + getUrlVars()["language"] : "")} replace />;
			}

			return <WrappedComponent {...this.props} />;
		}
	}

	WithAnanymous.displayName = `WithAnanymous(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;
	return WithAnanymous;
};

export default WithAnanymous;
