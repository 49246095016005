import  { useState } from "react";
import PropTypes from "prop-types";
import OutsideAlerter from "components/ui/outsideAlerter";

const Dropdown = ({ content, children, className, disabled = false, childrenContainerClassname = "", ...props }) => {
	/** State to show/hide menu */
	const [show, setShow] = useState(false);
	return (
		<OutsideAlerter callback={() => setShow(false)} className={"vs--dropdown" + (show ? " vs--dropdown-active" : "") + (className ? " " + className : "")} {...props}>
			<div className="vs--dropdown-container vs--dropdown-container-content" onClick={() => !disabled && setShow((prev) => !prev)}>
				{content}
			</div>
			{show ? (
				<div
					className={`vs--dropdown-container vs--dropdown-container-children ${childrenContainerClassname}`}
					onClick={(e) => {
						e.stopPropagation();
						if (e.target.closest(".vs--dropdown-container")) {
							setShow((prev) => !prev);
						}
					}}
				>
					{children}
				</div>
			) : null}
		</OutsideAlerter>
	);
};

Dropdown.propTypes = {
	className: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string, PropTypes.number]).isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string, PropTypes.number]).isRequired,
	childrenContainerClassname: PropTypes.string
};

export default Dropdown;
