import InternalForm from "./internalForm";
import FormItem from "./formItem";
import FormButton from "./formButton";

import useForm from "hooks/form/useForm";

const Form = InternalForm;

Form.Item = FormItem;
Form.useForm = useForm;
Form.Button = FormButton;

export default Form;
