import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import ConfirmModal from "components/ui/confirm";

import { closeTicketConfirmation } from "store/actions/tickets/confirmation.actions";
import { cancelTicket, cancelBet, payoutTicket, showTicket } from "store/actions/tickets/ticket.actions";

import { numberWithSpaces } from "utils/common";

import { BET_STATE } from "constants/betslip.constants";
import { TICKET_CONFIRMATION_MODAL_TYPE, TICKET_PAYOUT_TYPE } from "constants/common.constants";

/** Confirmation Modal For Ticket Cancel/Payout */
const TicketConfirm = ({ type, ticketId, isLoading, betId, amount, jackpotLevelType, closeTicketConfirmation, cancelTicket, cancelBet, payoutTicket, showTicket }) => {
	const { t } = useTranslation();

	/** Function, fire on confirm button click
	 * @function
	 * @returns {JSX}
	 * @memberOf TicketConfirm
	 */
	const handleConfirm = () => {
		if (type === TICKET_CONFIRMATION_MODAL_TYPE.CANCEL) {
			cancelTicket(ticketId);
		} else if (type === TICKET_CONFIRMATION_MODAL_TYPE.BET_CANCEL) {
			cancelBet(ticketId, betId);
		} else if (type === TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT) {
			payoutTicket({ id: ticketId, payoutType: TICKET_PAYOUT_TYPE.BETSLIP });
		} else if (type === TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT_ALL) {
			payoutTicket({ id: ticketId, payoutType: TICKET_PAYOUT_TYPE.ALL, jackpotLevelType });
		} else if (type === TICKET_CONFIRMATION_MODAL_TYPE.JACKPOT_PAYOUT) {
			payoutTicket({ id: ticketId, payoutType: TICKET_PAYOUT_TYPE.JACKPOT, jackpotLevelType });
		}
	};

	return (
		<ConfirmModal
			contentClassName="vs--ticket-confirm"
			visible={ticketId && type ? true : false}
			onCancel={closeTicketConfirmation}
			onConfirm={handleConfirm}
			isLoading={isLoading}
			success={false}
			title={
				type === TICKET_CONFIRMATION_MODAL_TYPE.CANCEL
					? t("cashier.ticketCancelConfirmationHeader").replace("%X%", ticketId)
					: [TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT, TICKET_CONFIRMATION_MODAL_TYPE.JACKPOT_PAYOUT, TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT_ALL].includes(type)
						? t("cashier.ticketPayoutConfirmationHeader").replace("%X%", ticketId)
						: type === TICKET_CONFIRMATION_MODAL_TYPE.BET_CANCEL
							? t("cashier.betCancelConfirmationHeader").replace("%X%", betId)
							: null
			}
			subTitle={
				type === TICKET_CONFIRMATION_MODAL_TYPE.CANCEL
					? t("cashier.ticketCancelConfirmationBody")
					: type === TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT
						? t("cashier.ticketPayoutConfirmationBody").replace("%X%", numberWithSpaces(amount))
						: type === TICKET_CONFIRMATION_MODAL_TYPE.JACKPOT_PAYOUT
							? t("cashier.ticketPayoutJackpotConfirmationBody").replace("%X%", numberWithSpaces(amount))
							: type === TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT_ALL
								? t("cashier.ticketPayoutAllConfirmationBody").replace("%X%", numberWithSpaces(amount))
								: type === TICKET_CONFIRMATION_MODAL_TYPE.BET_CANCEL
									? t("cashier.betCancelConfirmationBody")
									: null
			}
		/>
	);
};

/** TicketConfirm propTypes
 * PropTypes
 */
TicketConfirm.propTypes = {
	/** Redux state property, the action type (Cancel, Payout) */
	type: PropTypes.oneOf(Object.values(TICKET_CONFIRMATION_MODAL_TYPE)),
	/** Redux state property, the ticket id */
	ticketId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Redux state property, the bet id to cancel, in case of single bet cancelation */
	betId: PropTypes.number,
	/** Redux state property, payout amount to show(in case of payout) */
	amount: PropTypes.string,
	/** Redux state property, Jackpot payout level type to show(in case of payout) */
	jackpotLevelType: PropTypes.number,
	/** Redux state property, is true when action is in progress */
	isLoading: PropTypes.bool,
	/** Redux action to close confirmation modal */
	closeTicketConfirmation: PropTypes.func,
	/** Redux action to cancel ticket */
	cancelTicket: PropTypes.func,
	/** Redux action to cancel single bet */
	cancelBet: PropTypes.func,
	/** Redux action to payout ticket */
	payoutTicket: PropTypes.func,
	/** Redux action to show ticket viewing Modal */
	showTicket: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		type: state.tickets.confirmation.type,
		ticketId: state.tickets.confirmation.ticketId,
		betId: state.tickets.confirmation.betId,
		isLoading: state.tickets.confirmation.isLoading,
		amount: state.tickets.confirmation.amount,
		jackpotLevelType: state.tickets.confirmation.jackpotLevelType
	};
};

const mapDispatchToProps = (dispatch) => ({
	closeTicketConfirmation: () => {
		dispatch(closeTicketConfirmation());
	},
	cancelTicket: (ticketId) => {
		dispatch(cancelTicket(ticketId));
	},
	cancelBet: (ticketId, betId) => {
		dispatch(cancelBet(ticketId, betId));
	},
	payoutTicket: (params) => {
		dispatch(payoutTicket(params));
	},
	showTicket: () => {
		dispatch(showTicket());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketConfirm);
