import PropTypes from "prop-types";

/** Loader Component */
const Loader = ({ full, style }) => {
	return (
		<div className="vs--loader vs--flex vs--flex-row vs--align-center vs--justify-center" style={style ? style : full ? { height: "calc(100% - 68px)", paddingBottom: 8 } : null}>
			<div className="vs--loader-inner"></div>
		</div>
	);
};

/** Loader propTypes
 * PropTypes
 */
Loader.propTypes = {
	/** Should loader be centered on screen */
	full: PropTypes.bool,
	/** Css style of loader */
	style: PropTypes.object
};

export default Loader;
