import PropTypes from "prop-types";
import { mergeClassNames } from "utils/common";

const BUTTON_TYPE_CLASSES = {
	primary: "vs--ui-button-primary",
	button: ""
};

const Button = ({
	children,
	onClick,
	className,
	type = "primary",
	htmlType,
	disabled,
	loading,
	...rest
}) => {
	return (
		<button
			type={htmlType}
			className={
				mergeClassNames(
					BUTTON_TYPE_CLASSES[type],
					loading && "vs--ui-button-loading",
					disabled && "vs--ui-button-disabled",
					className
				)
			}
			onClick={onClick}
			disabled={disabled}
			{...rest}
		>
			<div className="vs--ui-button-container">
				{loading && (
					<div className="vs--ui-loader-icon-wrapper">
						<i className="ic_loader" />
					</div>
				)}
				{children}
			</div>
		</button>
	);
};

Button.propTypes = {
	/** Fires when a button is pressed */
	onClick: PropTypes.func,
	/** Class to rewrite default styles of button */
	className: PropTypes.string,
	/** Indicates that the button is disabled */
	disabled: PropTypes.bool,
	/** Button content */
	children: PropTypes.node,
	/** Show/Hide loader icon */
	loading: PropTypes.bool,
	/** Predefined styles of button */
	type: PropTypes.string,
	/** Available values for type attribute */
	htmlType: PropTypes.string,
};


export default Button;
