const launchRetailIntegration = () => {
	window.RT_INTEGRATION?.watchElement("vs--rightbar");
};

export const startRetailIntegration = () => {
	if (document.getElementById("rt--integration-js")) {
		return launchRetailIntegration();
	}
	const script = document.createElement("script");
	script.src = import.meta.env.SYSTEM_RETAIL_INTEGRATION_SCRIPT;

	script.id = "retail--integration-js";
	script.onload = function () {
		launchRetailIntegration();
	};
	document.body.appendChild(script);
};
