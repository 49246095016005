import { Fragment, useLayoutEffect } from "react";
import FlipUnitContainer from "./flipUnitContainer";
import { useState, useRef } from "react";
import { pipe } from "utils/common";
import moment from "moment";

const run_convert_format_mutate_pipeline = pipe(
	(arg) => arg.toString(),
	(arg) => arg.padStart(2, "0"),
	(arg) => arg.split("")
);

const getDefaultDigits = (dateStr) => {
	const date = moment.utc(dateStr).local().toDate();
	const timestump = Math.floor((date.getTime() - Date.now()) / 1000);
	const needToStop = timestump < 0;
	const hours = needToStop ? 0 : Math.floor(timestump / 3600);
	const minutes = needToStop ? 0 : Math.floor((timestump - hours * 3600) / 60);
	const seconds = needToStop ? 0 : Math.floor(timestump - hours * 3600 - minutes * 60);

	// set time units
	const [hoursSingleDigit, hoursTwoDigit] = run_convert_format_mutate_pipeline(hours);
	const [minutesSingleDigit, minutesTwoDigit] = run_convert_format_mutate_pipeline(minutes);
	const [secondsSingleDigit, secondsTwoDigit] = run_convert_format_mutate_pipeline(seconds);

	return {
		hoursSingleDigit,
		hoursTwoDigit,
		minutesSingleDigit,
		minutesTwoDigit,
		secondsSingleDigit,
		secondsTwoDigit,
		needToStop,
		timestump
	};
};

const FlipClock = ({ dateStr, drowHours = false }) => {
	const stateRef = useRef();

	const stateRetVal = useState(() => {
		const { hoursSingleDigit, hoursTwoDigit, minutesSingleDigit, minutesTwoDigit, secondsSingleDigit, secondsTwoDigit } = getDefaultDigits(dateStr);
		return {
			dateStr,
			hoursSingleDigit,
			hoursTwoDigit,
			hoursShuffleSingleDigit: false,
			hoursShuffleTwoDigit: false,
			minutesSingleDigit,
			minutesTwoDigit,
			minutesShuffleSingleDigit: false,
			minutesShuffleTwoDigit: false,
			secondsSingleDigit,
			secondsTwoDigit,
			secondsShuffleSingleDigit: false,
			secondsShuffleTwoDigit: false
		};
	});

	stateRef.current = stateRetVal;
	const [state, setState] = stateRetVal;

	const updateTime = (selfIDRefObject) => {
		const [state, setState] = stateRef.current;
		// const date = moment.utc(state.dateStr).local().toDate()
		// const timestump = Math.floor((date.getTime() - Date.now()) / 1000);
		// const needToStop = timestump < 0;
		const { needToStop, hoursSingleDigit, hoursTwoDigit, minutesSingleDigit, minutesTwoDigit, secondsSingleDigit, secondsTwoDigit } = getDefaultDigits(state.dateStr);
		if (needToStop) {
			clearInterval(selfIDRefObject.timerID);
		}
		// const hours = needToStop ? 0 : Math.floor(timestump / 3600)
		// const minutes = needToStop ? 0 : Math.floor((timestump - (hours * 3600)) / 60)
		// const seconds = needToStop ? 0 : Math.floor(timestump - (hours * 3600) - (minutes * 60))

		// // set time units
		// const [hoursSingleDigit, hoursTwoDigit] = run_convert_format_mutate_pipeline(hours);
		// const [minutesSingleDigit, minutesTwoDigit] = run_convert_format_mutate_pipeline(minutes);
		// const [secondsSingleDigit, secondsTwoDigit] = run_convert_format_mutate_pipeline(seconds);

		// on hour chanage, update hours and shuffle state
		if (hoursSingleDigit !== state.hoursSingleDigit) {
			const hoursShuffleSingleDigit = !state.hoursShuffleSingleDigit;
			setState((prev) => ({
				...prev,
				hoursSingleDigit,
				hoursShuffleSingleDigit
			}));
		}
		if (hoursTwoDigit !== state.hoursTwoDigit) {
			const hoursShuffleTwoDigit = !state.hoursShuffleTwoDigit;
			setState((prev) => ({
				...prev,
				hoursTwoDigit,
				hoursShuffleTwoDigit
			}));
		}

		// on minute chanage, update minutes and shuffle state
		if (minutesSingleDigit !== state.minutesSingleDigit) {
			const minutesShuffleSingleDigit = !state.minutesShuffleSingleDigit;
			setState((prev) => ({
				...prev,
				minutesSingleDigit,
				minutesShuffleSingleDigit
			}));
		}
		if (minutesTwoDigit !== state.minutesTwoDigit) {
			const minutesShuffleTwoDigit = !state.minutesShuffleTwoDigit;
			setState((prev) => ({
				...prev,
				minutesTwoDigit,
				minutesShuffleTwoDigit
			}));
		}

		// on second chanage, update seconds and shuffle state
		if (secondsSingleDigit !== state.secondsSingleDigit) {
			const secondsShuffleSingleDigit = !state.secondsShuffleSingleDigit;
			setState((prev) => ({
				...prev,
				secondsSingleDigit,
				secondsShuffleSingleDigit
			}));
		}
		if (secondsTwoDigit !== state.secondsTwoDigit) {
			const secondsShuffleTwoDigit = !state.secondsShuffleTwoDigit;
			setState((prev) => ({
				...prev,
				secondsTwoDigit,
				secondsShuffleTwoDigit
			}));
		}
	};

	useLayoutEffect(() => {
		const timerIDObj = { timerID: null };
		timerIDObj.timerID = setInterval(updateTime, 50, timerIDObj);
		const callback = (e) => {
			switch (document.visibilityState) {
				case "visible":
					timerIDObj.timerID = setInterval(updateTime, 50, timerIDObj);
					break;
				case "hidden":
					clearInterval(timerIDObj.timerID);
					break;
				default:
					break;
			}
		};
		document.addEventListener("visibilitychange", callback);
		return () => {
			clearInterval(timerIDObj.timerID);
			document.removeEventListener("visibilitychange", callback);
		};
	}, [dateStr]);

	return (
		<div className="vs--live-monitor-flipClock">
			{drowHours ? (
				<Fragment>
					<div className="vs--live-monitor-flipClock-section">
						<FlipUnitContainer unit={"hours"} digit={state.hoursSingleDigit} shuffle={state.hoursShuffleSingleDigit} />
						<FlipUnitContainer unit={"hours"} digit={state.hoursTwoDigit} shuffle={state.hoursShuffleTwoDigit} />
					</div>
					<div className="vs--live-monitor-flipClock-middle">
						<span className="vs--live-monitor-flipClock-middle-text">:</span>
					</div>
				</Fragment>
			) : null}

			<div className="vs--live-monitor-flipClock-section">
				<FlipUnitContainer unit={"minutes"} digit={state.minutesSingleDigit} shuffle={state.minutesShuffleSingleDigit} />
				<FlipUnitContainer unit={"minutes"} digit={state.minutesTwoDigit} shuffle={state.minutesShuffleTwoDigit} />
			</div>
			<div className="vs--live-monitor-flipClock-middle">
				<span className="vs--live-monitor-flipClock-middle-text">:</span>
			</div>
			<div className="vs--live-monitor-flipClock-section">
				<FlipUnitContainer unit={"seconds"} digit={state.secondsSingleDigit} shuffle={state.secondsShuffleSingleDigit} />
				<FlipUnitContainer unit={"seconds"} digit={state.secondsTwoDigit} shuffle={state.secondsShuffleTwoDigit} />
			</div>
		</div>
	);
};

export default FlipClock;
