import  { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import withAnanymous from "hocs/withAnanymous";

import { initTranslations } from "translations/config";

import Loader from "components/ui/loader";
import LanguageSelector from "components/ui/languageSelector";
import Maintenance from "components/ui/maintenance";

import { buildPathToStaticFolderOfCDN, getUrlVars } from "utils/common";

import { getSystemLanguages } from "store/actions/auth/language.actions";

import sessionType from "types/session.type";

/** Main Layout Component */
const Layout = ({ translationsLoaded, languages, getSystemLanguages, languagesLoaded, sessionFailed, session }) => {
	/** Init default system translations */
	useEffect(() => {
		getSystemLanguages();
	}, []);

	useEffect(() => {
		if (languagesLoaded) {
			let language = getUrlVars()["language"] || "EN";
			if (!languages.map((l) => l.toLowerCase()).includes(language.toLowerCase())) {
				language = "EN";
			}

			initTranslations(null, language, null, "en", Function.prototype, Function.prototype);
		}
	}, [languagesLoaded]);

	return (
		<div className="vs--container">
			{translationsLoaded ? (
				sessionFailed && session?.maintenanceMode ? (
					<Maintenance />
				) : (
					<div className="vs--auth">
						<LanguageSelector />
						<div className="vs--auth-popup vs--flex vs--flex-col vs--justify-center vs--align-center vs--pl-40 vs--pr-40">
							<div className="vs--auth-popup-logo vs--flex vs--justify-center vs--align-center vs--mb-20">
								<img src={buildPathToStaticFolderOfCDN("cashier-images/logo.png")} alt="logo" />
							</div>
							<div className="vs--auth-form">
								<Outlet />
							</div>
						</div>
					</div>
				)
			) : (
				<Loader full={true} />
			)}
		</div>
	);
};

/** Layout propTypes
 * PropTypes
 */
Layout.propTypes = {
	/** Is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux action to login cashier */
	getSystemLanguages: PropTypes.func,
	/** Redux state property, system languages */
	languages: PropTypes.arrayOf(PropTypes.string),
	/** Redux state property, is true when system languages are loaded */
	languagesLoaded: PropTypes.bool,
	/** Is true when session failed */
	sessionFailed: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		translationsLoaded: state.auth.translationsLoaded,
		languages: state.auth.languages,
		languagesLoaded: state.auth.languagesLoaded,
		session: state.auth.session,
		sessionFailed: state.auth.sessionFailed
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSystemLanguages: () => {
		dispatch(getSystemLanguages());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withAnanymous(Layout));
