import moment from "moment";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

/** Format datetime
 * @function
 * @param {string } date - date to format
 * @returns {object} - Moment date
 */
export const formatDateTime = (date) => {
	return moment.utc(date).local().format(DATE_TIME_FORMAT);
};

/** Format date
 * @function
 * @param {string } date - date to format
 * @returns {object} - Moment date
 */
export const formatDate = (date) => {
	return moment.utc(date).local().format(DATE_FORMAT);
};

/** Format time
 * @function
 * @param {string } date - date to format
 * @returns {object} - Moment date
 */
export const formatTime = (date) => {
	return moment.utc(date).local().format(TIME_FORMAT);
};

/** Get yesterday day
 * @function
 * @returns {object} - Moment date
 */
export const yesterday = () => {
	return moment().subtract(1, "days");
};

/** Get a week ago day
 * @function
 * @returns {object} - Moment date
 */
export const weekago = (count) => {
	return moment().subtract(count, "weeks");
};

/** Get months ago day
 * @function
 * @param {number} count - mounths count
 * @returns {object} - Moment date
 */
export const monthAgo = (count) => {
	return moment().subtract(count, "months");
};

/** Get defualt dates for all the filters
 * @function
 * @description - "from" date is 24 hour ago, and "to" date is now
 * @returns {object}
 */
export const getDefaultDate = () => {
	const TIME_FROM = moment(new Date(), TIME_FORMAT);
	const TIME_TO = moment(new Date(), TIME_FORMAT);
	let from_d, to_d;
	to_d = moment(new Date(), DATE_TIME_FORMAT);
	from_d = yesterday();
	from_d.set({ hour: TIME_FROM.get("hour"), minute: TIME_FROM.get("minute") });
	to_d.set({ hour: TIME_TO.get("hour"), minute: TIME_TO.get("minute") });

	return {
		from: from_d.toDate(),
		to: to_d.toDate()
	};
};
