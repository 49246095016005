import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { ADD_PENDING, UPDATE_PENDING, UPDATE_PENDING_BET, SET_PENDINGS, REMOVE_PENDING, PENDING_BEFORE, PENDING_AFTER } from "../../actionTypes";

const setPendingBefore = () => ({
	type: PENDING_BEFORE
});

const setPendingAfter = () => ({
	type: PENDING_AFTER
});

const setPendings = (pendings) => ({
	type: SET_PENDINGS,
	payload: { pendings }
});

export const addPending = (pending) => ({
	type: ADD_PENDING,
	payload: { pending }
});

export const removePending = (pending) => ({
	type: REMOVE_PENDING,
	payload: { pending }
});

export const updatePending = (pending, updateTicketInfo) => ({
	type: UPDATE_PENDING,
	payload: { pending, updateTicketInfo }
});

export const updatePendingBet = (bet) => ({
	type: UPDATE_PENDING_BET,
	payload: { bet }
});

export const getPendingTickets = () => {
	return (dispatch) => {
		dispatch(setPendingBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PENDINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPendings(data));
				dispatch(setPendingAfter());
			})
			.catch((ex) => {
				dispatch(setPendingAfter());
			});
	};
};
