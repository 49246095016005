import  { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import OutsideAlerter from "components/ui/outsideAlerter";

import { TICKETS_LIST_TYPE } from "constants/common.constants";
import { TICKET_TYPE, TICKET_PRINT_REQUEST_STATE } from "constants/ticket.constants";
import { BET_STATE } from "constants/betslip.constants";

import { requestForTicketReprint, printTicket } from "store/actions/tickets/ticket.actions";

import historyType from "types/history.type";
import sessionType from "types/session.type";

import { mapBetToPrintingTicket } from "utils/bets";

/** Reprint Button Component */
const ReprintButton = ({ listType, ticket, requestForTicketReprint, printTicket, session }) => {
	const { t } = useTranslation();

	const [showMenu, setShowMenu] = useState(false);

	const betTicketPrintRequestState = ticket?.ticketsInfo?.betTicketPrintRequestState ?? TICKET_PRINT_REQUEST_STATE.NONE;
	const cancelTicketPrintRequestState = ticket?.ticketsInfo?.cancelTicketPrintRequestState ?? TICKET_PRINT_REQUEST_STATE.NONE;
	const payoutTicketPrintRequestState = ticket?.ticketsInfo?.payoutTicketPrintRequestState ?? TICKET_PRINT_REQUEST_STATE.NONE;

	/** Function which fires on button click
	 * @function
	 * @param {object} event - click event object
	 * @memberOf ReprintButton
	 */
	const handleButtonClick = (event) => {
		event.stopPropagation();
		setShowMenu(!showMenu);
	};

	/** Function which fires on menu item click
	 * @function
	 * @param {object} event - click event object
	 * @param {number} type - ticket type (bet/cancel/payout)
	 * @memberOf ReprintButton
	 */
	const handleMenuItemClick = (event, type) => {
		event.stopPropagation();
		let requestState = TICKET_PRINT_REQUEST_STATE.NONE;

		if (type === TICKET_TYPE.BET) {
			requestState = betTicketPrintRequestState;
		} else if (type === TICKET_TYPE.CANCEL) {
			requestState = cancelTicketPrintRequestState
		} else if (type === TICKET_TYPE.PAYOUT) {
			requestState = payoutTicketPrintRequestState;
		}

		if (requestState === TICKET_PRINT_REQUEST_STATE.NONE || requestState === TICKET_PRINT_REQUEST_STATE.DECLINED) {
			requestForTicketReprint(ticket.id, type, listType);
		} else if (requestState === TICKET_PRINT_REQUEST_STATE.APPROVED) {
			if (ticket.bets && ticket.bets.length > 0) {
				printTicket({
					...mapBetToPrintingTicket({ ...ticket, bets: ticket.bets }, type),
					isReprint: true
				});
			}
		}
		setShowMenu(false);
	};

	/** Get menu item text
	 * @function
	 * @param {number} type - ticket type (bet/cancel/payout)
	 * @returns {string}
	 * @memberOf ReprintButton
	 */
	const getMenuItemText = (type) => {
		let text = "";
		let requestState = TICKET_PRINT_REQUEST_STATE.NONE;
		if (type === TICKET_TYPE.BET) {
			text = t("bet.bet");
			requestState = betTicketPrintRequestState;
		} else if (type === TICKET_TYPE.CANCEL) {
			text = t("common.cancel");
			requestState = cancelTicketPrintRequestState;
		} else if (type === TICKET_TYPE.PAYOUT) {
			text = t("cashier.payout");
			requestState = payoutTicketPrintRequestState;
		}
		if (requestState === TICKET_PRINT_REQUEST_STATE.PENDING) {
			text += ` (${t("bet.pending")})`;
		} else if (requestState === TICKET_PRINT_REQUEST_STATE.APPROVED) {
			text += ` (${t("cashier.reprint")})`;
		}
		return text;
	};

	/** Get menu item state
	 * @function
	 * @param {number} type - ticket type (bet/cancel/payout)
	 * @returns {string}
	 * @memberOf ReprintButton
	 */
	const getMenuItemState = (type) => {
		let requestState = TICKET_PRINT_REQUEST_STATE.NONE;
		if (type === TICKET_TYPE.BET) {
			requestState = betTicketPrintRequestState;
		} else if (type === TICKET_TYPE.CANCEL) {
			requestState = cancelTicketPrintRequestState;
		} else if (type === TICKET_TYPE.PAYOUT) {
			requestState = payoutTicketPrintRequestState;
		}

		if (requestState === TICKET_PRINT_REQUEST_STATE.PENDING) {
			return "pending";
		} else if (requestState === TICKET_PRINT_REQUEST_STATE.APPROVED) {
			return "approved";
		}
		return "pending";
	};

	/** Get menu state
	 * @function
	 * @returns {string}
	 * @memberOf ReprintButton
	 */
	const getMenuState = () => {
		let betItemState = getMenuItemState(TICKET_TYPE.BET);
		let cancelItemState = null,
			payoutItemState = null;
		if (ticket.state === BET_STATE.CANCELLED && session?.cancelTicket?.printCancellationTickets) {
			cancelItemState = getMenuItemState(TICKET_TYPE.CANCEL);
		}

		if (ticket.paidoutTime && session?.payoutTicket?.printPayoutTickets) {
			payoutItemState = getMenuItemState(TICKET_TYPE.PAYOUT);
		}

		if ([betItemState, cancelItemState, payoutItemState].includes("approved")) {
			return "approved";
		} else if ([betItemState, cancelItemState, payoutItemState].includes("pending")) {
			return "pending";
		} else {
			return "";
		}
	};

	return (
		<OutsideAlerter callback={() => setShowMenu(false)}>
			<Fragment>
				<button type="button" className="vs--button vs--modal-table-button vs--reprint-button vs--font-smallest vs--font-regular" onClick={handleButtonClick} title={t("cashier.request")} data-state={getMenuState()}>
					<span>{t("cashier.request")}</span>
				</button>
				<div className={"vs--reprint-menu vs--mt-2" + (!showMenu ? " vs--reprint-menu-hidden" : "")}>
					<div className="vs--reprint-menu-item vs--pb-8 vs--pt-8 vs--pl-32 vs--pr-16" onClick={(event) => handleMenuItemClick(event, TICKET_TYPE.BET)} data-state={getMenuItemState(TICKET_TYPE.BET)}>
						<span title={getMenuItemText(TICKET_TYPE.BET)}>{getMenuItemText(TICKET_TYPE.BET)}</span>
					</div>
					{ticket.state === BET_STATE.CANCELLED && session?.cancelTicket?.printCancellationTickets && (
						<div className="vs--reprint-menu-item vs--pb-8 vs--pt-8 vs--pl-32 vs--pr-16" onClick={(event) => handleMenuItemClick(event, TICKET_TYPE.CANCEL)} data-state={getMenuItemState(TICKET_TYPE.CANCEL)}>
							<span title={getMenuItemText(TICKET_TYPE.CANCEL)}>{getMenuItemText(TICKET_TYPE.CANCEL)}</span>
						</div>
					)}
					{ticket.paidoutTime && session?.payoutTicket?.printPayoutTickets && (
						<div className="vs--reprint-menu-item vs--pb-8 vs--pt-8 vs--pl-32 vs--pr-16" onClick={(event) => handleMenuItemClick(event, TICKET_TYPE.PAYOUT)} data-state={getMenuItemState(TICKET_TYPE.PAYOUT)}>
							<span title={getMenuItemText(TICKET_TYPE.PAYOUT)}>{getMenuItemText(TICKET_TYPE.PAYOUT)}</span>
						</div>
					)}
				</div>
			</Fragment>
		</OutsideAlerter>
	);
};

/** ReprintButton propTypes
 * PropTypes
 */
ReprintButton.propTypes = {
	/** The ticket list type */
	listType: PropTypes.oneOf(Object.values(TICKETS_LIST_TYPE)),
	/** The ticket */
	ticket: historyType,
	/** Redux action to do reprint request for ticket */
	requestForTicketReprint: PropTypes.func,
	/** Redux action to print ticket */
	printTicket: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	requestForTicketReprint: (id, type, ticketType) => {
		dispatch(requestForTicketReprint(id, type, ticketType));
	},
	printTicket: (ticket) => {
		dispatch(printTicket(ticket));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ReprintButton);
