import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import moment from "moment";
import eventType from "types/event.type";

import {
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	getLastResults,
	addLastResult,
	setCurrentGame,
	getEvent,
	updateEventLiveMonitor,
	updateFromLiveAndUpcomingsLiveMonitor,
	addLiveAndUpcomingsLiveMonitor,
	getLiveAndUpcomings
} from "store/actions/game/game.actions";
import { updateSeasonMarkets, getSeasonMarkets } from "store/actions/season/season.actions";
import { getGameRTPs } from "store/actions/auth/auth.actions";

import SignalRUtils from "utils/signalR";

import { GAME_EVENT_TYPE, GAME_STATUSES, GAME_TYPE } from "constants/game.constants";
import { isSeasonGame } from "utils/common";
import useEvent from "hooks/useEvent";

/* Keno Page Wrapper Container Component - Wrapper */
const Wrapper = ({ decodedData }) => {
	const params = useParams();
	const gameType = Number(params.gameType);
	const games = decodedData?.games ?? [];
	const game = games.find((game) => game?.type === gameType);
	const dispatch = useDispatch();
	const current = useSelector((state) => state.game.current);
	const currentEvent = useSelector((state) => state.game.matches.data.find((m) => m.id === current) || null);
	const seassionGames = useSelector((state) => state.auth.session.games);
	const liveAndUpcomingEvent = useSelector((state) => {
		const liveAndUpcomings = state.game.liveAndUpcomings.data;
		return liveAndUpcomings.find((e) => e.id === current);
	});

	const seasonId = liveAndUpcomingEvent?.seasonId || null;

	const getCurrentGameRtps = useEvent(() => {
		const seassionGame = seassionGames.find((g) => g.type === gameType);
		return seassionGame ? seassionGame.rtPs : [];
	});

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Wrapper
	 */
	const handleSignalREvents = useEvent((connectionArg) => {
		SignalRUtils.getConnections().forEach((con, index) => {
			const connection = con.getConnection()
			if (connection !== connectionArg.connection) {
				return;
			}
			if ((index === 0 || index === 2) && connection.state === "Connected") {
				if (game) {
					connection.invoke("Subscribe", `Events_${game.type}_${game.id}`);
				}
			}
			connection.off("Events");
			connection.on("Events", (data) => {
				const d = JSON.parse(data);
				if (d.gameType === gameType) {
					if (!isSeasonGame(d.gameType) || d.type === GAME_EVENT_TYPE.WEEK) {
						switch (true) {
							case d.status === GAME_STATUSES.NEW:
								dispatch(addLiveAndUpcomingsLiveMonitor(d));
								break;
							default:
								dispatch(updateFromLiveAndUpcomingsLiveMonitor(d));
								break;
						}
					}

					/** If season markets are updated */
					if (isSeasonGame(d.gameType) && d.seasonId === d.id && d.markets) {
						dispatch(updateSeasonMarkets(d));
					}
				}

				dispatch(updateEventLiveMonitor(d.id, d, getCurrentGameRtps(), d.status === GAME_STATUSES.FINISHED));
			});
		});
	});

	useEffect(() => {
		dispatch(setCurrentGame(gameType));
	}, [gameType]);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		dispatch(getLiveAndUpcomings(true));
		dispatch(getLastResults(true));
		if (game?.id) {
			dispatch(getGameRTPs(game.id));
		}
		SignalRUtils.buildConnections(handleSignalREvents, true);
		return () => {
			SignalRUtils.getConnections().forEach(con => con.getConnection().connection.off("Events"))
			SignalRUtils.removeConnections()
		}
	}, [game?.id])

	/** Load match details on current match change */
	useEffect(() => {
		if (current) {
			if (!currentEvent) {
				dispatch(getEvent(current, true));
			}
		}
	}, [current]);

	useEffect(() => {
		if (seasonId && isSeasonGame(gameType)) {
			dispatch(getSeasonMarkets(seasonId, true));
		}
	}, [seasonId]);

	useEffect(() => {
		if (!liveAndUpcomingEvent) {
			return;
		}
		const startTime = liveAndUpcomingEvent.startTime;
		const eventTime = moment.utc(startTime).local();
		const currentTime = moment();
		const diffTime = eventTime - currentTime;
		const duration = moment.duration(diffTime * 1000, "milliseconds");
		const seconds = duration.asSeconds() / 1000;
		if (!game) {
			return;
		}
		const cycle = game.cycleMinutes * 60;
		if (seconds > cycle) {
			getLiveAndUpcomings(true)
		}
	}, [current]);

	return <Outlet context={game} />;
};

export default Wrapper;
