import  { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import OutsideAlerter from "components/ui/outsideAlerter";

import { getUrlVars } from "utils/common";

import { getSystemLanguages } from "store/actions/auth/language.actions";

import AllFlags from "../languageFlags";

/** Login Page Language Selector Component */
const LanguageSelector = ({ languages, languagesLoaded, getSystemLanguages }) => {
	const { t } = useTranslation();

	let language = getUrlVars()["language"] || "EN";
	if (!languages.map((l) => l.toLowerCase()).includes(language.toLowerCase())) {
		language = "EN";
	}

	const [selected, setSelected] = useState(language);
	const [opened, setOpened] = useState(false);

	/** Load system languages */
	useEffect(() => {
		if (!languagesLoaded) {
			getSystemLanguages();
		}
	}, []);

	useEffect(() => {
		if (languagesLoaded) {
			let language = getUrlVars()["language"] || "EN";
			if (!languages.map((l) => l.toLowerCase()).includes(language.toLowerCase())) {
				language = "EN";
			}
			setSelected(language);
		}
	}, [languagesLoaded]);

	/** Fires on dropdown change
	 * @function
	 * @memberOf LanguageSelector
	 */
	const handleLanguageChange = (language) => {
		setSelected(language);
		setOpened(false);
		const href = location.href.split("?")[0];
		location.replace(href + "?language=" + language);
	};

	return (
		<div className="vs--language">
			<OutsideAlerter callback={() => setOpened(false)}>
				<Fragment>
					<div className="vs--language-selected vs--flex vs--align-center vs--justify-end" onClick={() => setOpened(!opened)}>
						{AllFlags[language] ? <img src={AllFlags[language]} alt={language} /> : <span className="vs--title vs--font-small vs--font-regular">{selected}</span>}
						<i className="ic_down vs--font-bigest" />
					</div>
					{opened && (
						<div className="vs--language-dropdown vs--pt-4 vs--pb-4">
							{languages.map((language) => {
								const translation = t([`common.${language}`, language]);
								return (
									<div className={"vs--language-dropdown-item vs--pl-16 vs--pr-8 vs--flex vs--align-center" + (language === selected ? " vs--language-active" : "")} key={language} onClick={() => handleLanguageChange(language)}>
										{AllFlags[language] ? (
											<>
												<img src={AllFlags[language]} alt={language} />
												<span className="vs--title vs--font-regular">{translation}</span>
											</>
										) : (
											<span className="vs--title vs--font-regular">{translation}</span>
										)}
									</div>
								);
							})}
						</div>
					)}
				</Fragment>
			</OutsideAlerter>
		</div>
	);
};

/** LanguageSelector propTypes
 * PropTypes
 */
LanguageSelector.propTypes = {
	/** Redux state property, system languages */
	languages: PropTypes.arrayOf(PropTypes.string),
	/** Redux state property, is true when system languages are loaded */
	languagesLoaded: PropTypes.bool,
	/** Redux action to login cashier */
	getSystemLanguages: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		languages: state.auth.languages,
		languagesLoaded: state.auth.languagesLoaded
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSystemLanguages: () => {
		dispatch(getSystemLanguages());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
