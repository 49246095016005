import  { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapPenaltyLiveInfoScene, printElement } from 'utils/common';
import { GAME_TYPE } from "constants/game.constants";
import { setResultsPrinting } from 'store/actions/results/results.actions';
import { isRacingGame, isSeasonGame, GetSeasonRoundText } from 'utils/common';
import { GAME_TYPE_TEXT_KEYS } from 'constants/game.constants';
import sessionType from 'types/session.type';
import resultType from "types/result.type";
import { isCupGame, isLeagueGame } from '../../../utils/common';

/** Results print Component */
const ResultsTicket = ({
	session,
	gameType,
	resultsToPrint,
	isLoading,
	setResultsPrinting,
	onPrint
}) => {

	const { t } = useTranslation(["ticket"]);

	const datasForDrow = useMemo(() => {
		if (!isSeasonGame(gameType)) {
			return [
				{ id: null, rounds: [resultsToPrint] }
			]
		}
		if (isLeagueGame(gameType)) {
			return [
				{ id: null, rounds: resultsToPrint }
			]
		}

		return [...resultsToPrint].map(season => ({
			id: season.id,
			rounds: season.events
		}))
	}, [resultsToPrint, gameType])

	/** Print on component mount */
	useEffect(() => {
		printElement("vs--print-wrapper", () => {
			setTimeout(() => {
				setResultsPrinting(false);
				onPrint && onPrint()
			}, 0)
		})
	}, [])


	/** Function to make scenes text
		* @function
		* @param {array} participants
		* @returns {string}
		* @memberOf ResultsTicket
  */
	const makeScenesTextCode = arr => {
		let res = "";
		arr.forEach((scene, i) => {
			let sceneName = "";
			if (scene.name.includes("_NG_") && scene.name.includes("_Foul_red_")) {
				sceneName = "R";
			} else if (scene.name.includes("_NG_") && scene.name.includes("_Foul_")) {
				sceneName = "Y";
			} else if (scene.name.includes("_Penalty_")) {
				sceneName = "P";
			} else if (scene.name.includes("_G_")) {
				sceneName = "G";
			} else {
				sceneName = "X";
			}
			res += (
				gameType === GAME_TYPE.PENALTY_SHOOTOUT
					? sceneName + scene.teamNumber + (i < 2 ? "," : "")
					: scene.teamNumber + sceneName + (i < 2 ? "," : "")
			)
			
		});

		return res;
	}
	const makeScenesText = scenes => {
		let str = "";
		str += makeScenesTextCode(scenes.slice(0, 3));
		str += " - ";
		str += makeScenesTextCode(scenes.slice(3, 6));
		return str;
	}

	const makeScenesTextPenalty = scenes => {
		const data = scenes
			.reduce((acc, scene) => {
				const sceneInfo = mapPenaltyLiveInfoScene(scene);
				acc[sceneInfo.teamNo - 1].push(`R${sceneInfo.roundNo}-${sceneInfo.hasGoal ? "G" : "X"}`)
				return acc;
			}, [[], []])
			.map(scenes => scenes.join(","))
		return data;
	}
	

	/** Function to make racing result text
		* @function
		* @param {array} participants
		* @returns {string}
		* @memberOf ResultsTicket
  */
	const makeRacingResult = participants => {
		const first = participants.find(p => p.place === 1);
		const second = participants.find(p => p.place === 2);
		const third = participants.find(p => p.place === 3);
		if (!first || !second || !third) return "";
		return `#${first.number}, #${second.number}, #${third.number}`
	}

	return (
		<Fragment>
			<div id="vs--print-wrapper" className="vs--print-wrapper">
				<div className="vs--print">
					<div className="vs--print-section">
						{
							session.betTicket.ticketLogoId && (
								<div className="vs--print-header">
									<img src={`${import.meta.env.SYSTEM_CDN_URL}/${session.partnerId}/images/${session.betTicket.ticketLogoId}_ticket_logo.png`} alt="logo" />
								</div>
							)
						}
						<div className="vs--print-header-sub vs--print-section">
							<div className="vs--print-header-sub-title">
								<i className="ic_virtualSports" />
								<h1>
									{t(`common.${GAME_TYPE_TEXT_KEYS[gameType] || ""}`)}
								</h1>
							</div>
						</div>

						{
							datasForDrow.map((season, i) => {
								return (
									<Fragment key={season.id || i} >
										{
											isCupGame(gameType) && season.id
												? (
													<div className="vs--print-header-sub vs--print-header-sub-league vs--print-section">
														<div className="vs--print-header-sub-title">
															<h1>
																{`${t('common.cupId')} ${season.id}`}
															</h1>
														</div>
													</div>
												)
												: null
										}
										<div className="vs--print-table">
									
											<div className={"vs--print-table-row vs--print-table-row-result vs--print-table-row-head" + (gameType === GAME_TYPE.PENALTY_SHOOTOUT ? " vs--print-table-row-result-penalty" : "")}>
												<div className="vs--print-table-cell vs--print-table-cell-id">
													<span>ID</span>
												</div>
												<div className={"vs--print-table-cell" + (gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType) ? " vs--print-table-cell-result" : " vs--print-table-cell-result-full")}>
													<span>
														{
															[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(gameType) || isSeasonGame(gameType)
																? t("cashier.result")
																: isRacingGame(gameType)
																	? t("cashier.finalists")
																	: gameType === GAME_TYPE.KENO
																		? t("common.winningBallNumbers")
																		: ""
														}
													</span>
												</div>
												<div className="vs--print-table-cell vs--print-table-cell-scenes">
													{
														gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType)
															? (
																<span>{t("cashier.scenes")}</span>
															)
															: null
													}
													{
														gameType === GAME_TYPE.PENALTY_SHOOTOUT
															? (
																<span>{`${t('cashier.outcome')}`}</span>
															)
															: null
													}
												</div>
											</div>
										</div>
										{
											season.rounds.map((round, i) => {
												const resultsToPrint = (!isSeasonGame(gameType) ? round || [] : round?.events || [])
												return (
													<div key={i}>
														{
															isSeasonGame(gameType)
																? (
																	<div className="vs--print-table-row vs--print-table-row-result vs--print-league-round">
																		<span style={{ textAlign: 'start', textDecoration: 'underline', fontWeight: 500 }}>
																			{
																				GetSeasonRoundText(gameType, round?.orderNumber ?? 0)
																			}
																		</span>
																		{
																			isCupGame(gameType)
																				? (
																					<span>{`(${t('common.id')} ${round.id})`}</span>
																				)
																				: null
																		}
																	</div>
																)
																: null
														}
														{
															resultsToPrint.map(result => {
																let additionalInfo = null;
																const team1 = result?.gameData?.team1 ?? {};
																const team2 = result?.gameData?.team2 ?? {};

																if (isCupGame(gameType)) {
																	additionalInfo = {}
																	additionalInfo.team1OT = team1.overTimeGoalCount ?? 0;
																	additionalInfo.team2OT = team2.overTimeGoalCount ?? 0;
																	additionalInfo.OT = additionalInfo.team1OT !== null || additionalInfo.team2OT !== null
																	additionalInfo.team1PS = team1.penaltyShootoutGoalCount ?? 0;
																	additionalInfo.team2PS = team2.penaltyShootoutGoalCount ?? 0;
																	additionalInfo.PS = additionalInfo.team1PS !== null || additionalInfo.team2PS !== null
																}
																return (
																	<div key={result.id} className={"vs--print-table-row vs--print-table-row-result" + (gameType === GAME_TYPE.PENALTY_SHOOTOUT ? " vs--print-table-row-result-penalty" : "")}>
																		<div className="vs--print-table-cell vs--print-table-cell-id">
																			<span>{result.id}</span>
																		</div>
																		<div
																			className={"vs--print-table-cell" + (
																				gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType)
																					? " vs--print-table-cell-result" + (isCupGame(gameType) ? " vs--flex vs--flex-col" : "")
																					: gameType === GAME_TYPE.KENO
																						? " vs--print-table-cell-result-keno"
																						: " vs--print-table-cell-result-full"
																			)
																			}
																		>
																			<span>
																				{
																					gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType)
																						? (
																							<>
																								<span>{`${team1.countryCode ?? ""} ${team1.goalCount ?? ""} - ${team2.goalCount ?? ""} ${team2.countryCode ?? ""}`}</span>
																							</>
																						)
																						: isRacingGame(gameType)
																							? makeRacingResult(result?.gameData?.participants ?? [])
																							: gameType === GAME_TYPE.KENO
																								? (result?.gameData?.scenes ?? []).filter(sc => sc.isCompleted).map(sc => sc.number).join(",")
																								: ""
																				}

																				{
																					gameType === GAME_TYPE.PENALTY_SHOOTOUT
																						? (
																							<>
																								<span>{`${team1.countryCode ?? ""} - ${team1.goalCount ?? ""}`}</span>
																								<br/>
																								<span>{`${team2.countryCode ?? ""} - ${team2.goalCount ?? ""}`}</span>
																							</>
																							
																						)
																						: null
																				}

																			</span>
																			{
																				isCupGame(gameType) && additionalInfo
																					? (
																						<>
																							{
																								additionalInfo.OT
																									? <span>{`${t('common.OvertimeShort')}: ${additionalInfo.team1OT} - ${additionalInfo.team2OT}`}</span>
																									: null
																							}
																							{
																								additionalInfo.PS
																									? <span>{`${t('common.penaltyShoutoutShort')}: ${additionalInfo.team1PS} - ${additionalInfo.team2PS}`}</span>
																									: null
																							}
																						</>
																					)
																					: null
																			}
																		</div>
																		{
																			gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType)
																				? (
																					<div className="vs--print-table-cell vs--print-table-cell-scenes vs--flex vs--flex-col">
																						<span>{makeScenesText(result?.gameData?.scenes ?? [])}</span>
																						{
																							isCupGame(gameType) && (additionalInfo.OT || additionalInfo.PS)
																								? (
																									<span>{makeScenesTextCode((result?.gameData?.scenes ?? []).slice(6))}</span>
																								)
																								: null
																						}
																					</div>
																				) : null
																		}

																		{
																			gameType === GAME_TYPE.PENALTY_SHOOTOUT
																				? (
																					<div className="vs--print-table-cell vs--print-table-cell-penalty vs--print-table-cell-scenes vs--flex vs--flex-col">
																						{
																							makeScenesTextPenalty(result?.gameData?.scenes ?? []).map((scenes, i) => (
																								<span key={i}>{scenes}</span>
																							))
																						}
																					</div>
																				)
																				: null
																		}
																	</div>
																)
															})
														}
													</div>
												)
											})
										}
									</Fragment>
								)
							})
						}
					</div>
					{
						(gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType))
							? (
								<div className="vs--print-result-info">
									<span className='vs--print-result-info-title vs--pb-12 vs--pt-12'>{t('cashier.information')}</span>
									<div className="vs--print-result-info-inner vs--flex">
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`G = ${t('cashier.ticketGoal')}`}</span>
										</div>
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`Y = ${t('cashier.ticketYellowCard')}`}</span>
										</div>
									</div>
									<div className="vs--print-result-info-inner vs--flex">
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`P = ${t('cashier.ticketPenalty')}`}</span>
										</div>
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`R = ${t('cashier.ticketRedCard')}`}</span>
										</div>
									</div>
									<div className="vs--print-result-info-inner vs--flex">
										<div className="vs--print-result-info-cell vs--print-result-info-cell-full vs--mb-4">
											<span>{`X = ${t('cashier.noEvent')}`}</span>
										</div>
										{
											isCupGame(gameType)
												? (
													<div className="vs--print-result-info-cell vs--print-result-info-cell-full vs--print-result-info-cell-full-ot vs--mb-4">
														<span>{`OT = ${t('common.overtime')}`}</span>
													</div>
												)
												: null
										}
									</div>
									{
										isCupGame(gameType)
											? (
												<div className="vs--print-result-info-inner vs--flex">
													<div className="vs--print-result-info-cell vs--print-result-info-cell-full vs--print-result-info-cell-full-ps vs--mb-4">
														<span>{`PS = ${t('common.penaltyShootout')}`}</span>
													</div>
												</div>
											)
											: null
									}
								</div>
							)
							: null
					}
					{
						gameType === GAME_TYPE.PENALTY_SHOOTOUT
							? (
								<div className="vs--print-result-info">
									<span className='vs--print-result-info-title vs--pb-12 vs--pt-12'>{t('cashier.information')}</span>
									<div className="vs--print-result-info-inner vs--flex">
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`R = ${t('cashier.round')}`}</span>
										</div>
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`G = ${t('cashier.goal')}`}</span>
										</div>
										<div className="vs--print-result-info-cell vs--mb-4">
											<span>{`X = ${t('cashier.noGoal')}`}</span>
										</div>
									</div>
								</div>
							)
							: null
					}
				</div>
			</div>
		</Fragment>
	)
}

/** ResultsTicket propTypes
	 * PropTypes
*/
ResultsTicket.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, array of results */
	resultsToPrint: PropTypes.arrayOf(resultType),
	/** Redux state property, is true when results are loading */
	isLoading: PropTypes.bool,
	/** Function to call after Print */
	onPrint: PropTypes.func,
	/** Current game type */
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux action to show/hide printing modal */
	setResultsPrinting: PropTypes.func
}

const mapStateToProps = state => {
	return {
		session: state.auth.session,
		resultsToPrint: state.results.resultsToPrint,
		isLoading: state.results.resultsToPrintIsLoading
	}
}
const mapDispatchToProps = dispatch => (
	{
		setResultsPrinting: type => {
			dispatch(setResultsPrinting(type))
		}
	}
)

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTicket)
