import  { cloneElement } from "react";
import PropTypes from "prop-types";

import useFormItem from "hooks/form/useFormItem";

const FormItem = ({ children, name, rules, label, dependencies, valuePropName = "value", className = "", ...props }) => {
	const { value, validationState, _INTERNAL_ } = useFormItem({
		name,
		rules,
		dependencies
	});

	const { onChange } = children.props;

	const handleFieldChange = (value) => {
		_INTERNAL_.updateFieldValue({ fieldName: name, value });

		if (typeof onChange === "function") {
			onChange(value);
		}

		if (Array.isArray(rules)) {
			_INTERNAL_.updateFieldValidation({ fieldName: name, value });
		}

		if (Array.isArray(dependencies)) {
			_INTERNAL_.updateFieldDependencies(dependencies);
		}
	};

	return (
		<div className={`vs--ui-form-item ${className}`} {...props}>
			<div className="vs--ui-form-item-box">
				<div className="vs--ui-form-item-box-label">
					<label htmlFor={name}>{label}</label>
				</div>
				{cloneElement(children, {
					[valuePropName]: value,
					id: name,
					onChange: handleFieldChange,
					status: validationState.isVisible ? "error" : "normal"
				})}
			</div>
			<span className={`vs--ui-form-item-validation-message ${validationState.isVisible ? "vs--ui-form-item-validation-message-active" : ""}`}>{validationState.message}</span>
		</div>
	);
};

/** FormItem propTypes
 * PropTypes
 */
FormItem.propTypes = {
	/** Items to render */
	children: PropTypes.node,
	/** Name of field */
	name: PropTypes.string.isRequired,
	/** List of Validations (will be passed after each change) */
	rules: PropTypes.array,
	/** Label of field */
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/** List of fields name which will be revalidated after current field change */
	dependencies: PropTypes.array,
	/** If we send child elements which have a different prop value name, we must specify that name with that prop */
	valuePropName: PropTypes.string,
	/** Class to rewrite default styles */
	className: PropTypes.string
};

export default FormItem;
