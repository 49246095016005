import  { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import runMarketUtilsFunction from 'utils/markets/run';

import { GAME_TYPE, GAME_STATUSES, GAME_ACTIVITY_STATE } from 'constants/game.constants';

import { doBet } from 'store/actions/betslip/betslip.actions';

import eventType from 'types/event.type';
import betType from 'types/bet.type';
import sessionType from 'types/session.type';
import { PENALTY_SHOOTOUT_MARKETS_GROUPS, PENALTY_SHOOTOUT_MARKETS_TABS } from 'constants/market.constants';
import MainMarketContents from './tabs/mainMarketContents';
import CorrectScoreMarketContents from './tabs/correctScoreMarketContents';
import PenaltyShotOutcomeContents from './tabs/penaltyShotOutcomeContents';

/** Penalty Markets list component */
const PenaltyMarkets = ({
	eventInfo,
	doBet,
	bets,
	currentGameType,
	markets,
	session,
	activeTab,
}) => {

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf PenaltyMarkets
*/
	const isOddSelected = id => {
		return bets.some(b => b.oddId === id && b.eventId === eventInfo.id);
	}

	/** Function to check if odd is disabled
	 * @function
	 * @param {object} id
	 * @returns {boolean}
	 * @memberOf PenaltyMarkets
*/
	const isOddDisabled = odd => {
		const game = session.games.find(g => g.type === currentGameType);

		return game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfo?.status) || !odd.factor || odd.factor < 1.01;
	}

	/** Function which fires on market click
		* @function
		* @description adds bet to betslip bets list
		* @param {object} odd - odd info
		* @param {object} market - market info
		* @memberOf PenaltyMarkets
	*/
	const handleOddClick = (odd, market) => {
		if (isOddDisabled(odd)) {
			return;
		}

		const group = runMarketUtilsFunction(
			"makeGroupTitle",
			[
				{
					group: market.group,
					argument: market.argument,
					gameData: eventInfo?.gameData ?? {},
					gameType: currentGameType
				}
			],
			currentGameType
		)

		doBet(odd, group, eventInfo);
	}

	const groupedMarkets = useMemo(() => {
		// Grouping markets
		const _results = markets.reduce((acc, market) => {
			let { odds, ...other } = market;

			if (!odds.length) {
				odds = Array(Math.max(market.marketCol, market.marketsCount)).fill({});
			}
			const newMarket = odds.flat().map(odd => ({ ...odd, ...other, argument: odd.argument }))

			acc[market.group] = {
				title: runMarketUtilsFunction(
					"makeGroupTitle",
					[
						{
							group: market.group,
							argument: market.argument,
							gameData: eventInfo?.gameData ?? {},
							gameType: currentGameType
						}
					],
					currentGameType
				),
				name: market.group,
				markets: [newMarket],
				marketCol: market.marketCol,
				needSort: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE === market.group,
				gridBy: market.gridBy,
				oddBy: market.oddBy,
				argument: market.argument
			}

			return acc
		}, {})

		
		const marketsArray = Object.values(_results).reduce((acc, res) => {
			res.markets = res.markets.flat();
			acc.push(res);
			return acc
		}, [])

		let index = 0;
		const maxColCountInRow = 1

		const allTags = marketsArray.reduce((...args) => {
			const [blocks, marketsInfo, , mainArr] = args;

			// Create Array for odds container
			if (index % maxColCountInRow === 0 || !marketsInfo) {
				blocks.push([]);

				if (!marketsInfo) {
					index = 0; return blocks;
				}
			}

			// Fill Array with odds container
			const marketContainer = [];

			if (!marketsInfo.marketCol) {
				marketContainer.push([]);
			} /* only for racing */

			if (marketsInfo.needSort) {
				for (let i = 0; i < marketsInfo.marketCol; i++) {
					marketContainer.push([]);
					for (let j = 0; j < marketsInfo.markets.length; j++) {
						const odd = marketsInfo.markets[j];
						if (!odd.name) {
							continue
						}
						if (i === Number(odd.name.split(":")[0])) {
							marketContainer[marketContainer.length - 1].push(odd);
						}
					}
				}
				
			} else {
				let matrixIndex = 0;

				for (let i = 0; i < marketsInfo.markets.length; i++) {
					const odd = marketsInfo.markets[i];

					if (odd && matrixIndex % marketsInfo.marketCol === 0) {
						marketContainer.push([]);
					}

					if (!odd) {
						marketContainer.push([]);
						matrixIndex = 0;
					} else {
						marketContainer[marketContainer.length - 1].push(odd);
						matrixIndex++;
					}
				}
			}
			blocks[blocks.length - 1].push({ marketsInfo, marketContainer })
			index++;
			return blocks
		}, [])
		return allTags.reduce((acc, elems) => {
			elems.forEach(elem => {
				elem.marketsInfo && (acc[elem.marketsInfo.name] = elem)
			})
			return acc
		}, {})
	}, [markets, activeTab])

	let Component = null;
	const props = {
		groupedMarkets, isOddDisabled, isOddSelected, handleOddClick
	}
	switch (activeTab) {
		case PENALTY_SHOOTOUT_MARKETS_TABS.MAIN:
			Component = MainMarketContents;
			break;
		case PENALTY_SHOOTOUT_MARKETS_TABS.CORRECT_SCORE:
			Component = CorrectScoreMarketContents;
			break;
		case PENALTY_SHOOTOUT_MARKETS_TABS.PENALTY_SHOT_OUTCOME:
			Component = PenaltyShotOutcomeContents;
			break;
		default:
			Component = Fragment;
			break;
	}

	return (
		<div
			className={`vs--markets-list vs--flex vs--flex-col`}
			data-market-tab={activeTab}
			data-market-game={currentGameType}
		>
			<div style={{ height: "100%" }} className={'vs--event-other vs--flex vs--flex-col'}>
				{
					<Component {...props} />
				}
			</div>
		</div>
	)
}

/** PenaltyMarkets propTypes
 * PropTypes
*/
PenaltyMarkets.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Array of markets to show */
	markets: PropTypes.array,
	/** Redux state property, current session */
	session: sessionType,
	/** Active tab */
	activeTab: PropTypes.string,
}

const mapStateToProps = state => {
	return {
		bets: state.betslip.bets,
		currentGameType: state.game.currentGameType,
		session: state.auth.session
	}
}

const mapDispatchToProps = dispatch => (
	{
		doBet: (market, group, eventInfo) => {
			dispatch(doBet(market, group, eventInfo));
		}
	}
)

export default connect(mapStateToProps, mapDispatchToProps)(PenaltyMarkets);