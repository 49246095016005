import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Loader from "components/ui/loader";
import ModalWrapper from "components/modalWrapper";
import Levels from "components/jackpotInfo/levels";
import Button from 'components/ui/button';

import { createStreamAccess } from "store/actions/auth/auth.actions";
import { getActiveJackpot } from "store/actions/bonuses/jackpot.actions";

const getSessionLanguageId = (state) => state.auth.session.languageId;
const getSessionLanguageFilePath = (state) => state.auth.session.languageFilePath;
const getSessionTemplateType = (state) => {
	const sessionTemplateType = state.auth.session.templateType;

	return sessionTemplateType < 1 ? 1 : sessionTemplateType;
};
const getActiveJackpotId = (state) => state.auth.session.activeJackpotId;
const getSessionCurrencyCode = (state) => state.auth.session.currency.code;
const getSessionCurrencyDecimalCount = (state) => state.auth.session.currency.decimalCount;
const getSessionCurrencySymbol = (state) => state.auth.session.currency.symbol;
const getIsLoading = (state) => state.bonuses.jackpot.isLoading;
const getJackpotInfo = (state) => state.bonuses.jackpot.data;

const JackpotInfo = ({ coordinates, onCancel }) => {
	const { t } = useTranslation();

	const languageId = useSelector(getSessionLanguageId);
	const languageFilePath = useSelector(getSessionLanguageFilePath);
	const sessionTemplateType = useSelector(getSessionTemplateType);
	const currencyDecimalCount = useSelector(getSessionCurrencyDecimalCount);
	const currencySymbol = useSelector(getSessionCurrencySymbol);
	const currencyCode = useSelector(getSessionCurrencyCode);
	const activeJackpotId = useSelector(getActiveJackpotId);
	const isLoading = useSelector(getIsLoading);
	const jackpotInfo = useSelector(getJackpotInfo);

	const dispatch = useDispatch();

	const getJackpotInfoAction = useCallback((params) => dispatch(getActiveJackpot(params)), [activeJackpotId]);

	const openLiveInfo = () => {
		dispatch(createStreamAccess(refreshToken => {
			const W = 500;
			const H = 384;

			const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;

			const left = ((screen.width) / 2) - (W / 2) + dualScreenLeft;
			const top = (screen.height / 2) - (H / 2);

			const streamData = {
				currencyDecimalCount,
				currencySymbol,
				currencyCode,
				activeJackpotId,
				refreshToken,
				languageFilePath,
				languageId,
				sessionTemplateType
			};

			const encodedStreamData = btoa(encodeURIComponent(JSON.stringify(streamData)));

			window.open(`${import.meta.env.SYSTEM_JACKPOT_LIVE_INFO}?data=${encodedStreamData}`, "Jackpot_" + Math.random(), `toolbar=no,location=no,status=no,menubar=no,titlebar=no,scrollbars=no,resizable=no,width=${W},height=${H},top=${top},left=${left}`);
		}));
	}

	useEffect(() => {
		if (activeJackpotId && Object.keys(jackpotInfo).length === 0) {
			getJackpotInfoAction();
		}
	}, [activeJackpotId]);

	return (
		<ModalWrapper
			coordinates={coordinates}
			title={t("cashier.jackpotInfo")}
			onCancel={onCancel}
			visible={true}
			classNames={{ content: "vs--modal vs--jackpot-modal" }}
			modalContentWrapperStyles={{ height: '100%' }}
		>
			{isLoading ? <Loader style={{ height: "100%" }} /> : (
				<div className="vs--jackpot-content">
					<div className="vs--flex vs--justify-end vs--mb-32">
						<Button className="vs--button vs--font-medium vs--font-smallest vs--pl-24 vs--pr-24 vs--pt-10 vs--pb-10" type="button" onClick={openLiveInfo}>
							{t('cashier.liveInfo')}
						</Button>
					</div>
					<Levels
						data={jackpotInfo?.levels}
						currencyDecimalCount={currencyDecimalCount}
						currencySymbol={currencySymbol}
						currencyCode={currencyCode}
						isInOtherRoute={false}
					/>
				</div>
			)}
		</ModalWrapper>
	)
};

export default JackpotInfo;