import  { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { setBetslipMode } from "store/actions/betslip/betslip.actions";

import { BETSLIP_MODES } from "constants/betslip.constants";

import betType from "types/bet.type";

/* Tabs of Betslip */
const BetSlipTabs = ({ setBetslipMode, mode, bets, useBonus }) => {
	const { t } = useTranslation();

	const betsCountRef = useRef();

	/** Function to check if tab should be disabled
	 * @function
	 * @param {string} mode - multi/single
	 * @returns {boolean}
	 * @memberOf BetSlipTabs
	 */
	const isModeDisabled = (mode) => {
		const disabledModes = [];
		if (bets.filter((b) => !b.expired).length < 2) {
			disabledModes.push(BETSLIP_MODES.MULTI);
		}

		if (useBonus) {
			disabledModes.push(BETSLIP_MODES.SINGLE);
		}

		return disabledModes.includes(mode);
	};

	/** Function fires on "tab click" button clock
	 * @function
	 * @param {string} mode - multi/single
	 * @memberOf BetSlipTabs
	 */
	const onTabClick = (mode) => {
		if (!isModeDisabled(mode)) setBetslipMode(mode);
	};

	/** Set betslip mode to single if bets count is < 2 */
	useEffect(() => {
		if (bets.filter((b) => !b.isExpired).length < 2) {
			setBetslipMode(BETSLIP_MODES.SINGLE);
		} else if (useBonus) {
			setBetslipMode(BETSLIP_MODES.MULTI);
		} else if (bets.filter((b) => !b.isExpired).length === 2 && betsCountRef.current < 2) {
			if (
				bets
					.filter((b) => !b.isExpired)
					.map((b) => b.eventId)
					.filter((x, i, a) => a.indexOf(x) == i).length === bets.filter((b) => !b.isExpired).length
			) {
				setBetslipMode(BETSLIP_MODES.MULTI);
			}
		}

		betsCountRef.current = bets.length;
	}, [bets]);

	return (
		<div className="vs--betslip-tabs vs--flex vs--flex-row">
			{Object.values(BETSLIP_MODES).map((tab) => (
				<div key={tab} className={"vs--betslip-tab vs--flex vs--flex-equal vs--align-center vs--justify-center  " + (mode === tab ? "vs--betslip-tab-active" : "") + (isModeDisabled(tab) ? " vs--betslip-tab-disabled" : "")} onClick={() => onTabClick(tab)}>
					<span className="vs--title vs--font-exstrasmall vs--font-regular">{t(`bet.${tab === BETSLIP_MODES.SINGLE ? "single" : "multi"}`) + (tab === BETSLIP_MODES.SINGLE || bets.length > 1 ? " (" + bets.length + ")" : "")}</span>
				</div>
			))}
		</div>
	);
};

/** BetSlipTabs propTypes
 * PropTypes
 */
BetSlipTabs.propTypes = {
	/** Redux action to set current bet slip mode(single/multi) */
	setBetslipMode: PropTypes.func,
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	setBetslipMode: (mode) => {
		dispatch(setBetslipMode(mode));
	}
});

const mapStateToProps = state => {
	return {
		mode: state.betslip.mode,
		bets: state.betslip.bets,
		useBonus: state.bonuses.standard.useBonus
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BetSlipTabs);
