import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Flag from "components/ui/flag";
import SeasonOdd from "../odd";

import useMarketDisabledForBonus from "hooks/useMarketDisabledForBonus";

import { groupByQty } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";

import { GAME_TYPE, GAME_STATUSES, GAME_ACTIVITY_STATE } from "constants/game.constants";

import { doBet } from "store/actions/betslip/betslip.actions";

import betType from "types/bet.type";
import sessionType from "types/session.type";
import rtpType from "types/rtp.type";
import eventType from "types/event.type";

const SeasonLine = ({ eventInfo = {}, parentEvent = {}, rtPs = [], activeTab = "", bets, currentGameType, session, doBet }) => {
	const markets = useMemo(() => {
		const allMarkets = runMarketUtilsFunction(
			"makeAllMarkets",
			[
				eventInfo?.markets ?? [],
				eventInfo?.gameData ?? {},
				rtPs, 
				eventInfo?.gameType ?? 0,
				[activeTab],
				parentEvent.orderNumber
			],
			eventInfo?.gameType ?? 0
		);

		return allMarkets?.[activeTab] ?? [];
	}, [eventInfo, rtPs, activeTab]);

	const { isSingleMarketDisable } = useMarketDisabledForBonus();

	const team1 = eventInfo?.gameData?.team1;
	const team2 = eventInfo?.gameData?.team2;

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf RegularMarkets
	 */
	const isOddSelected = (id) => {
		return bets.some((b) => b.oddId === id && b.eventId === eventInfo.id);
	};

	/** Function to check if odd is disabled
	 * @function
	 * @param {object} id
	 * @returns {boolean}
	 * @memberOf RegularMarkets
	 */
	const isOddDisabled = (odd) => {
		const game = session.games.find((g) => g.type === currentGameType);

		return (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(parentEvent?.status) ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(parentEvent?.status) ||
			!odd.factor ||
			odd.factor < 1.01
		);
	};

	/** Function which fires on market click
	 * @function
	 * @description adds bet to betslip bets list
	 * @param {object} odd - odd info
	 * @param {object} market - market info
	 * @memberOf RegularMarkets
	 */
	const handleOddClick = (odd, market) => {
		if (isOddDisabled(odd)) {
			return;
		}

		const group = runMarketUtilsFunction(
			"makeGroupTitle",
			[
				{
					group: market.group,
					argument: market.argument,
					gameData: eventInfo?.gameData ?? {},
					gameType: currentGameType
				}
			],
			currentGameType
		);

		doBet(odd, group, eventInfo);
	};

	return (
		<>
			{!(team1?.countryCode && team2?.countryCode) ? (
				<Fragment />
			) : (
				<div className="vs--markets-table-row vs--flex vs--align-center">
					<div className="vs--flex vs--markets-table-cell-fixed vs--align-center vs--justify-left">
						<div className="vs--markets-table-number vs--flex vs--align-center vs--justify-center vs--font-normal vs--font-medium vs--pl-16 vs--pr-12 ">{eventInfo?.orderNumber ?? 0}</div>
						<div className="vs--flex-col vs--align-center vs--justify-center vs--market-match">
							<div className="vs--flex vs--align-center vs--justify-center vs--mb-8">
								<span className="vs--pr-4">
									<Flag code={team1?.flagCode ?? ""} gameType={eventInfo?.gameType} />
								</span>
								<span className="vs--font-exstrasmall vs--font-medium">{team1?.countryCode}</span>
								<span className="vs--pl-16 vs--pr-16">VS</span>
								<span className="vs--font-exstrasmall vs--font-medium">{team2?.countryCode}</span>
								<span className="vs--pl-4">
									<Flag code={team2?.flagCode ?? ""} gameType={eventInfo?.gameType} />
								</span>
							</div>
							<div className="vs--flex vs--align-center vs--justify-center">
								<span className="vs--font-smallest vs--font-regular">{eventInfo?.id}</span>
							</div>
						</div>
					</div>
					{markets.map((market) => {
						const oddsArr = market.odds.flat();

						return (
							<Fragment key={market.group + market.argument}>
								{groupByQty(oddsArr.length ? oddsArr : Array.from({ length: market.marketCol }, () => ({ factor: 0 })), ["HalfMatch", "CorrectScore6Goals"].includes(market.group) ? 1 : 3).map((oddsGroup, i) => (
									<div key={market.group + market.argument + i} className="vs--markets-table-cell vs--flex vs--align-center vs--pl-16 vs--pr-16 vs--markets-border" data-col={market.marketCol} data-market={market.group}>
										{oddsGroup.map((odd, i) => {
											return <SeasonOdd key={market.group + i} odd={odd} market={market} isOddSelected={isOddSelected} isOddDisabled={isOddDisabled} handleOddClick={handleOddClick} isDisabledForBonus={isSingleMarketDisable(odd.factor)} />;
										})}
									</div>
								))}
							</Fragment>
						);
					})}
				</div>
			)}
		</>
	);
};

SeasonLine.propTypes = {
	/** Active tab */
	activeTab: PropTypes.string,
	/** Current event info */
	eventInfo: eventType,
	/** Week info of events */
	parentEvent: eventType,
	/** RTPS of current game */
	rtPs: PropTypes.arrayOf(rtpType),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, current session */
	session: sessionType,
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func
};
const mapStateToProps = (state) => {
	return {
		bets: state.betslip.bets,
		currentGameType: state.game.currentGameType,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	doBet: (market, group, eventInfo) => {
		dispatch(doBet(market, group, eventInfo));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonLine);
