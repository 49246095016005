import PropTypes from "prop-types";

import countryType from "./country.type";

const footballDataType = PropTypes.shape({
	team1: countryType,
	team2: countryType,
	scenes: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			time: PropTypes.string
		})
	)
});

const racingDataType = PropTypes.shape({
	participants: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			jockeyName: PropTypes.string,
			trainer: PropTypes.string,
			age: PropTypes.number,
			place: PropTypes.number
		})
	),
	venue: PropTypes.string
});

export default PropTypes.oneOfType([footballDataType, racingDataType]);
