import PropTypes from "prop-types";
import { flagCodeMapper } from "utils/flags";
import { buildPathToStaticFolderOfCDN } from "utils/common";
import { GAME_TYPE } from "constants/game.constants";

/** Flag Component */
const Flag = ({ code, gameType = GAME_TYPE.FOOTBALL_SINGLE_MATCH }) => {
	const mappedFlagCode = flagCodeMapper(gameType, code);
	const img = buildPathToStaticFolderOfCDN(`images/flags/${mappedFlagCode}`);
	return (
		<div className="vs--flag">
			<div
				className="vs--flag-icon"
				style={
					mappedFlagCode
						? {
								backgroundImage: `url(${img})`
							}
						: {}
				}
			/>
		</div>
	);
};

/** Flag propTypes
 * PropTypes
 */
Flag.propTypes = {
	/** Country code of flag */
	code: PropTypes.string,
	/** Game type wich flag need to show */
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

export default Flag;
