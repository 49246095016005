import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalWrapper from "components/modalWrapper";
import Button from 'components/ui/button';

import { showTicket } from "store/actions/tickets/ticket.actions";

import { mergeClassNames } from "utils/common";

import { JACKPOT_LEVELS_ICONS, JACKPOT_LEVELS_NAMES } from "constants/bonus.constants";

const getSessionCurrencySymbol = (state) => state.auth.session.currency.symbol;

const WonJackpotsNotification = ({ coordinates, wonJackpots, onCancel }) => {
	const { t } = useTranslation();
	const currencySymbol = useSelector(getSessionCurrencySymbol);

	const sortedWonJackpots = wonJackpots.sort((item1, item2) => item1.levelType - item2.levelType);

	const dispatch = useDispatch();

	return (
		<ModalWrapper
			visible={true}
			classNames={{ content: mergeClassNames("vs--modal vs--won-jackpots-notification", `vs--won-jackpots-notification-${sortedWonJackpots.at(-1).levelType}`) }}
			onCancel={onCancel}
			coordinates={coordinates}
			footer={null}
			centered={true}
		>
			<div className="vs--won-jackpots-notification-content vs--flex vs--flex-col vs--align-center">
				<span className="vs--won-jackpots-notification-content-title vs--font-bold vs--fs-28">{t('cashier.wonJackpotTicket')}</span>
				<div className="vs--won-jackpots-notification-content-levels vs--flex">
					{sortedWonJackpots.map((jackpot, index) => (
						<div
							key={jackpot.levelType}
							className={mergeClassNames(
								"vs--won-jackpots-notification-content-levels-level vs--flex vs--flex-col vs--align-center",
								`vs--won-jackpots-notification-content-levels-level-${jackpot.levelType}`,
								index > 0 && index < sortedWonJackpots.length && "vs--won-jackpots-notification-content-levels-level-divider"
							)}
						>
							<span className="vs--won-jackpots-notification-content-levels-level-pool-name vs--font-regular vs--font-exstrasmall">
								{jackpot.poolName || t(JACKPOT_LEVELS_NAMES[jackpot.levelType])}
							</span>
							<img className="vs--won-jackpots-notification-content-levels-level-icon" src={JACKPOT_LEVELS_ICONS[`level-${jackpot.levelType}`]} />
							<span className="vs--won-jackpots-notification-content-levels-level-amount vs--font-normal vs--font-regular">
								{jackpot.amount}&nbsp;{currencySymbol}
							</span>
						</div>
					))}
				</div>
				<Button
					className="vs--button vs--font-medium vs--font-smallest vs--pl-24 vs--pr-24 vs--pt-10 vs--pb-10"
					type="button"
					onClick={() => {
						dispatch(showTicket());
						onCancel();
					}}
				>
					{t('cashier.viewTicket')}
				</Button>
			</div>
		</ModalWrapper>
	)
};

export default WonJackpotsNotification;