import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import ConfettiAnimation from 'components/pages/jackpotLiveInfo/animations/confetti';
import JackpotWin from 'components/pages/jackpotLiveInfo/animations/jackpotWin';

import { useOutletContext } from "react-router";

import { JACKPOT_ANIMATIONS_TIMEOUTS } from "constants/bonus.constants";

const getWonJackpots = (state) => state.bonuses.jackpot.wonJackpots;

const JackpotAnimations = ({ getMainContainer }) => {
	const [animateableJackpot, setAnimatableJackpot] = useState(null);
	const [showCanfetti, setShowCanfetti] = useState(false);

	const timeoutIdsRef = useRef([]);
	const multiplierRef = useRef(0);
	const msDifferenceRef = useRef(0);

	const decodedData = useOutletContext();

	const wonJackpots = useSelector(getWonJackpots);

	useEffect(() => {
		const startedDate = new Date();
		const duration = (
			JACKPOT_ANIMATIONS_TIMEOUTS.CLOSING_SVG +
			JACKPOT_ANIMATIONS_TIMEOUTS.INACTIVE +
			JACKPOT_ANIMATIONS_TIMEOUTS.LEVELS_BETWEEN_DELAY
		);

		wonJackpots.forEach((jackpot, index) => {
			const timeout = (multiplierRef.current + index) * duration - msDifferenceRef.current;

			const timeoutId = setTimeout(() => {
				setAnimatableJackpot(jackpot);

				timeoutIdsRef.current.shift();
			}, timeout);

			timeoutIdsRef.current.push(timeoutId);
		});

		return () => {
			if (timeoutIdsRef.current.length > 0) {
				multiplierRef.current += timeoutIdsRef.current.length + 1;
				msDifferenceRef.current = new Date() - startedDate;
			}
			else {
				multiplierRef.current = 0;
				msDifferenceRef.current = 0;
			}
		}
	}, [wonJackpots]);

	useEffect(() => {
		return () => {
			timeoutIdsRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
		}
	}, []);

	return (
		<>
			<ConfettiAnimation
				animateableJackpot={animateableJackpot}
				showCanfetti={showCanfetti}
				wonJackpots={wonJackpots}
			/>
			<JackpotWin
				getMainContainer={getMainContainer}
				animateableJackpot={animateableJackpot}
				currencySymbol={decodedData?.currencySymbol}
				setAnimationFinish={() => {
					setShowCanfetti(false);
					setAnimatableJackpot(null);
				}}
				setShowCanfetti={setShowCanfetti}
			/>
		</>
	)
};

export default JackpotAnimations;