import { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import Modal from "components/ui/modal";

import Loader from "components/ui/loader";

import { mergeClassNames } from "utils/common";

/** Confirmation Modal Component */
const ConfirmModal = ({ contentClassName, visible, onCancel, onConfirm, isLoading, success, title, subTitle, extraButton, declineText, confirmText, ...otherProps }) => {
	const { t } = useTranslation();

	/** Function, fire on extra button click
	 * @function
	 * @returns {JSX}
	 * @memberOf ConfirmModal
	 */
	const handleExtraButtonClick = () => {
		onCancel();
		extraButton && extraButton.onClick && extraButton.onClick();
	};

	return (
		<Modal
			coordinates={{}}
			isOpen={visible}
			onCancel={onCancel}
			classNames={{
				content: mergeClassNames("vs--confirm vs--confirm-content", contentClassName),
				outside: "vs--confirm-outside",
				header: "vs--confirm-header",
				body: "vs--confirm-body"
			}}
			onOutsideClick={() => { }}
			closeIcon={null}
			{...otherProps}
		>
			<Fragment>
				<i className="ic_close vs--font-exstrasmall vs--confirm-close" onClick={onCancel} />
				{!isLoading ? (
					!success ? (
						<div className="vs--confirm-inner vs--pt-26 vs--text-center">
							<b className="vs--confirm-title vs--font-small vs--font-condensed vs--font-medium vs--text-center vs--mb-8 vs--mt-16">{title}</b>
							{subTitle && <span className="vs--confirm-desc vs--font-smallest vs--text-center">{subTitle}</span>}
							<div className="vs--confirm-buttons vs--flex vs--align-center vs--justify-center vs--mt-32">
								<button type="button" className="vs--button vs--button-modal vs--mr-8 vs--confirm-cancel" onClick={onCancel} title={declineText ? declineText : t("cashier.close")}>
									{declineText ? declineText : t("cashier.close")}
								</button>
								{extraButton ? (
									<button type="button" className="vs--button vs--button-modal vs--confirm-extra" onClick={handleExtraButtonClick} title={extraButton.text}>
										{extraButton.text}
									</button>
								) : null}
								<button type="button" className="vs--button vs--button-modal vs--ml-8 vs--confirm-confirm" onClick={onConfirm} title={confirmText ? confirmText : t("cashier.confirm")}>
									{confirmText ? confirmText : t("cashier.confirm")}
								</button>
							</div>
						</div>
					) : (
						<div className="vs--modal-success vs--pt-34 vs--pb-34 vs--text-center">
							<i className="ic_check" />
							<span className="vs--title vs--font-small vs--mt-8 vs--text-center vs--font-bold">{t("cashier.success")}</span>
						</div>
					)
				) : (
					<Loader />
				)}
			</Fragment>
		</Modal>
	);
};

/** ConfirmModal propTypes
 * PropTypes
 */
ConfirmModal.propTypes = {
	/** Modal Content className */
	contentClassName: PropTypes.string,
	/** If true, modal will be visible */
	visible: PropTypes.bool,
	/** Will fire on modal cancelation */
	onCancel: PropTypes.func,
	/** Will fire on modal confirm button click */
	onConfirm: PropTypes.func,
	/** If true, will show loader */
	isLoading: PropTypes.bool,
	/** If true, will success message */
	success: PropTypes.bool,
	/** Modal title */
	title: PropTypes.string,
	/** Modal subTitle */
	subTitle: PropTypes.string,
	/** Modal extra button */
	extraButton: PropTypes.shape({
		/** Modal extra button text*/
		text: PropTypes.string,
		/** Will fire on modal extra button click */
		onClick: PropTypes.func
	}),
	/** Confirm button text*/
	confirmText: PropTypes.string,
	/** Decline button text*/
	declineText: PropTypes.string
};

export default ConfirmModal;
