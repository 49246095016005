import { useSelector } from "react-redux";
import { getProjectType, getUrlVars } from "../utils/common";

const getIntegrationType = (state) => state.auth.session.integrationType;

const useIntegrationType = () => {
	if (!getUrlVars()["isPreview"]) {
		return getProjectType();
	}
	return useSelector(getIntegrationType);
};

export default useIntegrationType;
