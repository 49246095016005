export const COLLAPSE_MODE = {
	ACCORDION: "accordion",
	COLLAPSE: "collapse"
};

export const EXPAND_ICON_POSITION = {
	START: "start",
	END: "end"
};

export const DOTS = "...";

export const LEFT = "left";
export const RIGHT = "right";

export const DEFAULT_PAGE_SIZE_OPTIONS = [20, 50, 100, 200, 300, 500];
export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PAGE = 1;

//------------------------------------FORM--------------------------------//

export const VALIDATION_INITIAL_STATE = {
	isVisible: false,
	message: ""
};

export const VALIDATION_TYPES = {
	REQUIRED: "required",
	PATTERN: "pattern"
};

export const PREDEFINED_VALIDATIONS = {
	[VALIDATION_TYPES.REQUIRED]: function ({ value, message, whitespace }) {
		let fieldValue = value;

		if (whitespace) {
			fieldValue = value.trim();
		}

		if (!fieldValue && fieldValue !== 0) {
			return Promise.reject(message);
		}

		return Promise.resolve();
	},
	[VALIDATION_TYPES.PATTERN]: function ({ value, message, pattern }) {
		if (value === "") {
			return Promise.resolve();
		}

		if (pattern.test(value)) {
			return Promise.resolve();
		}

		return Promise.reject(message);
	}
};

export const SLIDER_RANGE_MAX_VALUE = 10;

//------------------------------------------------------------------------//
