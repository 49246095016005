import PropTypes from "prop-types";
import { connect } from "react-redux";
import runMarketUtilsFunction from "utils/markets/run";
import RegularMarkets from "../../markets/regular";
import rtpType from "types/rtp.type";
import eventType from "types/event.type";

const RoundSpecial = ({ activeTab, parentEvent, rtPs }) => {
	const allMarkets = runMarketUtilsFunction(
		"makeAllMarkets",
		[
			parentEvent?.markets ?? [],
			parentEvent?.gameData ?? {},
			rtPs,
			parentEvent?.gameType ?? 0,
			[activeTab]
		],
		parentEvent?.gameType ?? 0
	);

	const markets = allMarkets?.[activeTab] ?? [];
	return (
		<div className="vs--flex vs--align-center vs--pl-32 vs--pt-12">
			<RegularMarkets markets={markets} eventInfo={parentEvent} activeTab={activeTab} />
		</div>
	);
};

RoundSpecial.propTypes = {
	/** Active tab */
	activeTab: PropTypes.string,
	/** Current event info */
	eventInfos: PropTypes.arrayOf(eventType),
	/** Week info of events */
	parentEvent: eventType,
	/** RTPS of current game */
	rtPs: PropTypes.arrayOf(rtpType)
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslip.bets,
		currentGameType: state.game.currentGameType,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RoundSpecial);
