import { useLayoutEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const createWrapperAndAppendToBody = (wrapperId) => {
	const wrapperElement = document.createElement("div");
	wrapperElement.setAttribute("id", wrapperId);
	document.body.appendChild(wrapperElement);
	return wrapperElement;
};

const PortalWrapper = ({ children, className = "", wrapperId = "modal" }) => {
	const element = useMemo(() => {
		const element = document.createElement("div");
		if (className !== "") {
			element.classList.add(className);
		}
		return element;
	}, [className]);

	useLayoutEffect(() => {
		let rootElement = document.getElementById(wrapperId);

		if (!rootElement) {
			rootElement = createWrapperAndAppendToBody(wrapperId);
		}
		rootElement.appendChild(element);
		/* Hide page scroll when modal is opened */
		document.body.classList.add("vs--hide-overflow");

		return () => {
			if (rootElement) {
				rootElement.removeChild(element);
				if (rootElement.children.length === 0) {
					document.body.removeChild(rootElement);
					/* Show page scroll when modal is closed */
					document.body.classList.remove("vs--hide-overflow");
				}
			}
		};
	}, [element, wrapperId]);

	return createPortal(children, element);
};

PortalWrapper.propTypes = {
	/** React property, some jsx that will be rendered in wrapper element */
	children: PropTypes.node,
	/** React propery, wrapper element id attribute */
	wrapperId: PropTypes.string,
	/** React propery, className for wrapperElement items */
	className: PropTypes.string
};

export default PortalWrapper;
