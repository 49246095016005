import  { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Form from "components/ui/form";
import Button from "components/ui/button";
import Input from "components/ui/input";

import { required } from "utils/common";
import { redirectToPage } from "utils/navigate";

import PATHS from "constants/path.constants";

import { loginCashier } from "store/actions/auth/auth.actions";

/** Login page Component */
const LoginPage = ({ loginCashier, isLoginLoading }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [formInstance] = Form.useForm();

	const { executeRecaptcha } = useGoogleReCaptcha();

	/** Fires when form submitted
	 * @function
	 * @memberOf LoginPage
	 */
	const handleForm = () => {
		formInstance
			.validateFields()
			.then(({ userName, password }) => {
				if (import.meta.env.MODE === "development") {
					loginCashier(userName, password, "34f530b1728d4b28a5d1e39bb92be946", navigate);
				} else {
					executeRecaptcha().then((token) => {
						loginCashier(userName, password, token, navigate);
					});
				}
			})
			.catch(Function.prototype);
	};

	return (
		<Fragment>
			<b className="vs--auth-popup-title vs--title vs--font-medium vs--text-center">{t("cashier.signIn")}</b>

			<Form form={formInstance}>
				<Form.Item label="Username" name="userName" rules={[required(t("cashier.usernameRequired"))]}>
					<Input placeholder={t("cashier.username")} />
				</Form.Item>

				<Form.Item label="Password" name="password" rules={[required(t("cashier.passwordRequired"))]}>
					<Input.Password placeholder={t("cashier.password")} />
				</Form.Item>

				<div className="vs--auth-links vs--mt-8">
					<span onClick={() => redirectToPage(navigate, PATHS.FORGOT_PASSWORD)}>{t("cashier.forgotPassword")}?</span>
				</div>

				<Button htmlType="submit" className="vs--button vs--font-small vs--font-bold vs--pt-12 vs--pb-12" onClick={handleForm} disabled={isLoginLoading} loading={isLoginLoading}>
					{t("cashier.signIn")}
				</Button>
			</Form>
		</Fragment>
	);
};

/** LoginPage propTypes
 * PropTypes
 */
LoginPage.propTypes = {
	/** Redux action to login cashier */
	loginCashier: PropTypes.func,
	/** Redux state property, is true when login request is in process */
	isLoginLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	loginCashier: (userName, password, token, navigate) => {
		dispatch(loginCashier(userName, password, token, navigate));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoginLoading: state.auth.isLoginLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
