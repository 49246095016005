import PropTypes from "prop-types";

import TileGroup from "components/ui/datePicker/calendar/tileGroup";
import Decade from "components/ui/datePicker/calendar/centuryView/decade";

import { getCenturyStart, getCenturyEnd, getDecadeStart } from "utils/calendar";

const CenturyView = ({ selectedDate, ...props }) => {
	const start = getCenturyStart(selectedDate).getFullYear() - 10;
	const end = getCenturyEnd(selectedDate).getFullYear() + 10;

	return (
		<div className="vs--ui-calendar-century-view">
			<TileGroup
				{...props}
				className="vs--ui-calendar-century-view-decades"
				selectedDate={selectedDate}
				dateTransform={(year) => {
					const date = new Date();
					date.setFullYear(year);

					return getDecadeStart(date);
				}}
				dateType="decade"
				end={end}
				start={start}
				step={10}
				child={Decade}
			/>
		</div>
	);
};

CenturyView.propTypes = {
	selectedDate: PropTypes.object.isRequired,
	hover: PropTypes.object,
	locale: PropTypes.string,
	maxDate: PropTypes.object,
	minDate: PropTypes.object,
	onClick: PropTypes.func,
	onMouseOver: PropTypes.func,
	value: PropTypes.object
};

export default CenturyView;
