import PropTypes from "prop-types";
import eventType from "types/event.type";
import { useTranslation } from "react-i18next";

import Tooltip from "components/ui/tooltip";

const classesBySide = {
	top: ["vs--flex vs--pt-8 vs--pb-8 vs--pr-30 vs--pl-30", "vs--flex vs--pt-8 vs--pb-8 vs--pr-36 vs--pl-36", "vs--flex vs--pt-8 vs--pb-8 vs--pr-44 vs--pl-44", "vs--flex vs--pt-8 vs--pb-8 vs--pr-36 vs--pl-36"],
	right: ["vs--pt-16 vs--pb-16 vs--pr-8 vs--pl-8", "vs--pt-18 vs--pb-18 vs--pr-8 vs--pl-8", "vs--pt-22 vs--pb-22 vs--pr-8 vs--pl-8", "vs--pt-18 vs--pb-18 vs--pr-8 vs--pl-8"]
};

const buttonsGroups = [2, 3, 4, 5];

/** Keno Market Line Selection component*/
const KenoSelectSequence = ({ side, selectSequence, setSelectSequence, isOddDisabled, isDisabledSequence, raceFormatCount = 10 }) => {
	return (
		<div className={`vs--markets-keno-selection ${side === "top" ? "vs--flex vs--mb-16" : "vs--ml-6"}`}>
			{buttonsGroups.map((buttonsGroup, index) => {
				const disabled = isOddDisabled() || isDisabledSequence(buttonsGroup);

				const { t } = useTranslation();

				return (
					<Tooltip title={`${disabled ? `${t("cashier.max_selection")} ${raceFormatCount}` : ""}`} placement={side} overlayClassName="vs--ui-tooltip-warning" key={buttonsGroup}>
						<div
							className={`vs--markets-keno-selection-num vs--markets-keno-selection-num-${side} ${classesBySide[side][index]}` + (!disabled && selectSequence[side] === buttonsGroup ? " vs--markets-keno-selection-num-active" : "") + (disabled ? " vs--markets-keno-selection-num-disabled" : "")}
							onClick={() => {
								if (disabled) {
									return;
								}

								if (selectSequence[side] === buttonsGroup) {
									return setSelectSequence({});
								}

								setSelectSequence({ [side]: buttonsGroup });
							}}
						>
							{Array.from({ length: buttonsGroup }, (_, i) => (
								<div key={i} className={`vs--markets-keno-selection-num-sphere vs--flex vs--align-center vs--justify-center ${side === "top" ? "vs--mr-8" : "vs--mb-8"}`}>
									<span>{i + 1}</span>
								</div>
							))}
						</div>
					</Tooltip>
				);
			})}
		</div>
	);
};

/** KenoSelectLine propTypes
 * PropTypes
 */
KenoSelectSequence.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** React property, component render style */
	side: PropTypes.oneOf(["top", "right"]).isRequired,
	/** React state from parent, related sequence data */
	selectSequence: PropTypes.object,
	/** React set state function from parent, related sequence data */
	setSelectSequence: PropTypes.func,
	/** Redux state property, keno balls bet selection */
	kenoBalls: PropTypes.shape({
		balls: PropTypes.arrayOf(PropTypes.number),
		eventInfo: eventType,
		gameType: PropTypes.number
	}),
	/** React property function, is odds disabled */
	isOddDisabled: PropTypes.func,
	/** React property function, is sequence disabled */
	isDisabledSequence: PropTypes.func,
	// Current event format should be displayed
	raceFormatCount: PropTypes.number
};

export default KenoSelectSequence;
