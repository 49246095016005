import i18n from "i18next";
import store from "store/configureStore";

import { setAlertMessage } from "store/actions/common/common.actions";
import { setNoGameFound } from "store/actions/auth/auth.actions";

import { RESPONSE_STATE } from "constants/response.constants";

/** Show error notification
 * @function
 * @param {string} msg
 * @param {string} status
 * @param {string} resource
 */
export const showError = ({ msg, status, resource, duration }) => {
	if (status === RESPONSE_STATE.NOT_FOUND && (resource === "BetShop_Box" || (resource === "Game" && msg === "Blocked"))) {
		return store.dispatch(setNoGameFound(true));
	}

	if (!status) return store.dispatch(setAlertMessage(msg, "error", duration));

	let text = i18n.t(`errors.message.${status}`);
	const res = resource && resource !== "None" ? i18n.t(`errors.resources.${resource}`) : "";
	if (text) {
		text = text.replace("%resource%", res);
	}
	text = text || "";
	if (status === "InvalidParameters" && resource === "Operator") {
		text = i18n.t(`errors.message.${msg}`);
	}
	if (status === "AlreadyLogged") {
		text = msg;
	}

	store.dispatch(setAlertMessage(text, "error", duration));
};

/** Show success notification
 * @function
 * @param {string} msg
 */
export const showSuccess = (msg, duration) => {
	return store.dispatch(setAlertMessage(msg, "success", duration));
};

/** Show warning notification
 * @function
 * @param {string || jsx} msg
 */
export const showWarning = (msg, duration) => {
	return store.dispatch(setAlertMessage(msg, "warning", duration));
};

export const showMessageAlert = (msg, duration, action = "info") => {
	return store.dispatch(setAlertMessage(msg, action, duration));
};
