import  { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const CollapseRenderer = ({ content, isRowExpanded, expandIconPosition, showArrow, handleCollapseChange, rowId, header, expandIconRenderer }) => {
	const collapseContentRef = useRef(null);
	const isRowAlreadyExpandedRef = useRef(isRowExpanded);
	const isFirstRender = useRef(true);

	const setHeightToCollapseContent = useCallback((contentHeight) => {
		collapseContentRef.current.style.height = contentHeight;
	}, []);

	const resetCollapseContentStyles = () => {
		if (!isRowExpanded) {
			return;
		}

		setHeightToCollapseContent("");
		collapseContentRef.current.style.overflow = "";
	};

	const showHideCollapseContent = () => {
		setHeightToCollapseContent(`${collapseContentRef.current.scrollHeight}px`);
		handleCollapseChange(rowId);
	};

	// Update height on collapse change
	useEffect(() => {
		const contentHeight = isRowExpanded ? collapseContentRef.current.scrollHeight : 0;

		if (!isRowExpanded && !isFirstRender.current) {
			setHeightToCollapseContent(`${collapseContentRef.current.scrollHeight}px`);
		}

		isFirstRender.current = false;

		const timeoutId = setTimeout(() => {
			setHeightToCollapseContent(`${contentHeight}px`);
			collapseContentRef.current.style.overflow = "hidden";
		}, 0);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [isRowExpanded, setHeightToCollapseContent]);

	if (isRowExpanded) {
		isRowAlreadyExpandedRef.current = true;
	}

	return (
		<div className="vs--ui-collapse-wrapper">
			<div className={`vs--ui-collapse-header ${expandIconPosition}`} onClick={showHideCollapseContent} aria-expanded={isRowExpanded}>
				{showArrow && <div className={`vs--ui-header-icon ${isRowExpanded ? "vs--ui-header-icon-active" : ""}`}>{typeof expandIconRenderer === "function" ? expandIconRenderer() : <i className="ic_down vs--font-bigest vs--title" />}</div>}
				{header}
			</div>
			<div className={`vs--ui-collapse-content ${isRowExpanded ? "vs--ui-collapse-content-expanded" : ""}`} ref={collapseContentRef} onTransitionEnd={resetCollapseContentStyles}>
				<div className={`vs--ui-collapse-content-box`}>{isRowAlreadyExpandedRef.current && content}</div>
			</div>
		</div>
	);
};

/** CollapseRenderer propTypes
 * PropTypes
 */
CollapseRenderer.propTypes = {
	/** Function which will fire on change of collapse panel */
	handleCollapseChange: PropTypes.func,
	/** Indicates that current row expanded */
	isRowExpanded: PropTypes.bool,
	/** Expand icon renderer */
	expandIconRenderer: PropTypes.func,
	/** Elements to render  */
	content: PropTypes.node,
	/** Specified which mode is current */
	mode: PropTypes.string,
	/** Default expanded rows */
	defaultActiveIds: PropTypes.array,
	/** Expanded row ids controlled from outside  */
	activeRowIds: PropTypes.array,
	/** Specified expand icon position */
	expandIconPosition: PropTypes.string,
	/** Header content */
	header: PropTypes.node,
	/** Show/hide expand icon */
	showArrow: PropTypes.bool,
	/** Current Row id */
	rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CollapseRenderer;
