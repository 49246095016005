import  { Fragment } from "react";

import StreamComponent from "components/pages/stream";

const StreamRoute = () => {
	return (
		<Fragment>
			<StreamComponent />
		</Fragment>
	);
};

export default StreamRoute;
