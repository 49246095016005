import { useEffect, useRef, useState } from "react";

import { mergeClassNames, isNullish } from "utils/common";

const TIMEOUTS = {
	FASTEST: 100,
	FAST: 200,
	COMMON: 500
}

const getTimeoutByIndex = (arrLength, index) => {
	if (index === arrLength - 1) {
		return TIMEOUTS.FASTEST;
	}
	if (arrLength >= 2 && index === arrLength - 2) {
		return TIMEOUTS.FAST;
	}

	return TIMEOUTS.COMMON;
}

const ANIMATION_NAME = "slideUp";

const Num = ({
	className = "",
	animationClassName,
	numIndex,
	index,
	num,
	numlength
}) => {
	const [localnum, setLocalNum] = useState(num);

	const numRef = useRef();

	const handleAnimationEnd = (e) => {
		if (e.animationName === ANIMATION_NAME) {
			numRef.current?.classList.remove(animationClassName);
		}
	};

	useEffect(() => {
		let timeoutId;
		let prevNum;

		if (!isNullish(num) && numIndex >= 0) {
			prevNum = num;

			timeoutId = setTimeout(() => {
				setLocalNum(num)
			}, getTimeoutByIndex(numlength, numIndex));
		}

		return () => {
			clearTimeout(timeoutId);
			setLocalNum(prevNum);
		}
	}, [num, numlength, numIndex])

	return (
		<div
			ref={numRef}
			key={`${index}_${localnum}`}
			className={mergeClassNames(
				"vs--font-regular",
				className,
				localnum === "." && `${className}-dot vs--pl-4 vs--pr-4`,
				numIndex < 0 && `${className}-hidden`,
				animationClassName
			)}
			onAnimationEnd={(e) => {
				handleAnimationEnd(e, index, animationClassName)
			}}
		>
			{localnum}
		</div>
	)
};

export default Num;