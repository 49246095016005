import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { KENO_BALLS_COUNT } from "constants/game.constants";

import eventType from "types/event.type";

/** Kneo Game page Finished Event */
const KenoFinished = ({ lastResults, event, isLoading }) => {
	const { t } = useTranslation();

	const boardRows = [0, 1];

	/** Function , to get comleted scenes numbers
	 * @function
	 * @param {number} index - last result index
	 * @memberOf KenoFinished
	 */
	const getNumbers = (index) => (index === undefined ? (event?.gameData?.scenes ?? []) : (lastResults?.[index]?.gameData?.scenes ?? [])).map((sc) => sc.number);

	return (
		<div className="vs--keno-finished vs--flex vs--flex-col">
			<div className="vs--keno-finished-content vs--flex vs--align-center vs--justify-center vs--flex-col">
				<b className="vs--title vs--font-bigest vs--font-medium vs--mb-24">{t("common.winningBallNumbers")}</b>
				<div className="vs--keno-board vs--flex vs--flex-col vs--keno-board-finished vs--pl-16 vs--pr-16 vs--pb-16 vs--pt-16">
					<div className="vs--keno-live-pipe-title-wrapper vs--keno-live-pipe-title-wrapper-winning vs--flex vs--align-center vs--justify-center">
						<div className="vs--keno-live-pipe-title vs--flex-inline rt--align-center rt--justify-center vs--pl-24 vs--pr-24 vs--pt-8 vs--pb-8">
							<b className="vs--title vs--font-medium vs--pr-8">{t("common.eventId")}:</b>
							<span className="vs--title vs--font-medium">{event?.id}</span>
						</div>
					</div>

					{boardRows.map((row) => (
						<div className="vs--keno-board-row vs--flex vs--align-center vs--mb-8" key={row}>
							{getNumbers()
								.slice(10 * row, (row + 1) * 10)
								.map((num) => (
									<div className="vs--keno-board-col vs--keno-board-col-finished vs--flex vs--align-center vs--justify-center vs--mr-8" key={num}>
										<div className="vs--keno-board-col-inner vs--flex vs--align-center vs--justify-center">
											<div className="vs--keno-board-col-inner-bg vs--flex vs--align-center vs--justify-center">
												<span className="vs--title vs--font-medium">{num}</span>
											</div>
										</div>
									</div>
								))}
						</div>
					))}
				</div>

				{[0, 1].map((i) => (
					<div className="vs--mt-24" key={i}>
						<div className="vs--keno-live-pipe vs--keno-live-pipe-finished vs--pl-14 vs--pr-14 vs--pt-8 vs--pb-8 vs--flex vs--flex-col vs--align-center">
							<div className="vs--keno-live-pipe-title-wrapper vs--flex vs--align-center vs--justify-center">
								<div className="vs--keno-live-pipe-title vs--flex-inline rt--align-center rt--justify-center vs--pl-24 vs--pr-24 vs--pt-8 vs--pb-8">
									<b className="vs--title vs--font-medium vs--pr-8">{t("common.eventId")}:</b>
									<span className="vs--title vs--font-medium">{lastResults?.[i]?.id ?? ""}</span>
								</div>
							</div>
							<div className="vs--flex">
								{getNumbers(i).map((ball, index) => (
									<div className={`vs--keno-live-pipe-ball vs--flex vs--align-center vs--justify-center vs--mr-4 kenoBall${index}Animation`} key={ball}>
										<div className="vs--keno-live-pipe-ball-inner vs--flex vs--align-center vs--justify-center">
											<span className="vs--title vs--font-medium">{ball === 6 || ball === 9 ? ball + "." : ball}</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="vs--keno-finished-footer vs--flex vs--flex-col vs--flex vs--align-center vs--justify-center">
				<b className="vs--title vs--font-bigest vs--font-medium vs--font-uppercase">{t("common.nextMatchStartsSoon")}</b>
			</div>
		</div>
	);
};

/** KenoFinished propTypes
 * PropTypes
 */
KenoFinished.propTypes = {
	/** Redux state property, the array of last results */
	lastResults: PropTypes.arrayOf(eventType),
	/** Lat finished event */
	event: eventType,
	/** Redux state property, is true when last results are loading */
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		lastResults: state.game.lastResults.data,
		isLoading: state.game.lastResults.loading
	};
};

export default connect(mapStateToProps, null)(KenoFinished);
