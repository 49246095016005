import VideoPlayer from "../player";

import { lockOrientation, unLockOrientation, isMobile } from "utils/common";
import fullScreenAPI from "utils/fullscreen";

class MP4VideoPlayer extends VideoPlayer {
	constructor(streamConfiguration, options) {
		super(streamConfiguration, options);
	}

	init() {
		if (this.initialized) return;
		if (this.streamConfiguration === null || this.streamConfiguration === undefined) return this.setError(true);
		if (!this.streamConfiguration.url) return this.setError(true);

		this.player = document.createElement("video");
		this.player.setAttribute("playsInline", true);
		this.player.setAttribute("autoplay", "autoplay");
		this.mute();

		const playerDiv = document.getElementById(this.options.elementID);
		if (playerDiv) {
			playerDiv.append(this.player);
		}
		this.player.src = this.streamConfiguration.url;

		this.player.addEventListener("pause", this.onPause.bind(this));
		this.player.addEventListener("play", this.onPlay.bind(this));
		this.player.addEventListener("volumechange", this.volumeChangeHandler.bind(this));
		this.player.addEventListener("loadedmetadata", this.onPlayerReady.bind(this));

		fullScreenAPI.on("change", this.onFullscreenChange.bind(this));
		this.addEventListeners();
		this.initialized = true;
	}

	destroy() {
		if (this.player) {
			this.player.removeEventListener("pause", this.onPause.bind(this));
			this.player.removeEventListener("play", this.onPlay.bind(this));
			this.player.removeEventListener("volumechange", this.volumeChangeHandler.bind(this));
			this.player.removeEventListener("loadedmetadata", this.onPlayerReady.bind(this));
			fullScreenAPI.off("change", this.onFullscreenChange.bind(this));
			this.player.parentElement.removeChild(this.player);
			this.player = null;
			this.initialized = false;
		}
	}

	mute() {
		this.player.muted = true;
		this.options.onMute && this.options.onMute(true);
	}

	unmute() {
		this.player.muted = false;
		this.options.onMute && this.options.onMute(false);
	}

	setPlayerVolume(v) {
		this.player.volume = v;
	}

	getPlayerVolume() {
		return this.player.volume;
	}

	getPlayerMuted() {
		return this.player.muted;
	}

	onFullscreen() {
		if (!fullScreenAPI.isFullscreen) {
			const element = document.getElementsByClassName("vs--video-container")[0];
			fullScreenAPI.toggle(element);
			lockOrientation("landscape");
		} else {
			fullScreenAPI.toggle();
		}
	}

	onFullscreenChange(isFullscreen) {
		const element = document.getElementsByClassName("vs--video-container")[0];
		if (!fullScreenAPI.isFullscreen) {
			unLockOrientation();
			document.body.classList.remove("vs--fullscreen");
		} else {
			document.body.classList.add("vs--fullscreen");
		}
	}

	volumeChangeHandler(e) {
		this.onVolumeChange(e.target.volume);
	}

	addEventListeners() {
		const element = document.getElementById(this.options.elementID);

		if (isMobile()) {
			const clickElem = element.getElementsByTagName("video")[0];
			if (clickElem) {
				clickElem.removeEventListener("click", this.handleVideoElementClick.bind(this));
				clickElem.addEventListener("click", this.handleVideoElementClick.bind(this));
			}
		}
	}

	handleVideoElementClick() {
		const element = document.getElementById(this.options.elementID);
		const containerElem = element.parentElement;
		this.onControlsVisiblityChange(!containerElem.classList.contains("vs--video-container-controls-visible"));
	}
}

export default MP4VideoPlayer;
