import { Fragment, useEffect, useState } from "react";

const ms = 890;
const maxCountOfMinutesForImitation = 90;

const LiveMarketsTiming = () => {
	const [currentPercent, setCurrentPercent] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {

			setCurrentPercent(prev => prev + 1)

		}, ms)

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<Fragment>
			{`${(currentPercent > maxCountOfMinutesForImitation) ? maxCountOfMinutesForImitation : currentPercent}''`}
		</Fragment>
	);
};
export default LiveMarketsTiming;
