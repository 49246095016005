import { COLLAPSE_MODE, PREDEFINED_VALIDATIONS } from "constants/ui.constants";

export const updateExpandedRowIds = ({ mode, expandedRowIds, currentRowId }) => {
	if (mode === COLLAPSE_MODE.ACCORDION) {
		return expandedRowIds[0] !== currentRowId ? [currentRowId] : [];
	}

	// the case of Collapse mode
	if (expandedRowIds.includes(currentRowId)) {
		return expandedRowIds.filter((id) => id !== currentRowId);
	}

	return [...expandedRowIds, currentRowId];
};

export const constructArrayForGivenRange = ({ from, to }) => {
	if (typeof from !== "number" || typeof to !== "number") {
		console.error(new Error("array can be constructed only with numbers"));

		return [];
	}

	const constructedArray = [];

	for (let i = from; i <= to; i++) {
		constructedArray.push(i);
	}

	return constructedArray;
};

export const getDisplayedItemsRange = ({ currentPageSize, currentPage, total }) => {
	const currentPageLastItemIndex = currentPage * currentPageSize;
	const displayedItemsFrom = currentPageLastItemIndex - currentPageSize + 1;
	const displayedItemsTo = Math.min(currentPageLastItemIndex, total);

	return [displayedItemsFrom, displayedItemsTo];
};

export const isDropdownFitsOnScreen = ({ sizeChangerElement, dropDownElement }) => {
	const sizeChangerPosition = sizeChangerElement.getBoundingClientRect();

	const isDropdownFitsOnScreen = window.innerHeight - sizeChangerPosition.bottom > dropDownElement.clientHeight + 10;

	return isDropdownFitsOnScreen;
};

//------------------------------FORM---------------------------------//

export async function asyncValidator({ rules, value, formInstance }) {
	for (const validation of rules) {
		/**
		 * validation can be object that contains { type: string, message: string }
		 * or it can be function which will receive formInstance and
		 * return object that contains { validator: func }
		 */
		const validationObj = typeof validation === "function" ? validation(formInstance) : validation;

		const { message, type, pattern, whitespace, validator } = validationObj;

		try {
			if (typeof validator === "function") {
				await validator(value);
			} else {
				await PREDEFINED_VALIDATIONS[type]({ value, message, pattern, whitespace });
			}
		} catch (validationMessage) {
			// validationMessage return only validator function
			return Promise.reject(validationMessage ? validationMessage : message);
		}
	}

	return Promise.resolve();
}

export const getValidationMessageList = (STORE) => {
	const validationMessageList = [];

	for (const [fieldName, fieldStore] of Object.entries(STORE)) {
		const message = fieldStore.validationData?.message;

		if (!message) {
			continue;
		}

		validationMessageList.push({
			[fieldName]: message
		});
	}

	return validationMessageList;
};

//-------------------------------------------------------------------//
