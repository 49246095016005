import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalWrapper from "components/modalWrapper";

import { setConfirmingTransaction, rejectBetslip } from "store/actions/betslip/betslip.actions";

/** Transaction Confirmation Modal Component */
const TransactionConfirmation = ({ setConfirmingTransaction, rejectBetslip, confirmingTransaction }) => {
	const { t } = useTranslation();

	return (
		<ModalWrapper onCancel={() => setConfirmingTransaction(null)} visible={true} classNames={{ content: "vs--payment-method-modal vs--flex" }} closeIcon={null}>
			<div className="vs--reprint vs--payment-method">
				<div className="vs--reprint-header vs--text-center vs--mb-16 vs--mt-48">
					<b className="vs--font-small vs--font-medium">{t("cashier.confirmTransaction")}</b>
				</div>
				<div className="vs--payment-method-content">
					<span className="vs--font-small vs--font-medium vs--payment-method-content-title">{t("cashier.confirmTransactionInfo")}</span>
				</div>
				<div className="vs--flex vs--align-center vs--justify-center vs--mt-32">
					<button type="button" className="vs--button vs--button-modal vs--mr-8 vs--confirm-cancel" onClick={() => rejectBetslip(confirmingTransaction.id ? "" + confirmingTransaction.id : confirmingTransaction.id, confirmingTransaction.betTime)} title={t("cashier.decline")}>
						{t("common.decline")}
					</button>
					<button type="submit" className="vs--button vs--button-modal vs--ml-8 vs--confirm-confirm" onClick={() => setConfirmingTransaction(null)} title={t("cashier.confirm")}>
						{t("cashier.confirm")}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
};

/** TransactionConfirmation propTypes
 * PropTypes
 */
TransactionConfirmation.propTypes = {
	/** Redux action to show/hide transaction confirmation modal */
	setConfirmingTransaction: PropTypes.func,
	/** Redux action to reject betslip */
	rejectBetslip: PropTypes.func,
	/** Redux state property, current confirming ticket id */
	confirmingTransaction: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		confirmingTransaction: state.betslip.confirmingTransaction
	};
};

const mapDispatchToProps = (dispatch) => ({
	setConfirmingTransaction: (transaction) => {
		dispatch(setConfirmingTransaction(transaction));
	},

	rejectBetslip: (id, betTime) => {
		dispatch(rejectBetslip(id, betTime));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionConfirmation);
