import  { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { addBet } from "store/actions/betslip/betslip.actions";
import { parseHotKey, getHotKeyMarket } from "utils/hotKeys";
import runMarketUtilsFunction from "utils/markets/run";
import { GAME_STATUSES, GAME_TYPE } from "constants/game.constants";
import KEY_CODE from "constants/keyboard.constants";
import eventType from "types/event.type";
import betType from "types/bet.type";

/** HotKey input component for betslip*/
const HotKeyInput = ({ useBonus, matches, current, loading, addBet, bets, currency, currentGameType }) => {
	const { t } = useTranslation();
	const [markets, setMarkets] = useState([]);
	const [value, setValue] = useState("");
	const inputEl = useRef(null);

	const currentMatch = matches.find((m) => m.id === current);
	const event = currentMatch?.event ?? {};

	/** Function which fires on stake input key press
	 * @function
	 * @param {object} e - event object
	 * @memberOf HotKeyInput
	 */
	const onKeyPress = (e) => {
		if (betsAreDisabled()) return;

		const value = e.target.value.replace(/\s/g, "");
		if (e.keyCode == KEY_CODE.ENTER && value) {
			const parsedHotKey = parseHotKey(value, currentGameType);
			if (!parsedHotKey || !parsedHotKey.market) return;
			const market = getHotKeyMarket(parsedHotKey, markets);
			if (!market || !market.factor || market.factor < 1.01 || isOddSelected(market.oddId)) {
				return;
			}

			if (bets.length < 15) {
				addBet({
					gameData: event.gameData ?? {},
					type: event.type ?? null,
					orderNumber: event.orderNumber ?? null,
					startTime: event.startTime ?? null,
					eventId: event.id ?? null,
					id: event.id ?? null,
					factor: market.factor,
					showName: market.showName,
					ticketShowName: market.ticketShowName,
					groupTitle: runMarketUtilsFunction("makeGroupTitle", [{ group: market.group, argument: market.argument, gameData: event.gameData ?? {}, gameType: currentGameType }], currentGameType),
					oddId: market.oddId,
					gameType: currentGameType,
					stake: parsedHotKey.amount ? parsedHotKey.amount.toString() : (currency?.singleMin ?? 0).toString()
				});
			}
			setValue("");
			setTimeout(() => {
				inputEl && inputEl.current && inputEl.current.focus();
			}, 200);
		}
	};

	/** Function to check if the current match is active to do bet
	 * @function
	 * @returns {boolean}
	 * @memberOf HotKeyInput
	 */
	const betsAreDisabled = () => {
		const currentMatch = matches.find((m) => m.id === current);

		const state = currentMatch?.event?.status ?? null;
		return state !== GAME_STATUSES.NEW && state !== GAME_STATUSES.PREAMBLE_STARTED;
	};

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf HotKeyInput
	 */
	const isOddSelected = (id) => {
		return bets.some((b) => b.oddId === id && b.eventId === event?.id);
	};

	/** Autofocus input */
	useEffect(() => {
		setTimeout(() => {
			inputEl && inputEl.current && inputEl.current.focus();
		}, 0);
	}, []);

	/** Make markets data, to show */
	useEffect(() => {
		if (current) {
			const currentMatch = matches.find((m) => m.id === current);
			if (currentMatch && currentMatch.markets) {
				setMarkets([].concat.apply([], Object.values(currentMatch.markets)));
			}
		}
	}, [current, loading]);

	/** Clear hotkey input value, when user activate bonus */
	useEffect(() => {
		if (useBonus) {
			setValue("");
		}
	}, [useBonus]);

	return (
		<div className="vs--stake-hotkey vs--mb-8 vs--ml-8 ">
			<span className="vs--stake-input-label vs--font-regular vs--font-smalest">{t("cashier.hotkeyCode")}</span>
			<input disabled={useBonus} className="vs--font-smallest vs--font-medium vs--pr-16 vs--pl-16 vs--pt-10 vs--pb-10 vs--mt-8" onKeyDown={onKeyPress} onChange={(e) => setValue(e.target.value)} value={value} ref={inputEl} />
		</div>
	);
};

/** HotKeyInput propTypes
 * PropTypes
 */
HotKeyInput.propTypes = {
	/** Redux state property, all loaded matches */
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			event: eventType
		})
	),
	/** Redux state property, current match id */
	current: PropTypes.number,
	/** Redux state property, is true when match details is loading */
	loading: PropTypes.bool,
	/** Redux action to add bet into betslip bets */
	addBet: PropTypes.func,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, current session currency */
	currency: PropTypes.object,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		matches: state.game.matches.data,
		current: state.game.current,
		loading: state.game.matches.loading,
		bets: state.betslip.bets,
		currency: state.auth.session.currency,
		currentGameType: state.game.currentGameType,
		useBonus: state.bonuses.standard.useBonus,
	}
}

const mapDispatchToProps = (dispatch) => ({
	addBet: (bet) => {
		dispatch(addBet(bet));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(HotKeyInput);
