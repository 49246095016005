import  { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalWrapper from "components/modalWrapper";

import { placeBet, showPaymentMethod } from "store/actions/betslip/betslip.actions";

import { PAYMENT_TYPE } from "constants/common.constants";

/** Payment tMethod Modal Component */
const PaymentMethod = ({ placeBet, showPaymentMethod }) => {
	const { t } = useTranslation();

	const [selectedMethod, setSelectedMethod] = useState(null);

	/** Fires on payment method selecting
	 * @function
	 * @param {number} method
	 * @memberOf PaymentMethod
	 */
	const handleMethodSelection = (method) => {
		setSelectedMethod(method);
		showPaymentMethod(false);
		placeBet(method);
	};

	return (
		<ModalWrapper onCancel={() => showPaymentMethod(false)} visible={true} classNames={{ content: "vs--payment-method-modal", body: "vs--payment-method-modal-body" }}>
			<div className="vs--reprint vs--payment-method">
				<div className="vs--reprint-header vs--text-center vs--mb-16 vs--mt-48">
					<b className="vs--font-small vs--font-medium">{t("cashier.choosePaymentMethod")}</b>
				</div>
				<div className="vs--payment-method-content">
					<span className="vs--font-small vs--font-medium vs--payment-method-content-title">{t("cashier.choosePaymentMethodInfo")}</span>
					<div className="vs--payment-method-items vs--mt-24">
						<div className={"vs--payment-method-items-item vs--pl-16 vs--pr-16 vs--pt-16 vs--pb-16 vs--flex vs--align-center vs--mb-12" + (selectedMethod === PAYMENT_TYPE.CASH ? " vs--payment-method-items-item-selected" : "")} onClick={() => handleMethodSelection(PAYMENT_TYPE.CASH)}>
							<div className="vs--payment-method-items-item-button vs--flex vs--align-center vs--justify-center">
								<i className="ic_cash vs--font-bigest"></i>
							</div>
							<span className="vs--font-exstrasmall vs--pl-8 vs--mb-4">{t("cashier.cash")}</span>
						</div>
						<div className={"vs--payment-method-items-item vs--pl-16 vs--pr-16 vs--pt-16 vs--pb-16 vs--flex vs--align-center" + (selectedMethod === PAYMENT_TYPE.CREDIT_CARD ? " vs--payment-method-items-item-selected" : "")} onClick={() => handleMethodSelection(PAYMENT_TYPE.CREDIT_CARD)}>
							<div className="vs--payment-method-items-item-button vs--flex vs--align-center vs--justify-center">
								<i className="ic_credit-card vs--font-bigest"></i>
							</div>
							<span className="vs--font-exstrasmall vs--pl-8 vs--mb-4">{t("cashier.creditCard")}</span>
						</div>
					</div>
				</div>
			</div>
		</ModalWrapper>
	);
};

/** PaymentMethod propTypes
 * PropTypes
 */
PaymentMethod.propTypes = {
	/** Redux action to place bet */
	placeBet: PropTypes.func,
	/** Redux action to show/hide payment method modal */
	showPaymentMethod: PropTypes.func,
	/** Function that will be called on Paymetn Method modal close */
	onCancel: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	placeBet: (method) => {
		dispatch(placeBet(method));
	},
	showPaymentMethod: (show) => {
		dispatch(showPaymentMethod(show));
	}
});

export default connect(null, mapDispatchToProps)(PaymentMethod);
