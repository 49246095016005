import  { useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";

const GameButton = forwardRef(({ onClick, className, selected, disabled, children, ...props }, outerRef) => {
	const classes = ["btn", className];
	const innerRef = useRef();

	useImperativeHandle(outerRef, () => innerRef.current);
	useEffect(() => {
		if (!innerRef.current) {
			return;
		}
		if (selected && !disabled) {
			innerRef.current.classList.add("btn-selected");
			innerRef.current.setAttribute("aria-pressed", "true");
		}
		if (disabled) {
			innerRef.current.disabled = true;
			innerRef.current.classList.add("btn-disabled");
			innerRef.current.setAttribute("aria-disabled", "true");
		}

		return () => {
			if (!innerRef.current) {
				return;
			}
			if (selected && !disabled) {
				innerRef.current.classList.remove("btn-selected");
				innerRef.current.setAttribute("aria-pressed", "false");
			}
			if (disabled) {
				innerRef.current.disabled = false;
				innerRef.current.classList.remove("btn-disabled");
				innerRef.current.setAttribute("aria-disabled", "false");
			}
		};
	}, [selected, disabled]);

	return (
		<button
			ref={innerRef}
			onClick={(e) => {
				!disabled && onClick(e);
			}}
			className={classes.join(" ")}
			type="button"
			role="button"
			{...props}
		>
			{children}
		</button>
	);
});

GameButton.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	selected: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string, PropTypes.number])
};

export default GameButton;
