const getDocument = () => (typeof window !== "undefined" && typeof window.document !== "undefined" ? window.document : {});
const UA = window.navigator.userAgent.toLowerCase();
const IS_IOS = /ipad|iphone|ipod/i.test(UA) && !window.MSStream;
const IS_CHROME = /chrome/i.test(UA);
const IS_SAFARI = /safari/i.test(UA) && !IS_CHROME;

const fn = (function () {
	const fnMap = [
		["requestFullscreen", "exitFullscreen", "fullscreenElement", "fullscreenEnabled", "fullscreenchange", "fullscreenerror"],
		// New WebKit
		["webkitEnterFullscreen", "webkitExitFullscreen", "webkitFullscreenElement", "webkitFullscreenEnabled", "webkitfullscreenchange", "webkitfullscreenerror"],
		// Old WebKit
		["webkitRequestFullScreen", "webkitCancelFullScreen", "webkitCurrentFullScreenElement", "webkitCancelFullScreen", "webkitfullscreenchange", "webkitfullscreenerror"],
		["mozRequestFullScreen", "mozCancelFullScreen", "mozFullScreenElement", "mozFullScreenEnabled", "mozfullscreenchange", "mozfullscreenerror"],
		["msRequestFullscreen", "msExitFullscreen", "msFullscreenElement", "msFullscreenEnabled", "MSFullscreenChange", "MSFullscreenError"]
	];

	let val;
	let ret = {};

	for (let i = 0; i < fnMap.length; i++) {
		val = fnMap[i];
		if ((val && val[1] in getDocument()) || ((IS_IOS || IS_SAFARI) && i === 1)) {
			for (let j = 0; j < val.length; j++) {
				ret[fnMap[0][j]] = val[j];
			}
			return ret;
		}
	}

	return false;
})();

const eventNameMap = {
	change: fn.fullscreenchange,
	error: fn.fullscreenerror
};

const fullScreenAPI = {
	request: function (element) {
		return new Promise(
			function (resolve, reject) {
				const onFullScreenEntered = function () {
					this.off("change", onFullScreenEntered);
					resolve();
				}.bind(this);

				this.on("change", onFullScreenEntered);

				element = element || getDocument().documentElement;

				if (IS_IOS || IS_SAFARI) {
					element = element.getElementsByTagName("video")[0];
				}

				const returnPromise = element[fn.requestFullscreen]();

				if (returnPromise instanceof Promise) {
					returnPromise.then(onFullScreenEntered).catch(reject);
				}
			}.bind(this)
		);
	},
	exit: function () {
		return new Promise(
			function (resolve, reject) {
				if (!this.isFullscreen) {
					resolve();
					return;
				}

				const onFullScreenExit = function () {
					this.off("change", onFullScreenExit);
					resolve();
				}.bind(this);

				this.on("change", onFullScreenExit);

				var returnPromise = getDocument()[fn.exitFullscreen]();

				if (returnPromise instanceof Promise) {
					returnPromise.then(onFullScreenExit).catch(reject);
				}
			}.bind(this)
		);
	},
	toggle: function (element) {
		return this.isFullscreen ? this.exit() : this.request(element);
	},
	onchange: function (callback) {
		this.on("change", callback);
	},
	onerror: function (callback) {
		this.on("error", callback);
	},
	on: function (event, callback) {
		var eventName = eventNameMap[event];
		var el = IS_IOS || IS_SAFARI ? getDocument().getElementsByTagName("video")[0] : getDocument();
		if (eventName) {
			el.addEventListener(eventName, callback, false);
		}
	},
	off: function (event, callback) {
		var eventName = eventNameMap[event];
		var el = IS_IOS || IS_SAFARI ? getDocument().getElementsByTagName("video")[0] : getDocument();

		if (eventName) {
			el.removeEventListener(eventName, callback, false);
		}
	}
};

Object.defineProperties(fullScreenAPI, {
	isFullscreen: {
		get: function () {
			return Boolean(getDocument()[fn.fullscreenElement]);
		}
	},
	element: {
		enumerable: true,
		get: function () {
			return getDocument()[fn.fullscreenElement];
		}
	},
	isEnabled: {
		enumerable: true,
		get: function () {
			// Coerce to boolean in case of old WebKit
			return Boolean(getDocument()[fn.fullscreenEnabled]);
		}
	}
});

export default fullScreenAPI;
