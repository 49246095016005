import  { Children, useState } from "react";
import PropTypes from "prop-types";

import CollapseRenderer from "./collapseRenderer";

import { updateExpandedRowIds } from "utils/ui";

import { COLLAPSE_MODE } from "constants/ui.constants";

const Collapse = ({ children, onChange, mode = COLLAPSE_MODE.COLLAPSE, defaultActiveIds, activeRowIds, expandIconRenderer, expandIconPosition, className = "" }) => {
	/**
	 * isControlledFromOutside indicates that component
	 * should controlled from parent component.
	 * This prevents local state changes
	 */
	const isControlledFromOutside = Array.isArray(activeRowIds);

	/**
	 * In the case of an accordion mode
	 * expandRowIds will be an array containing only one ID.
	 */
	const [expandedRowIds, setExpandedRowIds] = useState(() => {
		if (isControlledFromOutside) {
			return;
		}

		if (Array.isArray(defaultActiveIds)) {
			return defaultActiveIds;
		}

		return [];
	});

	const handleCollapseChange = (rowId) => {
		const updatedExpandedRowIds = updateExpandedRowIds({
			expandedRowIds: isControlledFromOutside ? activeRowIds : expandedRowIds,
			mode,
			currentRowId: rowId
		});

		if (typeof onChange === "function") {
			onChange(updatedExpandedRowIds);
		}

		if (isControlledFromOutside) {
			return;
		}
		setExpandedRowIds(updatedExpandedRowIds);
	};

	return (
		<div className={`vs--ui-collapse-container ${className}`}>
			{Children.map(children, (child, index) => {
				const { header, rowId: id, showArrow = true } = child.props;
				const rowId = id ? id : index;
				const computedExpandedRowIds = isControlledFromOutside ? activeRowIds : expandedRowIds;
				const isRowExpanded = computedExpandedRowIds.includes(rowId);

				return <CollapseRenderer content={child} isRowExpanded={isRowExpanded} expandIconPosition={expandIconPosition} showArrow={showArrow} handleCollapseChange={handleCollapseChange} rowId={rowId} header={header} expandIconRenderer={expandIconRenderer} />;
			})}
		</div>
	);
};

Collapse.Panel = ({
	children
	/**
	 * header,
	 * showArrow,
	 * rowId,
	 */
}) => {
	return <>{children}</>;
};

/** Collapse propTypes
 * PropTypes
 */
Collapse.propTypes = {
	/** Function which will fire on change of collapse panel */
	onChange: PropTypes.func,
	/** Arrow icon renderer */
	arrowIconRenderer: PropTypes.func,
	/** Class to rewrite default styles of collapse */
	className: PropTypes.string,
	/** Expand icon renderer */
	expandIconRenderer: PropTypes.func,
	/** Specified expand icon position */
	expandIconPosition: PropTypes.string,
	/** Elements to render  */
	children: PropTypes.node,
	/** Specified which mode is current */
	mode: PropTypes.string,
	/** Default expanded rows */
	defaultActiveIds: PropTypes.array,
	/** Expanded row ids controlled from outside  */
	activeRowIds: PropTypes.array
};

/** Collapse.Panel propTypes
 * PropTypes
 */
Collapse.Panel.propTypes = {
	/** Elements to render  */
	children: PropTypes.node
};

export default Collapse;
