import PropTypes from "prop-types";

import { connect } from "react-redux";

/** Ticket ID Form Component */
const TicketIDForm = ({ inputRef, inputValue, onChange, disabled, onKeyDown, onButtonClick }) => {
	return (
		<div className="vs--ticket-id-content vs--pl-14 vs--pr-14 vs--pb-12 vs--pt-12">
			<input type="text" className="vs--ticket-id-form-input" value={inputValue} onChange={onChange} ref={inputRef} disabled={disabled} onKeyDown={onKeyDown} />
			<div className="vs--ticket-id-form-buttons vs--mt-16">
				{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
					<div className="vs--ticket-id-form-button vs--text-center vs--mb-8" key={i} onClick={(event) => onButtonClick(event,i)}>
						<span className="vs--font-exta-bigest vs--font-regular vs--title">{i}</span>
					</div>
				))}
				<div className="vs--ticket-id-footer">
					<div className="vs--ticket-id-form-button vs--text-center vs--ticket-id-form-button-delete" onClick={(event) => onButtonClick(event, "back")}>
						<span className="vs--font-normal vs--font-regular vs--title">
							<i className="ic_close" />
						</span>
					</div>
					<div className="vs--ticket-id-form-button vs--text-center" onClick={(event) => onButtonClick(event, 0)}>
						<span className="vs--font-exta-bigest vs--font-regular vs--title">0</span>
					</div>
					<div className="vs--ticket-id-form-button vs--ticket-id-form-button-enter vs--text-center" onClick={(event) => onButtonClick(event, "enter")}>
						<span className="vs--font-small vs--font-medium vs--title">OK</span>
					</div>
				</div>
			</div>
		</div>
	);
};

/** TicketIDForm propTypes
 * PropTypes
 */
TicketIDForm.propTypes = {
	// React ref for referance to input
	inputRef: PropTypes.object,
	// Value of input
	inputValue: PropTypes.string,
	// Input onChange event handler
	onChange: PropTypes.func,
	// enable/disable state of input
	disabled: PropTypes.bool,
	// Input onKeyDown event handler
	onKeyDown: PropTypes.func,
	// onClick handler of buttons of form
	onButtonClick: PropTypes.func
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TicketIDForm);
