import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { removeAlertMessage } from "store/actions/common/common.actions";

const Message = ({ message, removeAlertMessage }) => {
	const timeoutIdRef = useRef();
	const duration = message?.duration ?? 3000;

	useEffect(() => {
		timeoutIdRef.current = setTimeout(() => removeAlertMessage(message.id), duration);
	}, [message.id, duration]);

	/** Clearing setTimeout on component unmount (when removed message from messages) */
	useEffect(() => {
		return () => {
			clearTimeout(timeoutIdRef.current);
		};
	}, []);

	return (
		<div className={`vs--ui-message-notice vs--ui-message-notice-${message.action}`}>
			<div className={`vs--ui-message-notice-text vs--ui-message-notice-text-${message.action}`}>{message.content}</div>
		</div>
	);
};

Message.propTypes = {
	/** Redux state property, alert message data */
	message: PropTypes.object,
	/** Redux action, callback that will be called to remove message data */
	removeAlertMessage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	removeAlertMessage: (id) => {
		dispatch(removeAlertMessage(id));
	}
});

export default connect(null, mapDispatchToProps)(Message);
