import LiveMonitorAnimationContext from "contexts/liveMonitorAnimationContext";
import { useContext, useEffect, useRef } from "react";
import { flushSync } from "react-dom";
import { mergeClassNames } from "utils/common";

const AnimatedPage = ({ children, className, pageKey, style, onAnimationEnd }) => {
	const { state, setState, perChildAnimationDelay, perPage } = useContext(LiveMonitorAnimationContext);
	const ref = useRef();
	return state[pageKey] ? (
		<div
			data-page={pageKey}
			ref={ref}
			style={style}
			className={mergeClassNames("live-monitor-animated-page", className)}
			onAnimationEnd={() => {
				setTimeout(() => {
					if (perPage) {
						flushSync(() => {
							setState((prev) =>
								prev.map((v, i) => {
									if (pageKey - 1 === i) {
										return false;
									}
									return v;
								})
							);
						});
						flushSync(() => {
							setState((prev) =>
								prev.map((v, i) => {
									if (pageKey + 1 === i) {
										return true;
									}
									return v;
								})
							);
						});
					}
					if (typeof onAnimationEnd === "function") {
						onAnimationEnd();
					}
				}, perChildAnimationDelay);
			}}
		>
			{children}
		</div>
	) : null;
};
export default AnimatedPage;
