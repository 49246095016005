import PropTypes from "prop-types";
import moment from "moment";

import Tile from "components/ui/datePicker/calendar/tile";

import { CALENDAR_TYPE } from "constants/calendar.constants";

const Day = ({ calendarType, currentMonthIndex, date, selectedDate, onClick, ...otherProps }) => {
	const getClassName = () => {
		let className = "vs--ui-calendar-month-view-days-day";

		if (date.getTime() === selectedDate.startOf("date")._d.getTime()) {
			className += " vs--ui-calendar-month-view-days-day-active";
		}

		if (date.getMonth() !== currentMonthIndex) {
			className += " vs--ui-calendar-month-view-days-day-neighboring-month";
		}

		return className;
	};

	return (
		<Tile
			{...otherProps}
			selectedDate={selectedDate}
			date={date}
			onClick={onClick}
			className={getClassName()}
			formatAbbr={(date) => moment(date).format("MMMM D YYYY")}
			maxDateTransform={(date) => moment(date).startOf("day")._d}
			minDateTransform={(date) => moment(date).startOf("day")._d}
			view="month"
		>
			{moment(date).format("D")}
		</Tile>
	);
};

Day.propTypes = {
	currentMonthIndex: PropTypes.number.isRequired,
	calendarType: PropTypes.oneOf(Object.values(CALENDAR_TYPE)),
	selectedDate: PropTypes.object.isRequired,
	date: PropTypes.instanceOf(Date).isRequired,
	onClick: PropTypes.func
};

export default Day;
