import  { Fragment } from "react";

import LiveMonitorGameComponent from "components/pages/liveMonitor/game";

const LiveMonitorGameRoute = () => {
	return (
		<Fragment>
			<LiveMonitorGameComponent />
		</Fragment>
	);
};

export default LiveMonitorGameRoute;
