import  { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

import useCountDown from "hooks/useCountDown";

const UnavailableGame = ({ countdownValue }) => {
	const { t } = useTranslation();
	const [deadline, setDeadline] = useState(null);

	const time = useCountDown(deadline);

	useLayoutEffect(() => {
		if (!countdownValue) {
			return setDeadline(null);
		}
		setDeadline(moment.utc(countdownValue).local().toDate());
	}, [countdownValue]);

	return deadline ? (
		<div className="vs--markets-unavailable vs--flex vs--align-center vs--justify-center">
			<div className="vs--markets-unavailable-content">
				<span className="vs--text-center vs--font-small vs--font-regular">{t("common.thisRoundIsCurrentlyLive")}</span>
				<span className="vs--text-center vs--font-small vs--font-regular">{t("common.theUpcomingRoundMarketsWillBeAvailableAt")}</span>
				<div className="vs--markets-unavailable-content-countdown">
					<div className="vs--markets-unavailable-content-countdown-content">
						<span className="vs--markets-unavailable-content-countdown-content-value">{`${time.minutes}:${time.seconds}`}</span>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

UnavailableGame.propTypes = {
	countdownValue: PropTypes.string
};

export default UnavailableGame;
