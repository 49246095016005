import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import MarketOdd from "./marketOdd"
import { numberWithSpaces } from 'utils/common';

const MarketItemByTab = ({ marketsInfo, marketContainer, isOddDisabled, isOddSelected, handleOddClick, panelProps, ...props }) => {

	const { t } = useTranslation()

	const [tabs, setTabs] = useState(() => {
		const newTabs = [];
		marketsInfo.markets.forEach(market => {
			if (market.argument && !newTabs.includes(market.argument)) {
				newTabs.push(market.argument)
			}
		})

		return newTabs;
	})
	const [activeTab, setActiveTab] = useState(() => tabs[0])

	useEffect(() => {
		const newTabs = [];
		marketsInfo.markets.forEach(market => {
			if (market.argument && !newTabs.includes(market.argument)) {
				newTabs.push(market.argument)
			}
		})

		setTabs(newTabs)
		setActiveTab(prev => {
			if (prev && newTabs.includes(prev)) {
				return prev
			}

			return newTabs[0];
		})

	}, [marketsInfo, setTabs, setActiveTab])



	return (
		<div className="vs--markets-list-item-tab-panel" {...panelProps}>
			<div className='vs--markets-list-item' data-market={marketsInfo.name} {...props}>
				<div className='vs--markets-list-item-inner'>
					<div className="vs--markets-list-item-header vs--mb-12">
						<span className="vs--title vs--font-exstrasmall vs--font-medium vs--font-condensed">
							{marketsInfo.title}
						</span>
					</div>
					<div className="vs--markets-list-item-tabs vs--mb-12">
						{
							tabs.map((tab) => (
								<span
									key={tab}
									onClick={() => setActiveTab(tab)}
									className={"vs--markets-list-item-tab" + (tab === activeTab ? " vs--markets-list-item-tab-active" : " ")}
								>
									{t("common.round")} {tab}
								</span>
							))
						}
					</div>
					<div className={`vs--markets-list-item-body vs--flex vs--flex-${marketsInfo.gridBy}`}  style={{ rowGap: 8 }}>

						{marketContainer.map((marketRow, i) => {
							const filtered = marketRow.filter(odd => odd.argument === activeTab)
							if (!filtered.length) {
								return <Fragment key={i} />
							}

							return (
								<div key={i} className={`vs--flex vs--flex-${marketsInfo.oddBy}`}  style={{ columnGap: 8 }}>
									{filtered.map((odd, j) => (
										<MarketOdd
											key={`${i}:${j}`}
											odd={odd}
											isOddDisabled={isOddDisabled}
											isOddSelected={isOddSelected}
											handleOddClick={handleOddClick}
											numberWithSpaces={numberWithSpaces}
										/>
									))}
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
export default MarketItemByTab