import VideoPlayer from "../player";

import { lockOrientation, unLockOrientation, isMobile } from "utils/common";
import fullScreenAPI from "utils/fullscreen";

class WowzaVideoPlayer extends VideoPlayer {
	constructor(streamConfiguration, options) {
		super(streamConfiguration, options);
		this.LICENSE =
			"sZP7IYe6T6ft0u3eTub_3OkeCSAlFSa_Tuh-CS11IOziCKfiCLbk0LIe3lf6FOPlUY3zWokgbgjNIOf9flX6TuCZCSaoFSazTSB-3ux6TOz_3lCzFS4l0Q4e3u16CLft3OfVfK4_bQgZCYxNWoryIQXzImf90SCz0LBi3uRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3l0tTShL3leL3LCZFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz";
		this.LIBRARY_LOCATION = "/theoplayer/";
		this.akamaiTime = null;
		this.timeWhenAkamaiRequest = null;
		this.latencyManagerIntervalId = null;
		this.latencyInfoIntervalId = null;
	}

	init() {
		if (this.initialized) return;
		if (this.streamConfiguration === null || this.streamConfiguration === undefined) return this.setError(true);
		if (!this.streamConfiguration.streamUrl) return this.setError(true);
		if (!window.THEOplayer) return this.setError(true);
		const playerElement = document.getElementById(this.options.elementID);
		playerElement.classList.add("video-js");
		playerElement.classList.add("theoplayer-skin");
		playerElement.classList.add("vjs-16-9");
		this.player = new window.THEOplayer.Player(playerElement, {
			license: this.LICENSE,
			libraryLocation: this.LIBRARY_LOCATION,
			allowMixedContent: true,
			mutedAutoplay: "all"
		});

		this.player.controls = false;
		this.player.source = {
			sources: {
				src: this.streamConfiguration.streamUrl,
				lowLatency: true,
				type: "application/x-mpegurl"
			}
		};

		this.player.preload = "none";

		this.player.addEventListener("pause", this.onPause.bind(this));
		this.player.addEventListener("play", this.handlePlay.bind(this));
		this.player.addEventListener("volumechange", this.volumeChangeHandler.bind(this));
		this.player.addEventListener("loadeddata", this.playerReadyHandler.bind(this));
		this.player.addEventListener("error", this.errorHandler.bind(this));

		fullScreenAPI.on("change", this.onFullscreenChange.bind(this));

		setTimeout(() => {
			if (this.initialized) {
				this.mute();
				this.setPlayerVolume(0);
				this.player.play();
			}
		}, 1000);

		this.initialized = true;
	}

	destroy() {
		if (this.player) {
			this.player.removeEventListener("pause", this.onPause.bind(this));
			this.player.removeEventListener("play", this.handlePlay.bind(this));
			this.player.removeEventListener("volumechange", this.volumeChangeHandler.bind(this));
			this.player.removeEventListener("loadedmetadata", this.playerReadyHandler.bind(this));
			this.player.removeEventListener("error", this.errorHandler.bind(this));
			fullScreenAPI.off("change", this.onFullscreenChange.bind(this));
			clearInterval(this.latencyManagerIntervalId);
			clearInterval(this.latencyInfoIntervalId);
			this.player.playbackRate = 1;
			const container = this.player.element.closest(".vs--video-container");
			container.querySelector(".video-js").remove();
			this.player.destroy();

			const newPlayerDiv = document.createElement("div");
			newPlayerDiv.id = "vs--player";
			container.prepend(newPlayerDiv);
			this.player = null;
			this.initialized = false;
		}
	}

	mute() {
		this.player.muted = true;
		this.options.onMute && this.options.onMute(true);
	}

	unmute() {
		this.player.muted = false;
		this.options.onMute && this.options.onMute(false);
	}

	setPlayerVolume(v) {
		this.player.volume = v;
	}

	getPlayerVolume() {
		return this.player.volume;
	}

	getPlayerMuted() {
		return this.player.muted;
	}

	onFullscreen() {
		if (!fullScreenAPI.isFullscreen) {
			const element = document.getElementsByClassName("vs--video-container")[0];
			fullScreenAPI.toggle(element);
			lockOrientation("landscape");
		} else {
			fullScreenAPI.toggle();
		}
	}

	onFullscreenChange(isFullscreen) {
		const element = document.getElementsByClassName("vs--video-container")[0];
		if (!fullScreenAPI.isFullscreen) {
			unLockOrientation();
			document.body.classList.remove("vs--fullscreen");
		} else {
			document.body.classList.add("vs--fullscreen");
		}
	}

	volumeChangeHandler(e) {
		this.onVolumeChange(e.volume);
	}

	playerReadyHandler(e) {
		this.setError(false);
		this.onPlayerReady();
		this.addEventListeners();
		//this.manageLatency()
	}

	handlePlay() {
		this.onPlay();
	}

	addEventListeners() {
		if (isMobile()) {
			const element = this.player.element;
			if (element) {
				const containerElem = element.closest(".vs--video-container");
				const overlay = document.createElement("div");
				containerElem.append(overlay);
				overlay.style.position = "absolute";
				overlay.style.height = "100%";
				overlay.style.width = "100%";
				overlay.style.top = "0";
				overlay.style.left = "";
				overlay.style.zIndex = "6";
				overlay.style.opacity = "0";
				overlay.removeEventListener("click", this.handleVideoElementClick.bind(this));
				overlay.addEventListener("click", this.handleVideoElementClick.bind(this));
			}
		}
	}

	handleVideoElementClick() {
		const element = this.player.element;
		const containerElem = element.closest(".vs--video-container");
		this.onControlsVisiblityChange(!containerElem.classList.contains("vs--video-container-controls-visible"));
	}

	errorHandler(err) {
		console.log(err, "ERROR");
		if (err.type === "error") {
			setTimeout(() => {
				this.reload("FATAL");
			}, 400);
		}
	}

	manageLatency() {
		this.setupLatencyTuning();
		this.requestAkamaiTime();
		this.logLatency();
	}

	setupLatencyTuning() {
		const LATENCY_LOOP_INTERVAL = 500;
		const latencyTarget = 1000;
		const latencyWindow = 250;
		const latencySeekWindow = 5000;
		const latencyCatchupRate = 0.08;

		const latencyUpdateLoop = () => {
			if (this.player.paused) {
				return;
			}

			let currentLatency = this.calculateLatency();
			let shouldWeSeek = latencyTarget - latencySeekWindow > currentLatency || latencyTarget + latencySeekWindow < currentLatency;
			let shouldWeSpeedUp = latencyTarget - latencyWindow > currentLatency || latencyTarget + latencyWindow < currentLatency;
			let shouldSpeedUp = currentLatency > latencyTarget;
			let playbackRateIsAlreadyChanged = shouldSpeedUp ? this.player.playbackRate === 1 + latencyCatchupRate : this.player.playbackRate === 1 - latencyCatchupRate;
			if (shouldWeSeek) {
				this.player.currentTime += (currentLatency - latencyTarget) / 1000;
			} else if (shouldWeSpeedUp && !playbackRateIsAlreadyChanged) {
				this.player.playbackRate = shouldSpeedUp ? 1 + latencyCatchupRate : 1 - latencyCatchupRate;
			} else if (!shouldWeSpeedUp && playbackRateIsAlreadyChanged) {
				this.player.playbackRate = 1;
			}
		};

		this.latencyManagerIntervalId = setInterval(latencyUpdateLoop.bind(this), LATENCY_LOOP_INTERVAL);
	}

	requestAkamaiTime() {
		fetch("https://time.akamai.com/?iso&ms")
			.then((response) => response.text())
			.then((data) => {
				const date = new Date(data);
				this.akamaiTime = date.getTime();
				this.timeWhenAkamaiRequest = performance.now();
			});
	}

	calculateLatency() {
		let now = this.calculateCurrentTimestamp();
		let playbackTime = this.calculateCurrentProgramDt();
		return now.getTime() - playbackTime.getTime();
	}

	calculateCurrentTimestamp() {
		const diff = performance.now() - this.timeWhenAkamaiRequest;
		return new Date(this.akamaiTime + diff);
	}

	calculateCurrentProgramDt() {
		let offset = 1000;
		let currentProgramDateTime = this.player.currentProgramDateTime;
		if (!currentProgramDateTime || isNaN(currentProgramDateTime)) {
			return null;
		}
		return new Date(currentProgramDateTime.getTime() - offset);
	}

	logLatency() {
		const INFO_LOOP_INTERVAL = 250;

		const updateLatencyValue = () => {
			const currentLatency = this.calculateLatency();
			console.log("LATENCY === " + Math.floor(currentLatency) + "ms");
		};

		const infoLoop = () => {
			if (this.player.paused) {
				console.log("LATENCY === NO");
			} else {
				updateLatencyValue();
			}
		};

		this.latencyInfoIntervalId = setInterval(infoLoop.bind(this), INFO_LOOP_INTERVAL);
	}
}

export default WowzaVideoPlayer;
