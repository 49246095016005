import PropTypes from "prop-types";

import Button from "components/ui/button";

import { CALENDAR_VIEW } from "constants/calendar.constants";

const Tile = ({ selectedDate, style, children, className, date, formatAbbr, maxDate, maxDateTransform, minDate, minDateTransform, onClick, onMouseOver, isContentTileDisabled, view }) => {
	const isDisabled = () => {
		return (minDate && minDateTransform(minDate) > date) || (maxDate && maxDateTransform(maxDate) < date) || (isContentTileDisabled && isContentTileDisabled({ selectedDate, date, view }));
	};

	return (
		<Button className={`${className ? className : ""}`} disabled={isDisabled()} style={style} onClick={onClick ? (event) => onClick(date, event) : undefined} onFocus={onMouseOver ? () => onMouseOver(date) : undefined} onMouseOver={onMouseOver ? () => onMouseOver(date) : undefined} type="button">
			{formatAbbr ? <span title={formatAbbr(date)}>{children}</span> : children}
		</Button>
	);
};

Tile.propTypes = {
	style: PropTypes.object,
	/** Calendar view, depending on view showing calendar content */
	view: PropTypes.oneOf(Object.values(CALENDAR_VIEW)),
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired,
	className: PropTypes.string.isRequired,
	/** Date value that will be displayed */
	date: PropTypes.instanceOf(Date).isRequired,
	/** Moment object, maximum date for opened calendar active date select */
	maxDate: PropTypes.object,
	/** Moment object, minimum date for opened calendar active date select */
	minDate: PropTypes.object,
	onClick: PropTypes.func,
	onMouseOver: PropTypes.func,
	isContentTileDisabled: PropTypes.func,
	children: PropTypes.node.isRequired,
	formatAbbr: PropTypes.func,
	maxDateTransform: PropTypes.func.isRequired,
	minDateTransform: PropTypes.func.isRequired
};

export default Tile;
