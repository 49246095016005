import PropTypes from "prop-types";

import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	streams: PropTypes.arrayOf(
		PropTypes.shape({
			apiKey: PropTypes.string,
			languageCode: PropTypes.string,
			name: PropTypes.string,
			securityEnabled: PropTypes.bool,
			securityToken: PropTypes.string
		})
	)
});
