import { getDefaultDate } from "utils/dateTime";

import { SET_RESULTS, RESULTS_BEFORE, RESULTS_AFTER, SET_RESULTS_FILTERS, RESET_RESULTS_FILTERS, RESULTS_TO_PRINT_BEFORE, RESULTS_TO_PRINT_AFTER, SET_RESULTS_TO_PRINT, RESET_RESULTS_TO_PRINT, SET_RESULTS_PRINTING } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case RESULTS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case RESULTS_AFTER:
			return {
				...state,
				isLoading: false
			};
		case SET_RESULTS:
			return {
				...state,
				results: payload.results.item2,
				total: payload.results.item1
			};
		case SET_RESULTS_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					...payload.filters
				}
			};
		case RESET_RESULTS_FILTERS:
			return {
				...state,
				filters: {
					page: 1,
					limit: state.filters.limit,
					type: "",
					eventId: "",
					period: "24_hours",
					...getDefaultDate()
				}
			};
		case RESULTS_TO_PRINT_BEFORE:
			return {
				...state,
				resultsToPrintIsLoading: true
			};
		case RESULTS_TO_PRINT_AFTER:
			return {
				...state,
				resultsToPrintIsLoading: true
			};
		case SET_RESULTS_TO_PRINT:
			return {
				...state,
				resultsToPrint: payload.results.item2
			};
		case RESET_RESULTS_TO_PRINT:
			return {
				...state,
				resultsToPrint: []
			};
		case SET_RESULTS_PRINTING:
			return {
				...state,
				printingResults: payload.type
			};
		default:
			return state;
	}
};
