export const VS_PLAYER_SELECTOR_ID = "vs--custom-player"
export const PLAYER_WRAPPER_CLASS = "player-wrapper"
export const PLAYER_CONTAINER_CLASS = "player-container"
export const PLAYER_VIDEO_CLASS = "player-video"
export const POSTER_WRAPPER_CLASS = "poster-wrapper"
export const POSTER_CONTAINER_CLASS = "poster-container"
export const MEDIA_CONTROLS_CLASS = "media-controls"
export const MEDIA_CONTROL_PLAY_CLASS = "media-control-play"
export const MEDIA_CONTROL_GROUP_CLASS = "media-control-group"
export const MEDIA_CONTROL_VOLUME_CLASS = "media-control-volume"
export const MEDIA_CONTROL_FULLSCREEN_CLASS = "media-control-fullscreen"
export const ICON_POSTER_PLAY_CLASS = "icon-poster-play"
export const ICON_POSTER_LOADING_CLASS = "icon-poster-loading"
export const PLAYER_OVERLAY_CLASS = "player-overlay"
export const HIDDEN_CLASS = "vs--display-none"
export const PLAYER_ICON_CLASS = "player-icon"
export const MEDIA_CONTROL_STATE_PLAYING = "media-control-state-playing"
export const MEDIA_CONTROL_STATE_FULLSCREEN = "media-control-state-fullscreen"
export const MEDIA_CONTROL_STATE_MUTED = "media-control-state-muted"


export const PLAYER_EVENT_PROPERTY_NAMES ={
	ERROR: "onError",
	EVENT: "onEvent",
	MEDIA_INFO: "onMediaInfo",
	MESSAGE: "onMessage",
	MUTED: "onMuted",
	PAUSE: "onPause",
	PROGRESS: "onProgress",
	RESUME: "onResume",
	STATE: "onStats"
}