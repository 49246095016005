import  { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import Button from "components/ui/button";
import TemplateItem from "./item";

import { setShowTemplates, saveTemplate } from "store/actions/templates/templates.actions";
import { setSessionTemplateType } from "store/actions/auth/auth.actions";

import { CASHIER_TEMPLATES } from "constants/template.constants";

const Templates = ({ sessionTemplateType, setShowTemplates, saveTemplate, setSessionTemplateType }) => {
	const [selectedMode, setSelectedMode] = useState("light");
	const [savedTemplateType, setSavedTemplateType] = useState(null);

	const { t } = useTranslation();

	const handleSelectTemplatesMode = (e) => {
		const { currentTarget } = e;
		const buttonMode = currentTarget.dataset.mode;

		setSelectedMode(buttonMode);
	};

	const handleCancel = () => {
		setShowTemplates(false);
		setSessionTemplateType(savedTemplateType);
	};

	const handleSave = () => {
		saveTemplate(sessionTemplateType);
	};

	const templatesByMode = useMemo(
		() =>
			CASHIER_TEMPLATES.filter((template) => {
				return template.variation === selectedMode;
			}),
		[selectedMode]
	);

	const foundTemplateByType = useMemo(() => {
		return CASHIER_TEMPLATES.find((template) => template.type === sessionTemplateType);
	}, [sessionTemplateType]);

	useEffect(() => {
		if (foundTemplateByType) {
			setSelectedMode(foundTemplateByType.variation);

			setSavedTemplateType(foundTemplateByType.type);
		}
	}, []);

	return (
		<div className="vs--templates-container">
			<div className="vs--templates-header">{t("cashier.templates_header_title")}</div>
			<div className="vs--templates-content-block">
				<div className="vs--templates-tabs-block">
					<Button data-mode="light" type="button" className={"vs--templates-tab-button" + (selectedMode === "light" ? " vs--templates-tab-button-selected" : "")} onClick={handleSelectTemplatesMode}>
						{t("cashier.templates_tab_light_mode")}
					</Button>
					<Button data-mode="dark" type="button" className={"vs--templates-tab-button" + (selectedMode === "dark" ? " vs--templates-tab-button-selected" : "")} onClick={handleSelectTemplatesMode}>
						{t("cashier.templates_tab_dark_mode")}
					</Button>
				</div>
				<div className="vs--templates-list-block">
					{templatesByMode.map((template) => (
						<TemplateItem {...template} />
					))}
				</div>
				<div></div>
			</div>
			<div className={`vs--templates-actions-block-${foundTemplateByType.variation}`}>
				<Button type="button" onClick={handleCancel} data-mode={foundTemplateByType.variation}>
					{t("cashier.cancel")}
				</Button>
				<Button type="button" onClick={handleSave} data-mode={foundTemplateByType.variation}>
					{t("cashier.save")}
				</Button>
			</div>
		</div>
	);
};

Templates.propTypes = {
	// Redux state of session's current template type
	sessionTemplateType: PropTypes.number,
	// Redux action which controls Templates component visibility
	setShowTemplates: PropTypes.func,
	// Redux action which saves permanently session's template type
	saveTemplate: PropTypes.func,
	// Redux action which sets current template type
	setSessionTemplateType: PropTypes.func
};

const mapStateToProps = (state) => {
	let sessionTemplateType = state.auth.session.templateType;

	if (sessionTemplateType < 1) {
		sessionTemplateType = 1;
	}

	return {
		sessionTemplateType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setShowTemplates: (bool) => {
		dispatch(setShowTemplates(bool));
	},
	saveTemplate: (templateType) => {
		dispatch(saveTemplate(templateType));
	},
	setSessionTemplateType: (type) => {
		dispatch(setSessionTemplateType(type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
