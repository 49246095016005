import { useState, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isCupGame, isChampionsCupGame, isLeg1 } from "utils/common";
import { CUP_GAMES_FINAL_ROUND_NUMBER } from "constants/game.constants";
import { GAME_STATUSES } from "constants/game.constants";

const getCurrentGameType = (state) => state.game?.currentGameType ?? 0;
const getCurrent = (state) => state.game?.current ?? null;
const getLiveAndUpcomings = (state) => state.game?.liveAndUpcomings?.data ?? [];
const defaultState = { gameIsNotAvailable: false, countdownValue: null };
const setDefaultsIfNeed = (objOfComparing, setterCallback) => {
	if (Object.keys(defaultState).some((key) => defaultState[key] !== objOfComparing[key])) {
		setterCallback({ ...defaultState });
	}
};

const useUnavailableGameLayer = () => {
	const [gameAvailableState, setGameAvailableState] = useState({ ...defaultState });
	/* Need use useRef for remove dependencies of useLayoutEffect */
	const stateRef = useRef();
	stateRef.current = gameAvailableState;
	const currentGameType = useSelector(getCurrentGameType);
	const current = useSelector(getCurrent);
	const liveAndUpcomings = useSelector(getLiveAndUpcomings);

	useLayoutEffect(() => {
		if (!isCupGame(currentGameType)) {
			return setDefaultsIfNeed(stateRef.current, setGameAvailableState);
		}
		const currentRound = liveAndUpcomings.find((lau) => lau.id === current);
		if (!currentRound) {
			return setDefaultsIfNeed(stateRef.current, setGameAvailableState);
		}
		if (currentRound.orderNumber === 1 || currentRound.orderNumber === CUP_GAMES_FINAL_ROUND_NUMBER[currentGameType]) {
			return setDefaultsIfNeed(stateRef.current, setGameAvailableState);
		}
		if (currentRound.status !== GAME_STATUSES.STARTED) {
			return setDefaultsIfNeed(stateRef.current, setGameAvailableState);
		}
		if (isChampionsCupGame(currentGameType) && isLeg1(currentRound.orderNumber)) {
			return setDefaultsIfNeed(stateRef.current, setGameAvailableState);
		}
		const nextRound = liveAndUpcomings.find((lau) => [GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED].includes(lau.status));
		if (!nextRound) {
			return setDefaultsIfNeed(stateRef.current, setGameAvailableState);
		}
		setGameAvailableState((prev) => ({
			...prev,
			gameIsNotAvailable: true,
			countdownValue: nextRound.preambleStartTime
		}));
	}, [currentGameType, current, liveAndUpcomings, setGameAvailableState]);

	return gameAvailableState;
};

export default useUnavailableGameLayer;
