import PropTypes from "prop-types";

const InfoWrapper = ({ children }) => {
	return (
		<div className="vs--match-info vs--flex">
			<div className="vs--match-info-content">{children}</div>
		</div>
	);
};

InfoWrapper.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};

export default InfoWrapper;
