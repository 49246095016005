import { Fragment, useEffect, useState } from "react"
import { PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS, PENALTY_SHOOTOUT_MARKETS_GROUPS } from "constants/market.constants";
import MarketItem from "../marketItem";
import MarketItemByTab from "../marketItemByTab";
import { useTranslation } from "react-i18next";
import { GAME_TYPE } from "constants/game.constants";

const PenaltyShotOutcomeContents = ({ groupedMarkets, ...props }) => {

	const { t } = useTranslation()

	const [tabs, setTabs] = useState(() => {
		const newTabs = [];
		Object.keys(groupedMarkets).forEach((key) => {
			groupedMarkets[key].marketsInfo.markets.forEach(market => {
				if (market.argument && !newTabs.includes(market.argument)) {
					newTabs.push(market.argument)
				}
			})
		})

		return newTabs;
	})


	const [activeTab, setActiveTab] = useState(() => tabs[0])

	useEffect(() => {
		const newTabs = [];
		Object.keys(groupedMarkets).forEach((key) => {
			groupedMarkets[key].marketsInfo.markets.forEach(market => {
				if (market.argument && !newTabs.includes(market.argument)) {
					newTabs.push(market.argument)
				}
			})
		})

		setTabs(newTabs)
		setActiveTab(prev => {
			if (prev && newTabs.includes(prev)) {
				return prev
			}

			return newTabs[0];
		})

	}, [groupedMarkets, setTabs, setActiveTab])


	const renderMarketItem = (groupName, groupFilterFn = () => true) => {
		if (!groupedMarkets[groupName]) {
			return null
		}
		const groupedMarketsProps = {...groupedMarkets[groupName]}
		groupedMarketsProps.marketContainer = groupedMarketsProps.marketContainer.map(marketRow => {
			return marketRow.filter(odd => odd.argument === activeTab && groupFilterFn(odd))
		})
		return (
			<MarketItem {...groupedMarketsProps} {...Object.assign({}, props, { style: { marginBottom: 24 } })} />
		)
	}

	return (
		<div className="vs--flex vs--flex-col vs--pl-32">
			<div className="vs--markets-list-item-tabs vs--pb-16 vs--pt-16 vs--flex-equal">
				{
					tabs.map((tab) => (
						<span
							key={tab}
							onClick={() => setActiveTab(tab)}
							className={"vs--markets-list-item-tab" + (tab === activeTab ? " vs--markets-list-item-tab-active" : "")}
						>
							{t("common.round")} {tab}
						</span>
					))
				}
			</div>
			<div className="vs--flex vs--flex-row vs--flex-equal">
				<div className="vs--flex vs--flex-col vs--flex-equal">
					<div className="vs--markets-list-item-header vs--mb-24 vs--mt-12">
						<span className="vs--title vs--font-big vs--font-medium vs--font-condensed">
							{t(`markets.${GAME_TYPE.PENALTY_SHOOTOUT}.team`)} 1
						</span>
					</div>
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS)
					}
					{
						renderMarketItem(PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME])
					}
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME)
					}
				</div>
				<div className="vs--markets-border-regular" />
				<div className="vs--flex vs--flex-col vs--flex-equal vs--ml-16">
					<div className="vs--markets-list-item-header vs--mb-24 vs--mt-12">
						<span className="vs--title vs--font-big vs--font-medium vs--font-condensed">
							{t(`markets.${GAME_TYPE.PENALTY_SHOOTOUT}.team`)} 2
						</span>
					</div>
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS)
					}
					{
						renderMarketItem(PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME])
					}
					{
						renderMarketItem(PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME)
					}
				</div>
			</div>
		</div>
	)
}
export default PenaltyShotOutcomeContents