import { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Tooltip from "components/ui/tooltip";

import { setShowReprintLastTicket } from "store/actions/tickets/reprintLastTicket.actions";
import { getUrlVars } from "utils/common";

const Reprint = ({ lastBetReprintEnabled, setShowReprintLastTicket }) => {
	const { t } = useTranslation();
	const isPreview = getUrlVars()["isPreview"];

	if (!lastBetReprintEnabled) {
		return <Fragment />;
	}

	return (
		<Tooltip title={t("cashier.reprintLastTicket")} placement="bottom" overlayClassName="vs--reprint-tooltip">
			<div className="vs--pr-24 vs--print-icon" onClick={() => !isPreview && setShowReprintLastTicket(true)}>
				<i className="ic_reprint" />
			</div>
		</Tooltip>
	);
};

/** Reprint propTypes
 * PropTypes
 */
Reprint.propTypes = {
	/** Redux property to render reprint */
	lastBetReprintEnabled: PropTypes.bool,
	/** Redux action to show reprint last ticket modal */
	setShowReprintLastTicket: PropTypes.func
};

const mapStateToProps = (state) => ({
	lastBetReprintEnabled: state.auth.session.lastBetReprintEnabled
});

const mapDispatchToProps = (dispatch) => ({
	setShowReprintLastTicket: (show) => {
		dispatch(setShowReprintLastTicket(show));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Reprint);
