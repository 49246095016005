import PropTypes from "prop-types";

import { FormContext } from "./formContext";
import Form from ".";

const InternalForm = ({ children, form, initialValues, onFinish, onFinishFailed, onReset, ...props }) => {
	const [formInstance] = Form.useForm(form);

	const handleFormSubmit = (e) => {
		e.preventDefault();

		formInstance
			.validateFields()
			.then((fieldsValueObj) => {
				if (typeof onFinish === "function") {
					try {
						onFinish(fieldsValueObj);
					} catch (error) {
						// Should print error if `onFinish` callback failed
						console.error(error);
					}
				}
			})
			.catch((validationsMessageList) => {
				if (typeof onFinishFailed === "function") {
					onFinishFailed(validationsMessageList);
				}
				console.log(validationsMessageList);
			});
	};

	const handleFormReset = (e) => {
		e.preventDefault();

		if (typeof onReset === "function") {
			onReset();
			return;
		}

		if (initialValues) {
			formInstance.setFieldsValue(initialValues);
		}
	};

	return (
		<form onSubmit={handleFormSubmit} onReset={handleFormReset} {...props}>
			<FormContext.Provider value={{ formInstance, initialValues }}>{children}</FormContext.Provider>
		</form>
	);
};

/** InternalForm propTypes
 * PropTypes
 */
InternalForm.propTypes = {
	/** Items to render */
	children: PropTypes.node,
	/** Form instance generated in parent component */
	form: PropTypes.object,
	/** Form items initial values (will be set by item name)*/
	initialValues: PropTypes.object,
	/** Fires when the form is submitted and all validations are passed */
	onFinish: PropTypes.func,
	/** Fires when a form is submitted but at least one validation fails */
	onFinishFailed: PropTypes.func,
	/** Fires when a button type of reset clicked */
	onReset: PropTypes.func
};

export default InternalForm;
