

import Card from './card';


import SingleCard from "./singleCard";
import Cards from "./cards";
import { useLocation } from 'react-router';

const Levels = ({
	className = "", 
	data,
	currencyDecimalCount,
	currencyCode,
	currencySymbol,
	isInOtherRoute = false
}) => {

	const props = {
		className,
		data,
		currencyDecimalCount,
		currencyCode,
		currencySymbol
	}

	return (
		<div className="vs--jackpot-content-levels vs--flex vs--align-center vs--justify-center vs--active-jackpot-live-info-is-open">
			{
				isInOtherRoute && (data?.length === 1)
					? <SingleCard {...props} jackpot={data.at(0)} />
					: <Cards {...props} />
			}
		</div>
	)
};

export default Levels;