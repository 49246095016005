import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common";
import { flagCodeMapper } from "utils/flags";
import { RESULTS } from "constants/game.constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const TeamsInfo = ({ event, showGoalCount, showLastResults, className, leftColumnClassName, middleColumnClassName, rightColumnClassName, style, leftColumnStyle, middleColumnStyle, rightColumnStyle, textElementClassName, teamsHeadToHead = {} }) => {
	const { t } = useTranslation();

	const getResultLetter = (result) => {
		const textKey = result === RESULTS.WIN ? "livemonitor.football_england_league.w" : result === RESULTS.LOSE ? "livemonitor.football_england_league.l" : "livemonitor.football_england_league.d";

		const text = t(textKey);

		return text?.[0] || "";
	};
	const getTeamName = (teamCountryCode) => {
		if (!teamCountryCode) {
			return "";
		}
		const text = t(`livemonitor.football_england_league.${teamCountryCode.toLowerCase()}`);
		return text || "";
	};

	const lastResults = useMemo(() => {
		const results = { team1: [], team2: [] };
		if (event) {
			const countryCode1 = event?.gameData?.team1?.countryCode;
			const countryCode2 = event?.gameData?.team2?.countryCode;

			if (!countryCode1 || !countryCode2) {
				return results;
			}

			const currentTeams = teamsHeadToHead[`${countryCode1}-${countryCode2}`] || [];

			currentTeams.forEach((item) => {
				if (item.matchResult === RESULTS.WIN) {
					if (item.team1.countryCode === countryCode1) {
						results.team1.push(RESULTS.WIN);
						results.team2.push(RESULTS.LOSE);
					} else {
						results.team1.push(RESULTS.LOSE);
						results.team2.push(RESULTS.WIN);
					}
				} else if (item.matchResult === RESULTS.LOSE) {
					if (item.team1.countryCode === countryCode1) {
						results.team1.push(RESULTS.LOSE);
						results.team2.push(RESULTS.WIN);
					} else {
						results.team1.push(RESULTS.WIN);
						results.team2.push(RESULTS.LOSE);
					}
				} else {
					results.team1.push(RESULTS.DRAW);
					results.team2.push(RESULTS.DRAW);
				}
			});
		}
		results.team2.reverse();
		return results;
	}, [event, teamsHeadToHead, showLastResults]);

	return (
		<div style={style} className={mergeClassNames("vs--flex vs--flex-row", className)}>
			<div style={leftColumnStyle} className={mergeClassNames("vs--flex vs--flex-col vs--flex-grow", leftColumnClassName)}>
				<div className="vs--flex vs--flex-row vs--align-center vs--justify-end">
					<span className={textElementClassName}>{getTeamName(event.gameData.team1.countryCode)}</span>
					&nbsp;
					<img className="teams-flag" src={buildPathToStaticFolderOfCDN(`images/flags/${flagCodeMapper(event.gameType, event.gameData.team1.flagCode)}`)} alt={event.gameData.team1.countryCode} />
				</div>
				{showLastResults ? (
					<div className="vs--flex vs--flex-row">
						{lastResults.team1.map((result, i) => {
							return (
								<div key={i} className={`last-result last-result-${result}`}>
									<span className="last-result-text">{getResultLetter(result)}</span>
								</div>
							);
						})}
					</div>
				) : null}
			</div>

			<div style={middleColumnStyle} className={mergeClassNames("vs--flex vs--flex-col vs--justify-center vs--align-center", middleColumnClassName)}>
				{showGoalCount ? `${event.gameData.team1.goalCount}-${event.gameData.team2.goalCount}` : "vs"}
			</div>

			<div style={rightColumnStyle} className={mergeClassNames("vs--flex vs--flex-col vs--flex-grow", rightColumnClassName)}>
				<div className="vs--flex vs--flex-row vs--align-center vs--justify-start">
					<img className="teams-flag" src={buildPathToStaticFolderOfCDN(`images/flags/${flagCodeMapper(event.gameType, event.gameData.team2.flagCode)}`)} alt={event.gameData.team2.countryCode} />
					&nbsp;
					<span className={textElementClassName}>{getTeamName(event.gameData.team2.countryCode)}</span>
				</div>
				{showLastResults ? (
					<div className="vs--flex vs--flex-row">
						{lastResults.team2.map((result, i) => {
							return (
								<div key={i} className={`last-result last-result-${result}`}>
									<span className="last-result-text">{getResultLetter(result)}</span>
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};
export default TeamsInfo;
