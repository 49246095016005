import { GAME_TYPE } from "./game.constants";

export const MARKETS_GROUP_NAME = {
	REGULAR: "REGULAR",
	LIVE: "LIVE",
	RESULTS: "RESULTS"
};

export const MAIN_SCENES = {
	[GAME_TYPE.ENGLISH_LEAGUE]: {
		SUGGESTED_MARKETS: "SUGGESTED_MARKETS",
		LEAG_SPECIAL_AND_STATISTICS: "LEAG_SPECIAL_AND_STATISTICS",
		LIVE_INFO: "LIVE_INFO",
		RESULTS: "RESULTS"
	}
};

export const NEXT_SCENES = {
	[GAME_TYPE.ENGLISH_LEAGUE]: {
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].SUGGESTED_MARKETS]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LEAG_SPECIAL_AND_STATISTICS,
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LEAG_SPECIAL_AND_STATISTICS]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LIVE_INFO,
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LIVE_INFO]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].RESULTS,
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].RESULTS]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].SUGGESTED_MARKETS
	}
};

export const PREV_SCENES = {
	[GAME_TYPE.ENGLISH_LEAGUE]: {
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].SUGGESTED_MARKETS]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].RESULTS,
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LEAG_SPECIAL_AND_STATISTICS]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].SUGGESTED_MARKETS,
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LIVE_INFO]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LEAG_SPECIAL_AND_STATISTICS,
		[MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].RESULTS]: MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE].LIVE_INFO
	}
};
