export const CALENDAR_TYPE = {
	ARABIC: "Arabic",
	HEBREW: "Hebrew",
	ISO_8601: "ISO 8601",
	US: "US"
};

export const CALENDAR_VIEW = {
	MONTH: "month",
	YEAR: "year",
	DECADE: "decade",
	CENTURY: "century"
};

export const WEEKDAYS = {
	SUNDAY: 0,
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6
};

export const CALENDAR_TYPE_LOCALES = {
	[CALENDAR_TYPE.US]: ["en-CA", "en-US", "es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-DO", "es-EC", "es-GT", "es-HN", "es-MX", "es-NI", "es-PA", "es-PE", "es-PR", "es-SV", "es-VE", "pt-BR"],
	[CALENDAR_TYPE.ARABIC]: [
		// ar-LB, ar-MA intentionally missing
		"ar",
		"ar-AE",
		"ar-BH",
		"ar-DZ",
		"ar-EG",
		"ar-IQ",
		"ar-JO",
		"ar-KW",
		"ar-LY",
		"ar-OM",
		"ar-QA",
		"ar-SA",
		"ar-SD",
		"ar-SY",
		"ar-YE",
		"dv",
		"dv-MV",
		"ps",
		"ps-AR"
	],
	[CALENDAR_TYPE.HEBREW]: ["he", "he-IL"]
};
