import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { QR_TICKET_BEFORE, QR_TICKET_AFTER, SET_QR_TICKET } from "../../actionTypes";

const setQRTicketBefore = () => ({
	type: QR_TICKET_BEFORE
});

const setQRTicketAfter = () => ({
	type: QR_TICKET_AFTER
});

const setQRTicket = (ticket) => ({
	type: SET_QR_TICKET,
	payload: { ticket }
});

export const getQRTicket = (id, cb) => {
	return (dispatch) => {
		dispatch(setQRTicketBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_TICKET}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setQRTicket(data));
				dispatch(setQRTicketAfter());
			})
			.catch((ex) => {
				dispatch(setQRTicketAfter());
			});
	};
};
