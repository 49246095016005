import  { useRef } from "react";
import PropTypes from "prop-types";

import InputPassword from "./inputPassword";
import InputTextArea from "./inputTextArea";

const INPUT_STATUS_STYLE_CLASSES = {
	normal: "vs--ui-input-normal",
	error: "vs--ui-input-error",
	warning: "vs--ui-input-warning"
};

const Input = ({ className = "", prefix, suffix, disabled = false, placeholder, status = "normal", type = "text", onChange, ...props }) => {
	const inputAreaRef = useRef(null);

	const focusInput = () => {
		if (disabled) {
			return;
		}

		inputAreaRef.current.focus();
	};

	const internalChangeHandler = (e) => {
		const value = e.target.value;

		if (typeof onChange === "function") {
			onChange(value, e);
		}
	};

	return (
		<div className={`vs--ui-input ${INPUT_STATUS_STYLE_CLASSES[status]} ${className}`} onClick={focusInput} aria-disabled={disabled} data-input-type={type}>
			{typeof prefix === "function" && <div className="vs--ui-input-prefix">{prefix()}</div>}
			<span className="vs--ui-input-box" data-placeholder={placeholder}>
				<input type={type} className={`vs--ui-input-box-area`} ref={inputAreaRef} disabled={disabled} placeholder={placeholder} onChange={internalChangeHandler} {...props} />
			</span>
			{Boolean(suffix) && <div className="vs--ui-input-suffix">{suffix}</div>}
		</div>
	);
};

/** Input propTypes
 * PropTypes
 */
Input.propTypes = {
	/** Class to rewrite default styles of input */
	className: PropTypes.string,
	/** Render icon before input */
	prefix: PropTypes.func,
	/** Icon after input */
	suffix: PropTypes.node,
	/** Disabled input or not */
	disabled: PropTypes.bool,
	/** Placeholder of input */
	placeholder: PropTypes.string,
	/** Status of input */
	status: PropTypes.oneOf(["normal", "error", "warning"]),
	/** Input type */
	type: PropTypes.string,
	/** Fires when input value changed */
	onChange: PropTypes.func
};

Input.Password = InputPassword;
Input.TextArea = InputTextArea;

export default Input;
