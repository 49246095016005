import { Fragment, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Banners from "./banners";
import Tooltip from "components/ui/tooltip";

import { isNullish, mergeClassNames, numberWithSpaces, toFixed } from "utils/common";
import Loader from "components/ui/loader";
import { GAME_TYPE_TEXTS_BY_NAME } from "constants/game.constants";
import { REPORT_GAME_TYPES } from "constants/reports.constants";
import { TURNOVER_TYPE } from "constants/common.constants";
import turnoverType from "types/turnover.type";
import sessionType from "types/session.type";

/** Reports Modal Turnover Tab Content Component */
const Turnover = ({ turnover, isLoading, session, type }) => {
	const { t } = useTranslation();

	const classes = [" vs--report-table-container-fields", " vs--report-table-container-games", " vs--report-table-container-total"];

	/** Function to get respective value for respective game type and field from turnover report
	 * @function
	 * @param {string} gameType - game type or total
	 * @param {string} reportField - the field name
	 * @returns {number}
	 * @memberOf Turnover
	 */
	const getColValue = useCallback(
		(gameType, reportField) => {
			const decimalCount = session?.currency?.decimalCount ?? 0;

			const arr = ["betCount", "betSlipCount", "playedCount", "canceledCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"];

			if (gameType !== REPORT_GAME_TYPES.TOTAL) {
				const res = turnover?.report?.[gameType]?.[reportField] ?? "";
				if (res !== "") {
					if (arr.includes(reportField)) {
						return res;
					} else {
						return numberWithSpaces(toFixed(res, decimalCount));
					}
				} else {
					return "";
				}
			} else {
				const res = turnover[reportField] !== undefined ? turnover[reportField] : "";
				if (arr.includes(reportField)) {
					return res;
				} else {
					return numberWithSpaces(toFixed(res, decimalCount));
				}
			}
		},
		[turnover]
	);

	/** Table Rows data */
	const tableRows = useMemo(() => {
		switch (type) {
			case TURNOVER_TYPE.SIMPLE:
				return [
					{
						key: "turnover",
						title: t("cashier.turnover")
					},
					{
						key: "realGGR",
						title: t("cashier.ggr"),
						isSummery: true
					},
					{
						key: "betSlipCount",
						title: t("cashier.betSlipCount")
					},
					{
						key: "betCount",
						title: t("cashier.betCount")
					},
					{
						key: "wonCount",
						title: t("cashier.wonBetsCount")
					},
					{
						key: "won",
						title: t("cashier.won")
					},
					{
						key: "payout",
						title: t("cashier.payout")
					},
					{
						key: "wonUnpaid",
						title: t("cashier.wonNotClaimed")
					},
					{
						key: "canceledCount",
						title: t("cashier.canceledCount")
					},
					{
						key: "canceled",
						title: t("cashier.cancel")
					}
				];
			case TURNOVER_TYPE.BONUS:
				return [
					{
						key: "bonusTurnover",
						title: t("cashier.turnover")
					},
					{
						key: "bonusBetSlipsCount",
						title: t("cashier.betSlipCount")
					},
					{
						key: "bonusWonCount",
						title: t("cashier.wonBetsCount")
					},
					{
						key: "bonusWon",
						title: t("cashier.won")
					},
					{
						key: "bonusPayout",
						title: t("cashier.payout")
					},
					{
						key: "bonusWonUnpaid",
						title: t("cashier.wonNotClaimed")
					}
				];

			default:
				break;
		}
		return [];
	}, [type]);

	const renderBlock = useCallback(({ classsNamePrefix, text, withTitle, key, info = null }) => {
		return (
			<div className={mergeClassNames("vs--modal-table-block", !isNullish(classsNamePrefix) && `vs--modal-table-block-${classsNamePrefix}`)} key={key}>
				<span className={`${key === 0 ? "vs--modal-table-head" : "vs--modal-table-row"} vs--flex vs--flex-row vs--align-center vs--justify-between vs--text-right vs--title vs--font-regular vs--font-exstrasmall`} title={withTitle ? text : null}>
					{text}
					{info && (
						<Tooltip title={info} overlayClassName="vs--modal-table-block-tooltip">
							<i className="ic_information vs--font-small vs--ml-auto vs--pr-10" />
						</Tooltip>
					)}
				</span>
			</div>
		);
	}, []);

	const structure = useMemo(() => {
		const compareFunction = (a, b) => {
			// If either element is the "0" key, it should be placed at the end
			if (a === REPORT_GAME_TYPES.MIXED_MULTI) {
				return 1;
			} else if (b === REPORT_GAME_TYPES.MIXED_MULTI) {
				return -1;
			}

			return 0;
		};
		const games = [...Object.keys(turnover.report).sort(compareFunction), REPORT_GAME_TYPES.TOTAL];

		return [null, tableRows].reduce(
			(acc, colGroups, i) => {
				if (!Array.isArray(colGroups)) {
					if (colGroups === null) {
						acc[i].push([
							renderBlock({ text: " ", withTitle: false, key: 0 }),
							games.map((gameType) => renderBlock(
								{
									classsNamePrefix: gameType,
									text: t(GAME_TYPE_TEXTS_BY_NAME[gameType]),
									withTitle: true, key: gameType,
									info: gameType === REPORT_GAME_TYPES.MIXED_MULTI ? t("cashier.mixedMultiInfo") : null
								}
							))
						]);
					}
					return acc;
				}

				acc[i] = acc[i].concat(
					colGroups.map((row) => {
						return (
							<div key={row.key} className="vs--flex vs--flex-col vs--flex-equal">
								{[
									renderBlock({ text: row.title, withTitle: false, key: 0 }),
									...games.map((gameType) => renderBlock({
										classsNamePrefix: gameType,
										text: getColValue(gameType, row.key),
										withTitle: true,
										key: `${row.key}_${gameType}`
									}))
								]}
							</div>
						);
					})
				);
				return acc;
			},
			[[], []]
		);
	}, [turnover, getColValue, renderBlock, tableRows]);

	const getBannersData = () => {
		if (type === TURNOVER_TYPE.BONUS) {
			const {
				bonusTurnover,
				bonusBetSlipsCount,
				bonusWon
			} = turnover;

			return {
				bonusTurnover,
				bonusBetSlipsCount,
				bonusWon
			}
		}

		const {
			betCount,
			betSlipCount,
			turnover: gameTurnover,
			realGGR: ggr,
			startingLimit,
			finalLimit,
			won
		} = turnover;

		return {
			betSlipCount,
			betCount,
			turnover: gameTurnover,
			ggr,
			startingLimit,
			won,
			finalLimit
		}
	};

	return !isLoading ? (
		<div className="vs--modal-wrapper vs--report--modal-wrapper vs--flex vs--flex-col vs--flex-equal">
			<Banners type={type} data={getBannersData()} />
			<div className="vs--modal-table vs--flex vs--flex-row vs--report-table vs--flex-equal">
				{structure.map((elem, i) => {
					return (
						<div key={i} className={"vs--flex" + classes[i] + (i % 2 !== 0 ? " vs--flex-row vs--flex-equal" : " vs--flex-col")}>
							{elem}
						</div>
					);
				})}
			</div>
		</div>
	) : (
		<Loader style={{ height: "100%" }} />
	);
};

/** Turnover propTypes
 * PropTypes
 */
Turnover.propTypes = {
	/** Redux state property, current session */
	turnover: turnoverType,
	/** Redux state property, is true when loading turnover report */
	isLoading: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, turnover type */
	type: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		turnover: state.report.turnover,
		isLoading: state.report.isLoading,
		session: state.auth.session,
		type: state.report.type
	};
};

export default connect(mapStateToProps, null)(Turnover);
