import  { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import runMarketUtilsFunction from "utils/markets/run";
import { isSeasonGame, isLeagueGame } from "utils/common";
import { GAME_TYPE } from "constants/game.constants";
import eventType from "types/event.type";

/** Markets Tabs component */
const Tabs = ({ onTabClick, activeTab, currentGameType, current, matches, special }) => {
	const { t } = useTranslation();
	const tabs = useMemo(() => runMarketUtilsFunction("getMarketsTabs", [], currentGameType), [currentGameType]);

	/** Function which fires on tab click
	 * @function
	 * @param {string} tab - the clicked tab value
	 * @memberOf Tabs
	 */
	const handleTabClick = (market) => {
		onTabClick && onTabClick(market);
	};

	/** Function, checks wheter the tab has markets
		* @function
		* @param {string} tab - the tab value to check
		* @returns {boolean} 
		* @memberOf Tabs
  */
	const hasTabMarkets = tab => {
		const currentMatch = matches.find(m => m.id === current);
		const currentMatchMarkets = currentMatch?.markets ?? {};
		if (isSeasonGame(currentGameType) || [GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(currentGameType)) { return true }
		return currentMatchMarkets[tab] && currentMatchMarkets[tab].map(el => el.odds ? el.odds.length : 0).reduce((next, current) => next + current, 0) > 0;
	}

	const disabledTabs = useMemo(() => {
		const tabsInfo = tabs.reduce((acc, tab) => {
			acc[tab] = false;
			if (tab === "round") {
				const currentMatch = matches.find((m) => m.id === current);
				const currentMatchMarkets = currentMatch?.markets ?? {};
				acc[tab] = !currentMatchMarkets[tab] || !currentMatchMarkets[tab].length || !currentMatchMarkets[tab].some((m) => Array.isArray(m.odds) && m.odds.length > 0);
			}
			if (tab === "fulltime") {
				const currentMatchMarkets = special?.markets ?? [];
				acc[tab] =
					!currentMatchMarkets ||
					!currentMatchMarkets
						.map((m) => (m && Array.isArray(m.odds) ? m.odds.flat() : []))
						.flat()
						.some((m) => m.factor > 1.01);
			}
			return acc;
		}, {});
		return tabsInfo;
	}, [currentGameType, tabs, matches, current, special]);

	useEffect(() => {
		if (disabledTabs[activeTab]) {
			handleTabClick("main");
		}
	}, [activeTab, disabledTabs, handleTabClick]);

	return (
		<div className="vs--markets-tabs vs--flex">
			{tabs
				.filter((tab) => hasTabMarkets(tab))
				.map((tab) => (
					<div
						key={tab}
						className={"vs--markets-tab vs--flex vs--flex-equal vs--justify-center vs--align-center vs--pl-12 vs--pr-12" + (!disabledTabs[tab] && activeTab === tab ? " vs--markets-tab-active" : disabledTabs[tab] ? " vs--markets-tab-disabled" : "")}
						onClick={() => !disabledTabs[tab] && handleTabClick(tab)}
					>
						<span title={tab !== "fulltime" || !isSeasonGame(currentGameType) ? t(`cashier.${tab}Section`) : t(`markets.${currentGameType}.${isLeagueGame(currentGameType) ? "League" : "Cup"}Special`)} className="vs--title vs--font-big vs--font-condensed vs--font-regular">
							{tab !== "fulltime" || !isSeasonGame(currentGameType) ? t(`cashier.${tab}Section`) : t(`markets.${currentGameType}.${isLeagueGame(currentGameType) ? "League" : "Cup"}Special`)}
						</span>
					</div>
				))}
		</div>
	);
};

/** Tabs propTypes
 * PropTypes
 */
Tabs.propTypes = {
	/** Function which will fire on tab click */
	onTabClick: PropTypes.func,
	/** Active tab */
	activeTab: PropTypes.string,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, current match id */
	current: PropTypes.number,
	/** Redux state property, all loaded matches */
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			event: eventType
		})
	),
	/** Redux state property of special markets */
	special: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType,
		current: state.game.current,
		matches: state.game.matches.data,
		special: state.season.special
	};
};

export default connect(mapStateToProps, null)(Tabs);
