import { getUrlVars } from "utils/common";

/** Generate the path to navigate, keeping required query params
 * @function
 * @param {string} path - path to navigate
 * @returns {string}
 */
const generatePathToNavigate = (path) => {
	const queryParamsToKeepWhenNavigating = ["user", "apiKey", "secret", "currency", "language"];
	let toPath = path;
	for (let param of queryParamsToKeepWhenNavigating) {
		if (getUrlVars()[param] && !getUrlVars(path)[param]) {
			if (toPath.indexOf("?") > -1) {
				toPath = toPath + "&" + param + "=" + getUrlVars()[param];
			} else {
				toPath = toPath + "?" + param + "=" + getUrlVars()[param];
			}
		}
	}
	return toPath;
};

/** Navigate to page
 * @function
 * @param {object} navigate
 * @param {string} path - path to navigate
 */
export const navigateToPage = (navigate, path) => {
	navigate(generatePathToNavigate(path));
};

/** Redirect to page
 * @function
 * @param {object} navigate
 * @param {string} path - path to redirect
 */
export const redirectToPage = (navigate, path) => {
	navigate(generatePathToNavigate(path), { replace: true });
};
