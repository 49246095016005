import PropTypes from "prop-types";
import Tooltip from "components/ui/tooltip"
import Flag from "components/ui/flag";
import PenaltyTooltipTitle from "./penaltyTooltipTitle";
import { mapPenaltyLiveInfoScene } from "utils/common";
import eventType from "types/event.type";

const PenaltyResultComponent = ({ event, placement }) => {
	
	const gameData = event.gameData;
	const team1 = gameData?.team1 ?? {};
	const team2 = gameData?.team2 ?? {};

	const team1CountryCode = team1.countryCode ?? "";
	const team2CountryCode = team2.countryCode ?? "";
	const team1GoalCount = team1.goalCount ?? "";
	const team2GoalCount = team2.goalCount ?? "";
	const team1FlagCode = team1.flagCode ?? "";
	const team2FlagCode = team2.flagCode ?? "";

	const complitedScenes = gameData?.complitedScenes ?? [];

	const filledScenes = (
		complitedScenes.length % 2 === 1
			? [...complitedScenes, null]
			: complitedScenes
	)

	const scenes = filledScenes
		.map((scene, i, arr) => {
			if (scene) {
				return mapPenaltyLiveInfoScene(scene)
			}
			return { teamName: `team${(i % 2) + 1}`, hasGoal: false, roundNo: Math.ceil(arr.length / 2) }
		})
		.reduce((acc, sceneInfo) => {
			acc[sceneInfo.teamName].push(sceneInfo)
			return acc;
		}, { team1: [], team2: [] })

	const renderScenes = (teamScenes) => {
		return teamScenes.map((scene) => {
			return (
				<Tooltip
					key={scene.roundNo}
					title={<PenaltyTooltipTitle scene={scene} />}
					placement={placement}
					crossAxis={0}
					positionStrategy={'fixed'}
					overlayClassName="vs--result-block-body-penalty"
				>
					<span
						className={
							"vs--match-result-penalty-round" +
							(scene.hasGoal ? " vs--match-result-penalty-round-goal" : " vs--match-result-penalty-round-nogoal")
						}
					>
						<span className="vs--font-smallest vs--font-medium">{scene.roundNo}</span>
					</span>
				</Tooltip>
			)
		})
	}

	return (
		<div className="vs--match-result-penalty-col vs--flex vs--flex-col">
			<div className="vs--match-result-penalty-row vs--flex vs--flex-row" >
				<span className={team1GoalCount > team2GoalCount ? "vs--match-winner" : null}>
					{team1GoalCount}
				</span>
				<Flag code={team1FlagCode} gameType={event.gameType} />
				<div className="vs--match-result-penalty-team-name">
					<span className="vs--font-capitalize">{team1CountryCode}</span>
				</div>
				{
					renderScenes(scenes.team1)
				}
			</div>
			<div className="vs--match-result-penalty-row vs--flex vs--flex-row" >
				<span className={team2GoalCount > team1GoalCount ? "vs--match-winner" : null}>
					{team2GoalCount}
				</span>
				<Flag code={team2FlagCode} gameType={event.gameType} />
				<div className="vs--match-result-penalty-team-name">
					<span className="vs--font-capitalize">{team2CountryCode}</span>
				</div>
				{
					renderScenes(scenes.team2)
				}
			</div>
		</div>
	)
}

PenaltyResultComponent.propTypes = {
	event: eventType,
	placement: PropTypes.oneOf(["top", "bottom"])
}

export default PenaltyResultComponent