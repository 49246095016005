import PropTypes from "prop-types";
import Tooltip from "components/ui/tooltip";
import { GAME_TYPE } from "constants/game.constants";

const RectWithTooltip = ({ name = "", number, title = "", isLast, placement, postfix, middlewares = [], overlayClassName }) => {
	return (
		<Tooltip key={number} placement={placement} title={`${name}${postfix}`} middlewares={middlewares} overlayClassName={`vs--ui-tooltip vs--result-block-body ${overlayClassName}`} positionStrategy={"fixed"}>
			<span className="vs--result-block vs--flex vs--align-center vs--mr-8">
				{title !== "" && (
					<>
						<span className="vs--font-exstrasmall vs--font-regular">{title}</span>
						{number !== undefined && <span className="vs--result-bock-line vs--ml-4 vs--mr-4">-</span>}
					</>
				)}

				{number !== undefined && (
					<span className={`vs--result-block-num vs--flex vs--font-exstrasmall vs--font-regular vs--font-medium vs--align-center vs--justify-center vs--mr-4 vs--place-${number}`}>
						<span className="vs--result-block-num-text">{number}</span>
					</span>
				)}

				{title !== "" && isLast && <span className="vs--mt-5">,</span>}
			</span>
		</Tooltip>
	);
};

RectWithTooltip.propTypes = {
	name: PropTypes.string,
	jockeyName: PropTypes.string,
	trainer: PropTypes.string,
	number: PropTypes.number,
	title: PropTypes.string,
	isLast: PropTypes.bool,
	placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
	middlewares: PropTypes.arrayOf(PropTypes.object),
	postfix: PropTypes.string,
	overlayClassName: PropTypes.string
};

const RaceRect = ({ rectData, gameType, overlayClassName = "", placement = "right", middlewares = [], crossAxis = 0 }) => {
	if (!rectData || !Array.isArray(rectData)) {
		return null;
	}
	return rectData.map((recObj, i) => {
		const postfix = gameType === GAME_TYPE.GREYHOUNDS_RACE ? recObj.trainer : recObj.jockeyName;
		return <RectWithTooltip {...recObj} key={recObj.number || i} postfix={postfix ? "/" + postfix : ""} isLast={rectData.length !== i + 1} overlayClassName={overlayClassName} placement={placement} middlewares={middlewares} crossAxis={crossAxis} />;
	});
};

RaceRect.propTypes = {
	/** The bet of component */
	rectData: PropTypes.array,
	placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
	middlewares: PropTypes.arrayOf(PropTypes.object),
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	overlayClassName: PropTypes.string
};

export default RaceRect;
