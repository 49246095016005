import i18n from "translations/config";

import RacingSportMarkets from "./racingSportMarkets";
import { RACING_MARKETS_GROUPS } from "constants/market.constants";

class SteeplechaseSportMarkets extends RacingSportMarkets {
	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof SteeplechaseSportMarkets
	 */
	getGroupsStructure() {
		return {
			main: [
				{ group: RACING_MARKETS_GROUPS.WINNER, title: RACING_MARKETS_GROUPS.WINNER, outcomes: "*", marketsCount: 12, type: "race" },
				{ group: RACING_MARKETS_GROUPS.PLACE, title: RACING_MARKETS_GROUPS.PLACE, outcomes: "*", marketsCount: 12, type: "race" },
				{ group: RACING_MARKETS_GROUPS.SHOW, title: RACING_MARKETS_GROUPS.SHOW, outcomes: "*", marketsCount: 12, type: "race" },
				{ group: RACING_MARKETS_GROUPS.PAIR, title: RACING_MARKETS_GROUPS.PAIR, outcomes: "*", type: "race" },
				{ group: RACING_MARKETS_GROUPS.TRIPLE, title: RACING_MARKETS_GROUPS.TRIPLE, outcomes: "*", type: "race" },
				{ group: RACING_MARKETS_GROUPS.PAIR_ANY_ORDER, title: RACING_MARKETS_GROUPS.PAIR_ANY_ORDER, outcomes: "*", type: "race" },
				{ group: RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER, title: RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER, outcomes: "*", type: "race" },
			],
			extra: [
				{ group: RACING_MARKETS_GROUPS.WINNER_NUMBER, title: RACING_MARKETS_GROUPS.WINNER_NUMBER, outcomes: ["Over", "Under"], marketsCount: 7, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: RACING_MARKETS_GROUPS.TOTAL_123_PLACED, title: RACING_MARKETS_GROUPS.TOTAL_123_PLACED, outcomes: ["Over", "Under"], marketsCount: 7, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: RACING_MARKETS_GROUPS.RACE_EVEN_ODD, title: RACING_MARKETS_GROUPS.RACE_EVEN_ODD, outcomes: ["Even", "Odd"], marketsCount: 1, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: RACING_MARKETS_GROUPS.WILL_NOT_FINISHERS, title: RACING_MARKETS_GROUPS.WILL_NOT_FINISHERS, outcomes: ["Yes", "No"], marketsCount: 1, marketCol: 2, gridBy: "col", oddBy: "row", argument: null },
				{ group: RACING_MARKETS_GROUPS.TOTAL_NOT_FINISHERS, title: RACING_MARKETS_GROUPS.TOTAL_NOT_FINISHERS, outcomes: ["Over", "Under"], marketsCount: 3, marketCol: 3, gridBy: "col", oddBy: "row", argument: null },
			]
		};
	}

	/** Get market name from systemName
	 * @function
	 * @param {array } marketName - the market name
	 * @param {object} gameData - Event data
	 * @param {number} argument - the market argument
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	getNameForMarketGroup(marketName, outcome, argument) {
		if ([RACING_MARKETS_GROUPS.WINNER_NUMBER, RACING_MARKETS_GROUPS.TOTAL_NOT_FINISHERS, RACING_MARKETS_GROUPS.TOTAL_123_PLACED].includes(marketName)) {
			return "%" + outcome + "% (" + argument + ")";
		} else if ([RACING_MARKETS_GROUPS.RACE_EVEN_ODD].includes(marketName)) {
			return "%" + outcome + "%";
		} else {
			return outcome;
		}
	}
}

export default SteeplechaseSportMarkets;
