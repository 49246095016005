import * as signalR from "@microsoft/signalr";
import store from "store/configureStore";

export default class SignalRUtils {
	constructor(connectionURL, callback) {
		this.connection = null;
		this.promise = null;
		this.connectionURL = connectionURL;
		this.callback = callback;
		this.buildConnection();
	}

	/** Init SignalR
	 * @function
	 * @memberof SignalRUtils
	 */
	buildConnection() {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(this.connectionURL, {
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets
			})
			.build();

		this.startConnection();

		this.connection.onclose((err) => {
			this.handleConnectionError();
		});
	}

	/** Start signalR connection if it is not started yet
	 * @function
	 * @returns {Promise}
	 * @memberof SignalRUtils
	 */
	startConnection() {
		if (!this.promise) {
			this.promise = this.connection
				.start()
				.then(() => {
					this.callback && this.callback();
				})
				.catch(() => {
					this.handleConnectionError();
				});
		}
		return this.promise;
	}

	/** Stop signalR connection if it necessary
	 * @function
	 * @returns {void}
	 * @memberof SignalRUtils
	 */
	stopConnection() {
		try {
			this.connection.stop();
		} catch (error) {
			console.log(error);
		}
	}

	/** Function to call when connection failed
	 * @function
	 * @memberof SignalRUtils
	 */
	handleConnectionError() {
		setTimeout(() => {
			this.promise = null;
			this.startConnection();
		}, 5000);
	}

	/** Function to get connection
	 * @function
	 * @returns {object} connection - signalR connection
	 * @memberof SignalRUtils
	 */
	getConnection() {
		return this.connection;
	}

	/** Init all connections
	 * @function
	 * @static
	 * @param {function} callback
	 * @param {boolean} fromStream
	 * @memberof SignalRUtils
	 */
	static buildConnections(callback, fromStream) {
		SignalRUtils.connections = [];
		
		const token = (
			fromStream
				? store.getState()?.auth?.stream?.access?.wsToken
				: store.getState()?.auth?.player?.wsToken
		) ?? null;

		const connectionObjects = {
			adminSingnalR: null,
			cashierSingnalR: null,
			jobsSignalR: null
		}
		connectionObjects.adminSingnalR = new SignalRUtils(`${import.meta.env.SYSTEM_SIGNALR_URL_ADMIN}?accessToken=${token}`, () => callback(connectionObjects.adminSingnalR));
		connectionObjects.cashierSingnalR = new SignalRUtils(`${import.meta.env.SYSTEM_SIGNALR_URL_CASHIER}?accessToken=${token}`, () => callback(connectionObjects.cashierSingnalR));
		SignalRUtils.connections.push(connectionObjects.adminSingnalR);
		SignalRUtils.connections.push(connectionObjects.cashierSingnalR);
		if (import.meta.env.SYSTEM_SIGNALR_URL_JOBS) {
			connectionObjects.jobsSignalR = new SignalRUtils(`${import.meta.env.SYSTEM_SIGNALR_URL_JOBS}?accessToken=${token}`, () => callback(connectionObjects.jobsSignalR));
			SignalRUtils.connections.push(connectionObjects.jobsSignalR);
		}
	}

	/** Remove all connections
	 * @function
	 * @static
	 * @memberof SignalRUtils
	 */
	static removeConnections() {
		const connectionInfos = SignalRUtils.getConnections()
		connectionInfos.forEach((con) => {
			const { connection } = con.getConnection()
			connection.stop();
		});
		SignalRUtils.connections = [];
	}

	/** Init all connections
	 * @function
	 * @static
	 * @return {array} array of connections
	 * @memberof SignalRUtils
	 */
	static getConnections() {
		return SignalRUtils.connections || [];
	}
}
