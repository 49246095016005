import PropTypes from "prop-types";

import { connect } from "react-redux";

import RegularMarkets from "./regular";
import RaceMarkets from "./race";
import KenoMarkets from "./keno";
import Penalty from './penalty';

import { isRacingGame } from "utils/common";

import { GAME_TYPE } from "constants/game.constants";

import eventType from "types/event.type";

/** Markets list component */
const Markets = ({
	activeTab,
	eventInfo,
	markets,
	currentGameType
}) => {
	return (
		currentGameType === GAME_TYPE.KENO
		? (
			<KenoMarkets 
				eventInfo={eventInfo}
			/>
		)
		: currentGameType === GAME_TYPE.PENALTY_SHOOTOUT
			? (
				<Penalty 
					markets={markets} 
					eventInfo={eventInfo}
					activeTab={activeTab}
				/>
			)
			: (isRacingGame(currentGameType) && activeTab !== "extra")
				? (
					<RaceMarkets 
						markets={markets} 
						eventInfo={eventInfo}
						activeTab={activeTab}
					/>
				)
				: (
					<RegularMarkets 
						markets={markets} 
						eventInfo={eventInfo}
						activeTab={activeTab}
					/>
				)
	)
}

/** Markets propTypes
 * PropTypes
 */
Markets.propTypes = {
	/** Active tab */
	activeTab: PropTypes.string,
	/** Current event info */
	eventInfo: eventType,
	/** Array of markets to show */
	markets: PropTypes.array,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType
	};
};

export default connect(mapStateToProps, null)(Markets);
