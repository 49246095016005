import { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "components/ui/button";
import Input from "components/ui/input";
import Form from "components/ui/form";

import { redirectToPage } from "utils/navigate";

import { EMAIL_REGEX } from "constants/regex.constants";
import PATHS from "constants/path.constants";
import { VALIDATION_TYPES } from "constants/ui.constants";

import { forgotPassword } from "store/actions/auth/forgotPassword.actions";

/** Forgot Password page Component */
const ForgotPasswordPage = ({ forgotPassword, isSaving }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [formInstance] = Form.useForm();

	const [emailValue, setEmailValue] = useState("");
	const [showSuccess, setShowSuccess] = useState(false);

	/** Fires when form submitted
	 * @function
	 * @memberOf ForgotPasswordPage
	 */
	const handleForm = () => {
		formInstance
			.validateFields()
			.then(({ email }) => {
				forgotPassword(email, () => {
					setShowSuccess(true);
				});
			})
			.catch(Function.prototype);
	};

	/** Fires on resend button click
	 * @function
	 * @memberOf ForgotPasswordPage
	 */
	const handleResend = () => {
		forgotPassword(emailValue, Function.prototype);
	};

	return (
		<Fragment>
			<b className="vs--auth-popup-title vs--title vs--font-medium vs--text-center">{t("cashier.forgotPassword")}</b>
			<Form
				form={formInstance}
				initialValues={{
					email: ""
				}}
			>
				{!showSuccess ? (
					<Fragment>
						<Form.Item label="Email" name="email" rules={[{ type: VALIDATION_TYPES.PATTERN, pattern: EMAIL_REGEX, message: t("cashier.emailFormat") }]}>
							<Input minLength={6} maxLength={50} placeholder={t("cashier.enterEmail")} onChange={(value) => setEmailValue(value)} />
						</Form.Item>

						<div className="vs--auth-hint vs--text-left vs--mt-8">
							<span>{t("cashier.forgotPasswordSendInfo")}</span>
						</div>

						<Button htmlType="submit" className="vs--button vs--font-small vs--font-bold vs--pt-12 vs--pb-12" onClick={handleForm} disabled={isSaving} loading={isSaving}>
							{t("cashier.send")}
						</Button>
					</Fragment>
				) : (
					<Fragment>
						<div className="vs--modal-success vs--pt-34 vs--text-center">
							<i className="ic_check" />
							<span className="vs--title vs--font-small vs--mt-8 vs--text-center vs--font-bold vs--auth-popup-title">{t("cashier.forgotPasswordSendSuccess")}</span>
						</div>

						<Button htmlType="submit" className="vs--button vs--font-small vs--font-bold vs--pt-12 vs--pb-12 vs--mt-16" onClick={handleResend} disabled={isSaving} loading={isSaving}>
							{t("cashier.notReceived")}
						</Button>
					</Fragment>
				)}

				<div className="vs--auth-back-to-login vs--flex vs--align-center vs--justify-center vs--mt-16" onClick={() => redirectToPage(navigate, PATHS.LOGIN)}>
					<i className="ic_left vs--font-bigest"></i>
					<span className="vs--title vs--font-smallest vs--font-medium">{t("cashier.goBackToTheSignInPage")}</span>
				</div>
			</Form>
		</Fragment>
	);
};

/** ForgotPasswordPage propTypes
 * PropTypes
 */
ForgotPasswordPage.propTypes = {
	/** Redux action to send email for forgot password */
	forgotPassword: PropTypes.func,
	/** Redux state property, is true when sending email for forgot password request is in process */
	isSaving: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (email, onSuccess) => {
		dispatch(forgotPassword(email, onSuccess));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.auth.forgotPassword.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
