import PropTypes from "prop-types";

import MonthView from "components/ui/datePicker/calendar/monthView";
import YearView from "components/ui/datePicker/calendar/yearView";
import DecadeView from "components/ui/datePicker/calendar/decadeView";
import CenturyView from "components/ui/datePicker/calendar/centuryView";

import { CALENDAR_TYPE, CALENDAR_VIEW } from "constants/calendar.constants";

const Content = ({ view, calendarType, showFixedNumberOfWeeks, showNeighboringMonth, ...commonProps }) => {
	switch (view) {
		case CALENDAR_VIEW.CENTURY:
			return <CenturyView {...commonProps} />;
		case CALENDAR_VIEW.DECADE:
			return <DecadeView {...commonProps} />;
		case CALENDAR_VIEW.YEAR:
			return <YearView {...commonProps} />;
		case CALENDAR_VIEW.MONTH:
			return <MonthView calendarType={calendarType} showFixedNumberOfWeeks={showFixedNumberOfWeeks} showNeighboringMonth={showNeighboringMonth} {...commonProps} />;
		default:
			return <></>;
	}
};

Content.propTypes = {
	view: PropTypes.oneOf(Object.values(CALENDAR_VIEW)),
	calendarType: PropTypes.oneOf(Object.values(CALENDAR_TYPE)),
	showFixedNumberOfWeeks: PropTypes.bool,
	showNeighboringMonth: PropTypes.bool
};

export default Content;
