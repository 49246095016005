import FlussonicEmbedVideoPlayer from "./flussonicEmbedVideoPlayer";
import FlussonicCustomVideoPlayer from "./flussonicCustomPlayer";

const flussonicPlayerAdapter = (streamConfiguration, options) => {
	const url = new URL(streamConfiguration.url);
	return (
		["ws:", "wss:"].includes(url.protocol)
			? new FlussonicCustomVideoPlayer(streamConfiguration, options)
			: new FlussonicEmbedVideoPlayer(streamConfiguration, options)
	)
}

export default flussonicPlayerAdapter;