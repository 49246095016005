import { useTranslation } from "react-i18next";
import { buildPathToStaticFolderOfCDN, mergeClassNames, numberWithSpaces } from "utils/common";
import { flagCodeMapper } from "utils/flags";

const SpecialOdd = ({ gameType, odd, style, className, teamContainerClassName, teamNameContainerClassName, teamFlagContainerClassName, textContainerClassName, textClassName }) => {
	const { t } = useTranslation();
	return (
		<div className={mergeClassNames("season-special-odd", className)} style={style}>
			<div className={mergeClassNames("season-special-odd-text-container", textContainerClassName)}>
				<span className={mergeClassNames("season-special-odd-team", teamContainerClassName)}>
					<img className={mergeClassNames("season-special-odd-team-flag", teamFlagContainerClassName)} src={buildPathToStaticFolderOfCDN(`images/flags/${flagCodeMapper(gameType, odd.outcome)}`)} alt={odd.outcome} style={{ height: 36, width: 36 }} />
					<span className={mergeClassNames("season-special-odd-team-name", teamNameContainerClassName)}>{t(`livemonitor.football_england_league.${odd.outcome.toLowerCase()}`)}</span>
				</span>
				<span className={mergeClassNames("season-special-odd-text", textClassName)}>{numberWithSpaces(odd.factor)}</span>
			</div>
		</div>
	);
};
export default SpecialOdd;
