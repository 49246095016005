import { cloneElement, useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PropTypes from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { isReactComponent } from "utils/common";

const Tooltip = ({ title, children, overlayClassName = "", placement = "top", middlewares = [], ...otherProps }) => {
	const uuId = useRef(uuidV4());
	if (!isReactComponent(children)) {
		return children;
	}
	return (
		<>
			{cloneElement(children, Object.assign({}, children.props, { "data-tooltip-id": uuId.current }))}
			<ReactTooltip id={uuId.current} place={placement} className={"vs--ui-tooltip" + (overlayClassName ? " " + overlayClassName : "")} {...otherProps} middlewares={middlewares}>
				{title}
			</ReactTooltip>
		</>
	);
};

Tooltip.propTypes = {
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
	overlayClassName: PropTypes.string,
	mainAxis: PropTypes.number
};

export default Tooltip;
