import { Fragment } from "react"
import Card from "./card";
import { mergeClassNames, isNullish } from "utils/common";
import { useTranslation } from "react-i18next";

const Cards = ({
	className = "", 
	data,
	currencyDecimalCount,
	currencyCode,
	currencySymbol
}) => {
	const { t } = useTranslation();
	return (
		<Fragment>
			{
				data?.map((level) => (
					<div
						key={level.levelType}
						className={mergeClassNames(
							className,
							"vs--flex vs--flex-col",
							"vs--jackpot-content-levels-level",
							`vs--jackpot-content-levels-level-${level.levelType}`
						)}
					>
						<div className="vs--jackpot-content-levels-level-card-overlay">
							<Card
								currencyDecimalCount={currencyDecimalCount}
								currencyCode={currencyCode}
								currencySymbol={currencySymbol}
								jackpot={level}
							/>
						</div>
						{!isNullish(level.largestWinAmount) && level.largestWinAmount !== 0 && (
							<div className="vs--jackpot-content-levels-level-largest-win vs--flex vs--align-center vs--justify-center vs--font-medium">
								<span className="vs--jackpot-content-levels-level-largest-win-title">{t('cashier.largestWin')}:&nbsp;</span>
								<span className="vs--jackpot-content-levels-level-largest-win-title-sub">{level.largestWinAmount}&nbsp;{currencyCode}</span>
							</div>
						)}
					</div>
				))
			}
		</Fragment>
	)
}
export default Cards