import PropTypes from "prop-types";

const turnoverObject = {
	balance: PropTypes.number,
	canceled: PropTypes.number,
	betSlipCount: PropTypes.number,
	netTurnover: PropTypes.number,
	playedCount: PropTypes.number,
	payout: PropTypes.number,
	turnover: PropTypes.number,
	won: PropTypes.number,
	wonCount: PropTypes.number,
	wonUnpaid: PropTypes.number,
	ggr: PropTypes.number
};

const turnoverType = PropTypes.shape(turnoverObject);

export default PropTypes.shape({
	footballSingleMatch: turnoverType,
	horseRaceFlat: turnoverType,
	greyhoundRace: turnoverType,
	...turnoverObject
});
