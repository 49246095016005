import { useTranslation } from "react-i18next";
import { buildPathToStaticFolderOfCDN } from "utils/common";


/** Maintenance Component */
const Maintenance = () => {
	const { t } = useTranslation();
	return (
		<div className="vs--maintenance vs--flex vs--flex-col vs--align-center vs--justify-center vs--pl-8 vs--pr-8">
			<img src={buildPathToStaticFolderOfCDN("cashier-images/maintenance.svg")} alt="maintenance" />
			<b className="vs--maintenance-text vs--title vs--mt-24 vs--font-medium">{t("common.maintenanceModeText")}</b>
			<span className="vs--maintenance-sub-text vs--title vs--mt-8 vs--font-regular vs--font-big">{t("common.maintenanceModeSubText")}</span>
		</div>
	);
};

export default Maintenance;
