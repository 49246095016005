import  { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { isDropdownFitsOnScreen } from "utils/ui";

import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from "constants/ui.constants";

const SizeChanger = ({ onChange, pageSizeOptions = [...DEFAULT_PAGE_SIZE_OPTIONS], defaultPageSize, contentRenderer, arrowIconRenderer, className = "", activePageSize }) => {
	/**
	 * isControlledFromOutside indicates that component
	 * should controlled from parent component.
	 * This prevents local state changes
	 */
	const isControlledFromOutside = typeof activePageSize === "number";

	const [pageSize, setPageSize] = useState(() => {
		if (isControlledFromOutside) {
			return;
		}

		if (typeof defaultPageSize === "number") {
			return defaultPageSize;
		}

		if (Array.isArray(pageSizeOptions) && pageSizeOptions.length !== 0) {
			return pageSizeOptions[0];
		}

		return DEFAULT_PAGE_SIZE;
	});
	const [isDropdownVisible, setIsDropdownVisible] = useState(null);

	const sizeChangerRef = useRef(null);
	const dropdownRef = useRef(null);
	/**
	 * directionToOpenDropdownClassRef should only change
	 * when we want to show the dropdown, not hide it
	 */
	const directionToOpenDropdownClassRef = useRef(null);

	const currentPageSize = isControlledFromOutside ? activePageSize : pageSize;

	const hideDropDown = () => {
		setIsDropdownVisible(false);
	};

	const showDropDown = () => {
		setIsDropdownVisible(true);

		directionToOpenDropdownClassRef.current = isDropdownFitsOnScreen({
			sizeChangerElement: sizeChangerRef.current,
			dropDownElement: dropdownRef.current
		})
			? "vs--ui-open-to-down"
			: "vs--ui-open-to-up";
	};

	const toggleDropdown = (e) => {
		e.stopPropagation();

		if (isDropdownVisible) {
			hideDropDown();
			return;
		}

		showDropDown();
	};

	const handlePageSizeChange = (e, pageSize) => {
		e.stopPropagation();

		if (currentPageSize === pageSize) {
			hideDropDown();
			return;
		}

		if (typeof onChange === "function") {
			onChange(pageSize);
		}

		hideDropDown();

		if (isControlledFromOutside) {
			return;
		}

		setPageSize(pageSize);
	};

	const hideDropdownOnOutsideClick = useCallback(() => {
		// When the dropdown is not open yet, we should not call the hideDropDown function
		if (isDropdownVisible === null) {
			return;
		}

		hideDropDown();
	}, [isDropdownVisible]);

	useEffect(() => {
		window.addEventListener("click", hideDropdownOnOutsideClick);

		return () => {
			window.removeEventListener("click", hideDropdownOnOutsideClick);
		};
	}, [hideDropdownOnOutsideClick]);

	const showHideDropdownClass = isDropdownVisible ? "vs--ui-dropdown-visible" : isDropdownVisible === false ? "vs--ui-dropdown-hidden" : ""; // If isDropdownVisible is null(not open yet), don't set any class

	return (
		<div className={`vs--ui-size-changer-wrapper ${className}`}>
			<div className={`vs--ui-size-changer-container ${isDropdownVisible ? "vs--ui-size-changer-active" : ""}`} onClick={toggleDropdown} ref={sizeChangerRef}>
				{typeof contentRenderer === "function" ? contentRenderer(currentPageSize) : <p className="vs--ui-current-page-size">{currentPageSize}</p>}
				<div className="vs--ui-arrow-icon-wrapper">{typeof arrowIconRenderer === "function" ? arrowIconRenderer() : <i className="ic_down"></i>}</div>
				<div className={`vs--ui-size-changer-dropdown ${directionToOpenDropdownClassRef.current} ${showHideDropdownClass}`} ref={dropdownRef}>
					{pageSizeOptions.map((pageSize, index) => {
						return (
							<div key={index} className={`vs--ui-page-size-item ${currentPageSize === pageSize ? "vs--ui-active-page-size" : ""}`} onClick={(e) => handlePageSizeChange(e, pageSize)}>
								{typeof contentRenderer === "function" ? contentRenderer(pageSize) : <p>{pageSize}</p>}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

/** SizeChanger propTypes
 * PropTypes
 */
SizeChanger.propTypes = {
	/** Function which will fire on any change of pagination */
	onChange: PropTypes.func,
	/** arrow icon renderer */
	arrowIconRenderer: PropTypes.func,
	/** Class to rewrite default styles of size changer */
	className: PropTypes.string,
	/** Default count of items to display per page */
	defaultPageSize: PropTypes.number,
	/** Available page sizes  */
	pageSizeOptions: PropTypes.array,
	/** Size changer (including for each dropdown item) content renderer */
	contentRenderer: PropTypes.func,
	/** Current page size */
	activePageSize: PropTypes.number
};

export default SizeChanger;
