import  { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Tooltip from "components/ui/tooltip";
import PasswordValidationTooltip from "components/ui/validationTooltips/passwordValidationTooltip";
import Button from "components/ui/button";
import Input from "components/ui/input";
import Form from "components/ui/form";

import { redirectToPage } from "utils/navigate";
import { validatePasword } from "utils/password";
import { required } from "utils/common";

import PATHS from "constants/path.constants";

import { resetPassword, setPassword } from "store/actions/auth/resetPassword.actions";
import { getPasswordSettings } from "store/actions/auth/passwordSettings.actions";

import passwordSettingsType from "types/passwordSettings.type";

/** Password reset page Component */
const ResetPasswordPage = ({ resetPassword, setPassword, getPasswordSettings, passwordSettings, isReseting }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [formInstance] = Form.useForm();

	const [newPassword, setNewPassword] = useState("");
	const [newPasswordFocused, setNewPasswordFocused] = useState(false);

	/** Load password settings */
	useEffect(() => {
		const token = searchParams.get("t");
		const type = searchParams.get("type") || "forgotPassword";
		if (token) {
			getPasswordSettings(token, type);
		}
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf ResetPasswordPage
	 */
	const handleForm = () => {
		const token = searchParams.get("t");
		const type = searchParams.get("type") || "forgotPassword";
		formInstance
			.validateFields()
			.then((data) => {
				if (type === "forgotPassword") {
					resetPassword(token, data.newPassword, data.confirmPassword, () => {
						redirectToPage(navigate, PATHS.LOGIN);
					});
				} else {
					setPassword(token, data.newPassword, data.confirmPassword, () => {
						redirectToPage(navigate, PATHS.LOGIN);
					});
				}
			})
			.catch(Function.prototype);
	};

	return (
		<Fragment>
			<b className="vs--auth-popup-title vs--title vs--font-medium vs--text-center">{t("cashier.resetPassword")}</b>

			<div className="vs--change-password">
				<Form
					form={formInstance}
					initialValues={{
						newPassword: "",
						confirmPassword: ""
					}}
				>
					<Tooltip title={<PasswordValidationTooltip password={newPassword} passwordSettings={passwordSettings} />} placement="right" overlayClassName="vs--reset-password-tooltip" isOpen={newPasswordFocused || !!newPassword}>
						<Form.Item
							label="New Password"
							name="newPassword"
							className="vs--change-password-reset-password-form"
							rules={[
								required(t("cashier.passwordRequired")),
								() => ({
									validator(value) {
										return validatePasword(value, passwordSettings);
									}
								})
							]}
						>
							<Input.Password
								className="vs-login-action-inputs"
								name="confirmPassword"
								placeholder="Password"
								onFocus={() => setNewPasswordFocused(true)}
								onBlur={() => setNewPasswordFocused(false)}
								onChange={(value) => {
									setNewPassword(value);
									setTimeout(() => {
										if (formInstance.getFieldValue("confirmPassword") !== "") {
											formInstance.validateFields(["confirmPassword"]);
										}
									}, 0);
								}}
							/>
						</Form.Item>
					</Tooltip>
					<Form.Item
						label="Confirm New Password"
						name="confirmPassword"
						className="vs--change-password-reset-password-form"
						rules={[
							required(t("cashier.passwordRequired")),
							({ getFieldValue }) => ({
								validator(value) {
									if (value !== getFieldValue("newPassword")) {
										return Promise.reject(t("cashier.passwordsDoNotMatch"));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<Input.Password className="vs-login-action-inputs" name="newPassword" placeholder="Password" onPaste={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} />
					</Form.Item>

					<Button htmlType="submit" className="vs--button vs--font-small vs--font-bold vs--pt-12 vs--pb-12 vs--mt-16" onClick={handleForm} disabled={isReseting} loading={isReseting}>
						{t("cashier.resetPassword")}
					</Button>
				</Form>
			</div>
		</Fragment>
	);
};

/** ResetPasswordPage propTypes
 * PropTypes
 */
ResetPasswordPage.propTypes = {
	/** Redux action to reset password */
	resetPassword: PropTypes.func,
	/** Redux action to set password */
	setPassword: PropTypes.func,
	/** Redux state property, is true when reset password is in process */
	isReseting: PropTypes.bool,
	/** Redux action to get password settings */
	getPasswordSettings: PropTypes.func,
	/** Redux state property, the activation password settings */
	passwordSettings: passwordSettingsType
};

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (token, newPassword, confirmPassword, onSuccess) => {
		dispatch(resetPassword(token, newPassword, confirmPassword, onSuccess));
	},
	setPassword: (token, newPassword, confirmPassword, onSuccess) => {
		dispatch(setPassword(token, newPassword, confirmPassword, onSuccess));
	},
	getPasswordSettings: (token, type) => {
		dispatch(getPasswordSettings(token, type));
	}
});

const mapStateToProps = (state) => {
	return {
		isReseting: state.auth.resetPassword.isReseting,
		passwordSettings: state.auth.passwordSettings.passwordSettings
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
