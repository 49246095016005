import {
	SET_PLAYER,
	SET_SESSION_ID,
	SET_TOKEN_EXPIRATION,
	SET_SESSION,
	SET_SESSION_LOADED,
	SET_SESSION_FAILED,
	SET_SESSION_GAMES,
	SET_SESSION_NO_GAME_FOUND,
	SET_LOGIN_BEFORE,
	SET_LOGIN_AFTER,
	SET_BALANCE,
	SET_MAINTENANCE_MODE,
	SET_LOGO_ID,
	SET_BONUS_CONFIGS,
	SET_STREAM_CONFIGURATION,
	SET_GAME_RTPS,
	SET_STREAM_ACCESS,
	SET_ACTIVATE_PASSWORD_SETTINGS_BEFORE,
	SET_ACTIVATE_PASSWORD_SETTINGS_AFTER,
	SET_ACTIVATION_PASSWORD_SETTINGS,
	SET_ACTIVATE_BEFORE,
	SET_ACTIVATE_AFTER,
	RESET_PASSWORD_ACTION_BEFORE,
	RESET_PASSWORD_ACTION_AFTER,
	FORGOT_PASSWORD_ACTION_BEFORE,
	FORGOT_PASSWORD_ACTION_AFTER,
	SET_CHANGE_PASSWORD_SETTINGS_BEFORE,
	SET_CHANGE_PASSWORD_SETTINGS_AFTER,
	SET_CHANGE_PASSWORD_BEFORE,
	SET_CHANGE_PASSWORD_AFTER,
	SET_CHANGE_PASSWORD_SETTINGS,
	SET_TRANSLATIONS_LOADED,
	SET_LANGUAGES_BEFORE,
	SET_LANGUAGES_AFTER,
	SET_LANGUAGES,
	SET_SESSION_TEMPLATE_TYPE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_PLAYER:
			return {
				...state,
				player: payload.player
			};
		case SET_SESSION:
			return {
				...state,
				sessionLoaded: true,
				session: payload.session
			};
		case SET_SESSION_LOADED:
			return {
				...state,
				sessionLoaded: payload.loaded
			};
		case SET_SESSION_FAILED:
			return {
				...state,
				sessionFailed: payload.failed
			};
		case SET_SESSION_GAMES:
			return {
				...state,
				session: {
					...state.session,
					games: payload.games
				}
			};
		case SET_SESSION_NO_GAME_FOUND:
			return {
				...state,
				noGameFound: payload.noGameFound
			};
		case SET_SESSION_ID:
			return {
				...state,
				sessionId: payload.sessionId
			};
		case SET_SESSION_TEMPLATE_TYPE:
			return {
				...state,
				session: {
					...state.session,
					templateType: payload
				}
			};
		case SET_TOKEN_EXPIRATION:
			return {
				...state,
				refreshTokenExpiration: payload.seconds
			};
		case SET_BALANCE:
			return {
				...state,
				session: {
					...state.session,
					balance: payload.balance
				}
			};
		case SET_MAINTENANCE_MODE:
			return {
				...state,
				session: {
					...state.session,
					maintenanceMode: true
				}
			};
		case SET_LOGO_ID:
			return {
				...state,
				session: {
					...state.session,
					logoId: payload.logoId
				}
			};
		case SET_BONUS_CONFIGS:
			return {
				...state,
				session: {
					...state.session,
					betShopBonusConfigs: payload.configs
				}
			};
		case SET_STREAM_ACCESS:
			return {
				...state,
				stream: {
					...state.stream,
					access: payload.session,
					accessLoaded: true
				}
			};
		case SET_STREAM_CONFIGURATION:
			return {
				...state,
				session: {
					...state.session,
					games: state.session.games.map((g) => (g.id === payload.id ? { ...g, streamConfiguration: payload.configuration } : g))
				}
			};
		case SET_GAME_RTPS:
			return {
				...state,
				stream: {
					...state.stream,
					rtps: payload.rtPs
				}
			};
		case SET_LOGIN_BEFORE:
			return {
				...state,
				isLoginLoading: true
			};
		case SET_LOGIN_AFTER:
			return {
				...state,
				isLoginLoading: false
			};
		case SET_ACTIVATE_BEFORE:
			return {
				...state,
				activation: {
					...state.activation,
					isActivating: true
				}
			};
		case SET_ACTIVATE_AFTER:
			return {
				...state,
				activation: {
					...state.activation,
					isActivating: false
				}
			};
		case RESET_PASSWORD_ACTION_BEFORE:
			return {
				...state,
				resetPassword: {
					...state.resetPassword,
					isReseting: true
				}
			};
		case RESET_PASSWORD_ACTION_AFTER:
			return {
				...state,
				resetPassword: {
					...state.resetPassword,
					isReseting: false
				}
			};
		case FORGOT_PASSWORD_ACTION_BEFORE:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					isSaving: true
				}
			};
		case FORGOT_PASSWORD_ACTION_AFTER:
			return {
				...state,
				forgotPassword: {
					...state.forgotPassword,
					isSaving: false
				}
			};
		case SET_ACTIVATE_PASSWORD_SETTINGS_BEFORE:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					isLoading: true
				}
			};
		case SET_ACTIVATE_PASSWORD_SETTINGS_AFTER:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					isLoading: false
				}
			};
		case SET_ACTIVATION_PASSWORD_SETTINGS:
			return {
				...state,
				passwordSettings: {
					...state.passwordSettings,
					passwordSettings: payload.settings
				}
			};
		case SET_CHANGE_PASSWORD_SETTINGS_BEFORE:
			return {
				...state,
				changePassword: {
					...state.changePassword,
					isPasswordSettingsLoading: true
				}
			};
		case SET_CHANGE_PASSWORD_SETTINGS_AFTER:
			return {
				...state,
				changePassword: {
					...state.changePassword,
					isPasswordSettingsLoading: false
				}
			};
		case SET_CHANGE_PASSWORD_BEFORE:
			return {
				...state,
				changePassword: {
					...state.changePassword,
					isPasswordChanging: true
				}
			};
		case SET_CHANGE_PASSWORD_AFTER:
			return {
				...state,
				changePassword: {
					...state.changePassword,
					isPasswordChanging: false
				}
			};
		case SET_CHANGE_PASSWORD_SETTINGS:
			return {
				...state,
				changePassword: {
					...state.changePassword,
					passwordSettings: payload.settings
				}
			};
		case SET_TRANSLATIONS_LOADED:
			return {
				...state,
				translationsLoaded: true
			};
		case SET_LANGUAGES_BEFORE:
			return {
				...state,
				isLanguagesLoading: true
			};
		case SET_LANGUAGES_AFTER:
			return {
				...state,
				isLanguagesLoading: false
			};
		case SET_LANGUAGES:
			return {
				...state,
				languages: payload.languages,
				languagesLoaded: true
			};
		default:
			return state;
	}
};
