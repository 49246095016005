import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";

import ModalWrapper from "components/modalWrapper";

import useIntegrationType from "hooks/useIntegrationType";

import { closeTicket } from "store/actions/tickets/ticket.actions";
import { setTicketConfirmation } from "store/actions/tickets/confirmation.actions";

import TicketsList from "../list";
import WonJackpots from "../list/wonJackpots";

import { flagsToBinary, makeCurrencyText } from "utils/common";

import { TICKETS_LIST_TYPE, TICKET_CONFIRMATION_MODAL_TYPE, PROJECT_TYPE } from "constants/common.constants";

import historyType from "types/history.type";

const getSessionCurrency = state => state.auth.session.currency ?? {};

/** Viewing Ticket Component */
const ViewingTicket = ({ ticket, isLoading }) => {
	const { t } = useTranslation();
	const integrationType = useIntegrationType();

	const currency = useSelector(getSessionCurrency);

	const dispatch = useDispatch();

	const wonJackpots = ticket.wonJackpots;

	const wonJackpotsDefaultAccumulator = { totalPayoutAmount: 0, allowedJackpotPayoutLevelsList: [] };
	const { totalPayoutAmount, allowedJackpotPayoutLevelsList } = wonJackpots?.reduce((acc, jackpot) => {
		if (jackpot.payoutTime) {
			return acc
		}
		acc.totalPayoutAmount += jackpot.amount;
		acc.allowedJackpotPayoutLevelsList.push(jackpot.levelType)
		return acc;
	}, {...wonJackpotsDefaultAccumulator}) ?? {...wonJackpotsDefaultAccumulator};

	const allowedJackpotPayoutLevels = flagsToBinary(allowedJackpotPayoutLevelsList);

	const handleClose = () => dispatch(closeTicket());

	const handleTicketPayout = (ticketId, amount) => {
		dispatch(setTicketConfirmation({
			type: TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT_ALL,
			ticketId,
			amount,
			jackpotLevelType: allowedJackpotPayoutLevels
		}));
		handleClose();
	};

	const modalFooter = (
		<div data-type="actions" className="vs--viewing-ticket-modal-footer-content vs--flex vs--align-center vs--justify-end">
			<button
				type="button"
				className="vs--ticket-bet-action-button vs--ticket-bet-action-button-close vs--button vs--modal-table-button vs--font-smallest vs--font-medium"
				onClick={handleClose}
				title={t("common.close")}
			>
				<span>{t("common.close")}</span>
			</button>
			{ticket.allowPayout && integrationType !== PROJECT_TYPE.IFRAME ? (
				<button
					type="button"
					className="vs--ticket-bet-button vs--ticket-bet-action-button vs--ticket-bet-action-button-payout vs--ticket-bet-action-button-payout-all vs--button vs--modal-table-button vs--font-smallest vs--font-medium"
					onClick={() => handleTicketPayout(
						ticket.id,
						makeCurrencyText((ticket.bonusId ? ticket.redeem : ticket.winning) + totalPayoutAmount, currency))
					}
					title={t("cashier.payoutAll")}
				>
					<span>{t("cashier.payoutAll")}</span>
				</button>
			) : null}
		</div>
	)

	return (
		<ModalWrapper
			title={`${t("cashier.ticketID")} ${ticket.id}`}
			classNames={{ content: "vs--viewing-ticket-modal" }}
			width={"95%"}
			height={"80%"}
			onCancel={handleClose}
			visible={true}
			footer={wonJackpots?.length > 0 ? modalFooter : null}
		>
			{wonJackpots?.length > 0 && (
				<div className="vs--viewing-ticket-modal-content-title vs--font-medium vs--mt-24 vs--ml-24 vs--mb-16">{t('cashier.jackpot')}</div>
			)}
			{wonJackpots?.length > 0 && (
				<WonJackpots betslipId={ticket.id} type={TICKETS_LIST_TYPE.SINGLE} jackpots={ticket.wonJackpots} isLoading={isLoading} onCancel={handleClose} />
			)}
			{wonJackpots?.length > 0 && <div className="vs--viewing-ticket-modal-content-title vs--font-medium vs--mt-24 vs--ml-24 vs--mb-16">{t('cashier.betslipTicket')}</div>}
			<TicketsList
				tableClassNmae={wonJackpots?.length > 0 ? "vs--modal-table-won-jackpots" : ""}
				type={TICKETS_LIST_TYPE.SINGLE}
				items={[ticket]}
				isLoading={isLoading}
				onCancel={handleClose}
			/>
		</ModalWrapper>
	);
};

/** ViewingTicket propTypes
 * PropTypes
 */
ViewingTicket.propTypes = {
	/** Redux state property, the viewing ticket */
	ticket: historyType,
	/** Redux state property, is true when loading viewing ticket */
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		ticket: state.tickets.viewingTicket,
		isLoading: state.tickets.isTicketLoading
	};
};

export default connect(mapStateToProps, null)(ViewingTicket);
