import { useRef } from 'react';
import { useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';

import fullScreenAPI from "utils/fullscreen";

import { getUrlVars } from 'utils/common';

import Paths from 'constants/path.constants';

const JackpotLiveInfo = () => {
	const { t } = useTranslation();
	const decodedData = useOutletContext();

	const iframeRef = useRef();

	const dataQuery = getUrlVars()['data'];
	const activeJackpotId = decodedData?.activeJackpotId ?? null;

	/** Function to enter Fullscreen
	 * @function
	 * @memberOf JackpotLiveInfo
 */
	const enterFullscreen = () => {
		const element = document.getElementsByClassName("vs--stream-content-iframe")[0];
		fullScreenAPI.toggle(element);
	}

	/** Function to exit Fullscreen
	 * @function
	 * @memberOf JackpotLiveInfo
 */
	const exitFullscreen = () => {
		fullScreenAPI.toggle();
	}

	/** Function fires on fullscreen button click
	 * @function
	 * @memberOf JackpotLiveInfo
 */
	const onFullscreen = () => {
		if (!fullScreenAPI.isFullscreen) {
			enterFullscreen();
		} else {
			exitFullscreen();
		}
	}

	return (
		<div className="vs--stream vs--jackpot-live-info vs--flex vs--flex-col">
			<div className="vs--stream-content vs--flex-equal">
				<div className='vs--stream-content-iframe'>
					<iframe
						ref={iframeRef}
						className='vs--stream-content-iframe-element'
						src={`${Paths.JACKPOT_LIVE_INFO}/${activeJackpotId}?data=${dataQuery}`}
						allowFullScreen
					/>
					<div className="vs--stream-content-iframe-controls">
						<div className="vs--stream-content-iframe-controls-inner vs--flex vs--flex-row vs--align-center vs--justify-end vs--pl-8 vs--pr-8">
							<div className="vs--flex vs--flex-row vs--align-center">
								<div
									className="vs--stream-content-iframe-control"
									onClick={onFullscreen}
									data-action="fullscreen"
								>
									<i className="ic_video-fullscreen" title={t('common.fullscreen')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default JackpotLiveInfo