import  { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import Header from "components/header";
import Rightbar from "components/rightbar";
import Wrapper from "components/wrapper";
import Loader from "components/ui/loader";
import BlockedMessage from "components/ui/blockedMessage";
import TicketConfirm from "components/tickets/confirm";
import Maintenance from "components/ui/maintenance";

import withAuth from "hocs/withAuth";

import { initTranslations } from "translations/config";

import sessionType from "types/session.type";
import { buildPathToStaticFolderOfCDN } from "utils/common";

/** Main Layout Component */
const Layout = ({ sessionLoaded, sessionFailed, session, translationsLoaded, noGameFound }) => {
	/** Load translations */
	useEffect(() => {
		if (sessionLoaded && !sessionFailed) {
			initTranslations(session.languageFilePath, session.languageId, session.ticketLanguageFilePath, session.ticketLanguageId, Function.prototype, Function.prototype);
		}
	}, [sessionLoaded]);

	return (
		<Wrapper>
			<div className="vs--container vs--flex vs--flex-col vs--justify-start" data-pathname={location.pathname}>
				{sessionLoaded && translationsLoaded && !sessionFailed ? (
					<Fragment>
						<Header />
						<div className="vs--wrapper vs--flex vs--flex-equal vs--flex-row vs--mt-8">
							<div className="vs--wrapper-inner vs--flex-equal vs--overflow-x-hidden vs--overflow-y-hidden vs--pr-8 vs--pl-8">
								<Outlet />
							</div>
							<Rightbar />
						</div>
						<TicketConfirm />
					</Fragment>
				) : sessionFailed && noGameFound && translationsLoaded ? (
					<Fragment>
						<BlockedMessage imageSrc={buildPathToStaticFolderOfCDN("cashier-images/no-game-found.svg")} title={"common.noGameFound"} message={"common.noGameFoundMessage"} />
					</Fragment>
				) : sessionFailed && session?.maintenanceMode && translationsLoaded ? (
					<Fragment>
						<Maintenance />
					</Fragment>
				) : !sessionFailed ? (
					<Loader full={true} />
				) : (
					<Fragment />
				)}
			</div>
		</Wrapper>
	);
};

Layout.propTypes = {
	/** Redux state property, Is true when session loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, Is true when session failed */
	sessionFailed: PropTypes.bool,
	/** Redux state property, Is true when no game found */
	noGameFound: PropTypes.bool,
	/** Redux state property, Is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		sessionLoaded: state.auth.sessionLoaded,
		sessionFailed: state.auth.sessionFailed,
		noGameFound: state.auth.noGameFound,
		translationsLoaded: state.auth.translationsLoaded,
		session: state.auth.session
	};
};

export default connect(mapStateToProps, null)(withAuth(Layout));
