import moment from "moment";

import { SET_ALERT_MESSAGE, REMOVE_ALERT_MESSAGE, SET_ERROR_MESSAGE, SET_CURRENT_TIME } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_ALERT_MESSAGE:
			const msgLength = state.alertMessages.length;

			return {
				...state,
				alertMessages: [...(msgLength === 5 ? state.alertMessages.slice(1, msgLength) : state.alertMessages), { ...payload }]
			};
		case REMOVE_ALERT_MESSAGE:
			return {
				...state,
				alertMessages: state.alertMessages.filter((msg) => msg.id !== payload.id)
			};
		case SET_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: payload.message
			};
		case SET_CURRENT_TIME:
			return {
				...state,
				currentTime: moment().unix()
			};
		default:
			return state;
	}
};
