import { Fragment } from "react";
import { buildPathToStaticFolderOfCDN } from "utils/common";

/*
	Component for render video of Keno shuffle,
	it's mounted one time on keno stream main page
	and unmountwhen stream game type changed or stream window closed.
	By initial state it's hidden, the component KenoShuffleHideShow
	manage visibility state of this component
*/
const KenoShuffleVideo = () => {
	return (
		<Fragment>
			<img id="keno-shuffle" className="keno-shuffle-hidden" src={buildPathToStaticFolderOfCDN("media/cashier/keno-shuffle-1.gif")} alt={void 0} />
		</Fragment>
	);
};

export default KenoShuffleVideo;
