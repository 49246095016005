import  { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BETSLIP_MODES } from "constants/betslip.constants";
import betType from "types/bet.type";
import { setStake, updateBetStake, setOnBlurBets } from "store/actions/betslip/betslip.actions";
import { isValidAmount } from "utils/common";

/** Favorite bets Component for betslip*/
const FavoriteBets = ({ mode, bets, setStake, stake, currency, updateBetStake, focusedBetIdentifiers, setOnBlurBets, useBonus }) => {
	const favoriteBets = currency?.favoriteBets ?? null;

	if (!favoriteBets || !Array.isArray(favoriteBets)) {
		return <Fragment />;
	}

	const isDisabled = bets.length === 0 || useBonus;

	/** Function which fires on favorite bet click click
	 * @function
	 * @param {string} fav - favorite bet value
	 * @param {number} initial - initial value
	 * @returns {number}
	 * @memberOf FavoriteBets
	 */
	const applyFavoriteBet = (fav, initial) => {
		let update = initial || 0;
		if (fav.startsWith("+")) {
			let sum = Number(fav.substr(1));
			if (!isNaN(sum) && isValidAmount(initial, currency?.decimalCount ?? 0)) {
				const val = Number(update.toString().replaceAll(" ", "")) + sum;
				update = isValidAmount(val.toString(), currency?.decimalCount ?? 0) ? val : update;
			}
		} else if (fav.startsWith("x")) {
			let multiple = Number(fav.substr(1));
			if (!isNaN(multiple) && isValidAmount(initial, currency?.decimalCount ?? 0)) {
				const val = Number(update.toString().replaceAll(" ", "")) * multiple;
				update = isValidAmount(val.toString(), currency?.decimalCount ?? 0) ? val : update;
			}
		} else if (!isNaN(fav) && fav !== "" && fav !== null) {
			update = Number(fav);
		} else if (fav === "Max") {
			if (mode === BETSLIP_MODES.SINGLE) {
				update = currency?.singleMax ?? update;
			} else if (mode === BETSLIP_MODES.MULTI) {
				update = currency?.multiMax ?? update;
			}
		} else if (fav === "Min") {
			if (mode === BETSLIP_MODES.SINGLE) {
				update = currency?.singleMin ?? update;
			} else if (mode === BETSLIP_MODES.MULTI) {
				update = currency?.multiMin ?? update;
			}
		}
		return update;
	};

	/** Function which fires on favorite bet click click
	 * @function
	 * @param {string} fav - favorite bet value
	 * @memberOf FavoriteBets
	 */
	const onFavoriteBetClick = (fav) => {
		if (isDisabled) {
			return;
		}
		const focused = focusedBetIdentifiers !== null ? { ...focusedBetIdentifiers } : focusedBetIdentifiers;
		setOnBlurBets();
		if (focused) {
			if (focused.main) {
				setStake(applyFavoriteBet(fav, stake).toString());
			} else {
				const bet = bets.find(bet => focused.key === bet.key);

				if (bet) {
					updateBetStake(applyFavoriteBet(fav, bet.stake).toString(), bet.key);
				}
			}
		} else {
			if (mode === BETSLIP_MODES.SINGLE) {
				updateBetStake(applyFavoriteBet(fav, bets[0].stake).toString(), bets[0].key);
			} else {
				setStake(applyFavoriteBet(fav, stake).toString());
			}
		}
	};

	const rows = favoriteBets.reduce((acc, f) => {
		if (!acc.length || acc[acc.length - 1].length % 4 === 0) {
			acc.push([]);
		}
		acc[acc.length - 1].push(
			<div
				disabled={isDisabled}
				className={"vs--stake-fast vs--flex-equal vs--flex vs--flex-col vs--align-center vs--justify-center vs--text-center vs--pl-4 vs--pr-4 vs--pt-10 vs--pb-10 vs--mr-4 vs--ml-4 vs--mt-8" + (isDisabled ? " vs--stake-fast-disabled" : "")}
				key={f}
				onClick={() => onFavoriteBetClick(f)}
				title={f}
			>
				<span className="vs--title vs--font-small vs--font-regular">{f}</span>
			</div>
		);
		return acc;
	}, []);
	return (
		<div className="vs--pt-8 vs--pb-16">
			{rows.map((btns, i) => (
				<div key={i} className="vs--flex">
					{btns}
				</div>
			))}
		</div>
	);
};

/** FavoriteBets propTypes
 * PropTypes
 */
FavoriteBets.propTypes = {
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux action to set common stake in betslip */
	setStake: PropTypes.func,
	/** Redux state property, common stake of betslip */
	stake: PropTypes.string,
	/** Redux state property, current session */
	currency: PropTypes.object,
	/** Redux action to update single bet stake */
	updateBetStake: PropTypes.func,
	/** Redux state property, last focused stake input identifiers */
	focusedBetIdentifiers: PropTypes.object,
	/** Redux action to clear focused input data */
	setOnBlurBets: PropTypes.func,
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		mode: state.betslip.mode,
		bets: state.betslip.bets,
		stake: state.betslip.stake,
		currency: state.auth.session.currency,
		focusedBetIdentifiers: state.betslip.focusStake.betIdentifiers,
		useBonus: state.bonuses.standard.useBonus
	}
}

const mapDispatchToProps = (dispatch) => ({
	setStake: (stake) => {
		dispatch(setStake(stake));
	},
	updateBetStake: (stake, key) => {
		dispatch(updateBetStake(stake, key));
	},
	setOnBlurBets: () => {
		dispatch(setOnBlurBets());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteBets);
