import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Flag from "components/ui/flag";
import InfoWrapper from "./infoWrapper";
import { GetSeasonRoundText, isSeasonGame } from "utils/common";
import { GAME_TYPE } from "constants/game.constants";
import eventType from "types/event.type";

/** Match Info component */
const Info = ({ matchInfo, currentGameType }) => {
	const { t } = useTranslation();

	const team1 = matchInfo?.gameData.team1 ?? {};
	const team2 = matchInfo?.gameData.team2 ?? {};
	
	if ([GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(currentGameType)) {
		return (
			<InfoWrapper>
				<div className="vs--flex vs--align-center vs--font-uppercase">
					<div className="vs--flex vs--align-center vs--justify-between vs--flex-equal vs--match-info-content-team">
						<b className="vs--title vs--font-condensed vs--font-regular vs--font-small vs--mr-8 vs--text-left">{team1.countryCode ? t(`countries.${team1.countryCode}`) : ""}</b>
						<Flag code={team1.flagCode ?? ""} gameType={currentGameType} />
					</div>
					<span className="vs--pl-16 vs--pr-16 vs--title vs--font-regular vs--font-exstrasmall vs--match-info-content-separator">vs</span>
					<div className="vs--flex vs--align-center vs--justify-between vs--flex-equal vs--match-info-content-team">
						<Flag code={team2.flagCode ?? ""} gameType={currentGameType} />
						<b className="vs--title vs--font-condensed vs--font-regular vs--font-small vs--ml-8 vs--text-right">{team2.countryCode ? t(`countries.${team2.countryCode}`) : ""}</b>
					</div>
				</div>
			</InfoWrapper>
		);
	}

	if (isSeasonGame(currentGameType)) {
		return (
			<InfoWrapper>
				<div className="vs--flex vs--align-center">
					<div className="vs--match-info-special vs--ml-24 vs--pl-24 vs--flex vs--flex-col vs--align-enter vs--justify-center">
						<b className="vs--font-regular vs--font-small">{GetSeasonRoundText(currentGameType, matchInfo?.orderNumber ?? 0)}</b>
					</div>
				</div>
			</InfoWrapper>
		);
	}

	return "";
};

/** Info propTypes
 * PropTypes
 */
Info.propTypes = {
	/** Current match info */
	matchInfo: eventType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		currentGameType: state.game.currentGameType
	};
};

export default connect(mapStateToProps, null)(Info);
