import PropTypes from "prop-types";

import { GAME_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	limit: PropTypes.number,
	page: PropTypes.number,
	from: PropTypes.instanceOf(Date),
	to: PropTypes.instanceOf(Date),
	type: PropTypes.oneOf(Object.values(GAME_TYPE).concat([""])),
	eventId: PropTypes.string
});
