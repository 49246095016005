import { Fragment, useState, useEffect } from "react";

const LiveMarketProgressLine = () => {
	const [currentPercent, setCurrentPercent] = useState(0);
	const transition = 800;

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentPercent((prev) => prev + 1);
		}, 800);

		return () => {
			clearInterval(intervalId);
		};
	}, []);
	return (
		<Fragment>
			<span className="monitor-live-progressbar-line monitor-live-progressbar-line-left">
				<span
					style={{
						width: `${currentPercent * 2}%`,
						transition: currentPercent >= 50 ? null : `width ${transition}ms linear`
					}}
					className="monitor-live-progressbar-progress"
				></span>
			</span>

			{currentPercent >= 50 ? (
				<span className="monitor-live-progressbar-line monitor-live-progressbar-line-right">
					<span
						style={{
							width: `${(currentPercent - 50) * 2}%`,
							transition: `width ${transition}ms linear`
						}}
						className="monitor-live-progressbar-progress"
					></span>
				</span>
			) : null}
		</Fragment>
	);
};
export default LiveMarketProgressLine;
