import PropTypes from "prop-types";

import TileGroup from "components/ui/datePicker/calendar/tileGroup";
import Year from "components/ui/datePicker/calendar/decadeView/year";

import { getDecadeStart, getYearValidDate } from "utils/calendar";

const DecadeView = ({ selectedDate, ...props }) => {
	const start = getDecadeStart(selectedDate).getFullYear() - 1;
	const end = start + 11;

	return (
		<div className="vs--ui-calendar-decade-view">
			<TileGroup
				{...props}
				className="vs--ui-calendar-decade-view-years"
				selectedDate={selectedDate}
				dateTransform={(year) => {
					const date = new Date();
					date.setFullYear(year);
					date.setHours(0, 0, 0, 0);
					return getYearValidDate(selectedDate, date);
				}}
				dateType="year"
				start={start}
				end={end}
				child={Year}
			/>
		</div>
	);
};

DecadeView.propTypes = {
	selectedDate: PropTypes.object.isRequired
};

export default DecadeView;
