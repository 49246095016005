import { useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import ModalWrapper from "components/modalWrapper";
import Loader from "components/ui/loader";
import NoData from "components/ui/noData";

import { setShowReprintLastTicket, getLastReprintTicket, sendReprintLastTicketData } from "store/actions/tickets/reprintLastTicket.actions";

import { binaryToFlags, numberWithSpaces, makeCurrencyText, isLeagueGame, isChampionsCupGame, buildPathToStaticFolderOfCDN } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";

import { GAME_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";
import { BETSLIP_MODES } from "constants/betslip.constants";
import { TICKET_GENERAL_DETAILS, TICKET_MARKET_DETAILS, TICKET_REDEMPTION_DATE_VALUES } from "constants/ticket.constants";
import { DATE_TIME_FORMAT } from "constants/date.constants";

import ticketType from "types/ticket.type";
import sessionType from "types/session.type";

/** Reprint Last Ticket Component */
const ReprintLastTicket = ({ isLoading, setShowReprintLastTicket, getLastReprintTicket, sendReprintLastTicketData, ticket, session }) => {
	const { t } = useTranslation();

	/** Load last ticket */
	useEffect(() => {
		getLastReprintTicket();
	}, []);

	/** Get current ticket general details
	 * @function
	 * @returns {Array}
	 * @memberOf ReprintLastTicket
	 */
	const getGeneralDetails = () => binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), session.betTicket.generalDetails);

	/** Get current ticket markets selection
	 * @function
	 * @returns {Array}
	 * @memberOf ReprintLastTicket
	 */
	const getMarketSelection = () => binaryToFlags(Object.values(TICKET_MARKET_DETAILS), session.betTicket.marketSelection);

	/** Get redemption days count
	 * @function
	 * @returns {number}
	 * @memberOf ReprintLastTicket
	 */
	const getRedemptionDays = () => {
		switch (session.ticketRedemptionPeriod) {
			case TICKET_REDEMPTION_DATE_VALUES.SEVEN_DAYS:
				return 7;
			case TICKET_REDEMPTION_DATE_VALUES.FORTEEN_DAYS:
				return 14;
			case TICKET_REDEMPTION_DATE_VALUES.THIRTHY_DAYS:
				return 30;
			case TICKET_REDEMPTION_DATE_VALUES.FORTYFIVE_DAYS:
				return 45;
			case TICKET_REDEMPTION_DATE_VALUES.SIXTY_DAYS:
				return 60;
			case TICKET_REDEMPTION_DATE_VALUES.NINTY_DAYS:
				return 90;
			case TICKET_REDEMPTION_DATE_VALUES.HUNDREDTWENTY_DAYS:
				return 120;
			default:
				return 0;
		}
	};

	return (
		<ModalWrapper
			classNames={{ content: "vs--reprint-last-ticket-modal", body: "vs--reprint-last-ticket-modal-body" }}
			visible={true}
			onCancel={() => setShowReprintLastTicket(false)}
			modalContentWrapperStyles={{ height: 825 }}
			modalContentFooterStyles={{ marginTop: 0 }}
			footer={
				!isLoading && ticket ? (
					<div className="vs--reprint-footer vs--flex vs--justify-center vs--align-center vs--pr-16">
						<button className="vs--button vs--button-modal vs--mr-8 vs--confirm-cancel" onClick={() => setShowReprintLastTicket(false)}>
							{t("common.cancel")}
						</button>
						<button className="vs--button vs--button-modal vs--ml-8 vs--confirm-confirm" onClick={() => sendReprintLastTicketData()}>
							{t("common.reprint")}
						</button>
					</div>
				) : null
			}
		>
			{!isLoading ? (
				ticket ? (
					<div className="vs--reprint vs--reprint-large">
						<div className="vs--reprint-header vs--text-center vs--mb-24 vs--mt-32">
							<b className="vs--font-small vs--font-medium rt--pb-24">{t("cashier.reprintLastTicket")}</b>
						</div>
						<div className="vs--reprint-ticket vs--pt-24 vs--pr-24 vs--pb-32 vs--pl-24">
							<div className="vs--reprint-ticket-header vs--text-center vs--pb-16 vs--flex vs--align-center vs--justify-center">
								<i className="ic_virtualSports vs--font-normal vs--pr-8"></i>
								<span className="vs--font-small vs--font-regular">{t("common.virtualSports")}</span>
							</div>
							<div className="vs--reprint-ticket-info vs--pt-16 vs--pb-16">
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) && (
									<div className="vs--flex vs--justify-between vs--mb-12">
										<span>{t("cashier.betshop")}</span>
										<span>{session.projectName}</span>
									</div>
								)}
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) && (
									<div className="vs--flex vs--justify-between vs--mb-12">
										<span>{t("cashier.address")}</span>
										<span>{session.address}</span>
									</div>
								)}

								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.TICKET_ID) && (
									<div className="vs--flex vs--justify-between vs--mb-12">
										<span>{t("cashier.ticketID")}</span>
										<span>{ticket.id}</span>
									</div>
								)}
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.PRINTED_DATETIME) && (
									<div className="vs--flex vs--justify-between vs--mb-12">
										<span>{t("cashier.cashier")}</span>
										<span>{session?.cashier?.userName}</span>
									</div>
								)}

								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.VALIDATION_DATE) && session.ticketRedemptionPeriod !== TICKET_REDEMPTION_DATE_VALUES.NEVER && (
									<div className="vs--flex vs--justify-between">
										<span>{t("cashier.validTill")}</span>
										<span>{moment().add(getRedemptionDays(), "days").set({ hour: moment().hour(), minute: moment().minutes() }).format(DATE_TIME_FORMAT)}</span>
									</div>
								)}
							</div>
							{(ticket?.bets ?? []).map((bet) => {
								const team1 = bet?.gameData?.team1;
								const team2 = bet?.gameData?.team2;

								return (
									<div className="vs--reprint-ticket-info vs--reprint-ticket-info-border vs--pt-16 vs--pb-16" key={bet.id}>
										{getMarketSelection().includes(TICKET_MARKET_DETAILS.TEAM_NAMES) && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<b className="vs--font-medium">
													{team1 && team2
														? isLeagueGame(bet?.gameType) || isChampionsCupGame(bet?.gameType)
															? `${team1.countryCode ?? ""} - ${team2.countryCode ?? ""}`
															: `${t(`countries.${team1.countryCode ?? ""}`)} - ${t(`countries.${team2.countryCode ?? ""}`)}`
														: bet?.gameData?.venue
															? bet.gameData.venue
															: ""}
												</b>
											</div>
										)}
										{getMarketSelection().includes(TICKET_MARKET_DETAILS.GAME_TYPE) && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<span>{t("cashier.gameType")}</span>
												<span>{GAME_TYPE_TEXT_KEYS[bet?.gameType] ? t(`common.${GAME_TYPE_TEXT_KEYS[bet?.gameType]}`) : ""}</span>
											</div>
										)}
										{getMarketSelection().includes(TICKET_MARKET_DETAILS.DRAW_ID) && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<span>{t("common.eventId")}</span>
												<span>{bet.eventId}</span>
											</div>
										)}
										{getMarketSelection().includes(TICKET_MARKET_DETAILS.START_TIME) && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<span>{t("cashier.start")}</span>
												<span>{moment.utc(bet.startTime).local().format(DATE_TIME_FORMAT)}</span>
											</div>
										)}
										{getMarketSelection().includes(TICKET_MARKET_DETAILS.MARKETS) && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<span className="vs--reprint-nowrap-ellipsis vs--mr-10">{runMarketUtilsFunction("makeGroupTitle", [bet], bet.gameType)}</span>
												<span className="vs--reprint-nowrap-ellipsis">{runMarketUtilsFunction("makeOddText", [bet], bet.gameType)}</span>
											</div>
										)}

										{getMarketSelection().includes(TICKET_MARKET_DETAILS.MARKETS) && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<span>{t("bet.odds")}</span>
												<span>{numberWithSpaces(bet.factor)}</span>
											</div>
										)}

										{getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) && ticket.type === BETSLIP_MODES.SINGLE && (
											<div className="vs--flex vs--justify-between vs--mb-12">
												<span>{t("bet.stake")}</span>
												<span>{makeCurrencyText(bet.amount, session.currency)}</span>
											</div>
										)}

										{ticket.type === BETSLIP_MODES.SINGLE && getMarketSelection().includes(TICKET_MARKET_DETAILS.POSSIBLE_WIN) && (
											<div className="vs--flex vs--justify-between">
												<span>{t("bet.possibleWin")}</span>
												<span>{makeCurrencyText(Number(bet.amount) * Number(bet.factor), session.currency)}</span>
											</div>
										)}
									</div>
								)
							})}

							{(getMarketSelection().includes(TICKET_MARKET_DETAILS.BET_TYPE) || getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) || getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN)) && (
								<div className="vs--reprint-ticket-info vs--reprint-ticket-info-border vs--pt-16 vs--pb-20">
									{getMarketSelection().includes(TICKET_MARKET_DETAILS.BET_TYPE) && (
										<div className="vs--flex vs--justify-between vs--mb-12">
											<span>{t("bet.betType")}</span>
											<span>{ticket.type === BETSLIP_MODES.SINGLE ? t("bet.single") : t("bet.multi")}</span>
										</div>
									)}

									{getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) && (
										<div className="vs--flex vs--justify-between vs--mb-12">
											<span>{t("bet.stake")}</span>
											<span>{makeCurrencyText(ticket.totalAmount, session.currency)}</span>
										</div>
									)}

									{getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN) && (
										<div className="vs--flex vs--justify-between">
											<span>{t("cashier.maxPossibleWin")}</span>
											<span>{makeCurrencyText(ticket.possibleWin, session.currency)}</span>
										</div>
									)}
								</div>
							)}
							{getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_WIN_PER_TICKET) && (
								<div className="vs--reprint-ticket-info vs--reprint-ticket-info-border-dashed vs--pt-20">
									<div className="vs--flex vs--justify-between">
										<span>{t("cashier.maxWinPerTicket")}</span>
										<span>{makeCurrencyText(ticket.type === BETSLIP_MODES.SINGLE ? (session?.currency?.singlePossibleWinMax ?? Infinity) : (session?.currency?.multiPossibleWinMax ?? Infinity), session.currency)}</span>
									</div>
								</div>
							)}

							{session.betTicket.enableBarcodePrinting && (
								<div className="vs--respint-ticket-barcode vs--pl-18 vs--pt-20">
									<img src={buildPathToStaticFolderOfCDN("cashier-images/barcode.png")} />
								</div>
							)}
						</div>
					</div>
				) : (
					<NoData />
				)
			) : (
				<Loader style={{ marginTop: "100px" }} />
			)}
		</ModalWrapper>
	);
};

/** ReprintLastTicket propTypes
 * PropTypes
 */
ReprintLastTicket.propTypes = {
	/** Redux state property, is true when loading last ticket */
	isLoading: PropTypes.bool,
	/** Redux action to show reprint last ticket modal */
	setShowReprintLastTicket: PropTypes.func,
	/** Redux action to get last ticket for reprint */
	getLastReprintTicket: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, last ticket for reprint */
	ticket: ticketType,
	/** Redux action to reprint last ticket */
	sendReprintLastTicketData: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.tickets.reprintLastTicket.isLoading,
		ticket: state.tickets.reprintLastTicket.ticket,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	setShowReprintLastTicket: (show) => {
		dispatch(setShowReprintLastTicket(show));
	},

	getLastReprintTicket: () => {
		dispatch(getLastReprintTicket());
	},

	sendReprintLastTicketData: () => {
		dispatch(sendReprintLastTicketData());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ReprintLastTicket);
