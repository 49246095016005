import { useState, Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { TICKETS_LIST_TYPE } from "constants/common.constants";
import SwitcherPendingsSettled from "../../tickets/switcherPendingsSettled";

import historyType from "types/history.type";
import { getUrlVars } from "utils/common";

/** Pending Tickets button in header */
const PendingTicketsButton = ({ pendings }) => {
	const { t } = useTranslation();

	const [showPendings, setShowPendings] = useState(false);
	const isPreview = getUrlVars()["isPreview"];

	return (
		<Fragment>
			<button
				data-badge={pendings.length}
				type="button"
				className="vs--button vs--button-open-tickets vs--pl-24 vs--pr-24 vs--pt-7 vs--pb-7 vs--font-exstrasmall"
				onClick={() => !isPreview && setShowPendings(true)}
				title={t("cashier.pendingTickets")}
			>
				<span className="vs--button-open-tickets-text">{t("cashier.pendingTickets")}</span>
			</button>
			{showPendings && <SwitcherPendingsSettled defaultKey={TICKETS_LIST_TYPE.PENDING} onCancel={() => setShowPendings(false)} />}
		</Fragment>
	);
};

/** PendingTicketsButton propTypes
 * PropTypes
 */
PendingTicketsButton.propTypes = {
	/** Redux state property, array of pending tickets */
	pendings: PropTypes.arrayOf(historyType)
};

const mapStateToProps = (state) => {
	return {
		pendings: state.tickets.pendings,
		isLoading: state.tickets.isPendingsLoading
	};
};

export default connect(mapStateToProps, null)(PendingTicketsButton);
