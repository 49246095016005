import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_SETTLED, SETTLED_BEFORE, SETTLED_AFTER, SET_SETTLED_FILTERS, RESET_SETTLED_FILTERS, UPDATE_SETTLED } from "../../actionTypes";

const setSettledBefore = () => ({
	type: SETTLED_BEFORE
});

const setSettledAfter = () => ({
	type: SETTLED_AFTER
});

const setSettled = (settled) => ({
	type: SET_SETTLED,
	payload: { settled }
});

export const getSettledTickets = () => {
	return (dispatch, getState) => {
		dispatch(setSettledBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SETTLED}`,
			method: Methods.GET,
			params: {
				...getState().tickets.filters
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSettled(data));
				dispatch(setSettledAfter());
			})
			.catch((ex) => {
				dispatch(setSettledAfter());
			});
	};
};

export const setSettledFilters = (filters) => ({
	type: SET_SETTLED_FILTERS,
	payload: { filters }
});

export const resetSettledFilters = () => ({
	type: RESET_SETTLED_FILTERS
});

export const updateSettled = (settled) => ({
	type: UPDATE_SETTLED,
	payload: { settled }
});
