import  { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearBets, removeLastBet, clearForecast, clearKenoBalls } from "store/actions/betslip/betslip.actions";
import KEY_CODE from "constants/keyboard.constants";

/* Remove All button of Betslip */
const RemoveAll = ({ clearBets, removeLastBet }) => {
	const { t } = useTranslation();

	/** Add keyboard event listeners for bet removing */
	useEffect(() => {
		const handler = (e) => {
			if (e.ctrlKey && e.keyCode === KEY_CODE.BACKSPACE) {
				if (e.shiftKey) {
					clearBets();
				} else {
					removeLastBet();
				}
				e.preventDefault();
				e.stopPropagation();
			}
		};
		document.addEventListener("keyup", handler, false);

		return () => document.removeEventListener("keyup", handler);
	}, []);

	return (
		<button className="vs--button vs--button-remove-all vs--flex vs--justify-center vs--align-center" onClick={clearBets}>
			<span className="vs--title vs--font-normal vs--font-regular">
				<i className="ic_close" />
			</span>
		</button>
	);
};

/** RemoveAll propTypes
 * PropTypes
 */
RemoveAll.propTypes = {
	/** Redux action to clear betslip bets */
	clearBets: PropTypes.func,
	/** Redux action to remove betslip last bet */
	removeLastBet: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	clearBets: () => {
		dispatch(clearBets());
		dispatch(clearForecast());
		dispatch(clearKenoBalls());
	},
	removeLastBet: () => {
		dispatch(removeLastBet());
	}
});

export default connect(null, mapDispatchToProps)(RemoveAll);
