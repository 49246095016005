import { useEffect, useRef } from "react";
import anime from "animejs/lib/anime.es.js";

import { JACKPOT_LEVEL, JACKPOT_ANIMATIONS_TIMEOUTS } from "constants/bonus.constants";

const vW = 1920;
const vH = 1080;

const LEVELS_STOP_COLORS = {
	[JACKPOT_LEVEL.BRONZE]: {
		stop1: "#EC9B59",
		stop2: "#BE9060",
		stop3: "#B5743F",
		stop4: "#BF7E43",
	},
	[JACKPOT_LEVEL.SILVER]: {
		stop1: "#676A70",
		stop2: "#6A6C74",
		stop3: "#9A9CA6",
		stop4: "#7D7E84 ",
	},
	[JACKPOT_LEVEL.GOLD]: {
		stop1: "#B19C65",
		stop2: "#D8BD73",
		stop3: "#BF9E48",
		stop4: "#C59239",
	},
}

const TransitionSvg = ({
	getMainContainer, 
	jackpot, 
	toggleTransitionFrom = "top", 
	setShowCanfetti, 
	setAnimationFinish 
}) => {
	const navigationRef = useRef();
	const pageTransitionRef = useRef();
	const svgRef = useRef();

	const transitionOffsetX = vW / (Math.floor(Math.random() * 10) + 10);
	const transitionOffsetY = vH / (Math.floor(Math.random() * 10) + 10);
	const top = toggleTransitionFrom == 'bottom' ? transitionOffsetX : 0;
	const right = toggleTransitionFrom == 'left' ? transitionOffsetY : 0;
	const bottom = toggleTransitionFrom == 'top' ? transitionOffsetX : 0;
	const left = toggleTransitionFrom == 'right' ? transitionOffsetY : 0;

	const pageTransitionPathPoints = {
		default: `M0,0 C0,0 ${vW / 4},0 ${vW / 2},0 C${vW / 4 * 3},0 ${vW},0 ${vW},0 C${vW},0 ${vW},${vH / 4} ${vW},${vH / 2} C${vW},${vH / 4 * 3} ${vW},${vH} ${vW},${vH} C${vW},${vH} ${vW / 4 * 3},${vH} ${vW / 2},${vH} C${vW / 4},${vH} 0,${vH} 0,${vH} C0,${vH} 0,${vH / 4 * 3} 0,${vH / 2} C0,${vH / 4} 0,0 0,0 Z`,
		inner: `M0,0 C0,0 ${vW / 4},${top} ${vW / 2},${top} C${vW / 4 * 3},${top} ${vW},0 ${vW},0 C${vW},0 ${(vW - right)},${vH / 4} ${(vW - right)},${vH / 2} C${(vW - right)},${vH / 4 * 3} ${vW},${vH} ${vW},${vH} C${vW},${vH} ${vW / 4 * 3},${vH - bottom} ${vW / 2},${vH - bottom} C${vW / 4},${vH - bottom} 0,${vH} 0,${vH} C0,${vH} ${left},${vH / 4 * 3} ${left},${vH / 2} C${left},${vH / 4} 0,0 0,0 Z`,
		outer: `M0,0 C0,0 ${vW / 4},${-top} ${vW / 2},${-top} C${vW / 4 * 3},${-top} ${vW},0 ${vW},0 C${vW},0 ${(vW - (-right))},${vH / 4} ${(vW - (-right))},${vH / 2} C${(vW - (-right))},${vH / 4 * 3} ${vW},${vH} ${vW},${vH} C${vW},${vH} ${vW / 4 * 3},${vH - (-bottom)} ${vW / 2},${vH - (-bottom)} C${vW / 4},${vH - (-bottom)} 0,${vH} 0,${vH} C0,${vH} ${-left},${vH / 4 * 3} ${-left},${vH / 2} C${-left},${vH / 4} 0,0 0,0 Z`
	}

	useEffect(() => {
		const transitionTimeoutId = setTimeout(() => {	
			const mainContainer = getMainContainer();

			mainContainer.classList.add('is-open');
	
			setTimeout(() => {
				mainContainer.classList.add('vs--active-jackpot-live-info-winner-active')
			}, JACKPOT_ANIMATIONS_TIMEOUTS.ACTIVE);
	
			if (svgRef.current.querySelector('path')) {
				pageTransitionRef.current.style[toggleTransitionFrom] = '-100%';
				pageTransitionRef.current.style.width = vW + 'px';
				pageTransitionRef.current.style.height = vH + 'px';
			}
	
			svgRef.current.removeAttribute('hidden')
	
			anime({
				targets: pageTransitionRef.current,
				duration: 1200,
				easing: 'easeInQuad',
				top: (toggleTransitionFrom == 'top') ? 0 : null,
				right: (toggleTransitionFrom == 'right') ? 0 : null,
				bottom: (toggleTransitionFrom == 'bottom') ? 0 : null,
				left: (toggleTransitionFrom == 'left') ? 0 : null,
				complete: () => {
					if (navigationRef.current) {
						setShowCanfetti(true)
					}
				}
			});
	
			anime({
				targets: svgRef.current.querySelector('#transition-path'),
				duration: 400,
				easing: 'linear',
				d: pageTransitionPathPoints.inner,
				complete: () => {
					anime({
						targets: svgRef.current.querySelector('#transition-path'),
						duration: 300,
						easing: 'linear',
						d: pageTransitionPathPoints.outer
					});
				}
			});
		}, JACKPOT_ANIMATIONS_TIMEOUTS.TRANSITION_SVG)

		const closingTimeoutId = setTimeout(() => {
			navigationRef.current.style.transition = "all 1.1s ease-in-out"
			navigationRef.current.style.height = 0;

			const mainContainer = getMainContainer();
			mainContainer.classList.remove('is-open');

			pageTransitionRef.current.style.height = 0;
			pageTransitionRef.current.style.transition = "all 1.1s ease-in-out";
			
			setTimeout(() => {
				mainContainer.classList.remove('vs--active-jackpot-live-info-winner-active')
				setAnimationFinish(true);
			}, JACKPOT_ANIMATIONS_TIMEOUTS.INACTIVE);
		}, JACKPOT_ANIMATIONS_TIMEOUTS.CLOSING_SVG);

		return () => {
			clearTimeout(transitionTimeoutId);
			clearTimeout(closingTimeoutId);
		}
	}, []);

	return (
		<nav ref={navigationRef} className="vs--active-jackpot-live-info-win">
			<div className="vs--active-jackpot-live-info-win-handle" data-permalink="none" data-position="top">
				<svg className="shape" width="220" height="220" preserveAspectRatio="none" viewBox="0 0 220 220">
					<path id="initial-path" d="M30,0 C42,0 102,0 110,0 C118,0 178,-0 190,0 C166,-0 150,0 110,0 C70,0 54,-0 30,0 Z">
					</path>
				</svg>
			</div>

			<div ref={pageTransitionRef} className="page-transition">
				<svg hidden={true} ref={svgRef} className="vs--active-jackpot-live-info-win-shape" width={vW} height={vH} viewBox={`0 0 ${vW} ${vH}`} fill="url(#gradient)" preserveAspectRatio="none">
					<g>
						<path
							id="transition-path"
							d={pageTransitionPathPoints.default}
						/>
					</g>

					<defs>
						<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform="rotate(45)">
							<stop offset="0%" stopColor={LEVELS_STOP_COLORS[jackpot.levelType].stop1} stopOpacity="1" />
							<stop offset="29%" stopColor={LEVELS_STOP_COLORS[jackpot.levelType].stop2} stopOpacity="1" />
							<stop offset="66%" stopColor={LEVELS_STOP_COLORS[jackpot.levelType].stop3} stopOpacity="1" />
							<stop offset="100%" stopColor={LEVELS_STOP_COLORS[jackpot.levelType].stop4} stopOpacity="1" />
						</linearGradient>
					</defs>
				</svg>
			</div>
		</nav>
	);
};

export default TransitionSvg;