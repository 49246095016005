import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setSessionTemplateType } from "store/actions/auth/auth.actions";

const TemplateItem = ({ key, thumbnail, name, type, sessionTemplateType, setSessionTemplateType }) => {
	const handleClickTemplateItem = () => {
		setSessionTemplateType(type);
	};

	return (
		<div className="vs--templates-item-block" key={key} onClick={handleClickTemplateItem}>
			<div className="vs--templates-item-header">{name}</div>
			<div className={`vs--templates-item-thumbnail ${type === sessionTemplateType ? "vs--templates-item-thumbnail-selected" : ""}`}>
				<img src={thumbnail} alt="template" />
			</div>
		</div>
	);
};

TemplateItem.propTypes = {
	// Iterated component's key
	key: PropTypes.number,
	// Template item's image
	thumbnail: PropTypes.string,
	// Template item's name
	name: PropTypes.string,
	// Template item's type
	type: PropTypes.number,
	// Redux state of session's current template type
	sessionTemplateType: PropTypes.number,
	// Redux action which sets current template type
	setSessionTemplateType: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sessionTemplateType: state.auth.session.templateType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setSessionTemplateType: (type) => {
		dispatch(setSessionTemplateType(type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateItem);
