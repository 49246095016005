import { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SeasonList from "./list";
import SeasonLine from "./line";
import SeasonSpecial from "./special/season";
import RoundSpecial from "./special/round";
import CorrectScore from "./correctScore";
import runMarketUtilsFunction from "utils/markets/run";
import { GAME_TYPE } from "constants/game.constants";
import rtpType from "types/rtp.type";
import eventType from "types/event.type";

/** Season Markets list component */
const Season = ({ currentGameType, activeTab, eventInfos, parentEvent, rtPs, special }) => {
	const marketNames = useMemo(() => {
		const args = [{ roundOrderNumber: parentEvent.orderNumber }];
		const groupsStructure = runMarketUtilsFunction("getGroupsStructure", args, currentGameType);
		const m = groupsStructure?.activeTab ?? [];
		return m;
	}, [activeTab, currentGameType, parentEvent]);

	return (
		<div className="vs--league-markets" data-raund={parentEvent.orderNumber}>
			<SeasonList currentGameType={currentGameType} activeTab={activeTab} marketNames={marketNames} isUncommon={["fulltime", "round", "CorrectScore6Goals"].includes(activeTab)} showOddNames={!["round", "CorrectScore6Goals"].includes(activeTab)}>
				{(() => {
					switch (activeTab) {
						case "fulltime":
							return <SeasonSpecial special={special} />;
						case "round":
							return <RoundSpecial rtPs={rtPs} activeTab={activeTab} parentEvent={parentEvent} />;
						case "CorrectScore6Goals":
							return <CorrectScore eventInfos={eventInfos} activeTab={activeTab} rtPs={rtPs} />;
						default:
							break;
					}
					return Array.isArray(eventInfos)
						? eventInfos.map((eventInfo) => {
								return <SeasonLine key={eventInfo?.id} eventInfo={eventInfo} rtPs={rtPs} activeTab={activeTab} parentEvent={parentEvent} />;
							})
						: null;
				})()}
			</SeasonList>
		</div>
	);
};

Season.propTypes = {
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Active tab */
	activeTab: PropTypes.string,
	/** Current event info */
	eventInfos: PropTypes.arrayOf(eventType),
	/** Week info of events */
	parentEvent: eventType,
	/** RTPS of current game */
	rtPs: PropTypes.arrayOf(rtpType),
	/** Redux state property of special markets */
	special: PropTypes.object
};

const mapStateToProps = (state) => {
	const currentGame = state.auth.session.games.find((g) => g.type === state.game.currentGameType);

	return {
		currentGameType: state.game.currentGameType,
		rtPs: currentGame?.rtPs ?? [],
		special: state.season.special
	};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Season);
