import { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Tooltip from "components/ui/tooltip";

import ClockCounter from 'components/ui/clockCounter';
import Menu from './menu';
import Info from './info';
import PendingTicketsButton from './pendingTicketsButton';
import LanguageSelector from 'components/ui/languageSelector';
import Reprint from './reprint';
import Dropdown from "components/ui/dropdown";
import Pools from "components/jackpotInfo/pools";

import useIntegrationType from '../../hooks/useIntegrationType';

import { getActiveJackpot } from "store/actions/bonuses/jackpot.actions";

import {
	closeFullscreen,
	isFullscreen,
	openFullscreen,
	makeCurrencyText,
	getUrlVars,
	buildPathToStaticFolderOfCDN
} from "utils/common";

import { PROJECT_TYPE } from "constants/common.constants";

import eventType from "types/event.type";
import sessionType from "types/session.type";


import { GAME_TYPE } from 'constants/game.constants';

/* Header Component */
const Header = ({
	matches,
	current,
	session,
	jackpotInfo,
	currentTime,
	getActiveJackpot
}) => {
	const integrationType = useIntegrationType();
	const { t } = useTranslation();
	const isPreview = getUrlVars()["isPreview"];

	const currentMatch = matches.find((match) => match.id === current);
	const upcomingMatchInfo = {
		gameData: currentMatch?.event?.gameData ?? {},
		id: currentMatch?.event?.id ?? null,
		orderNumber: currentMatch?.event?.orderNumber ?? null,
		startTime: currentMatch?.event?.startTime ?? "",
		gameType: currentMatch?.event?.gameType ?? ""
	};

	/* Tooltip */
	const BetShopAddres = (
		<span className="betshop-tooltip">
			{session?.projectName}&nbsp;{session?.address}
		</span>
	);

	const isIntegrationTypeStandalone = integrationType === PROJECT_TYPE.STANDALONE;

	useEffect(() => {
		if (!isPreview && session?.activeJackpotId && Object.keys(jackpotInfo).length === 0) {
			getActiveJackpot();
		}
	}, [session?.activeJackpotId, jackpotInfo, isPreview]);

	return (
		<div
			className={`vs--header vs--pt-4 vs--pb-4 vs--flex vs--align-center ${!isIntegrationTypeStandalone ? "vs--justify-between" : ""}`}
		>
			{
				isIntegrationTypeStandalone
					? (
						<>
							<div
								className="vs--header-section vs--pl-24 vs--pr-24 vs--flex vs--flex-col vs--justify-center vs--align-center vs--header-section-with-border"
								data-section="game"
							>
								<img
									alt="logo"
									src={session.betShopLogoPath ? `${import.meta.env.SYSTEM_CDN_URL}/${session.betShopLogoPath.toLowerCase()}` : buildPathToStaticFolderOfCDN("cashier-images/betshop-default-logo.png")}
								/>
							</div>
							<div
								className="vs--header-section vs--pl-24 vs--pr-24 vs--flex vs--flex-row vs--justify-center vs--align-center vs--header-section-with-border"
								data-section="time"
							>
								<i className="ic_time vs--font-normal vs--mb-4 vs--pr-6" />
								<b className="vs--font-small vs--font-regular">
									{moment.unix(currentTime).format("HH:mm")}
								</b>
							</div>
							{session.activeJackpotId && (
								<div className='vs--header-section vs--header-section-jackpot vs--header-section-with-border vs--pl-16 vs--pr-8 vs--flex vs--flex-row vs--justify-center vs--align-center'>
									<Dropdown
										className="vs--header-section-jackpot-dropdown"
										childrenContainerClassname="vs--header-section-jackpot-dropdown-container vs--pt-16 vs--pb-16 vs--pl-16 vs--pr-16"
										content={(
											<div className='vs--flex vs--align-center'>
												<img alt="jackpot" src={buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot.svg")} />
												<i className='ic_down' />
											</div>
										)}
										disabled={isPreview}
									>
										<Pools
											className='vs--header-section-jackpot-dropdown-container-content'
											data={jackpotInfo?.levels}
										/>
									</Dropdown>
								</div>
							)}
						</>
					)
					: upcomingMatchInfo.id !== null
						? (
							<div className='vs--flex vs--header-id-section'>
								<div
									className={
										"vs--header-section vs--pl-24 vs--flex vs--flex-row vs--justify-center vs--align-center vs--header-section-with-border" + (
											[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(upcomingMatchInfo.gameType)
												? " vs--pr-24"
												: ""
										)
									}
									data-section="drawId"
								>
									<span
										className="vs--font-regular vs--font-small"
										title={t('common.id')}
									>
										{t('common.id')}:&nbsp;
									</span>
									<b className="vs--font-regular vs--font-small">
										{upcomingMatchInfo?.id}
									</b>
								</div>
								<div
									className={
										"vs--header-section vs--pr-24 vs--flex vs--justify-end vs--align-center" + (
											[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(upcomingMatchInfo.gameType)
												? " vs--pl-24 vs--header-section-with-border"
												: ""
										)
									}
									data-section="match"
								>
									<div className="vs--flex vs--justify-center vs--align-center">
										<Info matchInfo={upcomingMatchInfo} />
									</div>
								</div>
							</div>
						)
						: null
			}

			<div className={`vs--header-section vs--pl-24 vs--pr-24 vs--flex vs--flex-col vs--justify-center vs--align-center ${isIntegrationTypeStandalone ? "vs--standalon-position" : "vs--iframe-position"}`} data-section="countdown">
				{upcomingMatchInfo.id !== null ? <ClockCounter date={upcomingMatchInfo.startTime} /> : null}
			</div>

			{isIntegrationTypeStandalone && upcomingMatchInfo.id !== null ? (
				<>
					<div className="vs--header-section vs--pl-24 vs--pr-24 vs--flex vs--justify-end vs--align-center vs--header-section-with-border" data-section="match">
						<div className="vs--flex vs--flex-col vs--justify-center vs--align-center">
							<Info matchInfo={upcomingMatchInfo} />
						</div>
					</div>
					<div className="vs--header-section vs--pl-24 vs--pr-24 vs--flex vs--flex-row vs--justify-center vs--align-center vs--header-section-with-border" data-section="drawId">
						<span className="vs--font-regular vs--font-small vs--pr-6" title={t("common.id")}>
							{t("common.id")}:&nbsp;
						</span>
						<b className="vs--font-regular vs--font-small">{upcomingMatchInfo?.id}</b>
					</div>
				</>
			) : null}

			{((isIframe, children) => {
				if (isIframe) {
					return <div className="vs--flex">{children}</div>;
				}
				return children;
			})(
				!isIntegrationTypeStandalone,
				<>
					<div className="vs--header-section vs--header-section-button-box  vs--pl-24 vs--pr-24 vs--flex vs--flex-col vs--justify-center vs--align-center vs--header-section-with-border" data-section="tickets" data-standalone={isIntegrationTypeStandalone}>
						<PendingTicketsButton />
					</div>
					{isIntegrationTypeStandalone ? (
						<>
							<div className="vs--header-section vs--pl-24 vs--pr-24 vs--flex vs--flex-col vs--justify-center vs--align-center vs--header-section-with-border" data-section="credit">
								<b className="vs--font-medium vs--font-normal">{makeCurrencyText(Number(session?.balance) || 0, session.currency)}</b>
							</div>
							<div className="vs--header-section vs--flex vs--pl-24 vs--header-section-with-border vs--align-center vs--header-section-actions" data-section="actions">
								<Reprint />
								<div className="vs--pr-24 vs--fullscreen-icon" onClick={() => (isFullscreen() ? closeFullscreen() : openFullscreen())}>
									<i className="ic_fullscreen" />
								</div>
								{!isPreview && isIntegrationTypeStandalone && <LanguageSelector />}
							</div>
							<div className="vs--header-section vs--pl-24 vs--header-section-username vs--flex " data-section="account">
								<div className="vs--header-section-username-icon vs--flex vs--align-center vs--pr-8">
									<i className="ic_user" />
								</div>
								<div className="vs--text-left vs--flex vs--flex-col vs--justify-center">
									<b className="vs--font-regular vs--font-smallest">{!isPreview ? `${session?.cashier?.firstName ?? ""} ${session?.cashier?.lastName ?? ""}` : "Name Surname"}</b>
									<Tooltip title={BetShopAddres} placement="bottom" overlayClassName="betshop-address-tooltip">
										<span className="vs--font-regular vs--font-mini">
											{session?.projectName ?? ""}&nbsp;{session?.address ?? ""}
										</span>
									</Tooltip>
								</div>
							</div>
						</>
					) : (
						<div className="vs--header-section vs--flex vs--justify-end vs--align-center vs--header-section-actions" data-section="actions">
							<Reprint />
						</div>
					)}
					<div className="vs--header-section vs--pr-24 vs--flex vs--justify-end vs--align-center vs--header-section-actions" data-section="actions">
						<Menu />
					</div>
				</>
			)}
		</div>
	);
};

/** Header propTypes
 * PropTypes
 */
Header.propTypes = {
	/** Redux state property, all loaded matches */
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			event: eventType
		})
	),
	/** Redux state property, current match id */
	current: PropTypes.number,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, active Jackpot Info */
	jackpotInfo: PropTypes.object,
	/** Redux state property, current time */
	currentTime: PropTypes.number,
	/** Redux action to get Active Jackpot Info */
	getActiveJackpot: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		matches: state.game.matches.data,
		current: state.game.current,
		session: state.auth.session,
		jackpotInfo: state.bonuses.jackpot.data,
		currentTime: state.common.currentTime,
	}
}

const mapDispatchToProps = dispatch => (
	{
		getActiveJackpot: () => {
			dispatch(getActiveJackpot());
		}
	}
)

export default connect(mapStateToProps, mapDispatchToProps)(Header);
