import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import BetSlipTabs from "./betslipTabs";
import SingleBet from "./singleBet";
import Stake from "./stake";
import PaymentMethod from "./paymentMethod";
import ConfirmationTransaction from "./confirmTransaction";
import PendingBetslip from "./pendingBetslip";

import { getEventsRepeatedBets } from "utils/bets";

import { BETSLIP_MODES } from "constants/betslip.constants";

import betType from "types/bet.type";

/* Betslip Tab Component */
const BetSlip = ({ mode, bets, showPaymentMethod, confirmingTransaction }) => {
	const { t } = useTranslation();

	const groupedRepeatedBets = getEventsRepeatedBets(bets, mode);

	return (
		<Fragment>
			<BetSlipTabs />
			<div className={"vs--betslip vs--flex vs--flex-equal vs--flex-col" + (bets.length > 0 ? " vs--flex-grow" : "")}>
				{
					<Fragment>
						<div className="vs--betslip-bets">
							<PendingBetslip />
							{bets.map((bet, index) => {
								return (
									<SingleBet
										bet={bet}
										key={bet.key}
										index={index}
										groupedRepeatedBets={groupedRepeatedBets}
									/>
								);
							})}
						</div>
						{!bets.length && (
							<div className="vs--betslip-empty vs--flex vs--flex-col vs--align-center vs--mt-54">
								<h5 className="vs--font-exstrasmall vs--font-regular vs--mb-12">{t("bet.betslipEmptyText")}</h5>
								<p className="vs--font-smallest vs--font-regular vs--mb-12">{t("bet.betslipEmptySubtext")}</p>
							</div>
						)}
					</Fragment>
				}
			</div>
			<Stake />
			{showPaymentMethod && <PaymentMethod />}
			{confirmingTransaction && <ConfirmationTransaction />}
		</Fragment>
	);
};

/** BetSlip propTypes
 * PropTypes
 */
BetSlip.propTypes = {
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, to show/hide payment method modal */
	showPaymentMethod: PropTypes.bool,
	/** Redux state property, confirming transaction id */
	confirmingTransaction: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		mode: state.betslip.mode,
		bets: state.betslip.bets,
		showPaymentMethod: state.betslip.showPaymentMethod,
		confirmingTransaction: state.betslip.confirmingTransaction
	};
};

export default connect(mapStateToProps, null)(BetSlip);
