import PropTypes from "prop-types";

import TileGroup from "components/ui/datePicker/calendar/tileGroup";
import Day from "components/ui/datePicker/calendar/monthView/day";

import { getDayOfWeek, getYear } from "utils/calendar";
import { CALENDAR_TYPE } from "constants/calendar.constants";

const Days = ({ selectedDate, calendarType, showFixedNumberOfWeeks, showNeighboringMonth, ...otherProps }) => {
	const year = getYear(selectedDate);
	const monthIndex = selectedDate.month();

	const hasFixedNumberOfWeeks = showFixedNumberOfWeeks || showNeighboringMonth;
	const dayOfWeek = getDayOfWeek(selectedDate, calendarType);

	const offset = hasFixedNumberOfWeeks ? 0 : dayOfWeek;

	/**
	 * Defines on which day of the month the grid shall start. If we simply show current
	 * month, we obviously start on day one, but if showNeighboringMonth is set to
	 * true, we need to find the beginning of the week the first day of the month is in.
	 */
	const start = (hasFixedNumberOfWeeks ? -dayOfWeek : 0) + 1;

	/**
	 * Defines on which day of the month the grid shall end. If we simply show current
	 * month, we need to stop on the last day of the month, but if showNeighboringMonth
	 * is set to true, we need to find the end of the week the last day of the month is in.
	 */
	const getEnd = () => {
		if (showFixedNumberOfWeeks) {
			// Always show 6 weeks
			return start + 6 * 7 - 1;
		}

		const daysInMonth = selectedDate.daysInMonth();

		if (showNeighboringMonth) {
			const activeEndDate = new Date();
			activeEndDate.setFullYear(year, monthIndex, daysInMonth);
			activeEndDate.setHours(0, 0, 0, 0);
			const daysUntilEndOfTheWeek = 7 - getDayOfWeek(activeEndDate, calendarType) - 1;
			return daysInMonth + daysUntilEndOfTheWeek;
		}

		return daysInMonth;
	};

	return (
		<TileGroup
			{...otherProps}
			className="vs--ui-calendar-month-view-days"
			selectedDate={selectedDate}
			count={7}
			currentMonthIndex={monthIndex}
			dateTransform={(day) => {
				const date = new Date();
				date.setFullYear(year, monthIndex, day);
				date.setHours(0, 0, 0, 0);
				return date;
			}}
			dateType="day"
			start={start}
			end={getEnd()}
			offset={offset}
			child={Day}
		/>
	);
};

Days.propTypes = {
	calendarType: PropTypes.oneOf(Object.values(CALENDAR_TYPE)),
	showFixedNumberOfWeeks: PropTypes.bool,
	showNeighboringMonth: PropTypes.bool,
	selectedDate: PropTypes.object.isRequired
};

export default Days;
