import PropTypes from "prop-types";

const TileGroup = ({ className, count = 3, dateTransform, dateType, end, offset, start, step = 1, child: Child, value, ...tileProps }) => {
	let point = start;

	const tiles = Array.from({ length: (end - point + 1) / step }, () => {
		const date = dateTransform(point);
		point += step;

		return (
			<Child
				key={date.getTime()}
				className="vs--ui-calendar-tile"
				style={{
					flexBasis: `${100 / count}%`,
					flexShrink: 0,
					flexGrow: 0,
					overflow: "hidden"
				}}
				date={date}
				point={point}
				{...tileProps}
			/>
		);
	});

	return <div className={className}>{tiles}</div>;
};

TileGroup.propTypes = {
	className: PropTypes.string,
	count: PropTypes.number,
	dateTransform: PropTypes.func.isRequired,
	dateType: PropTypes.string,
	end: PropTypes.number.isRequired,
	offset: PropTypes.number,
	step: PropTypes.number,
	start: PropTypes.number.isRequired,
	child: PropTypes.func.isRequired,
	value: PropTypes.object
};

export default TileGroup;
