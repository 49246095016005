import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initTranslations } from 'translations/config';

import Loader from 'components/ui/loader';
import Wrapper from "components/pages/jackpotLiveInfo/wrapper";

import { refreshStreamAccess } from 'store/actions/auth/auth.actions';
import { getActiveJackpot } from "store/actions/bonuses/jackpot.actions";
import { getUrlVars } from "utils/common";

const getIsTranslationLoaded = (state) => state.auth.translationsLoaded;
const getStreamAccessLoaded = (state) => state.auth.stream.accessLoaded;

/** Active Jackpot Live Info Layout Component */
const Layout = () => {
	const [decodedData, setDecodedData] = useState(null);

	const translationsLoaded = useSelector(getIsTranslationLoaded);
	const streamAccessLoaded = useSelector(getStreamAccessLoaded);

	const dispatch = useDispatch();

	const activeJackpotId = decodedData?.activeJackpotId ?? null;
	const streamRefreshToken = decodedData?.refreshToken ?? null;

	const scale = () => {
		const coificientX = window.innerWidth / 1920;
		const coificientY = window.innerHeight / 1080;

		document.body.style.transform = "scaleX(" + coificientX + ") scaleY(" + coificientY + ")";
	}

	/** Scale functionality */
	useEffect(() => {
		scale();
		window.addEventListener("resize", scale);
		return () => {
			window.removeEventListener("resize", scale);
		}
	}, []);

	/** Load translations */
	useEffect(() => {
		document.documentElement.classList.add("vs--html-active-jackpot-live-info");
		let streamData = { languageId: "en" };
		try {
			const streamEncodedData = getUrlVars()['data'];
			streamData = JSON.parse(decodeURIComponent(atob(streamEncodedData)));
			setDecodedData(streamData);
		} catch (ex) {
			console.log("can't decode data")
		}

		initTranslations(
			streamData.languageFilePath,
			streamData.languageId,
			streamData.languageFilePath,
			streamData.languageId,
			Function.prototype,
			Function.prototype
		);
	}, []);

	useEffect(() => {
		if (translationsLoaded && streamRefreshToken) {
			dispatch(refreshStreamAccess(streamRefreshToken));
		}
	}, [translationsLoaded, streamRefreshToken]);

	useEffect(() => {
		if (streamAccessLoaded && activeJackpotId) {
			dispatch(getActiveJackpot(true));
		}
	}, [streamAccessLoaded, activeJackpotId]);

	return (
		<div className="vs--container vs--container-jackpot-live-info">
			<div className='vs--container-jackpot-live-info-blur'>
				{
					translationsLoaded && streamAccessLoaded ? (
						<Wrapper decodedData={decodedData} />
					) : <Loader full={true} />
				}
			</div>
		</div>
	)
}

export default Layout;
