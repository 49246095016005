import useEvent from "hooks/useEvent";
import { useCallback, useEffect } from "react";

import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { setWonJackpotBonuses, updateJackpotBonus } from "store/actions/bonuses/jackpot.actions";

import SignalRUtils from "utils/signalR";

/* Active Jackpot Live Info Wrapper Container Component - Wrapper */
const Wrapper = ({ decodedData }) => {
	const dispatch = useDispatch();

	const activeJackpotId = decodedData?.activeJackpotId ?? null;

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Wrapper
	 */
	const handleSignalREvents = useEvent(
		(connectionArg) => {
			SignalRUtils.getConnections().forEach((con, index) => {
				const connection = con.getConnection();
				if (connection !== connectionArg.connection) {
					return;
				}
				if (index === 1 && connection.state === "Connected") {
					connection.invoke("Subscribe", `Jackpots_${activeJackpotId}`);
				}
				connection.off("Jackpots");
				connection.on("Jackpots", (data) => {
					const d = JSON.parse(data);
					dispatch(updateJackpotBonus(d));
				});

				connection.off("WonJackpots");
				connection.on("WonJackpots", (data) => {
					const d = JSON.parse(data);

					dispatch(setWonJackpotBonuses(d));
				});
			});
		},
	);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		SignalRUtils.buildConnections(handleSignalREvents, true);
		return () => {
			SignalRUtils.removeConnections();
		};
	}, [activeJackpotId]);

	return <Outlet context={decodedData} />;
};

export default Wrapper;
