import moment from "moment";

import { CALENDAR_TYPE, CALENDAR_VIEW, WEEKDAYS } from "constants/calendar.constants";

/**
 * Gets year from date.
 *
 * @param {Date|number|string} date Date to get year from.
 */
export const getYear = (date) => {
	if (date instanceof Date) {
		return date.getFullYear();
	}

	if (moment.isMoment(date)) {
		return date.year();
	}

	if (typeof date === "number") {
		return date;
	}

	return Number(date);
};

export const getMonthStart = (date) => {
	return moment(date).startOf("month").toDate();
};

export const getMonthEnd = (date) => {
	return moment(date).endOf("month").toDate();
};

export const getYearStart = (date) => {
	return moment(date).startOf("year").toDate();
};

export const getYearEnd = (date) => {
	return moment(date).endOf("year").toDate();
};

export const getDecadeStart = (date) => {
	return moment(date)
		.subtract(getYear(date) % 10, "years")
		.startOf("year")
		.toDate();
};

export const getDecadeEnd = (date) => {
	return moment(date)
		.subtract(getYear(date) % 10, "years")
		.add(9, "years")
		.startOf("year")
		.toDate();
};

export const getDecadeRange = (date) => {
	return [getDecadeStart(date), getDecadeEnd(date)];
};

export const getCenturyStart = (date) => {
	return moment(date)
		.subtract(getYear(date) % 100, "years")
		.startOf("year")
		.toDate();
};

export const getCenturyEnd = (date) => {
	return moment(date)
		.subtract(getYear(date) % 100, "years")
		.add(99, "years")
		.startOf("year")
		.toDate();
};

export const getCenturyRange = (date) => {
	return [getCenturyStart(date), getCenturyEnd(date)];
};

export const getMonthValidDate = (prevDate, nextDate) => {
	const prevDateClone = moment(prevDate);
	const nextDateClone = moment(nextDate);
	const prevDateDay = prevDateClone.date();
	const nextDateDays = nextDateClone.daysInMonth();

	if (prevDateDay > nextDateDays) {
		return nextDateClone
			.month(nextDateClone.month() + 1)
			.date(0)
			.toDate();
	}

	return nextDateClone.date(prevDateDay).toDate();
};

export const getYearValidDate = (prevDate, nextDate) => {
	return getMonthValidDate(prevDate, moment(nextDate).month(prevDate.month()));
};

/**
 * Returns the beginning of a given range.
 *
 * @param {string} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
export const getBegin = (rangeType, date) => {
	switch (rangeType) {
		case CALENDAR_VIEW.CENTURY:
			return moment(date)
				.subtract(getYear(date) % 100, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.DECADE:
			return moment(date)
				.subtract(getYear(date) % 10, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.YEAR:
			return moment(date).startOf("year").toDate();
		case CALENDAR_VIEW.MONTH:
			return moment(date).toDate();
		default:
			return null;
	}
};

export const getViewPrevContentActiveDate = (rangeType, date) => {
	switch (rangeType) {
		case CALENDAR_VIEW.CENTURY:
			return moment(date)
				.subtract(getYear(date) % 100, "years")
				.subtract(100, "years")
				.toDate();
		case CALENDAR_VIEW.DECADE:
			return moment(date)
				.subtract(getYear(date) % 10, "years")
				.subtract(10, "years")
				.toDate();
		case CALENDAR_VIEW.YEAR:
			return moment(date).subtract(1, "years").toDate();
		case CALENDAR_VIEW.MONTH:
			return moment(date).subtract(1, "months").toDate();
		default:
			return null;
	}
};

export const getViewNextContentActiveDate = (rangeType, date) => {
	switch (rangeType) {
		case CALENDAR_VIEW.CENTURY:
			return moment(date)
				.subtract(getYear(date) % 100, "years")
				.add(100, "years")
				.toDate();
		case CALENDAR_VIEW.DECADE:
			return moment(date)
				.subtract(getYear(date) % 10, "years")
				.add(10, "years")
				.toDate();
		case CALENDAR_VIEW.YEAR:
			return moment(date).add(1, "years").toDate();
		case CALENDAR_VIEW.MONTH:
			return moment(date).add(1, "months").toDate();
		default:
			return null;
	}
};

export const getViewPrevContentMinDate2 = (rangeType, date) => {
	switch (rangeType) {
		case CALENDAR_VIEW.CENTURY:
			return moment(date)
				.subtract(getYear(date) % 100, "years")
				.subtract(100, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.DECADE:
			return moment(date)
				.subtract(getYear(date) % 10, "years")
				.subtract(10, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.YEAR:
			return moment(date).subtract(1, "years").startOf("year").toDate();
		case CALENDAR_VIEW.MONTH:
			return moment(date).subtract(12, "months").startOf("month").toDate();
		default:
			return null;
	}
};

export const getViewNextContentMinDate2 = (rangeType, date) => {
	switch (rangeType) {
		case CALENDAR_VIEW.CENTURY:
			return moment(date)
				.subtract(getYear(date) % 100, "years")
				.add(100, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.DECADE:
			return moment(date)
				.subtract(getYear(date) % 10, "years")
				.add(10, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.YEAR:
			return moment(date).add(1, "years").startOf("year").toDate();
		case CALENDAR_VIEW.MONTH:
			return moment(date).add(12, "months").startOf("month").toDate();
		default:
			return null;
	}
};

/**
 * Returns the end of a given range.
 *
 * @param {string} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
export const getEnd = (rangeType, date) => {
	switch (rangeType) {
		case CALENDAR_VIEW.CENTURY:
			return moment(date)
				.subtract(getYear(date) % 100, "years")
				.add(99, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.DECADE:
			return moment(date)
				.subtract(getYear(date) % 10, "years")
				.add(9, "years")
				.startOf("year")
				.toDate();
		case CALENDAR_VIEW.YEAR:
			return moment(date).add(1, "years").startOf("year").subtract(1, "days").endOf("day");
		case CALENDAR_VIEW.MONTH:
			return moment(date).add(1, "months").startOf("month").subtract(1, "days").endOf("day");
		default:
			return null;
	}
};

/**
 * Creates a range out of two values, ensuring they are in order and covering entire period ranges.
 *
 * @param {string} rangeType Range type (e.g. 'day')
 * @param {Date} date1 First date.
 * @param {Date} date2 Second date.
 */
export const getValueRange = (rangeType, date1, date2) => {
	const rawNextValue = [date1, date2].sort((a, b) => a.getTime() - b.getTime());
	return [getBegin(rangeType, rawNextValue[0]), getEnd(rangeType, rawNextValue[1])];
};

export const getDayOfWeek = (date, calendarType = CALENDAR_TYPE.ISO_8601) => {
	const weekday = moment(date).startOf("month").toDate().getDay();

	switch (calendarType) {
		case CALENDAR_TYPE.ISO_8601:
			// Shifts days of the week so that Monday is 0, Sunday is 6     (weekday + 6) % 7
			return weekday;
		case CALENDAR_TYPE.ARABIC:
			return (weekday + 1) % 7;
		case CALENDAR_TYPE.HEBREW:
		case CALENDAR_TYPE.US:
			return weekday;
		default:
			return weekday; // (weekday + 6) % 7;
	}
};

/**
 * Returns a boolean determining whether a given date is the current day of the week.
 *
 * @param {Date} date Date.
 */
export const isCurrentDayOfWeek = (date) => {
	return moment(date).day() === new Date().getDay();
};

/**
 * Returns a boolean determining whether a given date is a weekend day.
 *
 * @param {Date} date Date.
 * @param {string} [calendarType="ISO 8601"] Calendar type.
 */
export const isWeekend = (date, calendarType = CALENDAR_TYPE.ISO_8601) => {
	const weekday = date.getDay();

	switch (calendarType) {
		case CALENDAR_TYPE.ARABIC:
		case CALENDAR_TYPE.HEBREW:
			return weekday === WEEKDAYS.FRIDAY || weekday === WEEKDAYS.SATURDAY;
		case CALENDAR_TYPE.ISO_8601:
		case CALENDAR_TYPE.US:
			return weekday === WEEKDAYS.SATURDAY || weekday === WEEKDAYS.SUNDAY;
		default:
			return weekday === WEEKDAYS.SATURDAY || weekday === WEEKDAYS.SUNDAY;
	}
};

/**
 * Returns a value no smaller than min and no larger than max.
 *
 * @param {*} value Value to return.
 * @param {*} min Minimum return value.
 * @param {*} max Maximum return value.
 */
export const between = (value, min, max) => {
	if (min && min > value) {
		return min;
	}
	if (max && max < value) {
		return max;
	}
	return value;
};
