import PropTypes from "prop-types";

import TileGroup from "components/ui/datePicker/calendar/tileGroup";
import Month from "components/ui/datePicker/calendar/yearView/month";

import { getMonthValidDate } from "utils/calendar";

const YearView = ({ selectedDate, minDate, maxDate, ...props }) => {
	const modifyToValidDate = (date) => {
		if (minDate.isSame(date, "year") && minDate.isSame(date, "month") && minDate.isAfter(date, "date")) {
			date.setDate(minDate.date());
		} else if (maxDate.isSame(date, "year") && maxDate.isSame(date, "month") && maxDate.isBefore(date, "date")) {
			date.setDate(maxDate.date());
		}

		return date;
	};

	return (
		<div className="vs--ui-calendar-year-view">
			<TileGroup
				{...props}
				className="vs--ui-calendar-year-view-months"
				selectedDate={selectedDate}
				minDate={minDate}
				maxDate={maxDate}
				dateTransform={(monthIndex) => {
					const date = new Date();

					date.setFullYear(selectedDate.year(), monthIndex);
					date.setHours(0, 0, 0, 0);
					return modifyToValidDate(getMonthValidDate(selectedDate, date));
				}}
				dateType="month"
				start={0}
				end={11}
				child={Month}
			/>
		</div>
	);
};

YearView.propTypes = {
	/** Moment object, opened calendar active date */
	selectedDate: PropTypes.object.isRequired,
	/** Moment object, minimum date for opened calendar active date select */
	minDate: PropTypes.object,
	/** Moment object, maximum date for opened calendar active date select */
	maxDate: PropTypes.object
};

export default YearView;
