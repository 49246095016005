import  { Fragment } from "react";

import LiveMonitorComponent from "components/pages/liveMonitor";

const LiveMonitorRoute = () => {
	return (
		<Fragment>
			<LiveMonitorComponent />
		</Fragment>
	);
};

export default LiveMonitorRoute;
