import { GAME_TYPE } from "constants/game.constants";

const TOTAL_ARGUMENT_REGEX = new RegExp(/((([1-5])(\.25|\.5|\.75){0,1})|(0\.5|0\.25|0\.75))$/);
const HANDICAP_ARGUMENT_REGEX = new RegExp(/((([1-5])(\.25|\.5|\.75){0,1})|(0\.5|0\.25|0\.75))$/);
const WINNER_AND_TOTAL_ARGUMENT_REGEX = new RegExp(/((([1-5])(\.5){0,1}))$/);
const WINNER_NUMBER_ARGUMENT_REGEX = new RegExp(/((([1-9]|1[0-1])(\.5){1}))$/);
const TOTAL_3_WAY_ARGUMENT_REGEX = new RegExp(/([0-6])$/);

/** Get rules for hotkeys
	  * @function
	  * @param {number} gameType - the game type
	  * @returns {object}
 */
const getHotKeysRules = (gameType) => {
	/** Football correct score Outcomes */
	const correctScoreOutcomes = {};
	for (let i = 0; i < 7; i++) {
		for (let j = 0; j < 7; j++) {
			if (i + j < 7) {
				correctScoreOutcomes[`Home${i}Away${j}`] = `2${i}${j}`;
			}
		}
	}

	/** Racing Outcomes */
	const getRacesOutcomes = code => {
		const racesOutcomes = {}
		for (let i = 1; i < 13; i++) {
			racesOutcomes[`${i}`] = `${code}${i}`
		}
		return racesOutcomes;
	}

	/** Racing Pair outcomes */
	const pairOutcomes = () => {
		const outcomes = {};
		for (let i = 1; i < 13; i++) {
			for (let j = 1; j < 13; j++) {
				if (i !== j) {
					outcomes[`${i}-${j}`] = [GAME_TYPE.HORSE_RACING, GAME_TYPE.HORSE_STEEPLECHASING].includes(gameType) ? `F${i}${j}` : `1F${i}${j}`
				}
			}
		}
		return outcomes;
	}

	/** Racing Triple outcomes */
	const tripleOutcomes = () => {
		const outcomes = {};
		for (let i = 1; i < 13; i++) {
			for (let j = 1; j < 13; j++) {
				for (let k = 1; k < 13; k++) {
					if (i !== j && i !== k && j !== k) {
						outcomes[`${i}-${j}-${k}`] = [GAME_TYPE.HORSE_RACING, GAME_TYPE.HORSE_STEEPLECHASING].includes(gameType) ? `T${i}${j}${k}` : `1T${i}${j}${k}`
					}
				}
			}
		}
		return outcomes;
	}

	const dataMap = {
		[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: {
			"Winner3Way": {
				regex: new RegExp(/^[0,1,2]$/),
				outcomes: {
					"Home": "1",
					"Draw": "0",
					"Away": "2"
				}
			},
			"DoubleChance": {
				regex: new RegExp(/^(10|12|02)$/),
				outcomes: {
					"HomeOrDraw": "10",
					"HomeOrAway": "12",
					"AwayOrDraw": "02"
				}
			},
			"Total3Way": {
				regex: new RegExp(/^3(\*|-|=)(([0-6]){1})$/),
				outcomes: {
					"Over": "3*",
					"Under": "3-",
					"Equal": "3="
				}
			},
			"WinnerAndTotal": {
				regex: new RegExp(/^[0,1,2]\/(\*|-)((([1-5])(\.5){0,1}))$/),
				outcomes: {
					"HomeAndOver": "1/*",
					"DrawAndOver": "0/*",
					"AwayAndOver": "2/*",
					"HomeAndUnder": "1/-",
					"DrawAndUnder": "0/-",
					"AwayAndUnder": "2/-"
				}
			},
			"HalfMatch": {
				regex: new RegExp(/^1([0-2])([0-2])$/),
				outcomes: {
					"P1P1": "111",
					"P1H": "110",
					"P1P2": "112",
					"HP1": "101",
					"HH": "100",
					"HP2": "102",
					"P2P2": "122",
					"P2H": "120",
					"P2P1": "121"
				}
			},
			"ResultWithoutDraw": {
				regex: new RegExp(/^5([1-2])$/),
				outcomes: {
					"Home": "51",
					"Away": "52"
				}
			},
			"Penalty": {
				regex: new RegExp(/^PN{0,1}$/i),
				outcomes: {
					"Yes": "P",
					"No": "PN"
				}
			},
			"YellowCard": {
				regex: new RegExp(/^YN{0,1}$/i),
				outcomes: {
					"Yes": "Y",
					"No": "YN"
				}
			},
			"RedCard": {
				regex: new RegExp(/^RN{0,1}$/i),
				outcomes: {
					"Yes": "R",
					"No": "RN"
				}
			},
			"Handicap": {
				regex: new RegExp(/^\/([1-2])-{0,1}([0-3])(\.5){0,1}$/),
				outcomes: {
					"Home": "/1",
					"Away": "/2"
				}
			},
			"AsianHandicap": {
				regex: new RegExp(/^\/([1-2])-{0,1}([0-3])(\.25|\.75){0,1}$/),
				outcomes: {
					"Home": "/1",
					"Away": "/2"
				}
			},
			"Total": {
				regex: new RegExp(/^(1|2){0,1}(\*|-)((([1-5])(\.5){0,1})|(0\.5))$/),
				outcomes: {
					"Over": "*",
					"Under": "-",
					"HomeOver": "1*",
					"HomeUnder": "1-",
					"AwayOver": "2*",
					"AwayUnder": "2-"
				}
			},
			"AsianTotal": {
				regex: new RegExp(/^(1|2){0,1}(\*|-)((([1-5])(\.25|\.75){0,1})|(0\.25|0\.75))$/),
				outcomes: {
					"Over": "*",
					"Under": "-",
					"HomeOver": "1*",
					"HomeUnder": "1-",
					"AwayOver": "2*",
					"AwayUnder": "2-"
				}
			},
			"CorrectScore6Goals": {
				regex: new RegExp(/^2([0-6])([0-6])$/),
				outcomes: correctScoreOutcomes
			},
			"WillHomeTeamScore": {
				regex: new RegExp(/^7([1-2])$/),
				outcomes: {
					"Yes": "71",
					"No": "72"
				}
			},
			"WillAwayTeamScore": {
				regex: new RegExp(/^8([1-2])$/),
				outcomes: {
					"Yes": "81",
					"No": "82"
				}
			},
			"WillBothTeamsScore": {
				regex: new RegExp(/^9([1-2])$/),
				outcomes: {
					"Yes": "91",
					"No": "92"
				}
			},
			"Points": {
				regex: new RegExp(/^(O|E)$/i),
				outcomes: {
					"Odd": "O",
					"Even": "E"
				}
			},
			"NextPoint": {
				regex: new RegExp(/^T([0-2])$/i),
				outcomes: {
					"Home": "T1",
					"Neither": "T0",
					"Away": "T2"
				}
			}
		},
		[GAME_TYPE.HORSE_RACING]: {
			"Winner": {
				regex: new RegExp(/^W([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("W")
			},
			"Place": {
				regex: new RegExp(/^P([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("P")
			},
			"Show": {
				regex: new RegExp(/^S([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("S")
			},
			"Pair": {
				regex: new RegExp(/^F([1-9]|1[0-2])([1-9]|1[0-2])$/),
				outcomes: pairOutcomes()
			},
			"Triple": {
				regex: new RegExp(/^T([1-9]|1[0-2])([1-9]|1[0-2])([1-9]|1[0-2])$/),
				outcomes: tripleOutcomes()
			},
			"WinnerNumber": {
				regex: new RegExp(/^(\*|-)((([1-9]|1[0-1])(\.5){1}))$/),
				outcomes: {
					"Over": "*",
					"Under": "-"
				}
			},
			"RaceEvenOdd": {
				regex: new RegExp(/^(O|E)1$/i),
				outcomes: {
					"Odd": "O1",
					"Even": "E1"
				}
			},
		},
		[GAME_TYPE.HORSE_STEEPLECHASING]: {
			"Winner": {
				regex: new RegExp(/^W([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("W")
			},
			"Place": {
				regex: new RegExp(/^P([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("P")
			},
			"Show": {
				regex: new RegExp(/^S([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("S")
			},
			"Pair": {
				regex: new RegExp(/^F([1-9]|1[0-2])([1-9]|1[0-2])$/),
				outcomes: pairOutcomes()
			},
			"Triple": {
				regex: new RegExp(/^T([1-9]|1[0-2])([1-9]|1[0-2])([1-9]|1[0-2])$/),
				outcomes: tripleOutcomes()
			},
			"WinnerNumber": {
				regex: new RegExp(/^(\*|-)((([1-9]|1[0-1])(\.5){1}))$/),
				outcomes: {
					"Over": "*",
					"Under": "-"
				}
			},
			"RaceEvenOdd": {
				regex: new RegExp(/^(O|E)1$/i),
				outcomes: {
					"Odd": "O1",
					"Even": "E1"
				}
			},
		},
		[GAME_TYPE.GREYHOUNDS_RACE]: {
			"Winner": {
				regex: new RegExp(/^V([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("V")
			},
			"Place": {
				regex: new RegExp(/^1P([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("1P")
			},
			"Show": {
				regex: new RegExp(/^1S([1-9]|1[0-2])$/),
				outcomes: getRacesOutcomes("1S")
			},
			"Pair": {
				regex: new RegExp(/^1F([1-9]|1[0-2])([1-9]|1[0-2])$/),
				outcomes: pairOutcomes()
			},
			"Triple": {
				regex: new RegExp(/^1T([1-9]|1[0-2])([1-9]|1[0-2])([1-9]|1[0-2])$/),
				outcomes: tripleOutcomes()
			},
			"WinnerNumber": {
				regex: new RegExp(/^1(\*|-)((([1-9]|1[0-1])(\.5){1}))$/),
				outcomes: {
					"Over": "1*",
					"Under": "1-"
				}
			},
			"RaceEvenOdd": {
				regex: new RegExp(/^1(O|E)$/i),
				outcomes: {
					"Odd": "1O",
					"Even": "1E"
				}
			},
		}
	}

	return dataMap[gameType];
}

/** Checks if exist rule which match to hotkey
	  * @function
	  * @param {string} hotKey
	  * @param {number} gameType - the game type
	  * @returns {object} - matching rule
 */
const matchToRule = (hotKey, gameType) => {
	const rules = getHotKeysRules(gameType);
	return Object.keys(rules).find(rule => rules[rule].regex.test(hotKey));
}

/** Get argument from hotkey
	  * @function
	  * @param {string} hotKey
	  * @param {string} market - market name whcih hotkey match
	  * @returns {string}
 */
const getArgumentFromHotKey = (hotkey, market) => {
	let argument = null;
	switch (market) {
		case "Total":
		case "AsianTotal":
			argument = TOTAL_ARGUMENT_REGEX.exec(hotkey)[0];
			break;
		case "WinnerAndTotal":
			argument = WINNER_AND_TOTAL_ARGUMENT_REGEX.exec(hotkey)[0];
			break;
		case "Total3Way":
			argument = TOTAL_3_WAY_ARGUMENT_REGEX.exec(hotkey)[0];
			break;
		case "Handicap":
		case "AsianHandicap":
			argument = HANDICAP_ARGUMENT_REGEX.exec(hotkey)[0];
			break;
		case "WinnerNumber":
			argument = WINNER_NUMBER_ARGUMENT_REGEX.exec(hotkey)[0];
			break;
		default:
			break;
	}

	return argument ? Number(argument) : null;
}

/** Parse hotkey - get market and amount from it
	  * @function
	  * @param {string} hotKey
	  * @param {number} gameType - the game type
	  * @returns {object}
 */
export const parseHotKey = (hotkey, gameType) => {
	hotkey = hotkey.toUpperCase();
	const result = {
		amount: 0,
		market: null,
		error: false,
		argument: null,
		outcome: null
	}
	if (!hotkey) return result;
	const parts = hotkey.split("+").map(p => p.trim());
	const market = matchToRule(parts[0], gameType);
	if (market) {
		result.market = market;
		result.argument = getArgumentFromHotKey(parts[0], market);
		let outcomeValue = parts[0];
		if (result.argument) {
			outcomeValue = outcomeValue.replace(result.argument, "")
		}
		const marketOutcomes = getHotKeysRules(gameType)[market].outcomes;
		result.outcome = Object.keys(marketOutcomes).find(k => marketOutcomes[k].toLowerCase() === outcomeValue.toLowerCase());

	} else {
		result.error = true;
	}
	if (parts[1]) {
		if (!isNaN(parts[1])) {
			result.amount = Number(parts[1])
		} else {
			result.error = true;
		}
	}
	return result;
}

/** Get matching odd from hotkey
	  * @function
	  * @param {object} parsedHotkey - parsed hotkey
	  * @param {array} markets - array of all markets for current match
	  * @returns {object}
 */
export const getHotKeyMarket = (parsedHotkey, markets) => {
	const market = markets.find(m => m.group === parsedHotkey.market && (parsedHotkey.market !== "Total3Way" || parsedHotkey.argument === Number(m.argument)));

	if (!market) return null;
	let odd = null;

	if (market.odds.length === 1) {
		odd = market.odds[0].find(o => o.outcome === parsedHotkey.outcome && (o.argument === parsedHotkey.argument || parsedHotkey.market === "NextPoint"))
	} else {

		for (let i = 0; i < market.odds.length; i++) {
			let od = market.odds[i];
			if (["Total", "AsianTotal"].includes(parsedHotkey.market)) {
				let outcome = parsedHotkey.outcome.replace("Home", "").replace("Away", "");
				if (parsedHotkey.outcome.includes("Home")) {
					odd = od.find(o => o.outcome === outcome && o.argument === parsedHotkey.argument && o.name.includes("%[Home]%"));
				} else if (parsedHotkey.outcome.includes("Away")) {
					odd = od.find(o => o.outcome === outcome && o.argument === parsedHotkey.argument && o.name.includes("%[Away]%"));
				} else {
					odd = od.find(o => o.outcome === outcome && o.argument === parsedHotkey.argument && !o.name.includes("%[Home]%") && !o.name.includes("%[Away]%"));
				}
			} else {
				odd = od.find(o => o.outcome === parsedHotkey.outcome && o.argument === parsedHotkey.argument);
			}
			if (!odd) {
				odd = null;
			} else {
				break;
			}
		}
	}
	if (!odd) return null;

	return {
		oddId: odd.id,
		factor: odd.factor,
		showName: odd.showName,
		group: market.group,
		argument: market.argument
	}
}
