import  { useState } from "react";
import PropTypes from "prop-types";
import Input from "./index";

const InputPassword = ({ showVisibilityToggle = true, ...props }) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const togglePasswordVisibility = (e) => {
		e.stopPropagation();

		setIsPasswordVisible(!isPasswordVisible);
	};

	const visibilityToggleRenderer = showVisibilityToggle ? (
		<div onClick={togglePasswordVisibility} className="vs--ui-input-password-icon">
			{isPasswordVisible ? <i className="ic_showText" /> : <i className="ic_hideText" />}
		</div>
	) : null;

	return <Input suffix={visibilityToggleRenderer} {...props} type={isPasswordVisible ? "text" : "password"} />;
};

/** InputPassword propTypes
 * PropTypes
 */
InputPassword.propTypes = {
	/** Show or hide password visibility toggle icon */
	showVisibilityToggle: PropTypes.bool
};

export default InputPassword;
