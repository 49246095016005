import "./polyfill";

import ReactDOM from "react-dom/client";

import "./assets/scss/style.scss";

import App from "./App";

if (import.meta.env.MODE === "production" && location.protocol !== "https:") {
	location.replace(`https:${location.href.substring(location.protocol.length)}`);
} else {
	const root = ReactDOM.createRoot(document.getElementById("root"));
	root.render(/* <React.StrictMode> */ <App /> /* </React.StrictMode> */);
}
