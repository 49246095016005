import { useEffect, useRef } from "react";

const useFirstRender = ({ callBackFn }) => {
	const isFirstRenderRef = useRef(true);

	useEffect(() => {
		if (isFirstRenderRef.current) {
			callBackFn();
			isFirstRenderRef.current = false;
		}
	});
};

export default useFirstRender;
