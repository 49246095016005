import  { cloneElement, useRef, useState, useCallback, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

const classNameFullyInner = "vs-scrollable-navbar-inner-fully";

const ScrollableNavbar = ({ wrapperClassName = null, containerClassName = null, innerClassName = null, keyFieldName = null, elements = [], renderFunction = () => <Fragment />, isActiveChecking = () => false, onStepsChange, onContentFullyFit, startFromFirstElement }) => {
	const rowElem = useRef();
	const rowElemInner = useRef();
	const elementsRef = useRef();
	elementsRef.current = elements;
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);
	const [translate, setTranslate] = useState(0);

	const showHideArrows = useCallback((translate) => {
		if (rowElem.current && rowElemInner.current && rowElem.current.offsetWidth - translate < rowElemInner.current.scrollWidth) {
			setShowRightArrow(true);
		} else {
			setShowRightArrow(false);
		}

		if (translate < 0) {
			setShowLeftArrow(true);
		} else {
			setShowLeftArrow(false);
		}
	}, []);

	const slideLeft = () => {
		let step = rowElemInner.current.firstChild ? rowElemInner.current.firstChild.offsetWidth + 20 : 0;
		setTranslate((translate) => Math.min(translate + step, 0));
	};

	const slideRight = () => {
		setTranslate((translate) => {
			let step = rowElemInner.current && rowElemInner.current.firstChild ? rowElemInner.current.firstChild.offsetWidth + 20 : 0;
			if (rowElem.current && rowElemInner.current && rowElem.current.offsetWidth - translate + step > rowElemInner.current.scrollWidth) {
				step = rowElemInner.current.scrollWidth - rowElem.current.offsetWidth + translate;
			}

			return translate - step;
		});
	};

	useEffect(() => {
		const fn = () => setTranslate(0);
		window.addEventListener("resize", fn);
		return () => {
			window.removeEventListener("resize", fn);
		};
	}, [setTranslate]);

	useEffect(() => {
		const onWindowResize = () => {
			if (rowElemInner.current.classList.contains(classNameFullyInner)) {
				rowElemInner.current.classList.remove(classNameFullyInner);
			}
			const containerWidth = parseFloat(getComputedStyle(rowElem.current).width) || 0;
			const navbarWidth = parseFloat(getComputedStyle(rowElemInner.current).width) || 0;
			if (containerWidth >= navbarWidth) {
				setTranslate(0);
				rowElemInner.current.classList.add(classNameFullyInner);
				typeof onContentFullyFit === "function" && onContentFullyFit(true);
			} else {
				typeof onContentFullyFit === "function" && onContentFullyFit(false);
			}
			showHideArrows(translate);
		};
		onWindowResize();
		window.addEventListener("resize", onWindowResize);
		return () => {
			window.removeEventListener("resize", onWindowResize);
		};
	}, [translate, showHideArrows, setTranslate, onContentFullyFit]);

	useEffect(() => {
		const onScrollAreaKeyPress = (event) => {
			if (event.which === 37) {
				slideLeft();
			} else if (event.which === 39) {
				slideRight();
			}
		};
		if (rowElem.current) {
			rowElem.current.removeEventListener("keydown", onScrollAreaKeyPress);
			rowElem.current.addEventListener("keydown", onScrollAreaKeyPress);
		}
		return () => {
			rowElem.current && rowElem.current.removeEventListener("keydown", onScrollAreaKeyPress);
		};
	}, [rowElem.current, slideLeft, slideRight]);

	useEffect(() => {
		setTimeout(() => {
			if (startFromFirstElement && !translate) {
				setTranslate(0);
			}
		}, 150);
	}, []);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (rowElemInner.current && rowElemInner.current.children.length) {
				if (rowElemInner.current.classList.contains(classNameFullyInner)) {
					rowElemInner.current.classList.remove(classNameFullyInner);
				}
				let valueForTranslate = 0;
				const getTotalWidth = (computedStyles) => {
					const marginLeft = parseFloat(computedStyles.marginLeft) || 0;
					const width = parseFloat(computedStyles.width) || 0;
					const marginRight = parseFloat(computedStyles.marginRight) || 0;
					return marginLeft + width + marginRight;
				};
				for (let i = 0; i < rowElemInner.current.children.length; i++) {
					const elem = rowElemInner.current.children[i];
					if (isActiveChecking(elementsRef.current[elem.dataset.index])) {
						break;
					}
					const computedStyle = getComputedStyle(elem);
					valueForTranslate += getTotalWidth(computedStyle);
				}

				const containerWidth = parseFloat(getComputedStyle(rowElem.current).width);
				const navbarWidth = parseFloat(getComputedStyle(rowElemInner.current).width);

				if (valueForTranslate <= Math.ceil(containerWidth / 2)) {
					valueForTranslate = 0;
				} else {
					valueForTranslate = Math.min(valueForTranslate, navbarWidth - containerWidth);
				}
				let step = Math.round(Math.abs(valueForTranslate));

				if (navbarWidth <= containerWidth) {
					typeof onContentFullyFit === "function" && onContentFullyFit(true);
					step = 0;
					rowElemInner.current.classList.add(classNameFullyInner);
				} else {
					typeof onContentFullyFit === "function" && onContentFullyFit(false);
					rowElemInner.current.classList.remove(classNameFullyInner);
				}

				const directionFactor = -1;

				setTranslate(directionFactor * step);
			}
		}, 100);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [rowElemInner.current, rowElemInner.current?.children, rowElemInner.current?.children?.length, setTranslate, isActiveChecking, onContentFullyFit, startFromFirstElement]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (typeof onStepsChange === "function") {
				onStepsChange(translate);
			}
		}, 0);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [translate, onStepsChange]);

	return (
		<div className={`vs-scrollable-navbar${wrapperClassName ? " " + wrapperClassName : ""}`} ref={rowElem}>
			<div className={`vs-scrollable-navbar-container${containerClassName ? " " + containerClassName : ""}`}>
				<div className={`vs-scrollable-navbar-inner${innerClassName ? " " + innerClassName : ""}`} ref={rowElemInner} style={{ transform: "translateX(" + translate + "px)" }}>
					{elements.map((element, index, array) => {
						return cloneElement(renderFunction(element, index, array), {
							key: keyFieldName === null ? index : element[keyFieldName],
							"data-index": index
						});
					})}
				</div>
			</div>
			<div className={"vs-scrollable-navbar-arrow vs-scrollable-navbar-arrow-left " + (!showLeftArrow ? "vs-scrollable-navbar-arrow-disabled" : "")} onClick={() => slideLeft()}>
				<div className="vs-scrollable-navbar-arrow-inner vs--flex vs--flex-row vs--justify-center vs--align-center">
					<i className="ic_left vs--font-bigest" />
				</div>
			</div>
			<div className={"vs-scrollable-navbar-arrow vs-scrollable-navbar-arrow-right " + (!showRightArrow ? "vs-scrollable-navbar-arrow-disabled" : "")} onClick={() => slideRight()}>
				<div className="vs-scrollable-navbar-arrow-inner vs--flex vs--flex-row vs--justify-center vs--align-center">
					<i className="ic_right vs--font-bigest" />
				</div>
			</div>
		</div>
	);
};

ScrollableNavbar.propTypes = {
	wrapperClassName: PropTypes.string,
	containerClassName: PropTypes.string,
	innerClassName: PropTypes.string,
	elements: PropTypes.arrayOf(PropTypes.any).isRequired,
	keyFieldName: PropTypes.string.isRequired,
	renderFunction: PropTypes.func.isRequired,
	isActiveChecking: PropTypes.func.isRequired,
	onStepsChange: PropTypes.func,
	onContentFullyFit: PropTypes.func,
	startFromFirstElement: PropTypes.bool
};

export default ScrollableNavbar;
