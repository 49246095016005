import  { Fragment } from "react";

import MainComponent from "components/pages/main";

const MainRoute = () => {
	return (
		<Fragment>
			<MainComponent />
		</Fragment>
	);
};

export default MainRoute;
