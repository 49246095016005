import { buildPathToStaticFolderOfCDN } from "utils/common";

// --------------------------------------------------------------------------------------------------------------------------------
// Dark templates images
const TEMPLATE1 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-1.png");
const TEMPLATE8 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-8.png");
const TEMPLATE9 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-9.png");
const TEMPLATE10 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-10.png");
const TEMPLATE11 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-11.png");
const TEMPLATE12 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-12.png");
const TEMPLATE13 = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/template-13.png");

// Light templates images
const PURPLE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-purple.png");
const BONDI_BLUE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-bondi-blue.png");
const BRITISH_GREEN_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-british-green.png");
const ORANGE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-orange.png");
const BLUE_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-blue.png");
const GREEN_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-green.png");
const RED_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-red.png");
const MARDI_GRAS_TEMPLATE_IMAGE = buildPathToStaticFolderOfCDN("cashier-images/cashier-templates/light-template-mardi-gras.png");


export const CASHIER_TEMPLATES = [
	{
		key: 1,
		thumbnail: TEMPLATE1,
		name: "Yellow Jasper",
		variation: "dark",
		type: 1
	},
	{
		key: 128,
		thumbnail: TEMPLATE8,
		name: "Sapphire",
		variation: "dark",
		type: 128
	},
	{
		key: 256,
		thumbnail: TEMPLATE9,
		name: "Cats Eye (Tanzanite)",
		variation: "dark",
		type: 256
	},
	{
		key: 512,
		thumbnail: TEMPLATE10,
		name: "Aquamarine",
		variation: "dark",
		type: 512
	},
	{
		key: 1024,
		thumbnail: TEMPLATE11,
		name: "Carnelian",
		variation: "dark",
		type: 1024
	},
	{
		key: 2048,
		thumbnail: TEMPLATE12,
		name: "Turquoise",
		variation: "dark",
		type: 2048
	},
	{
		key: 4096,
		thumbnail: TEMPLATE13,
		name: "Lilac",
		variation: "dark",
		type: 4096
	},
	{
		key: 2,
		thumbnail: PURPLE_TEMPLATE_IMAGE,
		name: "Purple",
		variation: "light",
		type: 2
	},
	{
		key: 4,
		thumbnail: BONDI_BLUE_TEMPLATE_IMAGE,
		name: "Bondi Blue",
		variation: "light",
		type: 4
	},
	{
		key: 8,
		thumbnail: BRITISH_GREEN_TEMPLATE_IMAGE,
		name: "British Green",
		variation: "light",
		type: 8
	},
	{
		key: 16,
		thumbnail: ORANGE_TEMPLATE_IMAGE,
		name: "Orange",
		variation: "light",
		type: 16
	},
	{
		key: 32,
		thumbnail: BLUE_TEMPLATE_IMAGE,
		name: "Blue",
		variation: "light",
		type: 32
	},
	{
		key: 64,
		thumbnail: GREEN_TEMPLATE_IMAGE,
		name: "Green",
		variation: "light",
		type: 64
	},
	{
		key: 8192,
		thumbnail: RED_TEMPLATE_IMAGE,
		name: "Red",
		variation: "light",
		type: 8192
	},
	{
		key: 16384,
		thumbnail: MARDI_GRAS_TEMPLATE_IMAGE,
		name: "Mardi Gras",
		variation: "light",
		type: 16384
	}
];
