import  { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import FiltersPrint from "./print";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import { yesterday, weekago, monthAgo } from "utils/dateTime";

import { setResultsFilters, getResults, resetResultsFilters } from "store/actions/results/results.actions";
import { GAME_TYPE_TEXT_KEYS } from "constants/game.constants";

import Select from "components/ui/select";
import Input from "components/ui/input";
import DatePicker from "components/ui/datePicker";

import resultsFilterType from "types/resultsFilter.type";
import sessionType from "types/session.type";

/** Filters section component in history page */
const Filters = ({ setResultsFilters, resetResultsFilters, getResults, filters, session }) => {
	const { t } = useTranslation();

	const [enableClear, setEnableClear] = useState(false);

	/** Set default filters */
	useEffect(() => {
		return () => {
			resetResultsFilters();
		};
	}, []);

	/** Update datepicker values on, period dropdown change */
	useEffect(() => {
		const FORMAT = DATE_TIME_FORMAT;
		const TIME_FROM = moment(filters.period !== "24_hours" ? "00:00" : new Date(), TIME_FORMAT);
		const TIME_TO = moment(new Date(), TIME_FORMAT);

		let from_d, to_d;

		to_d = moment(new Date(), FORMAT);

		switch (filters.period) {
			case "24_hours":
				from_d = yesterday();
				break;
			case "1_week":
				from_d = weekago(1);
				break;
			case "2_weeks":
				from_d = weekago(2);
				break;
			case "1_month":
				from_d = monthAgo(1);
				break;
			case "3_months":
				from_d = monthAgo(3);
				break;
		}
		if (from_d) from_d.set({ hour: TIME_FROM.get("hour"), minute: TIME_FROM.get("minute") });
		if (to_d) to_d.set({ hour: TIME_TO.get("hour"), minute: TIME_TO.get("minute") });
		setResultsFilters({
			from: from_d.toDate(),
			to: to_d.toDate()
		});
	}, [filters.period]);

	/** Function which fires on "show" button click
	 * @function
	 * @memberOf Filters
	 */
	const handleShow = () => {
		setResultsFilters({ page: 1 });
		getResults();
	};

	/** Function which fires on "clear" button click
	 * @function
	 * @memberOf Filters
	 */
	const handleClear = () => {
		if (!enableClear) return;
		setEnableClear(false);
		resetResultsFilters();
		getResults();
	};

	/** Function , handler for inputs change
	 * @function
	 * @param {string} name - field name
	 * @param {string} value - field value
	 * @memberOf Filters
	 */
	const handleInputChange = (name, value) => {
		if (!/^[0-9]*$/.test(value) && value !== "") return;
		setResultsFilters({ [name]: value });
		setEnableClear(true);
	};

	/** Function , handler for select change
	 * @function
	 * @param {string} name - field name
	 * @param {string} value - field value
	 * @memberOf Filters
	 */
	const handleSelectChange = (name, value) => {
		setResultsFilters({ [name]: value });
		setEnableClear(true);
	};

	const games = session?.games ?? [];

	return (
		<Fragment>
			<div className="vs--flex vs--flex-row vs--filters vs--align-end vs--justify-between vs--pt-24 vs--pb-24 vs--pl-18 vs--pr-24" style={{ width: "100%" }}>
				<div className="vs--flex-col vs--mr-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.periodOfTime")}</span>
					<Select value={filters.period} defaultValue="24_hours" suffixIcon={<i className="ic_down"></i>} onChange={(e) => handleSelectChange("period", e)} getPopupContainer={(trigger) => trigger.parentNode}>
						<Select.Option value="24_hours">{t("common.24Hours")}</Select.Option>
						<Select.Option value="1_week">{t("common.1Week")}</Select.Option>
						<Select.Option value="2_weeks">{t("common.2Weeks")}</Select.Option>
						<Select.Option value="1_month">{t("common.1Month")}</Select.Option>
						<Select.Option value="3_months">{t("common.3Months")}</Select.Option>
					</Select>
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item" id="vs--filters-item-from">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.from")}</span>
					<DatePicker value={moment(filters.from)} onChange={(value) => handleSelectChange("from", value.toDate())} maxDate={moment(filters.to)} suffixIcon={<i className="ic_down"></i>} />
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item" id="vs--filters-item-to">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.to")}</span>
					<DatePicker value={moment(filters.to)} onChange={(value) => handleSelectChange("to", value.toDate())} minDate={moment(filters.from)} maxDate={moment()} suffixIcon={<i className="ic_down"></i>} />
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("cashier.gameType")}</span>
					<Select suffixIcon={<i className="ic_down"></i>} onChange={(e) => handleSelectChange("type", e === "all" ? null : e)} value={filters.type || "all"}>
						{games.length > 0 && <Select.Option value="all">{t("cashier.all")}</Select.Option>}
						{games.map((game) => {
							return (
								<Select.Option key={game.id} value={game.type}>
									{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}
								</Select.Option>
							);
						})}
					</Select>
				</div>

				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-item">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8">{t("common.eventId")}</span>
					<Input onChange={(value) => handleInputChange("eventId", value)} value={filters.eventId} maxLength={18} placeholder={t("common.eventId")} />
				</div>
				<div className="vs--flex-col vs--mr-8 vs--ml-8 vs--filters-button">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8"> </span>
					<button type="button" className={"vs--button vs--button-secondary vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-smallest vs--font-medium" + (!enableClear ? " vs--button-disabled" : "")} onClick={handleClear}>
						{t("cashier.clear")}
					</button>
				</div>
				<div className="vs--flex-equal vs--mr-8 vs--ml-8 vs--filters-button">
					<span className="vs--title vs--font-regular vs--font-normal vs--filters-title vs--mb-8"> </span>
					<button type="button" className="vs--button vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-smallest vs--font-medium" onClick={handleShow}>
						{t("common.show")}
					</button>
				</div>
				<FiltersPrint className="vs--flex-equal vs--mr-8 vs--filters-item" style={{ marginLeft: "auto" }} />
			</div>
		</Fragment>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to update results filters */
	setResultsFilters: PropTypes.func,
	/** Redux action to reset results filters */
	resetResultsFilters: PropTypes.func,
	/** Redux action to get results */
	getResults: PropTypes.func,
	/** Redux state property, current filters of results page */
	filters: resultsFilterType,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		filters: state.results.filters,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	setResultsFilters: (filters) => {
		dispatch(setResultsFilters(filters));
	},
	resetResultsFilters: () => {
		dispatch(resetResultsFilters());
	},
	getResults: () => {
		dispatch(getResults());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
