import  { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Collapse from "components/ui/collapse";
import Loader from "components/ui/loader";
import NoData from "components/ui/noData";
import TicketDetails from "./details";
import Filters from "./filters";
import Pagination from "components/ui/pagination";
import ReprintButton from "./reprintButton";

import { setSettledFilters, getSettledTickets } from "store/actions/tickets/settled.actions";
import { setTicketConfirmation } from "store/actions/tickets/confirmation.actions";

import useIntegrationType from "hooks/useIntegrationType";

import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";
import { TICKETS_LIST_TYPE, TICKET_CONFIRMATION_MODAL_TYPE, BONUS_TYPE, PROJECT_TYPE } from "constants/common.constants";

import historyType from "types/history.type";
import sessionType from "types/session.type";
import settledTicketsFilterType from "types/settledTicketsFilter.type";

import { getBetStateText, isBetslipCancelable } from "utils/bets";
import { numberWithSpaces, makeCurrencyText, mergeClassNames } from "utils/common";

/** Tickets List Component */
const TicketsList = ({ 
	tableClassNmae,
	type, 
	items, 
	isLoading, 
	total, 
	setSettledFilters, 
	getSettledTickets, 
	filters, 
	session, 
	setTicketConfirmation, 
	onCancel 
}) => {
	const { t } = useTranslation();
	const integrationType = useIntegrationType();

	const [opened, setOpened] = useState([]);

	useEffect(() => {
		if (items[0] && type === TICKETS_LIST_TYPE.SINGLE) {
			setOpened([items[0].id]);
		}
	}, [items]);

	/** Function which detects if there is a bet with winn
	 * @function
	 * @returns {boolean}
	 * @memberOf TicketsList
	 */
	const hasWinItem = () => type === TICKETS_LIST_TYPE.SETTLED || items.find((i) => [BET_STATE.RETURN, BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST].includes(i.state));

	/** Function, fires on ticket cancel button click
	 * @function
	 * @param {number} ticketId - ticket Id to cancel
	 * @memberOf TicketsList
	 */
	const handleTicketCancel = (ticketId) => {
		setTicketConfirmation({ type: TICKET_CONFIRMATION_MODAL_TYPE.CANCEL, ticketId });
		onCancel();
	};

	/** Function, fires on ticket payout button click
	 * @function
	 * @param {number} ticketId - ticket Id to payout
	 * @memberOf TicketsList
	 */
	const handleTicketPayout = (ticketId, amount) => {
		setTicketConfirmation({ type: TICKET_CONFIRMATION_MODAL_TYPE.PAYOUT, ticketId, amount });
		onCancel();
	};

	/** Function which Renders row
	 * @function
	 * @param {object} item - the item to show in row
	 * @param {number} index - the row index
	 * @returns {JSX}
	 * @memberOf TicketsList
	 */
	const renderRow = (item, index) => {
		return (
			<div className={"vs--modal-table-row vs--flex vs--flex-row vs--align-center vs--justify-between vs--font-regular vs--pr-16 vs--pl-2" + (index !== 0 ? " vs--mt-2" : "")}>
				{type !== TICKETS_LIST_TYPE.SINGLE && (
					<div data-type="expand">
						<i className="ic_down vs--font-bigest vs--title" />
					</div>
				)}
				<div data-type="date" className={`${type === TICKETS_LIST_TYPE.SINGLE ? "vs--ml-16" : ""}`}>
					<span title={moment.utc(item.betTime).local().format(DATE_TIME_FORMAT)}>{moment.utc(item.betTime).local().format(DATE_TIME_FORMAT)}</span>
				</div>
				<div data-type="status" className="vs--modal-table-status-col">
					<div className="vs--ticket-bet-status" data-state={item.state}>
						<span className={"vs--font-regular vs--tickets-status vs--tickets-status-" + item.state} title={getBetStateText(item.state)}>
							{getBetStateText(item.state)}
						</span>
					</div>
				</div>
				{type !== TICKETS_LIST_TYPE.PENDING && type !== TICKETS_LIST_TYPE.SETTLED && (
					<div data-type="id">
						<span title={item.id}>{item.id}</span>
					</div>
				)}
				<div data-type="betType">
					<span title={`${t(`bet.${item.type === BETSLIP_MODES.SINGLE ? "single" : "multi"}`)}${item.betCount > 1 ? "(" + item.betCount + ")" : ""}`}>{`${t(`bet.${item.type === BETSLIP_MODES.SINGLE ? "single" : "multi"}`)}${item.betCount > 1 ? "(" + item.betCount + ")" : ""}`}</span>
				</div>
				<div data-type="stake">
					<span title={makeCurrencyText(item.totalAmount, session.currency)}>{makeCurrencyText(item.totalAmount, session.currency)} </span>
				</div>
				<div data-type="odds">
					<span title={item.type === BETSLIP_MODES.MULTI ? numberWithSpaces(item.totalFactor.toFixed(2)) : "-"}>{item.type === BETSLIP_MODES.MULTI ? numberWithSpaces(item.totalFactor.toFixed(2)) : "-"}</span>
				</div>
				<div data-type="possibleWin">
					<span title={makeCurrencyText(item.bonusId && item.bonusType === BONUS_TYPE.DOUBLEDOOBLE ? item.possibleWin * 2 : item.possibleWin, session.currency)}>
						{makeCurrencyText(item.bonusId && item.bonusType === BONUS_TYPE.DOUBLEDOOBLE ? item.possibleWin * 2 : item.possibleWin, session.currency)}
						{item.bonusId ? <i className="ic_gift vs--font-small" /> : null}
					</span>
				</div>
				{hasWinItem() ? (
					<div data-type="winning">
						<span title={item.winning ? makeCurrencyText(item.winning, session.currency) : "-"}>
							{item.winning ? makeCurrencyText(item.bonusId ? item.redeem : item.winning, session.currency) : "-"}
							{item.winning && type === TICKETS_LIST_TYPE.SETTLED && item.bonusId ? <i className="ic_gift vs--font-small" /> : null}
						</span>
					</div>
				) : null}
				<div data-type="cashier">
					<span title={item.userName}>{item.userName}</span>
				</div>
				{type === TICKETS_LIST_TYPE.SETTLED && (
					<Fragment>
						<div data-type="paidoutAt">
							<span title={item.paidoutTime ? moment.utc(item.paidoutTime).local().format(DATE_TIME_FORMAT) : "-"}>{item.paidoutTime ? moment.utc(item.paidoutTime).local().format(DATE_TIME_FORMAT) : "-"}</span>
						</div>
						<div data-type="paidoutBy">
							<span title={item.paidoutBy ? item.paidoutBy : "-"}>{item.paidoutBy ? item.paidoutBy : "-"}</span>
						</div>
					</Fragment>
				)}
				<div data-type="actions" className="vs--flex vs--align-center vs--justify-end">
					{type === TICKETS_LIST_TYPE.SINGLE && isBetslipCancelable(item) ? (
						<button type="button" className="vs--ticket-bet-button vs--ticket-bet-action-button vs--button vs--modal-table-button vs--font-smallest vs--font-medium" onClick={() => handleTicketCancel(item.id)} title={t("common.cancel")}>
							<span>{t("cashier.cancelBetslip")}</span>
						</button>
					) : null}
					{item.allowPayout && type === TICKETS_LIST_TYPE.SINGLE && integrationType !== PROJECT_TYPE.IFRAME ? (
						<button type="button" className="vs--ticket-bet-button vs--ticket-bet-action-button vs--ticket-bet-action-button-payout vs--button vs--modal-table-button vs--font-smallest vs--font-medium" onClick={() => handleTicketPayout(item.id, makeCurrencyText(item.bonusId ? item.redeem : item.winning, session.currency))} title={t("cashier.payout")}>
							<span>{t("cashier.payout")}</span>
						</button>
					) : null}
					{type !== TICKETS_LIST_TYPE.SINGLE && <ReprintButton listType={type} ticket={item} />}
				</div>
			</div>
		);
	};

	/** Function which will fire on pagination change
	 * @function
	 * @param { object } e - selected filters
	 * @memberOf TicketsList
	 */
	const handlePaginationChange = (e) => {
		setSettledFilters(e);
		getSettledTickets();
	};

	return (
		<Fragment>
			{type === TICKETS_LIST_TYPE.SETTLED && <Filters />}
			{!isLoading ? (
				items.length > 0 ? (
					<div className="vs--modal-wrapper vs--flex-equal">
						<div
							className={mergeClassNames(
								`${type === TICKETS_LIST_TYPE.SETTLED ? "vs--modal-table-settled" : "vs--modal-table-pending"}`,
								"vs--flex vs--flex-col vs--tickets-table",
								tableClassNmae
							)}
							data-table={type === TICKETS_LIST_TYPE.PENDING || (type === TICKETS_LIST_TYPE.SINGLE && items[0]?.state === BET_STATE.PENDING) ? "pending" : "settled"}
						>
							<div className="vs--modal-table-head vs--flex vs--flex-row vs--align-center vs--justify-between vs--font-medium vs--font-small">
								{type !== TICKETS_LIST_TYPE.SINGLE && <div data-type="expand"></div>}
								<div data-type="date">
									<span title={t("common.date")}>{t("common.date")}</span>
								</div>
								<div data-type="status" className="vs--modal-table-status-col">
									<span title={t("bet.status")}>{t("bet.status")}</span>
								</div>

								{type !== TICKETS_LIST_TYPE.PENDING && type !== TICKETS_LIST_TYPE.SETTLED && (
									<div data-type="id">
										<span title={t("common.id")}>{t("common.id")}</span>
									</div>
								)}
								<div data-type="betType">
									<span title={t("bet.betType")}>{t("bet.betType")}</span>
								</div>
								<div data-type="stake">
									<span title={t("bet.totalStake")}>{t("bet.totalStake")}</span>
								</div>
								<div data-type="odds">
									<span title={t("bet.odds")}>{t("bet.odds")}</span>
								</div>
								<div data-type="possibleWin">
									<span title={t("bet.possibleWin")}>{t("bet.possibleWin")}</span>
								</div>
								{hasWinItem() ? (
									<div data-type="winning">
										<span title={t("bet.winning")}>{t("bet.winning")}</span>
									</div>
								) : null}
								<div data-type="cashier">
									<span title={t("cashier.cashier")}>{t("cashier.cashier")}</span>
								</div>
								{type === TICKETS_LIST_TYPE.SETTLED && (
									<Fragment>
										<div data-type="paidoutAt">
											<span title={t("cashier.paidoutAt")}>{t("cashier.paidoutAt")}</span>
										</div>
										<div data-type="paidoutBy">
											<span title={t("cashier.paidoutBy")}>{t("cashier.paidoutBy")}</span>
										</div>
									</Fragment>
								)}
								<div data-type="actions">{type !== TICKETS_LIST_TYPE.SINGLE && <span title={t("cashier.reprintRequest")}>{t("cashier.reprintRequest")}</span>}</div>
							</div>
							<div className="vs--modal-table-body vs--flex-equal">
								<Collapse activeRowIds={opened} onChange={(e) => (type !== TICKETS_LIST_TYPE.SINGLE ? setOpened(e) : false)} className={`vs--ui-collapse ${type === TICKETS_LIST_TYPE.SINGLE ? "vs--ui-collapse-cursor-default" : ""}`}>
									{items.map((item, index) => {
										return (
											<Collapse.Panel header={renderRow(item, index)} key={item.id} rowId={item.id} showArrow={false}>
												<TicketDetails type={type} id={item.id} items={items} isOpened={opened.indexOf(item.id) > -1} onCancel={onCancel} />
											</Collapse.Panel>
										);
									})}
								</Collapse>
							</div>
						</div>
					</div>
				) : (
					<NoData />
				)
			) : (
				<Loader style={{ height: "100%" }} />
			)}
			{type === TICKETS_LIST_TYPE.SETTLED && total > 0 ? (
				<div className="vs--modal-pagination-bottom">
					<Pagination
						total={total}
						onChange={handlePaginationChange}
						page={filters.page}
						renderTotal={(total, pageSize) => {
							return <span>{`${pageSize[0]} - ${pageSize[1]} items of ${total}`}</span>;
						}}
						siblingCount={2}
					/>
				</div>
			) : null}
		</Fragment>
	);
};

/** TicketsList propTypes
 * PropTypes
 */
TicketsList.propTypes = {
	/** Tickets list type (Pending, Single) */
	type: PropTypes.oneOf(Object.values(TICKETS_LIST_TYPE)),
	/** List of tickets to show in list */
	items: PropTypes.arrayOf(historyType),
	/** Is true when loading items */
	isLoading: PropTypes.bool,
	/** Total count of items */
	total: PropTypes.number,
	/** Redux state property, current filters of bet history page */
	filters: settledTicketsFilterType,
	/** Redux action to update settled tickets filters */
	setSettledFilters: PropTypes.func,
	/** Redux action to get settled tickets */
	getSettledTickets: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux action ,to open ticket cancel confirmation popup */
	setTicketConfirmation: PropTypes.func,
	/** Function to close ticket list popup */
	onCancel: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		filters: state.tickets.filters,
		total: state.tickets.settledTotal
	};
};

const mapDispatchToProps = (dispatch) => ({
	setTicketConfirmation: (params) => {
		dispatch(setTicketConfirmation({ ...params, betId: null }));
	},
	setSettledFilters: (filters) => {
		dispatch(setSettledFilters(filters));
	},
	getSettledTickets: () => {
		dispatch(getSettledTickets());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsList);
