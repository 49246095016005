import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import { getLiveAndUpcomings, removeFromLiveAndUpcomings, updateFromLiveAndUpcomings, addLiveAndUpcomings, getLastResults, addLastResult, setCurrentGame } from "store/actions/game/game.actions";
import { getActiveJackpot, updateJackpotBonus, setWonJackpotBonuses } from 'store/actions/bonuses/jackpot.actions';
import { getGameRTPs } from "store/actions/auth/auth.actions";

import SignalRUtils from "utils/signalR";

import { GAME_STATUSES, GAME_TYPE } from "constants/game.constants";

/* Keno Page Wrapper Container Component - Wrapper */
const KenoWrapper = ({
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	getLastResults,
	addLastResult,
	setCurrentGame,
	getGameRTPs,
	decodedData,
	getActiveJackpot,
	updateJackpotBonus,
	setWonJackpotBonuses
}) => {
	const game = decodedData?.kenoGame ?? null;
	const activeJackpotId = decodedData?.activeJackpotId ?? null;

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Wrapper
	 */
	const handleSignalREvents = (connectionArg) => {
		SignalRUtils.getConnections().forEach((con, index) => {
			const connection = con.getConnection()
			if (connection !== connectionArg.connection) {
				return;
			}
			if ((index === 0 || index === 2) && connection.state === "Connected") {
				if (game) {
					connection.invoke("Subscribe", `Events_${game.type}_${game.id}`);
				}
			}

			connection.off("Events");

			connection.on("Events", (data) => {
				const parsedData = JSON.parse(data);

				if (parsedData.gameType !== GAME_TYPE.KENO) {
					return;
				}

				if (parsedData.status === GAME_STATUSES.FINISHED) {
					removeFromLiveAndUpcomings(parsedData.id);

					if (parsedData.gameType === GAME_TYPE.KENO) {
						addLastResult(parsedData);
					}
				} else if (parsedData.status === GAME_STATUSES.STARTED || parsedData.status === GAME_STATUSES.CLOSE_FOR_BETTING || parsedData.status === GAME_STATUSES.PREAMBLE_STARTED) {
					updateFromLiveAndUpcomings(parsedData);
				} else if (parsedData.status === GAME_STATUSES.NEW) {
					addLiveAndUpcomings(parsedData);
				}
			});

			if (activeJackpotId !== null) {
				if (index === 1 && connection.state === "Connected") {
					connection.invoke("Subscribe", `Jackpots_${activeJackpotId}`);
				}
				connection.off("Jackpots");
				connection.on("Jackpots", (data) => {
					const d = JSON.parse(data);
					updateJackpotBonus(d);
				});

				connection.off("WonJackpots");
				connection.on("WonJackpots", (data) => {
					const d = JSON.parse(data);

					setWonJackpotBonuses(d);
				});
			}
		});
	};

	useEffect(() => {
		setCurrentGame(GAME_TYPE.KENO);
	}, []);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		getLiveAndUpcomings();
		getLastResults();

		if (game?.id) {
			getGameRTPs(game.id);
		}

		SignalRUtils.buildConnections(handleSignalREvents, true);
		return () => {
			SignalRUtils.getConnections().forEach(con => con.getConnection().connection.off("Events"))
			SignalRUtils.removeConnections();
		};
	}, [game?.id]);

	useEffect(() => {
		if(activeJackpotId !== null) {
			getActiveJackpot(true);
		}
	}, [activeJackpotId]);

	return <Outlet context={decodedData} />;
};

/** KenoWrapper propTypes
 * PropTypes
 */
KenoWrapper.propTypes = {
	/** Redux action to get live and upcoming matches */
	getLiveAndUpcomings: PropTypes.func,
	/** Redux action to remove match from live and upcomings matches */
	removeFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to update match in live and upcomings matches */
	updateFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to add match to live and upcomings matches */
	addLiveAndUpcomings: PropTypes.func,
	/** Redux action to get last results */
	getLastResults: PropTypes.func,
	/** Redux action to add match to last results */
	addLastResult: PropTypes.func,
	/** Redux action to set current game */
	setCurrentGame: PropTypes.func,
	/** React property, decoded data taken from url */
	decodedData: PropTypes.object,
	/** Redux action to get game rtps */
	getGameRTPs: PropTypes.func,
	/** Redux action to get Active Jackpot Info */
	getActiveJackpot: PropTypes.func,
	/** Redux action to update jackpot info */
	updateJackpotBonus: PropTypes.func,
	/** Redux action, to set won Jackpots */
	setWonJackpotBonuses: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getLiveAndUpcomings: () => {
		dispatch(getLiveAndUpcomings(true));
	},
	removeFromLiveAndUpcomings: (id) => {
		dispatch(removeFromLiveAndUpcomings(id));
	},
	updateFromLiveAndUpcomings: (game) => {
		dispatch(updateFromLiveAndUpcomings(game));
	},
	addLiveAndUpcomings: (game) => {
		dispatch(addLiveAndUpcomings(game));
	},
	getLastResults: () => {
		dispatch(getLastResults(true));
	},
	addLastResult: (result) => {
		dispatch(addLastResult(result));
	},
	setCurrentGame: (type) => {
		dispatch(setCurrentGame(type));
	},
	getGameRTPs: (id) => {
		dispatch(getGameRTPs(id));
	},
	getActiveJackpot: (fromStream) => {
		dispatch(getActiveJackpot(fromStream));
	},
	updateJackpotBonus: data => {
		dispatch(updateJackpotBonus(data))
	},
	setWonJackpotBonuses: (data) => {
		dispatch(setWonJackpotBonuses(data));
	}
});

export default connect(null, mapDispatchToProps)(KenoWrapper);
