import  { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import VideoControls from "./controls";
import VideoLayers from "./layers";

import { isMobile, isIOS } from "utils/common";

import { createPlayer } from "utils/videoPlayer";

import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

import streamConfigurationType from "types/streamConfiguration.type";

let playerInstance = null;

/** Video Component */
const VideoPlayer = ({ streamConfiguration, streamProvider, streamUpdateFn, isDemo, defaultExtended, onExtend, onMinimize, showMinimize, showFullscreen, showExtend }) => {
	const { t } = useTranslation();

	const [muted, setMuted] = useState(true);
	const [paused, setPaused] = useState(false);
	const [volume, setVolume] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [showControls, setShowControls] = useState(false);
	const [started, setStarted] = useState(false);

	const [minimized, setMinimized] = useState(false);
	const [extended, setExtended] = useState(defaultExtended);

	/** Initialize Player */
	useEffect(() => {
		playerInstance = createPlayer(streamProvider, streamConfiguration, {
			elementID: "vs--player",
			languageCode: null,
			streamUpdateFn: streamUpdateFn,
			onError: (err) => setError(err),
			onVolumeChange: (volume) => setVolume(volume),
			onPause: () => setPaused(true),
			onPlay: () => {
				setPaused(false);
				setStarted(true);
			},
			onMute: (m) => setMuted(m),
			onReady: () => setLoaded(true),
			onDestroy: () => setLoaded(false),
			onControlsVisiblityChange: (v) => setShowControls(v)
		});

		return () => {
			playerInstance.destroy();
		};
	}, [streamProvider]);

	useEffect(() => {
		playerInstance.destroy();
		playerInstance.setStreamConfiguration(streamConfiguration);
		playerInstance.init();
	}, [streamConfiguration]);

	/** Function which will be called on minimize button click
	 * @function
	 * @memberOf VideoPlayer
	 */
	const handleMinimize = () => {
		const update = !minimized;
		setMinimized(update);
		onMinimize && onMinimize(update);
	};

	/** Function which will be called on enlarge/shrink button click
	 * @function
	 * @memberOf VideoPlayer
	 */
	const handleEnlargeShrink = () => {
		const update = !extended;
		setExtended(update);
		onExtend && onExtend(update);
	};

	return (
		<div
			className={
				"vs--video-container" +
				(!started ? " vs--video-container-untouched" : "") +
				(isDemo && !error ? " vs--video-container-demo" : "") +
				(error ? " vs--video-container-error" : "") +
				(paused ? " vs--video-container-paused" : "") +
				(showControls ? " vs--video-container-controls-visible" : "") +
				(minimized ? " vs--video-container-minimized" : "")
			}
			data-demo={t("common.demo")}
		>
			<div id="vs--player" data-mobile={isMobile()} />
			{!error && loaded && (
				<VideoControls
					onMuteUnmute={() => playerInstance.toggleMuteUnmute()}
					onFullscreen={() => playerInstance.onFullscreen()}
					onEnlargeShrink={handleEnlargeShrink}
					onMinimize={handleMinimize}
					isMuted={volume === 0 || muted}
					extended={extended}
					showMinimize={showMinimize}
					showFullscreen={showFullscreen}
					showExtend={showExtend}
					volume={volume}
					onVolumeChange={(volume) => playerInstance.setPlayerVolume(volume)}
				/>
			)}
			{error && <VideoLayers error={error} />}
		</div>
	);
};

/** VideoPlayer propTypes
 * PropTypes
 */
VideoPlayer.propTypes = {
	/** Stream configuration object */
	streamConfiguration: streamConfigurationType,
	/** Stream provider */
	streamProvider: PropTypes.oneOf([...Object.values(GAME_STREAM_CONFIGURATION_TYPE), 0]),
	/** Function to call to update stream */
	streamUpdateFn: PropTypes.func,
	/**Is Demo Mode */
	isDemo: PropTypes.bool,
	/** Is video enlarged By default*/
	defaultExtended: PropTypes.bool,
	/** Function which will fire on player minimize */
	onMinimize: PropTypes.func,
	/** Function which will fire on player shronk, enlarge */
	onExtend: PropTypes.func,
	/** Show minimize button on mobile */
	showMinimize: PropTypes.bool,
	/** Show fullscreen button */
	showFullscreen: PropTypes.bool,
	/** Show extend button */
	showExtend: PropTypes.bool
};

export default VideoPlayer;
