import  { memo, useMemo, useState } from "react";
import PropTypes from "prop-types";

import SizeChanger from "../sizeChanger";

import { constructArrayForGivenRange, getDisplayedItemsRange } from "utils/ui";

import { LEFT, RIGHT, DOTS, DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE, DEFAULT_PAGE } from "constants/ui.constants";

const Pagination = ({ onChange, total = 0, defaultCurrentPage = DEFAULT_PAGE, siblingCount = 1, skipPageCount = 5, arrowIconRenderer, skipIconRenderer, className = "", showSizeChanger = true, defaultPageSize, pageSizeOptions = [...DEFAULT_PAGE_SIZE_OPTIONS], renderTotal, sizeChanger, page }) => {
	/**
	 * isControlledFromOutside indicates that component
	 * should controlled from parent component.
	 * This prevents local state changes
	 */
	const isControlledFromOutside = typeof page === "number";

	const [activePage, setActivePage] = useState(() => {
		if (isControlledFromOutside) {
			return;
		}

		return defaultCurrentPage;
	});
	const [currentPageSize, setCurrentPageSize] = useState(() => {
		if (typeof defaultPageSize === "number") {
			return defaultPageSize;
		}

		if (Array.isArray(pageSizeOptions) && pageSizeOptions.length !== 0) {
			return pageSizeOptions[0];
		}

		return DEFAULT_PAGE_SIZE;
	});

	const currentPage = isControlledFromOutside ? page : activePage;
	const totalPageCount = Math.ceil(total / currentPageSize);

	const handleChange = (currentPage, currentPageSize) => {
		if (typeof onChange === "function") {
			onChange({ page: currentPage, limit: currentPageSize });
		}

		setCurrentPageSize(currentPageSize);

		if (isControlledFromOutside) {
			return;
		}

		setActivePage(currentPage);
	};

	const handlePaginationChange = (pageNumber) => {
		if (pageNumber === currentPage) {
			return;
		}

		handleChange(pageNumber, currentPageSize);
	};

	const handleSizeChangerChange = (pageSize) => {
		handleChange(DEFAULT_PAGE, pageSize);
	};

	const skipCertainCountOfPrevPages = () => {
		if (currentPage - skipPageCount < 1) {
			return handlePaginationChange(1);
		}

		handlePaginationChange(currentPage - skipPageCount);
	};

	const skipCertainCountOfNextPages = () => {
		if (currentPage + skipPageCount > totalPageCount) {
			return handlePaginationChange(totalPageCount);
		}

		handlePaginationChange(currentPage + skipPageCount);
	};

	const goToPrevPage = () => {
		if (currentPage === 1 || !totalPageCount) {
			return;
		}

		handlePaginationChange(currentPage - 1);
	};

	const goToNextPage = () => {
		if (currentPage === totalPageCount || !totalPageCount) {
			return;
		}

		handlePaginationChange(currentPage + 1);
	};

	const paginationRange = useMemo(() => {
		if (!totalPageCount) {
			return [1];
		}

		/**
		 * totalItemsToDisplay is total number of items to be displayed in pagination.
		 * (firstPage, lastPage, currentPage, siblingCount and 2*DOTS)
		 */
		const totalItemsToDisplay = 2 * siblingCount + 5;

		/**
		 * Case 1:
		 */
		if (totalItemsToDisplay >= totalPageCount) {
			return constructArrayForGivenRange({ from: 1, to: totalPageCount });
		}

		/**
		 * Calculate left and right sibling index and
		 * make sure they are within range 1 and totalPageCount
		 */
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 3;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		/**
		 * Case 2: No left dots to show, but rights dots to be shown
		 */
		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = constructArrayForGivenRange({
				from: 1,
				to: leftItemCount
			});

			return [...leftRange, DOTS, totalPageCount];
		}

		/**
		 * Case 3: No right dots to show, but left dots to be shown
		 */
		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = constructArrayForGivenRange({
				from: totalPageCount - rightItemCount + 1,
				to: totalPageCount
			});

			return [firstPageIndex, DOTS, ...rightRange];
		}

		/**
		 * Case 4: Both left and right dots to be shown
		 */
		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = constructArrayForGivenRange({
				from: leftSiblingIndex,
				to: rightSiblingIndex
			});

			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [currentPage, siblingCount, totalPageCount]);

	return (
		<div className={`vs--ui-pagination-container ${className}`}>
			<div className="vs--ui-wrapper">
				{showSizeChanger && <SizeChanger onChange={handleSizeChangerChange} pageSizeOptions={pageSizeOptions} activePageSize={currentPageSize} {...sizeChanger} />}
				{typeof renderTotal === "function" && renderTotal(total, getDisplayedItemsRange({ currentPage, currentPageSize, total }))}
			</div>
			<div className="vs--ui-wrapper">
				<div onClick={goToPrevPage} className={currentPage !== 1 && totalPageCount ? "vs--ui-inactive-item" : "vs--ui-disabled-item"}>
					{typeof arrowIconRenderer === "function" ? (
						arrowIconRenderer({
							direction: LEFT,
							disabled: currentPage === 1 || !totalPageCount
						})
					) : (
						<i className="ic_left vs--font-bigest" />
					)}
				</div>
				{paginationRange.map((item, index) => {
					if (item === DOTS) {
						return (
							<div key={index} onClick={index === 1 ? skipCertainCountOfPrevPages : skipCertainCountOfNextPages} className="vs--ui-pagination-dots">
								{typeof skipIconRenderer === "function" ? skipIconRenderer() : <i className="ic_more vs--ui-pagination-dots-icon" />}
								<div className={`vs--ui-double-arrow-icon-wrapper ${index === 1 ? "vs--ui-double-arrow-icon-left" : ""}`}>
									<i className="ic_doubleArrowRight vs--ui-double-arrow-icon" />
								</div>
							</div>
						);
					}

					return (
						<div key={index} className={item === currentPage ? "vs--ui-active-item" : "vs--ui-inactive-item"} onClick={() => handlePaginationChange(item)}>
							<p>{item}</p>
						</div>
					);
				})}
				<div onClick={goToNextPage} className={currentPage !== totalPageCount && totalPageCount ? "vs--ui-inactive-item" : "vs--ui-disabled-item"}>
					{typeof arrowIconRenderer === "function" ? (
						arrowIconRenderer({
							direction: RIGHT,
							disabled: currentPage === totalPageCount || !totalPageCount
						})
					) : (
						<i className="ic_right vs--font-bigest" />
					)}
				</div>
			</div>
		</div>
	);
};

/** Pagination propTypes
 * PropTypes
 */
Pagination.propTypes = {
	/** Total count of items */
	total: PropTypes.number,
	/** Current pagination page */
	page: PropTypes.number,
	/** Function which will fire on any change of pagination */
	onChange: PropTypes.func,
	/** Default page */
	defaultCurrentPage: PropTypes.number,
	/** Current page sibling count to be displayed */
	siblingCount: PropTypes.number,
	/** Count of pages to skip when clicking the skip icon */
	skipPageCount: PropTypes.number,
	/** Left and right arrow renderer */
	arrowIconRenderer: PropTypes.func,
	/** Left and right skip icon renderer */
	skipIconRenderer: PropTypes.func,
	/** Class to rewrite default styles of pagination */
	className: PropTypes.string,
	/** Show or hide size changer */
	showSizeChanger: PropTypes.bool,
	/** Default count of items to display per page */
	defaultPageSize: PropTypes.number,
	/** Available page sizes  */
	pageSizeOptions: PropTypes.array,
	/** Displayed items range and total items count renderer */
	renderTotal: PropTypes.func,
	/** Size changer props */
	sizeChanger: PropTypes.object
};

export default memo(Pagination);
