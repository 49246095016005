import { HIDDEN_CLASS, MEDIA_CONTROL_STATE_FULLSCREEN, MEDIA_CONTROL_STATE_MUTED, MEDIA_CONTROL_STATE_PLAYING } from "./constants.js";

const toggleStateClass = (tag, className, state) => {
	const fnName = state ? "remove" : "add";
	tag.classList[fnName](className)
}

const resizeOverlay = ({ target, tags, player }) => {
	const equals = {
		height: "100%",
		width: "100%",
	};

	if (target.videoHeight === 0 && target.videoWidth === target.videoHeight) {
		tags.playerOverlay.style.height = equals.height;
		tags.playerOverlay.style.width = equals.width;
		tags.playerOverlay.style.aspectRatio = "1 / 1";
		return;
	}

	const fullWidth = {
		height: "initial",
		width: "100%",
	};

	const fullHeight = {
		height: "100%",
		width: "initial",
	};

	const boundingClientRect = target.getBoundingClientRect()

	const vW = boundingClientRect.width;
	const vH = boundingClientRect.height;
	const oW = window.outerWidth;
	const oH = window.outerHeight;

	const sizes = (
		vH > vW || oH >= oW
			? fullWidth
			: fullHeight
	)

	tags.playerOverlay.style.height = sizes.height;
	tags.playerOverlay.style.width = sizes.width;

	tags.playerOverlay.style.aspectRatio = target.videoWidth / target.videoHeight;
};

export const playPauseListener = ({ target, eventName, tags, player }) => {
	target.addEventListener(eventName, () => {
		const state = player.media.paused;
		if (state) {
			player.play();
		} else {
			player.pause();
		}
	});
};

export const resumeHandler = ({ target, eventName, tags, player }) => {
	target[eventName] = () => {
		if (Object.hasOwn(player, "_stalling")) {
			toggleStateClass(tags.iconPosterLoader, HIDDEN_CLASS, true)
		}
		tags.iconPosterPlay.classList.add(HIDDEN_CLASS);
		tags.mediaControlPlay.classList.add(MEDIA_CONTROL_STATE_PLAYING)
		resizeOverlay({ target: tags.playerVideo, tags, player })
	};
};

export const pauseHandler = ({ target, eventName, tags, player }) => {
	target[eventName] = () => {
		tags.iconPosterPlay.classList.remove(HIDDEN_CLASS);
		tags.mediaControlPlay.classList.remove(MEDIA_CONTROL_STATE_PLAYING)
	};
};

export const showMediaControlsListener = ({
	target,
	eventName,
	tags,
	player,
}) => {
	target.addEventListener(eventName, () => {
		tags.mediaControls.classList.add("media-controls-show");
		tags.mediaControls.classList.remove("media-controls-hide");
	});
};

export const hideMediaControlsListener = ({
	target,
	eventName,
	tags,
	player,
}) => {
	target.addEventListener(eventName, () => {
		tags.mediaControls.classList.add("media-controls-hide");
		tags.mediaControls.classList.remove("media-controls-show");
	});
};

export const resizeListener = ({ target, tags, player }) => {
	
	resizeOverlay({ target, tags, player });
	const resizeObserver = new ResizeObserver(() => resizeOverlay({ target, tags, player }));

	resizeObserver.observe(target);
};



export const fullscreenListener = ({ target, tags }) => {
	toggleStateClass(target, MEDIA_CONTROL_STATE_FULLSCREEN, true)
	target.addEventListener("click", () => {
		const state = Boolean(document.fullscreenElement)
		toggleStateClass(target, MEDIA_CONTROL_STATE_FULLSCREEN, state)
		if (state) {
			document.exitFullscreen();
		} else {
			tags.vsPlayer.requestFullscreen();
		}
	});
};

export const volumeListener = ({ target, tags }) => {
	toggleStateClass(target, MEDIA_CONTROL_STATE_MUTED, false)
	target.addEventListener("click", () => {
		tags.playerVideo.muted = !tags.playerVideo.muted;
	});
};

export const volumeHandler = ({ target, tags }) => {
	target.addEventListener("volumechange", () => {
		toggleStateClass(tags.mediaControlVolume, MEDIA_CONTROL_STATE_MUTED, !target.muted)
	});
};

export const stallHandler = () => {}
export const stallListener = ({ target, tags, player }) => {
	const showLoaderEventList = ["waiting", "stalled", "error"];
	const hideLoaderEventList = ["canplay", "playing"];

	showLoaderEventList.forEach(eventName => {
		target.addEventListener(eventName, () => {
			if (!Object.hasOwn(player, "_stalling")) {
				return
			}
			toggleStateClass(tags.iconPosterLoader, HIDDEN_CLASS, true)
		});
	})

	hideLoaderEventList.forEach(eventName => {
		target.addEventListener(eventName, () => {
			if (player.playing) {
				return
			}
			toggleStateClass(tags.iconPosterLoader, HIDDEN_CLASS, false)
		});
	})
};



