import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

class VideoPlayer {
	constructor(streamConfiguration, options) {
		this.player = null;
		this.error = false;
		this.retryCount = 0;
		this.volume = 0;
		this.initialized = false;
		this.streamConfiguration = streamConfiguration;
		this.options = options;
	}

	getInstance() {
		return this.player;
	}

	setStreamConfiguration(configuration) {
		this.streamConfiguration = configuration;
	}

	setError(err) {
		this.error = err;
		this.options.onError && this.options.onError(err);
		if (err) {
			this.destroy();
		}
	}

	onPlay() {
		this.options.onPlay && this.options.onPlay();
		this.retryCount = 0;
	}

	onPause() {
		this.options.onPause && this.options.onPause();
	}

	onVolumeChange(volume) {
		this.volume = volume;
		this.options.onVolumeChange && this.options.onVolumeChange(volume);
		if (this.volume === 0) {
			this.mute();
		} else {
			this.unmute();
		}
	}

	onPlayerReady() {
		this.options.onReady && this.options.onReady();
		if (this.volume === 0) {
			this.mute();
		} else {
			this.unmute();
		}
	}

	onControlsVisiblityChange(isVisible) {
		this.options.onControlsVisiblityChange && this.options.onControlsVisiblityChange(isVisible);
	}

	reload(errorType) {
		this.destroy();
		if (this.retryCount === 5) {
			this.retryCount = 0;
			this.setError(true);
			setTimeout(() => this.reload(errorType), 600000);
		} else {
			if (errorType === "EXPIRED") {
				this.options.streamUpdateFn && this.options.streamUpdateFn(this);
			} else {
				this.init();
			}
		}
		this.retryCount++;
	}

	toggleMuteUnmute() {
		if (this.getPlayerVolume() === 0 || this.getPlayerMuted()) {
			this.unmute();
			this.setPlayerVolume(0.9);
			this.onVolumeChange(0.9);
		} else {
			this.mute();
			this.setPlayerVolume(0);
			this.onVolumeChange(0);
		}
	}

	/** Abstract Methods */
	makeConfigs() {
		return null;
	}
	init() {
		return null;
	}
	destroy() {
		return null;
	}
	mute() {
		return null;
	}
	unmute() {
		return null;
	}
	onFullscreen() {
		return null;
	}
	setPlayerVolume(v) {
		return null;
	}
	getPlayerVolume() {
		return 0;
	}
	getPlayerMuted() {
		return false;
	}
}

export default VideoPlayer;
