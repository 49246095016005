import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

import { RACING_MARKETS_GROUPS } from "constants/market.constants";

class RacingSportMarkets extends BaseMarkets {
	constructor() {
		super();
		this.overUnderArgumentPriority = [2.5, 1.5, 3.5, 2, 1, 3, 0.5, 4, 4.5, 5, 5.5];
	}

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof RacingSportMarkets
	 */
	getGroupsStructure() {
		return {
			main: [
				{ group: RACING_MARKETS_GROUPS.WINNER, title: RACING_MARKETS_GROUPS.WINNER, outcomes: "*", marketsCount: 12, type: "race" },
				{ group: RACING_MARKETS_GROUPS.PLACE, title: RACING_MARKETS_GROUPS.PLACE, outcomes: "*", marketsCount: 12, type: "race" },
				{ group: RACING_MARKETS_GROUPS.SHOW, title: RACING_MARKETS_GROUPS.SHOW, outcomes: "*", marketsCount: 12, type: "race" },
				{ group: RACING_MARKETS_GROUPS.PAIR, title: RACING_MARKETS_GROUPS.PAIR, outcomes: "*", type: "race" },
				{ group: RACING_MARKETS_GROUPS.TRIPLE, title: RACING_MARKETS_GROUPS.TRIPLE, outcomes: "*", type: "race" },
				{ group: RACING_MARKETS_GROUPS.PAIR_ANY_ORDER, title: RACING_MARKETS_GROUPS.PAIR_ANY_ORDER, outcomes: "*", type: "race" },
				{ group: RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER, title: RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER, outcomes: "*", type: "race" }
			],
			extra: [
				{ group: RACING_MARKETS_GROUPS.WINNER_NUMBER, title: RACING_MARKETS_GROUPS.WINNER_NUMBER, outcomes: ["Over", "Under"], marketsCount: 7, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: RACING_MARKETS_GROUPS.RACE_EVEN_ODD, title: RACING_MARKETS_GROUPS.RACE_EVEN_ODD, outcomes: ["Even", "Odd"], marketsCount: 1, marketCol: 2, gridBy: "row", oddBy: "col", argument: null }
			]
		};
	}

	/** Get market name from systemName
	 * @function
	 * @param {array } marketName - the market name
	 * @param {object} gameData - Event data
	 * @param {number} argument - the market argument
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	getNameForMarketGroup(marketName, outcome, argument) {
		if ([RACING_MARKETS_GROUPS.WINNER_NUMBER].includes(marketName)) {
			return "%" + outcome + "% (" + argument + ")";
		} else if ([RACING_MARKETS_GROUPS.RACE_EVEN_ODD].includes(marketName)) {
			return "%" + outcome + "%";
		} else {
			return outcome;
		}
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {object} gameData - Game Data
	 * @param {number} gameType - Current game type
	 * @returns {string}
	 * @memberof RacingSportMarkets
	 */
	makeName({ str, gameData, argument, gameType, isTicket, group, isDefaultTranslation = false }) {
		const participants = gameData?.participants || [];
		let result = str;

		const ns = isTicket ? "ticket:" : "";

		switch (true) {
			case str.includes("%Even%"):
				result = str.replace("%Even%", i18n.t(`${ns}markets.${gameType}.Even`));
				break;
			case str.includes("%Odd%"):
				result = str.replace("%Odd%", i18n.t(`${ns}markets.${gameType}.Odd`));
				break;
			case !isNaN(str):
				result = `#${participants[Number(str) - 1].number}.${participants[Number(str) - 1].name}`;
				break;
			case str.startsWith("Race "):
				result = `#${participants[Number(str.split(" ")[1]) - 1].number}.${participants[Number(str.split(" ")[1]) - 1].name}`;
				break;
			case /^[0-9]+-[0-9]+$/.test(str):
				const isNotPairAnyOrder = group !== RACING_MARKETS_GROUPS.PAIR_ANY_ORDER;

				result = `${isNotPairAnyOrder ? `${i18n.t(`${ns}markets.${gameType}.1st`)} ` : ""}#${participants[Number(str.split("-")[0]) - 1].number}.${participants[Number(str.split("-")[0]) - 1].name}/${isNotPairAnyOrder ? `${i18n.t(`${ns}markets.${gameType}.2nd`)} ` : ""}#${participants[Number(str.split("-")[1]) - 1].number}.${participants[Number(str.split("-")[1]) - 1].name}`;
				break;
			case /^[0-9]+-[0-9]+-[0-9]+$/.test(str):
				const isNotTripleAnyOrder = group !== RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER;

				result = `${isNotTripleAnyOrder ? `${i18n.t(`${ns}markets.${gameType}.1st`)} ` : ""}#${participants[Number(str.split("-")[0]) - 1].number}.${participants[Number(str.split("-")[0]) - 1].name}/${isNotTripleAnyOrder ? `${i18n.t(`${ns}markets.${gameType}.2nd`)} ` : ""}#${participants[Number(str.split("-")[1]) - 1].number}.${participants[Number(str.split("-")[1]) - 1].name}/${isNotTripleAnyOrder ? `${i18n.t(`${ns}markets.${gameType}.3rd`)} ` : ""}#${participants[Number(str.split("-")[2]) - 1].number}.${participants[Number(str.split("-")[2]) - 1].name}`;
				break;
			case str.includes("%Over%"):
				result = str.replace("%Over%", i18n.t(`${ns}markets.${gameType}.over`));
				break;
			case str.includes("%Under%"):
				result = str.replace("%Under%", i18n.t(`${ns}markets.${gameType}.under`));
				break;
			default:
				if (isDefaultTranslation) {
					result = i18n.t(`markets.${gameType}.${str}`);
				}
				break;
		}
		return result;
	}

	getRectData({ outcome, gameData, gameType, group, isTicket = false }) {
		const participants = gameData && gameData.participants ? gameData.participants : [];
		let result = [];
		const ns = isTicket ? "ticket:" : "";

		switch (true) {
			case !isNaN(outcome):
				result = [{ ...participants[Number(outcome) - 1] }];
				break;
			case outcome.startsWith("Race "):
				result = [{ ...participants[Number(outcome.split(" ")[1]) - 1] }];
				break;
			case /^[0-9]+-[0-9]+$/.test(outcome):
				const isNotPairAnyOrder = group !== RACING_MARKETS_GROUPS.PAIR_ANY_ORDER;

				result = [
					{ ...participants[Number(outcome.split("-")[0]) - 1], title: isNotPairAnyOrder? i18n.t(`${ns}markets.${gameType}.1st`) : "" },
					{ ...participants[Number(outcome.split("-")[1]) - 1], title: isNotPairAnyOrder ? i18n.t(`${ns}markets.${gameType}.2nd`) : "" }
				];
				break;
			case /^[0-9]+-[0-9]+-[0-9]+$/.test(outcome):
				const isNotTripleAnyOrder = group !== RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER;

				result = [
					{ ...participants[Number(outcome.split("-")[0]) - 1], title: isNotTripleAnyOrder ? i18n.t(`${ns}markets.${gameType}.1st`) : "" },
					{ ...participants[Number(outcome.split("-")[1]) - 1], title: isNotTripleAnyOrder ? i18n.t(`${ns}markets.${gameType}.2nd`) : "" },
					{ ...participants[Number(outcome.split("-")[2]) - 1], title: isNotTripleAnyOrder ? i18n.t(`${ns}markets.${gameType}.3rd`) : "" }
				];
				break;
			case outcome === "Even" || outcome === "Odd":
				result = [{ title: i18n.t(`${ns}markets.${gameType}.${outcome}`) }];
				break;
			case outcome === "Over" || outcome === "Under":
				result = [{ title: i18n.t(`${ns}markets.${gameType}.${outcome.toLowerCase()}`) }];
				break;
			default:
				break;
		}

		return result;
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof RacingSportMarkets
	 */
	makeWinnerMarketName(market) {
		return this.makeName({ str: market.outcome, gameData: market.gameData, argument: null, gameType: market.gameType });
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof RacingSportMarkets
	 */
	makeGroupTitle(market) {
		let res = "";
		let gr = market.group;
		const ns = market.isTicket ? "ticket:" : "";

		res += i18n.t(`${ns}markets.${market.gameType}.${gr}`) + (market.argument ? " " + market.argument : "");
		return res;
	}

	/** Make beuty text of bet
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof RacingSportMarkets
	 */
	makeBetText(bet) {
		return this.makeGroupTitle({ group: bet.group, gameType: bet.gameType }) + ": " + this.makeOddText(bet);
	}

	/** Make beuty text of odd
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof RacingSportMarkets
	 */
	makeOddText(bet) {
		return this.makeName({ str: bet.outcome, group: bet.group, gameData: bet.gameData, argument: null, gameType: bet.gameType });
	}
}

export default RacingSportMarkets;
