import axios from "axios";
import { refreshToken, logout, utils_refreshStreamAccess } from "utils/auth";

import { RESPONSE_STATE } from "constants/response.constants";
import { showError } from "utils/message";
import { setCookie, getCookie } from "utils/common";

import store from "store/configureStore";
import ApiUrls from "constants/api.constants";

import { initTranslations } from "translations/config";

import { setMaintenanceMode, setSessionFailed } from "store/actions/auth/auth.actions";

axios.defaults.withCredentials = true;

const COOKIE_NAME = "_ga_anl_cf3q";

const checkPathAndUpdateCookies = (data, responseInfo) => {
	if (getCookie(COOKIE_NAME)) {
		return;
	}
	if (data?.status !== RESPONSE_STATE.SUCCESS) {
		return;
	}
	const responseURL = responseInfo?.request?.responseURL ?? "";
	if (!responseURL) {
		return;
	}
	const url = new URL(responseURL);
	if (![ApiUrls.AUTHENTICATE, ApiUrls.LOGIN].some((path) => url.pathname.includes(path))) {
		return;
	}
	const token = data?.token ?? "";
	if (!token) {
		return;
	}
	setCookie(COOKIE_NAME, token, 365);
};

// const DOSRedirect = () => {

// 	const redirectUrl = new URL(import.meta.env.SYSTEM_API_URL);
// 	redirectUrl.pathname = ApiUrls.CHALLENGE_PROCESSING;
// 	redirectUrl.search = "url=" + window.location.href;

// 	return window.location.replace(redirectUrl);
// }

axios.interceptors.request.use(
	(config) => {
		if (config?.headers?.grant_type !== "refresh_token") {
			const token = store.getState()?.auth?.player?.token ?? null;
			if (token) {
				config.headers["Authorization"] = "Bearer " + token;
			}

			//Set default content type
			if (!config.headers["Content-Type"] || config.headers["Content-Type"] === "text/plain;charset=utf-8") {
				config.headers["Content-Type"] = "application/json;charset=utf-8";
			}

			// Handle custom params
			if (config.headers["stream"]) {
				const streamToken = store.getState()?.auth?.stream?.access?.token ?? null;
				if (streamToken) {
					config.headers["Authorization"] = "Bearer " + streamToken;
				}
			}
		} else {
			delete config.headers["grant_type"];
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	({ status, data, ...other }) => {
		checkPathAndUpdateCookies(data, other);

		if (data?.status !== RESPONSE_STATE.SUCCESS) {
			showError({ msg: data?.message ?? "", status: data?.status ?? "", resource: data?.resource ?? "" });
		}
		return { status, data };
	},
	(error) => {
		// if (!error.response) {
		// 	return DOSRedirect();
		// }
		const response = error?.response ?? {};
		const status = response.status ?? 0;
		const errorData = response.data ?? {};
		const errorConfig = response.config ?? {};
		if (status >= 400) {
			if (errorData?.status === RESPONSE_STATE.UNAUTHORIZED) {
				const config = error.response.config;

				if (status === 401) {
					const refresh_token = store.getState()?.auth?.player?.refreshToken ?? null;

					// Handle custom params
					if (config.headers["stream"]) {
						const streamRefreshToken = store.getState()?.auth?.stream?.access?.refreshToken ?? null;
						return utils_refreshStreamAccess(streamRefreshToken, config);
					}

					if (refresh_token) {
						return refreshToken(refresh_token, config);
					} else {
						logout();
					}
				} else if (status === 400) {
					if (!config.headers["stream"]) {
						logout();
					}
				}
			} else if (errorData?.status === RESPONSE_STATE.NOT_ALLOWED && errorData?.resource === "Cashier" && !(error?.response?.config?.url ?? "").includes(ApiUrls.LOGIN)) {
				logout();
			} else if (errorData?.status === RESPONSE_STATE.MAINTENANCE_MODE) {
				if (store.getState().auth.translationsLoaded) {
					store.dispatch(setSessionFailed(true));
					store.dispatch(setMaintenanceMode());
				} else {
					initTranslations(null, "en", null, "en", () => {
						store.dispatch(setSessionFailed(true));
						store.dispatch(setMaintenanceMode());
					});
				}
			} else {
				if (store.getState().auth.translationsLoaded) {
					showError({ msg: errorData?.message ?? "", status: errorData?.status ?? "", resource: errorData?.resource ?? "" });
				} else {
					initTranslations(null, "en", null, "en", () => {
						showError({ msg: errorData?.message ?? "", status: errorData?.status ?? "", resource: errorData?.resource ?? "" });
					});
				}
			}
		}
		return Promise.reject(error);
	}
);
