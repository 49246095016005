import VideoPlayer from "../../player";

class FlussonicEmbedVideoPlayer extends VideoPlayer {
	constructor(streamConfiguration, options) {
		super(streamConfiguration, options);
	}

	init() {
		if (this.initialized) return;
		if (this.streamConfiguration === null || this.streamConfiguration === undefined) return this.setError(true);
		if (!this.streamConfiguration.url) return this.setError(true);
		const element = document.getElementById(this.options.elementID);

		const iframe = document.createElement("iframe");
		iframe.src = this.streamConfiguration.url;
		iframe.width = "100%";
		iframe.height = (element.offsetWidth * 9) / 16;
		iframe.frameBorder = "0";
		iframe.scrolling = "0";
		iframe.allowFullscreen = "allowfullscreen";
		iframe.style.border = "none";
		iframe.style.position = "absolute";
		iframe.style.top = "50%";
		iframe.style.left = "50%";
		iframe.style.transform = "translate(-50%, -50%)";

		element.appendChild(iframe);
		this.setError(false);

		this.initialized = true;

		window.addEventListener("resize", this.resize);
	}

	destroy() {
		const element = document.getElementById(this.options.elementID);
		this.initialized = false;
		if (element) {
			element.innerHTML = "";
		}

		window.removeEventListener("resize", this.resize);
	}

	resize = () => {
		const element = document.getElementById(this.options.elementID);
		if (element) {
			Array.prototype.forEach.call(element.children, (child) => {
				if (child.nodeName === "IFRAME") {
					child.height = (element.offsetWidth * 9) / 16;
				}
			});
		}
	};
}

export default FlussonicEmbedVideoPlayer;
