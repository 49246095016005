import { useEffect, useState } from "react";
import "prop-types";
import moment from "moment";
import KenoFinished from "../finished";
import KenoShuffleHideShow from "../kenoShuffle/kenoShuffleHideShow";
import { GAME_STATUSES, KENO_BALLS_COUNT, KENO_WINNER_BALLS_COUNT } from "constants/game.constants";
import eventType from "types/event.type";
import { buildPathToStaticFolderOfCDN } from "utils/common";

let interval = null;
let timer1 = null;
let timer2 = null;
let timer3 = null;

/** Keno Game page Live Event */
const KenoLive = ({ event }) => {
	const [showFinished, setShowFinished] = useState(false);
	const [showLastResults, setShowLastResults] = useState(false);
	const [activeBalls, setActiveBalls] = useState([]);
	const [countDown, setCountDown] = useState(null);
	const [shuffleTransition, setShuffleTransition] = useState(false);

	const scenes = event?.gameData?.scenes ?? [];

	/** Function , to get comleted scenes numbers
	 * @function
	 * @param {number} type - clicked game type
	 * @memberOf KenoLive
	 */
	const getCompletedSceneNumbers = () => {
		return scenes
			.filter((sc) => sc.isCompleted)
			.map((sc) => sc.number);
	};

	/** Function , to check if ball in table is active
	 * @function
	 * @param {number} type - clicked game type
	 * @memberOf KenoLive
	 */
	const isActive = (num) => {
		return activeBalls.includes(num);
	};

	/** Function , to start balls animation
	 * @function
	 * @memberOf KenoLive
	 */
	const startBallsAnimation = () => {
		const numbers = scenes.map((sc) => sc.number);

		interval = setInterval(() => {
			setActiveBalls((prev) => {
				return prev.length < KENO_WINNER_BALLS_COUNT ? prev.concat([numbers[prev.length]]) : prev;
			});

			setShuffleTransition(false);
			setTimeout(() => {
				setShuffleTransition(true);
			}, 30);
		}, 1900);
	};

	useEffect(() => {
		if (activeBalls.length === KENO_WINNER_BALLS_COUNT) {
			clearInterval(interval);
			clearTimeout(timer1);
			clearTimeout(timer2);
			timer1 = setTimeout(() => {
				setShowFinished(true);
				timer2 = setTimeout(() => {
					setShowLastResults(true);
				}, 9000);
			}, 2500);
		}
	}, [activeBalls]);

	useEffect(() => {
		if (event?.status === GAME_STATUSES.CLOSE_FOR_BETTING) {
			const startTime = moment.utc(event.startTime).local().toDate();
			let diff = moment().diff(startTime, "seconds");
			diff = 0 - diff;

			if (diff > 0) {
				setCountDown(diff);
				changeCountDown(diff);
			}
		} else {
			clearTimeout(timer3);
			setCountDown(null);
			setActiveBalls(getCompletedSceneNumbers());

			if (getCompletedSceneNumbers() > 0 && getCompletedSceneNumbers() < KENO_WINNER_BALLS_COUNT) {
				setTimeout(() => {
					setShuffleTransition(true);
				}, 0);

				startBallsAnimation();
			} else if (getCompletedSceneNumbers() === KENO_WINNER_BALLS_COUNT) {
				setShowLastResults(true);
			} else {
				startBallsAnimation();
			}
		}

		return () => {
			clearInterval(interval);
			clearTimeout(timer1);
			clearTimeout(timer2);
			clearTimeout(timer3);
		};
	}, [event?.status]);

	/** Function , to update countdown
	 * @function
	 * @param {number} seconds
	 * @memberOf KenoLive
	 */
	const changeCountDown = (seconds) => {
		if (seconds > 0) {
			timer3 = setTimeout(() => {
				setCountDown(seconds - 1);
				changeCountDown(seconds - 1);
			}, 1000);
		}
	};

	const boardRows = Array.from({ length: Math.ceil(KENO_BALLS_COUNT / 10) }, (_, i) => i);
	const boardCols = Array.from({ length: 10 }, (_, i) => i + 1);

	if (countDown !== null) {
		return (
			<div className="vs--keno-live vs--flex vs--align-center vs--justify-center">
				<img
					src={
						countDown === 0
							? buildPathToStaticFolderOfCDN("cashier-images/keno-count-down-start.svg")
							: countDown === 1
								? buildPathToStaticFolderOfCDN("cashier-images/keno-count-down-1.svg")
								: countDown === 2
									? buildPathToStaticFolderOfCDN("cashier-images/keno-count-down-2.svg")
									: buildPathToStaticFolderOfCDN("cashier-images/keno-count-down-3.svg")
					}
				alt="Keno Countdown"
			/>
			</div>
		);
	}

	return !showLastResults ? (
		<div className="vs--keno-live">
			<div className={`vs--keno-live-wrapper vs--flex vs--align-center vs--justify-between ${showFinished ? "vs--keno-live-wrapper-finished" : ""}`}>
				<div className="vs--keno-board vs--pl-8 vs--pr-8 vs--pb-8 vs--pt-8">
					{boardRows.map((row) => (
						<div className="vs--keno-board-row vs--flex vs--align-center vs--mb-8" key={row}>
							{boardCols.map((col) => (
								<div className={`vs--keno-board-col vs--flex vs--align-center vs--justify-center vs--mr-8 ${isActive(row * 10 + col) ? "vs--keno-board-col-active" : ""}`} key={col}>
									<div className="vs--keno-board-col-inner vs--flex vs--align-center vs--justify-center">
										<div className="vs--keno-board-col-inner-bg vs--flex vs--align-center vs--justify-center">
											<span className="vs--title vs--font-medium">{row * 10 + col}</span>
										</div>
									</div>
								</div>
							))}
						</div>
					))}
				</div>

				<div className="vs--keno-live-shuffle-wrapper">
					<div className="vs--keno-live-count vs--flex vs--align-center vs--justify-center">
						<span className="vs--title vs--font-regular">
							<b className="vs--title vs--font-regular">{activeBalls.length}</b>
							<span className="vs--title vs--font-regular">/{KENO_WINNER_BALLS_COUNT}</span>
						</span>
					</div>
					<div className="vs--keno-live-shuffle">
						{!showFinished && <KenoShuffleHideShow />}
						<div className="vs--keno-live-shuffle-appear vs--flex vs--align-center vs--justify-center">
							<div className="vs--keno-live-shuffle-appear-inner vs--flex vs--align-center vs--justify-center">
								<div className={`vs--keno-live-shuffle-appear-inner-bg vs--flex vs--align-center vs--justify-center ${shuffleTransition ? "vs--keno-live-shuffle-appear-inner-bg-active" : ""}`}>
									<span className="vs--title vs--font-medium">{activeBalls[activeBalls.length - 1]}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="vs--keno-live-pipe vs--mt-20 vs--pl-8 vs--pt-8 vs--pb-8 vs--flex vs--align-center">
				{activeBalls.map((ball, index) => (
					<div className={`vs--keno-live-pipe-ball vs--flex vs--align-center vs--justify-center vs--mr-4 kenoBall${index}Animation`} key={ball}>
						<div className="vs--keno-live-pipe-ball-inner vs--flex vs--align-center vs--justify-center">
							<span className="vs--title vs--font-medium">{ball === 6 || ball === 9 ? `${ball} .` : ball}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	) : (
		<KenoFinished event={event} />
	);
};

/** KenoLive propTypes
 * PropTypes
 */
KenoLive.propTypes = {
	/** Current event */
	event: eventType
};

export default KenoLive;
