import  { Fragment } from "react";

import KenoComponent from "components/pages/keno";

const KenoRoute = () => {
	return (
		<Fragment>
			<KenoComponent />
		</Fragment>
	);
};

export default KenoRoute;
