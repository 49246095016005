import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { showSuccess } from "utils/message";

import { FORGOT_PASSWORD_ACTION_BEFORE, FORGOT_PASSWORD_ACTION_AFTER } from "../../actionTypes";

const setForgotPasswordActionBefore = () => ({
	type: FORGOT_PASSWORD_ACTION_BEFORE
});

const setForgotPasswordActionFinished = () => ({
	type: FORGOT_PASSWORD_ACTION_AFTER
});

export const forgotPassword = (email, onSuccess) => {
	return (dispatch) => {
		dispatch(setForgotPasswordActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FORGOT_PASSWORD}`,
			method: Methods.POST,
			data: { email }
		})
			.then(({ data: { message: msg, status } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					showSuccess(msg);
					onSuccess && onSuccess();
				}
				dispatch(setForgotPasswordActionFinished());
			})
			.catch((ex) => {
				dispatch(setForgotPasswordActionFinished());
			});
	};
};
