import AnimatedCard from "./animatedCard";
import StaticCard from "./staticCard";
import usePrevious from "hooks/usePrevious";

const FlipUnitContainer = ({ digit, shuffle, unit }) => {
	// assign digit values
	let currentDigit = Number(digit);
	let previousDigit = usePrevious(currentDigit, 0);

	// shuffle digits
	const digit1 = shuffle ? previousDigit : currentDigit;
	const digit2 = !shuffle ? previousDigit : currentDigit;

	// shuffle animations
	const animation1 = shuffle ? "fold" : "unfold";
	const animation2 = !shuffle ? "fold" : "unfold";

	return (
		<div className="vs--live-monitor-flipUnitContainer">
			<StaticCard position={"upperCard"} digit={currentDigit} />
			<StaticCard position={"lowerCard"} digit={previousDigit} />
			<AnimatedCard digit={digit1} animation={animation1} />
			<AnimatedCard digit={digit2} animation={animation2} />
			<div className="vs--live-monitor-fastening vs--live-monitor-fastening-left"></div>
			<div className="vs--live-monitor-fastening vs--live-monitor-fastening-right"></div>
		</div>
	);
};

export default FlipUnitContainer;
