import PropTypes from "prop-types";
import gameDataType from "./gameData.type";

export default PropTypes.shape({
	state: PropTypes.number,
	startTime: PropTypes.string,
	gameData: gameDataType,
	eventId: PropTypes.number,
	id: PropTypes.number
});
