import PropTypes from "prop-types";
import { connect } from "react-redux";
import { doBet } from "store/actions/betslip/betslip.actions";
import useMarketDisabledForBonus from "hooks/useMarketDisabledForBonus";
import runMarketUtilsFunction from "utils/markets/run";
import SeasonOdd from "../odd";
import Flag from "components/ui/flag";
import { GAME_TYPE, GAME_ACTIVITY_STATE, GAME_EVENT_TYPE } from "constants/game.constants";
import betType from "types/bet.type";
import sessionType from "types/session.type";

const SeasonSpecial = ({ special, bets, doBet, session, currentGameType }) => {
	const markets = special?.markets ?? [];
	const seasonId = special?.seasonId ?? null;
	const startTime = special?.startTime ?? 0;

	const { isSingleMarketDisable } = useMarketDisabledForBonus();

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf RegularMarkets
	 */
	const isOddSelected = (id) => {
		return bets.some((b) => b.oddId === id && b.eventId === seasonId);
	};

	/** Function to check if odd is disabled
	 * @function
	 * @param {object} id
	 * @returns {boolean}
	 * @memberOf RegularMarkets
	 */
	const isOddDisabled = (odd) => {
		const game = session.games.find((g) => g.type === currentGameType);

		return (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			!odd.factor ||
			odd.factor < 1.01
		);
	};

	/** Function which fires on market click
	 * @function
	 * @description adds bet to betslip bets list
	 * @param {object} odd - odd info
	 * @param {object} market - market info
	 * @memberOf RegularMarkets
	 */
	const handleOddClick = (odd, market) => {
		if (isOddDisabled(odd)) return;
		const group = runMarketUtilsFunction("makeGroupTitle", [{ group: market.group, argument: null, gameData: null, gameType: currentGameType }], currentGameType);
		doBet(odd, group, { id: seasonId, startTime: startTime, gameData: null, gameType: currentGameType, type: GAME_EVENT_TYPE.LEAGUE });
	};

	return markets.length > 0 ? (
		<div className="vs--flex">
			{markets.map((market) => {
				const oddsArr = (market?.odds ?? []).flat();
				return (
					<div key={market.group} className="vs--league-markets-col vs--flex vs--flex-col vs--flex-equal vs--markets-border">
						{oddsArr
							.sort((next, prev) => (prev.factor < next.factor ? 1 : -1))
							.reduce((acc, odd, i) => {
								if (!isOddDisabled(odd)) {
									acc.push(
										<div key={odd.outcome} className="vs--markets-table-row vs--flex vs--justify-between vs--align-center vs--pl-24 vs--pr-24" data-col={market.marketCol} data-market={market.group}>
											<div className="vs--flex vs--align-center">
												<Flag code={odd.outcome} gameType={currentGameType} />
												<span className="vs--pl-4 vs--font-big vs--font-medium">{odd.showName}</span>
											</div>
											<div>
												<SeasonOdd key={market.group + i} odd={odd} market={market} isOddSelected={isOddSelected} isOddDisabled={isOddDisabled} handleOddClick={handleOddClick} isDisabledForBonus={isSingleMarketDisable(odd.factor)} />
											</div>
										</div>
									);
								}
								return acc;
							}, [])}
					</div>
				);
			})}
		</div>
	) : null;
};

SeasonSpecial.propTypes = {
	/** Redux state property of special markets */
	special: PropTypes.object,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslip.bets,
		currentGameType: state.game.currentGameType,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	doBet: (market, group, eventInfo) => {
		dispatch(doBet(market, group, eventInfo));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonSpecial);
