import  { Fragment } from "react";

import ForgotPasswordComponent from "components/pages/forgotPassword";

const ForgotPasswordRoute = () => {
	return (
		<Fragment>
			<ForgotPasswordComponent />
		</Fragment>
	);
};

export default ForgotPasswordRoute;
