import PropTypes from "prop-types";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import Betslip from "./betslip";
import Templates from "./templates";

/* Rightbar Component */
const Rightbar = ({ showTemplates }) => {
	const { t } = useTranslation();

	return (
		<div id="vs--rightbar" className="vs--rightbar">
			<div className="vs--pr-8 vs--pb-8 vs--flex vs--flex-col">{showTemplates ? <Templates /> : <Betslip />}</div>
		</div>
	);
};

Rightbar.propTypes = {
	showTemplates: PropTypes.any
};

const mapStateToProps = (state) => {
	return {
		showTemplates: state.templates.isVisible
	};
};

export default connect(mapStateToProps, null)(Rightbar);
