import PropTypes from "prop-types";

import { TICKET_TYPE } from "constants/ticket.constants";
import { BETSLIP_MODES } from "constants/betslip.constants";

import betType from "types/bet.type";
import { BONUS_TYPE } from "constants/common.constants";
import wonJackpotType from "./wonJackpot.type";

export default PropTypes.shape({
	/** The ticket type */
	ticketType: PropTypes.oneOf(Object.values(TICKET_TYPE)),
	/** Bets array in ticket */
	bets: PropTypes.arrayOf(betType),
	/** Ticket id */
	id: PropTypes.number,
	/** Ticket betslip type single/multi */
	type: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Ticket total amount */
	totalAmount: PropTypes.number,
	/** Ticket possible win */
	possibleWin: PropTypes.number,



	additionalFactor: PropTypes.number,
	bonusId: PropTypes.string,
	bonusType: PropTypes.oneOf(Object.values(BONUS_TYPE)),
	wonJackpots: PropTypes.arrayOf(wonJackpotType)
});
