import PropTypes from "prop-types";

import gameDataType from "./gameData.type";

export default PropTypes.shape({
	key: PropTypes.string,  // Unique key generated by Frontend during addBet
	gameData: gameDataType,
	eventId: PropTypes.number,
	id: PropTypes.number,
	groupTitle: PropTypes.string,
	showName: PropTypes.string,
	factor: PropTypes.number,
	stake: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	startTime: PropTypes.string,
	bonusId: PropTypes.string
})
