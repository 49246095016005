import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tooltip from "components/ui/tooltip";
import Flag from "components/ui/flag";
import { isCupGame } from "utils/common";
import eventType from "types/event.type";
import ScenesComponent from "./scenesComponent";

const EventComponent = ({ event, expanded = true, className, ...props }) => {
	const { t } = useTranslation();
	const isCup = isCupGame(event?.gameType ?? 0);
	let additionalInfo = null;
	const team1 = event?.gameData?.team1 ?? {};
	const team2 = event?.gameData?.team2 ?? {};

	if (isCup) {
		additionalInfo = {};
		additionalInfo.team1OT = team1.overTimeGoalCount ?? 0;
		additionalInfo.team2OT = team2.overTimeGoalCount ?? 0;
		additionalInfo.OT = additionalInfo.team1OT !== null || additionalInfo.team2OT !== null;
		additionalInfo.team1PS = team1.penaltyShootoutGoalCount ?? 0;
		additionalInfo.team2PS = team2.penaltyShootoutGoalCount ?? 0;
		additionalInfo.PS = additionalInfo.team1PS !== null || additionalInfo.team2PS !== null;
	}
	const team1Goals = team1?.goalCount ?? 0;
	const team2Goals = team2?.goalCount ?? 0;

	const isTeam1Win = team1Goals !== team2Goals ? team1Goals > team2Goals : additionalInfo ? (additionalInfo.team1OT !== additionalInfo.team2OT ? additionalInfo.team1OT > additionalInfo.team2OT : additionalInfo.team1PS > additionalInfo.team2PS) : false;

	/* isTeam1Win !== !isTeam2Win, becouse data can be buget */

	const isTeam2Win = team2Goals !== team1Goals ? team2Goals > team1Goals : additionalInfo ? (additionalInfo.team2OT !== additionalInfo.team1OT ? additionalInfo.team2OT > additionalInfo.team1OT : additionalInfo.team2PS > additionalInfo.team1PS) : false;

	return (
		<div key={event.id} className={`vs--flex vs--modal-table-league-result${expanded ? " vs--pr-16" : ""}` + (className ? " " + className : "")} {...props}>
			<div className="vs--flex vs--align-center">
				<div className="vs--league-match-country vs--flex vs--align-center vs--justify-start">
					<span className={`vs--pr-4 ${expanded ? " vs--pl-16" : ""}`}>
						<Flag code={team1.flagCode ?? ""} gameType={event?.gameType} />
					</span>
					<span className="vs--league-match-results">{team1.countryCode}</span>
				</div>
				<div className="vs--pr-12 vs--pl-12">
					<span className={"vs--league-match-result vs--font-exstrasmall vs--font-medium" + (isTeam1Win ? " vs--match-winner" : "")}>{team1Goals}</span>
					<span className="vs--league-match-result vs--font-exstrasmall vs--font-medium vs--pl-4 vs--pr-4">:</span>
					<span className={"vs--league-match-result vs--font-exstrasmall vs--font-medium" + (isTeam2Win ? " vs--match-winner" : "")}>{team2Goals}</span>
				</div>
				<div className="vs--league-match-country vs--flex vs--align-center vs--justify-end">
					<span>{team2.countryCode}</span>
					<span className="vs--pr-8 vs--pl-4">
						<Flag code={team2.flagCode ?? ""} gameType={event?.gameType} />
					</span>
				</div>
			</div>
			<div className="vs--flex vs--align-center vs--flex-equal">
				{isCup && additionalInfo !== null && (additionalInfo.PS || additionalInfo.OT) ? (
					<Tooltip
						overlayClassName="vs--result-scene-tooltip vs--result-scene-tooltip-league"
						placement="left"
						title={
							<div className="vs--result-scene-tooltip-container vs--flex vs--flex-row">
								{additionalInfo.OT ? (
									<div className="vs--flex vs--flex-col vs--justify-center vs--text-center vs--result-scene-tooltip-line-ot">
										<span className="vs--font-medium vs--font-smallest vs--pb-4">{t("common.OvertimeShort")}</span>
										<span className="vs--font-regular vs--font-smallest">
											<span className={additionalInfo.team1OT > additionalInfo.team2OT ? "vs--match-winner" : ""}>{additionalInfo.team1OT}</span>
											<span className="vs--pl-8 vs--pr-8">:</span>
											<span className={additionalInfo.team2OT > additionalInfo.team1OT ? "vs--match-winner" : ""}>{additionalInfo.team2OT}</span>
										</span>
									</div>
								) : null}
								{additionalInfo.PS ? (
									<div className="vs--flex vs--flex-col vs--justify-center vs--text-center vs--result-scene-tooltip-line-ps">
										<span className="vs--font-medium vs--font-smallest vs--pb-4">{t("common.penaltyShoutoutShort")}</span>
										<span className="vs--font-regular vs--font-smallest">
											<span className={additionalInfo.team1PS > additionalInfo.team2PS ? "vs--match-winner" : ""}>{additionalInfo.team1PS}</span>
											<span className="vs--pl-8 vs--pr-8">:</span>
											<span className={additionalInfo.team2PS > additionalInfo.team1PS ? "vs--match-winner" : ""}>{additionalInfo.team2PS}</span>
										</span>
									</div>
								) : null}
							</div>
						}
					>
						<div className={"vs--mr-8 vs--modal-table-league-result-" + (additionalInfo.PS ? "ps" : "ot")}>
							<span className="vs--font-mini">{additionalInfo.PS ? t("common.penaltyShoutoutShort") : additionalInfo.OT ? t("common.OvertimeShort") : null}</span>
						</div>
					</Tooltip>
				) : null}
				{isCup && !expanded ? <span className="vs--league-event-id vs--pl-8 vs--flex vs--flex-equal">{event.id}</span> : null}
				{expanded ? (
					<>
						<span className="vs--league-event-id vs--pl-8 vs--flex vs--flex-equal">{event.id}</span>
						<ScenesComponent event={event} />
					</>
				) : null}
			</div>
		</div>
	);
};

EventComponent.propTypes = {
	event: eventType,
	expanded: PropTypes.bool,
	className: PropTypes.string
};

export default EventComponent;
