import i18n from "translations/config";

import { PARTNER_SECURITY_PASSWORD_PATTERN, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE, PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS } from "constants/common.constants";

/** Validate function for password
 * @function
 * @param {string} value - value of password field
 * @param {object} passwordSettings - password validation settings
 */
export const validatePasword = (value, passwordSettings) => {
	if (value.length < passwordSettings.passwordMinLimit || value.length > passwordSettings.passwordMaxLimit) {
		return Promise.reject(i18n.t("cashier.mustBeBetween").replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit));
	}

	if (passwordSettings.pattern?.type === PARTNER_SECURITY_PASSWORD_PATTERN.CONTAINS) {
		const contain = passwordSettings.pattern?.contain ?? [];

		for (let i = 0; i < contain.length; i++) {
			let result;

			switch (contain[i].type) {
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.UPPERCASE:
					result = /[A-Z]/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.LOWERCASE:
					result = /[a-z]/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.DIGITS:
					result = /[0-9]/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.DASH:
					result = /-/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.UNDERSCORE:
					result = /_/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.SPACE:
					result = /\s/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.SPECIAL:
					result = /[!#$%&*+,./:;=?@\\^`|~'"]/.test(value);
					break;
				case PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS.BRACKETS:
					result = /[{([<>\])}]/.test(value);
					break;
			}

			if (result && contain[i].value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.NONE) {
				return Promise.reject(i18n.t("cashier.mustNotContain").replace("%X%", i18n.t(`cashier.contain_type_${contain[i].type}`)));
			} else if (!result && contain[i].value === PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE.MUST) {
				return Promise.reject(i18n.t("cashier.mustContain").replace("%X%", i18n.t(`cashier.contain_type_${contain[i].type}`)));
			}
		}

		const alsoIncludeCharacters = passwordSettings.pattern?.alsoIncludeCharacters ?? "";

		if (alsoIncludeCharacters) {
			if (
				!alsoIncludeCharacters
					.split("")
					.every((char) => value.includes(char))
			) {
				return Promise.reject(i18n.t("cashier.mustInclude").replace("%X%", alsoIncludeCharacters));
			}
		}
	} else if (passwordSettings.pattern?.type === PARTNER_SECURITY_PASSWORD_PATTERN.PATTERN) {
		const reg = new RegExp(passwordSettings.pattern?.regularExpression ?? "", "g");
		if (!reg.test(value)) {
			return Promise.reject(i18n.t("cashier.mustMatchRegularExpression").replace("%X%", passwordSettings.pattern?.regularExpression ?? ""));
		}
	}

	return Promise.resolve();
};
